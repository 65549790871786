import * as CSM from "../../../ClickStateManager.js";

export default class Barrel extends CSM.ClickStateManager {

    videoSize = { x: 2048, y: 1024 };               // width and height of the original video material
    clickAreaPosition = { x: 930, y: 540 };         // position of Object in original Video in px
    clickAreaSize = { x: 252, y: 397 };             // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;    //Form of the clickable Area
    mediaPfad = "/spielelemente/oelfass";

    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

        /*if (this.clickCount == 1) {
            this.executeAction(this.findActionNumber(12, 1));
            this.findBestAudioForActionAndCombiActions(this.prevActions[this.prevActions.length - 1], []);
            return;
        }
        /*
        if (this.clickCount >= 2) {
            this.executeAction(this.findActionNumber(12, 2));
            this.findBestAudioForActionAndCombiActions(this.prevActions[this.prevActions.length - 1], []);
            return;
        }


//        this.executeAction(this.findActionNumber(7, 1)); return;
//        if (this.clickCount == 1 && this.objDiv.id=="mother→childbarrel01") {this.executeAction(this.findActionNumber(7, 1)); return;}
/*        if (this.clickCount == 1) {this.executeAction(this.findActionNumber(11, 1)); return;}
        if (this.clickCount == 2) {this.executeAction(this.findActionNumber(11, 3)); this.executeCombiAction(this.findCombiActionNumber(11, 1)); return;}
        if (this.clickCount == 3) {this.executeAction(this.findActionNumber(1, 1)); this.executeCombiAction(this.findCombiActionNumber(11, 3)); return;}
        if (this.clickCount == 4) {this.executeAction(this.findActionNumber(1, 1)); this.executeCombiAction(this.findCombiActionNumber(11, 4)); return;}
        if (this.clickCount == 5) {this.executeAction(this.findActionNumber(6, 7)); this.executeCombiAction(this.findCombiActionNumber(7, 5)); return;}
        if (this.clickCount == 6) {this.executeAction(this.findActionNumber(6, 8)); this.executeCombiAction(this.findCombiActionNumber(7, 6)); return;}
        if (this.clickCount == 1) {this.executeAction(this.findActionNumber(2, 1)); return;}
        if (this.clickCount == 2) {this.executeAction(this.findActionNumber(2, 2)); return;}
        if (this.clickCount == 3) {this.executeAction(this.findActionNumber(2, 3)); return;}
        if (this.clickCount == 4) {this.executeAction(this.findActionNumber(12, 3)); return;}
        if (this.clickCount == 5) {this.executeAction(this.findActionNumber(12, 4)); return;}
/*        if (this.clickCount == 6) {this.executeAction(this.findActionNumber(1, 6)); return;}
        if (this.clickCount == 7) {this.executeAction(this.findActionNumber(1, 8)); return;}
        if (this.clickCount == 8) {this.executeAction(this.findActionNumber(1, 9)); return;}
        if (this.clickCount == 9) {this.executeAction(this.findActionNumber(6, 11)); return;}
        if (this.clickCount == 10) {this.executeAction(this.findActionNumber(6, 12)); return;}
        if (this.clickCount == 11) {this.executeAction(this.findActionNumber(6, 13)); return;}
        if (this.clickCount == 12) {this.executeAction(this.findActionNumber(6, 14)); return;}
        if (this.clickCount == 13) {this.executeAction(this.findActionNumber(6, 15)); return;}
        return;*/

        this.lokalerEskalationswert = this.clickCount / 50;
        if (this.lokalerEskalationswert > 1) {this.lokalerEskalationswert = 1;}
    
        this.findPossibleNextActions();

        // Sonderregeln
        this.applyRepetitionRule(4, 3, 4);
        this.applyRepetitionRule(4, 4, 4);

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Init und Exit",             elements: 2,    probability: 0,     krassitaet: 1, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "1 Schwingen",                 elements: 4,    probability: 0.5,   krassitaet: 1, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "2 Stauchen",                  elements: 3,    probability: 0.3,   krassitaet: 1, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "3 Ungetüm drehen",            elements: 17,   probability: 0.2,   krassitaet: 2, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "4 Ungetüm drehen Random",     elements: 4,    probability: 0.2,   krassitaet: 2, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "5 Snake",                     elements: 8,    probability: 0/*.2*/,   krassitaet: 2, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "6 VolumeEmitter Schläge",     elements: 7,    probability: 0.5,   krassitaet: 1, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "7 Kindfässer",                elements: 2,    probability: 0/*.4*/,   krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "8 Fassballett",               elements: 6,    probability: 0/*.1*/,   krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "9 Langsam einfaden",          elements: 1,    probability: 0.1,   krassitaet: 1, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "10 Tear Apart",               elements: 6,    probability: 0.1,   krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "11 Tear Apart schnell",       elements: 6,    probability: 0.1,   krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "12 Ungetüm drehen Loop",      elements: 7,    probability: 0.2,   krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "13 VolumeEmitter zappeln",    elements: 7,    probability: 0.3,   krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "14 Tear together",            elements: 1,    probability: 0.3,   krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "15 Diverse Abschlüsse",       elements: 2,    probability: 2,   krassitaet: 2, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
    ];

    combiGroups = [
        {name: "0 Farbwechsel schnell",                 elements: 6,    probability: 0.3,   krassitaet: 2,  addableToGroups: [1, 2, 11],           incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "1 Farbwechsel langsam",                 elements: 6,    probability: 0.3,   krassitaet: 2,  addableToGroups: [3, 4, 5, 6, 10, 11], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "2 Blendmodes schnell helle Steeldrum",  elements: 7,    probability: 0.3,   krassitaet: 3,  addableToGroups: [1, 2, 11],           incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20], demandedSteelDrumState: "light"  },
        {name: "3 Blendmodes schnell dunkle Steeldrum", elements: 5,    probability: 0.3,   krassitaet: 3,  addableToGroups: [1, 2, 11],           incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20], demandedSteelDrumState: "dark"  },
        {name: "4 Blendmodes langsam helle Stelldrum",  elements: 7,    probability: 0.3,   krassitaet: 3,  addableToGroups: [3, 4, 5, 6, 10, 11], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20], demandedSteelDrumState: "light"  },
        {name: "5 Blendmodes langsam dunkle Stelldrum", elements: 5,    probability: 0.3,   krassitaet: 3,  addableToGroups: [3, 4, 5, 6, 10, 11], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20], demandedSteelDrumState: "dark"  },
        {name: "6 Verzerrungen schnell",                elements: 6,    probability: 0.6,   krassitaet: 1,  addableToGroups: [1],                  incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "7 Verzerrungen langsam",                elements: 6,    probability: 0.6,   krassitaet: 1,  addableToGroups: [6],                  incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "8 Rotationen",                          elements: 6,    probability: 0.6,   krassitaet: 1,  addableToGroups: [5],                  incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "9 Langsam größer werden",               elements: 6,    probability: 0.6,   krassitaet: 1,  addableToGroups: [3, 4, 5, 6, 10, 11], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "10 Schnelle 1440-Grad-Rotation",        elements: 4,    probability: 0.6,   krassitaet: 1,  addableToGroups: [11],                 incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "11 Duesenjaeger",                       elements: 6,    probability: 0.6,   krassitaet: 1,  addableToGroups: [1, 2, 10, 11],       incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "12 Blendmodes back schnell",            elements: 1,    probability: 0.6,   krassitaet: 2,  addableToGroups: [1, 2, 11],           incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "13 Blendmodes back langsam",            elements: 1,    probability: 0.6,   krassitaet: 2,  addableToGroups: [3, 4, 5, 6, 10, 11], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
    ];

    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Barrel_Swing_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_01_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Swing",
                    startTime: 0,
                    playBackRate: 2,
                    //loadOnStart: true
                }/*,
                {
                    actionType: CSM.ActionType.PlayAudio,
                    mediaTitle: "oelfasssound"
                }*/
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 9, actions: [1]},
                {group: 11, actions: [5]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 14, actions: [1]},
                {group: 15, actions: [1, 2]}
            ],
            allowIfVideoEnded: "Barrel_Snake"
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Swing",
                    startTime: 0,
                    playBackRate: 2.5
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 11, actions: [5]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 14, actions: [1, 2]},
                {group: 15, actions: [1, 2]}
            ]
        },
        
        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Swing",
                    startTime: 0,
                    playBackRate: 3
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 11, actions: [5]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 14, actions: [1, 2]},
                {group: 15, actions: [1, 2]}
            ]
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Swing",
                    startTime: 0,
                    playBackRate: 4
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 11, actions: [5]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 14, actions: [1, 2]},
                {group: 15, actions: [1, 2]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2–1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "barrel_quetsch_01"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_04_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 15, actions: [1, 2]}
            ]
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "barrel_quetsch_02"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_05_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [1]},
            ]
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "barrel_quetsch_03"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_06_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [2]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02",
                    startTime:  0 + (15 * 33.33),
                    endTime:    0 + (18 * 33.33),
                    playBackRate: 1,
                    followAction: {group: 3, action: 2}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_18_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_18_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_18_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_18_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_06_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [3]},
                {group: 3, actions: [2]}
            ]
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02",
                    startTime:  0 + (18 * 33.33),
                    endTime:    0 + (15 * 33.33),
                    playBackRate: -1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [1]}
            ]
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02",
                    startTime:  0 + (15 * 33.33),
                    endTime:    0 + (26 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_16_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_07_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [3]},
                {group: 3, actions: [2]}
            ]
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02",
                    startTime:  0 + (28 * 33.33),
                    endTime:    2000 + (4 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [3]}
            ]
        },

        // Action 3-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02",
                    startTime:  2000 + (4 * 33.33),
                    endTime:    1000 + (8 * 33.33),
                    playBackRate: -1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [4, 6, 7, 9, 10, 12]}
            ]
        },

        // Action 3-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02",
                    startTime:  1000 + (8 * 33.33),
                    endTime:    2000 + (4 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [5, 8, 11]}
            ]
        },

        // Action 3-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02",
                    startTime:  0 + (28 * 33.33),
                    endTime:    2000 + (4 * 33.33),
                    playBackRate: 2
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [3]}
            ]
        },

        // Action 3-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02",
                    startTime:  2000 + (4 * 33.33),
                    endTime:    1000 + (8 * 33.33),
                    playBackRate: -2
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [4, 6, 7, 9, 10, 12]}
            ]
        },

        // Action 3-9
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02",
                    startTime:  1000 + (8 * 33.33),
                    endTime:    2000 + (4 * 33.33),
                    playBackRate: 2
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [5, 8, 11]}
            ]
        },

        // Action 3-10
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02",
                    startTime:  0 + (28 * 33.33),
                    endTime:    2000 + (4 * 33.33),
                    playBackRate: 4
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [3]}
            ]
        },

        // Action 3-11
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02",
                    startTime:  2000 + (4 * 33.33),
                    endTime:    1000 + (15 * 33.33),
                    playBackRate: -4
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [4, 6, 7, 9, 10]}
            ]
        },

        // Action 3-12
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02",
                    startTime:  1000 + (8 * 33.33),
                    playBackRate: 4
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [5, 8, 11]}
            ]
        },

        // Action 3-13
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_03",
                    startTime:  0 + (25 * 33.33),
                    endTime:    1000 + (27 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_09_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [12]},
                {group: 4, actions: [2]}
            ]
        },

        // Action 3-14
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_03",
                    startTime:  1000 + (28 * 33.33),
                    endTime:    1000 + (22 * 33.33),
                    playBackRate: -1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [13, 17]}
            ]
        },

        // Action 3-15
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_03",
                    startTime:  1000 + (22 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [14]}
            ]
        },

        // Action 3-16
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_03",
                    startTime:  0 + (25 * 33.33),
                    endTime:    1000 + (3 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [12]},
                {group: 4, actions: [2]}
            ]
        },

        // Action 3-17
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_03",
                    startTime:  2000 + (3 * 33.33),
                    endTime:    1000 + (22 * 33.33),
                    playBackRate: -1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_16_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 9, 12, 14]},
                {group: 7, actions: [1, 2]},
                {group: 12, actions: [5, 6, 7]},
                {group: 15, actions: [1, 2]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 4 *** ///////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType:         CSM.ActionType.VideoPlayback,
                    mediaTitle:         "Barrel_Hammerfass_02",
                    startTimePrevious:  true,
                    endTimeRandom:      true,
                    endTimeMin:         1000 + (8 * 33.33),
                    endTimeMax:         2000 + (12 * 33.33),
                    playBackRate:       4
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [3, 4, 5, 6, 7, 8, 9, 10, 11]},
                {group: 4, actions: [1]}
            ]
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType:         CSM.ActionType.VideoPlayback,
                    mediaTitle:         "Barrel_Hammerfass_02",
                    startTimePrevious:  true,
                    playBackRate:       4
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 4, actions: [1]}
            ]
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType:         CSM.ActionType.VideoPlayback,
                    mediaTitle:         "Barrel_Hammerfass_03",
                    startTimePrevious:  true,
                    endTimeRandom:      true,
                    endTimeMin:         0 + (26 * 33.33),
                    endTimeMax:         1000 + (11 * 33.33),
                    playBackRate:       1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [16]},
                {group: 4, actions: [3, 4]}
            ]
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType:         CSM.ActionType.VideoPlayback,
                    mediaTitle:         "Barrel_Hammerfass_03",
                    startTimePrevious:  true,
                    endTimeRandom:      true,
                    endTimeMin:         1000 + (11 * 33.33),
                    endTimeMax:         1000 + (26 * 33.33),
                    playBackRate:       1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
//                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [14, 15]},
                {group: 4, actions: [3, 4]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 5 *** ///////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Swing",
                    startTime: 0,
                    endTime:   1000 + (17 * 33.33),
                    playBackRate: 3,
                    followAction: {group: 5, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 15, actions: [1, 2]},
            ]
        },

        // Action 5-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Swing",     // eigentlich Snake, aber das aoll ja nicht geladen werden
                    //mediaTitle: "Barrel_Snake",
                    startTime:  0 + (30 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_05_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_17_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_05_opacity"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_05_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_10_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 5, actions: [1]}
            ]
        },

        // Action 5-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Swing",     // eigentlich Snake, aber das aoll ja nicht geladen werden
                    //mediaTitle: "Barrel_Snake",
                    playBackRateCommand: "reverse",
                    startVideo: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRateCommand: "reverse",
                    newClass: "barrel_cssanim_05_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_17_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRateCommand: "reverse",
                    newClass: "barrel_cssanim_05_opacity"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRateCommand: "reverse",
                    newClass: "barrel_cssanim_05_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRateCommand: "reverse",
                    newClass: "barrel_cssanim_03_10_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 5, actions: [2, 3]}
            ],
            //forbidIfVideoEnded: "Barrel_Snake"
            forbidIfVideoEnded: "Barrel_Swing"      // eigentlich Snake
        },

        // Action 5-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Swing",     // korrekt hier
                    startTime: 0,
                    playBackRate: 6,
                    followAction: {group: 5, action: 5}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 15, actions: [1, 2]}
            ]
        },

        // Action 5-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Swing",     // eigentlich Snake, aber das aoll ja nicht geladen werden
                    //mediaTitle: "Barrel_Snake",
                    startTime:  -1, //  eigentlich: 17000 + (0 * 33.33),
                    playBackRate: -1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_17_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_17_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_17_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_11_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 5, actions: [4]}
            ]
        },

        // Action 5-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Swing",     // eigentlich Snake, aber das aoll ja nicht geladen werden
                    //mediaTitle: "Barrel_Snake",
                    playBackRateCommand: "reverse",
                    startVideo: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRateCommand: "reverse",
                    newClass: "barrel_cssanim_17_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRateCommand: "reverse",
                    newClass: "barrel_cssanim_17_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRateCommand: "reverse",
                    newClass: "barrel_cssanim_17_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRateCommand: "reverse",
                    newClass: "barrel_cssanim_03_11_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 5, actions: [5, 6]}
            ],
            //forbidIfVideoEnded: "Barrel_Snake"
            forbidIfVideoEnded: "Barrel_Swing"      // eigentlich Snake
        },

        // Action 5-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Swing",     // eigentlich Snake, aber das aoll ja nicht geladen werden
                    //mediaTitle: "Barrel_Snake",
                    playBackRate: 2,
                    startVideo: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRate: -2,
                    startAnimation: false,
                    newClass: "barrel_cssanim_17_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRate: -2,
                    startAnimation: false,
                    newClass: "barrel_cssanim_17_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRate: -2,
                    startAnimation: false,
                    newClass: "barrel_cssanim_17_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRate: 2,
                    startAnimation: false,
                    newClass: "barrel_cssanim_03_11_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 5, actions: [5, 6]}
            ],
            //forbidIfVideoEnded: "Barrel_Snake"
            forbidIfVideoEnded: "Barrel_Swing"      // eigentlich Snake
        },

        // Action 5-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Swing",     // eigentlich Snake, aber das aoll ja nicht geladen werden
                    //mediaTitle: "Barrel_Snake",
                    playBackRate: 2,
                    startVideo: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRate: -2,
                    startAnimation: false,
                    newClass: "barrel_cssanim_05_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRate: -2,
                    startAnimation: false,
                    newClass: "barrel_cssanim_17_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRate: -2,
                    startAnimation: false,
                    newClass: "barrel_cssanim_05_opacity"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRate: -2,
                    startAnimation: false,
                    newClass: "barrel_cssanim_05_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    playBackRate: 2,
                    startAnimation: false,
                    newClass: "barrel_cssanim_03_11_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 5, actions: [2, 3]}
            ],
            //forbidIfVideoEnded: "Barrel_Snake"
            forbidIfVideoEnded: "Barrel_Swing"      // eigentlich Snake
        },

        /////////////////////////////////////////// *** GRUPPE 6 *** ///////////////////////////////////////////
        // Action 6-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter",
                    startTime: 0,
                    endTime:   0 + (15 * 33.33),
                    playBackRate: 1,
                    followAction: {group: 6, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 15, actions: [1, 2]}
            ]
        },

        // Action 6-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter",
                    playBackRate: -1,
                    startVideo: false
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 6, actions: [1]}
            ]
        },

        // Action 6-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter",
                    startTime: 0,
                    endTime:   0 + (10 * 33.33),
                    playBackRate: 1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 15, actions: [1, 2]}
            ]
        },

        // Action 6-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter",
                    startTime: 0 + (10 * 33.33),
                    endTime:   0,
                    playBackRate: -1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 6, actions: [3]}
            ]
        },


        // Action 6-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter_Brodeln",
                    startTime: -1,
                    endTime: 1000 + (13 * 33.33),
                    playBackRate: -1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 15, actions: [1, 2]}
            ]
        },

        // Action 6-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter_Brodeln",
                    startTime: 1000 + (13 * 33.33),
                    playBackRate: -1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 6, actions: [5]}
            ]
        },

        // Action 6-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter_Brodeln",
                    startTime: 0,
                    playBackRate: 2
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 6, actions: [6]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 7 *** ///////////////////////////////////////////
        // Action 7-1
        {
            singleActions: [           
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Swing",
                    startTime: 0,
                    playBackRate: 2
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "barrel",
                    nrOfInstances: 4,
                    inheritMotherScale: true,
                    targetAction: {group: 7, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 15, actions: [1, 2]}
            ]
        },

        // Action 7-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement,
                    //targetObjectSelector: "#$$$→OilBarrel_01_Swing-video",
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Barrel_Swing_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_02_$i$_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_02_$i$_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_02_$i$_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_02_$i$_relativePositionY2"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 8 *** ///////////////////////////////////////////
        // Action 8-1
        {
            singleActions: [           
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Barrel_Swing_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv:	"absolutePositionX",
                    newProperties:	[{property: "transform", value: "this.fassballettRandomX()"}]            
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv:	"absolutePositionY",
                    newProperties:	[{property: "transform", value: "this.fassballettInitialY()"}]            
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv:	"absoluteScale",
                    newProperties:	[{property: "transform", value: "this.fassballettInitialScale()"}]            
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 8-2
        {
            singleActions: [
                {
                    actionType:	CSM.ActionType.ChangeAnimation,
                    animationDiv:	"rotate",
                    newProperties:	[{property: "transform", value: "this.fassballettRandomRotate()"}]            
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 8-3
        {
            singleActions: [
                {
                    actionType:	CSM.ActionType.ChangeAnimation,
                    animationDiv:	"absolutePositionY",
                    newProperties:	[{property: "transform", value: "this.fassballettMoveUp()"}]            
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 8-4
        {
            singleActions: [
                {
                    actionType:	CSM.ActionType.ChangeAnimation,
                    animationDiv:	"rotate",
                    newProperties:	[{property: "transform", value: "this.fassballettRotateBackToZero(); this.fassballettRandomRotate();"}]            
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 8-5
        {
            singleActions: [
                {
                    actionType:	CSM.ActionType.ChangeAnimation,
                    animationDiv:	"absolutePositionX",
                    newProperties:	[{property: "transform", value: "this.moveToCenter()"}]            
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 8-6
        {
            singleActions: [
                {
                    actionType:	CSM.ActionType.ChangeAnimation,
                    animationDiv:	"absolutePositionY",
                    newProperties:	[{property: "transform", value: "this.fassballettMoveUpABit()"}]            
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 9 *** ///////////////////////////////////////////
        // Action 9-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Barrel_Swing_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_01_opacity"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 10 *** ///////////////////////////////////////////
        // Action 10-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_TearApart_PreLoop_MG",
                    startTime: 0,
                    followAction: {group: 10, action: 2},
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_12_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 11, actions: [5]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 14, actions: [1, 2]},
                {group: 15, actions: [1, 2]}
            ]
        },

        // Action 10-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_TearApart_Loop_MG",
                    startTime: 0,
                    loop: true,
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_13_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 10, actions: [1]}
            ],
            addState: ["zappeln"]
        },

        // Action 10-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_TearApart_Loop_MG",
                    playBackRateCommand: "reverse",
                    loop: true,
                    startVideo: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_13_clickArea",
                    playBackRateCommand: "reverse"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 10, actions: [2, 3]}
            ]
        },

        // Action 10-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_TearApart_Loop_MG",
                    playBackRate: -12,
                    loop: false,
                    startVideo: false,
                    followAction: {group: 10, action: 5}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 10, actions: [2, 3]}
            ]
        },

        // Action 10-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_TearApart_PreLoop_MG",
                    startTime: -1,
                    playBackRate: -12
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 10, actions: [4]}
            ],
            removeState: ["zappeln"]
        },

        // Action 10-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_TearApart_PreLoop_MG",
                    startVideo: false,
                    playBackRate: -12
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 10, actions: [1]}
            ],
            removeState: ["zappeln"]
        },

        /////////////////////////////////////////// *** GRUPPE 11 *** ///////////////////////////////////////////
        // Action 11-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_TearApart_PreLoop_MG",
                    startTime: 0,
                    followAction: {group: 11, action: 2},
                    playBackRate: 4
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_12_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 11, actions: [5]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 14, actions: [1, 2]},
                {group: 15, actions: [1, 2]}
            ]
        },

        // Action 11-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_TearApart_Loop_MG",
                    startTime: 0,
                    loop: true,
                    playBackRate: 4
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_13_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 11, actions: [1]}
            ],
            addState: ["zappeln"]
        },

        // Action 11-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_TearApart_Loop_MG",
                    playBackRateCommand: "reverse",
                    loop: true,
                    startVideo: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_13_clickArea",
                    playBackRateCommand: "reverse"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 11, actions: [2, 3]}
            ]
        },

        // Action 11-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_TearApart_Loop_MG",
                    playBackRate: -12,
                    loop: false,
                    startVideo: false,
                    followAction: {group: 11, action: 5}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 11, actions: [2, 3]}
            ]
        },

        // Action 11-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_TearApart_PreLoop_MG",
                    startTime: -1,
                    playBackRate: -12
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 11, actions: [4]}
            ],
            removeState: ["zappeln"]
        },

        // Action 11-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_TearApart_PreLoop_MG",
                    startVideo: false,
                    playBackRate: -12
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 11, actions: [1]}
            ],
            removeState: ["zappeln"]
        },

        /////////////////////////////////////////// *** GRUPPE 12 *** ///////////////////////////////////////////
        // Action 12-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02_PreLoop_MG",
                    startTime:  0 + (15 * 33.33),
                    playBackRate: 1,
                    followAction: {group: 12, action: 2}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_18_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_18_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_18_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_14_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [3]},
                {group: 3, actions: [2]}
            ]
        },

        // Action 12-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02_Loop_MG",
                    playBackRate: 1,
                    loop: true
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 12, actions: [1]}
            ]
        },

        // Action 12-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_19_01_rotate"
                }

            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 12, actions: [2, 4]}
            ]
        },

        // Action 12-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_19_02_rotate"
                }

            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 12, actions: [3]}
            ]
        },

        // Action 12-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02_Loop_MG",
                    startVideo: false,
                    playBackRate: -4,
                    followAction: {group: 12, action: 6},
                    loop: false
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 12, actions: [2, 4]}
            ]
        },

        // Action 12-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_Hammerfass_02_PreLoop_MG",
                    startVideo: false,
                    playBackRate: -2,
                    followAction: {group: 12, action: 7}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 12-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Barrel_Swing_frame0001"
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 13 *** ///////////////////////////////////////////
        // Action 13-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter_PreLoop",
                    startTime: 0,
                    playBackRate: 4,
                    followAction: {group: 13, action: 2}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_10_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_10_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_10_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 15, actions: [1, 2]}
            ]
        },

        // Action 13-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter_Loop",
                    startTime: 0,
                    playBackRate: 4,
                    loop: true
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 13, actions: [1]}
            ]
        },

        // Action 13-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter_Loop",
                    playBackRateCommand: "reverse",
                    startVideo: false,
                    loop: true
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 13, actions: [2]}
            ]
        },

        // Action 13-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter_Loop",
                    playBackRate: 4,
                    loop: false,
                    startVideo: false,
                    followAction: {group: 13, action: 5}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 13, actions: [2, 3]}
            ]
        },

        // Action 13-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter_PostLoop",
                    startTime: 0,
                    playBackRate: 4
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 13, actions: [4]}
            ]
        },


       // Action 13-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter",
                    startTime: -1,
                    playBackRate: -1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 3, actions: [15]},
                {group: 5, actions: [7]},
                {group: 6, actions: [2, 4, 7]},
                {group: 7, actions: [1, 2]},
                {group: 12, actions: [5, 6, 7]},
                {group: 13, actions: [5]},
                {group: 15, actions: [1, 2]}
            ]
        },

        // Action 13-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter",
                    playBackRateCommand: "reverse",
                    startVideo: false
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 13, actions: [6, 7]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 14 *** ///////////////////////////////////////////
        // Action 14-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_TearApart_PreLoop_MG",
                    startTime: -1,
                    playBackRate: -12
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    newProperties: [{property: "transform", value: "this.randomX()"}]
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionY",
                    newProperties: [{property: "transform", value: "this.randomY()"}]
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absoluteScale",
                    newProperties: [{property: "transform", value: "this.randomScale()"}]
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [],
            removeState: ["zappeln"]
        },

        /////////////////////////////////////////// *** GRUPPE 15 *** ///////////////////////////////////////////
        // Action 15-1
        {
            singleActions: [
                {
                    actionType:         CSM.ActionType.VideoPlayback,
                    mediaTitle:         "Barrel_Hammerfass_03",
                    startTimePrevious:  true,
                    playBackRate:       1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_normal_a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_01_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_00_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_03_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 4, actions: [3, 4]}
            ]
        },       
        
        // Action 15-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Barrel_VolumeEmitter",
                    playBackRate: 6,
                    startVideo: false
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 13, actions: [7]}
            ]
        },        

    ];

    randomX() {return "translateX(" + (10 + Math.floor(Math.random()*80)) + "vw)";}
    randomY() {return "translateY(" + (10 + Math.floor(Math.random()*80)) + "vh)";}
    randomScale() {return "scale(" + (0.5 + Math.random() * 0.5) + ")";}


    fassballettX = 50;
    fassballettY = 105;
    fassballettRotate = Math.floor(Math.random()*2) - 0.5;
    fassballettRotateMax = 45;
    fassballettInitialY() {
        return "translateY(" + this.fassballettY + "vh)";
    }
    fassballettInitialScale() {
        return "scale(0.5)";
    }
    fassballettRandomX() {
        this.fassballettX = 10 + Math.floor(Math.random()*80);
        return "translateX(" + this.fassballettX + "vw)";
    }
    fassballettRandomRotate() {
        this.fassballettRotate = Math.floor(Math.random() * this.fassballettRotateMax) * Math.sign(this.fassballettRotate) * (-1);
        if (Math.abs(this.fassballettRotate) < 1) {this.fassballettRotate = 0;}
        return "rotate(" + this.fassballettRotate + "deg)";
    }
    fassballettMoveUp() {
        this.fassballettY = this.fassballettY - 4;
        return "translateY(" + this.fassballettY + "vh)"
    }
    fassballettMoveUpABit() {
        this.fassballettY = this.fassballettY - 1.5;
        return "translateY(" + this.fassballettY + "vh)"
    }
    fassballettRotateBackToZero() {
        this.fassballettRotateMax = this.fassballettRotateMax / 1.1;
    }
    moveToCenter() {
        this.fassballettX = (this.fassballettX - 50) / 1.1 + 50;
        if (Math.abs(50 - this.fassballettX) < 1) {this.fassballettX = 50;}
        return "translateX(" + this.fassballettX + "vw)";
    }

    combiClickActions: Array<CSM.ClickAction> = [
        ///////////////////////////////////////// *** COMBIGROUP 0 *** /////////////////////////////////////////
        // Action 0-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_09_01_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_09_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_09_02_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_09_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_09_03_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_09_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_09_04_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_09_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_09_05_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_09_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_09_06_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_09_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 1 *** /////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_01_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_02_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_03_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_04_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_05_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_06_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_08_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 2 *** /////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_color_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_color-burn_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_darken_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_hard-light_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_luminosity_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_multiply_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_soft-light_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 3 *** /////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_difference_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_exclusion_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_hard-light_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_lighten_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_luminosity_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 4 *** /////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_06_color_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_06_color-burn_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_06_darken_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_06_hard-light_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_06_luminosity_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_06_multiply_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_06_soft-light_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 5 *** /////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_06_difference_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_06_exclusion_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_06_hard-light_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_06_lighten_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_06_luminosity_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 6 *** /////////////////////////////////////////
        // Action 6-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_01_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_01_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_01_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_02_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_02_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_02_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_03_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_03_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_03_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_04_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_04_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_04_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_05_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_05_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_05_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_06_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_06_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_11_06_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 7 *** /////////////////////////////////////////
        // Action 7-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_01_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_01_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_01_relativeScale2"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 7-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_02_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_02_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_02_relativeScale2"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 7-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_03_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_03_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_03_relativeScale2"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 7-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_04_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_04_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_04_relativeScale2"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 7-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_05_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_05_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_05_relativeScale2"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 7-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_06_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_06_perspective"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_12_06_relativeScale2"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 8 *** /////////////////////////////////////////
        // Action 8-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_13_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 8-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_13_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 8-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_13_03_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 8-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_13_04_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 8-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_13_05_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 8-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_13_06_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 9 *** /////////////////////////////////////////
        // Action 9-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_14_01_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 9-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_14_02_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 9-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_14_03_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 9-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_14_04_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 9-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_14_05_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 9-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_14_06_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 10 *** /////////////////////////////////////////
        // Action 10-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_15_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 10-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_15_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 10-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_15_03_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 10-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_15_04_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 11 *** /////////////////////////////////////////
        // Action 11-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 11-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 11-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 11-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 11-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 11-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 12 *** /////////////////////////////////////////
        // Action 12-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_06_normal_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 13 *** /////////////////////////////////////////
        // Action 12-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "barrel_cssanim_07_normal_a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

    ]

    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);


    audioActions: Array<CSM.AudioAction> = [
        // 1 Normales Schwingen
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 7, actions: [1]}
            ],
            standardSounds: [
                {
                    sample: "barrelsoundSlow",
                    midiRange: [58, 62],
                    dynamicRange: [0.2, 0.2]
                }
            ],
            alternativeSounds: [
                {
                    sample: "barrelsoundMedium",
                    midiRange: [58, 62],
                    dynamicRange: [0.2, 0.2]
                },
                {
                    sample: "barrelsoundFast",
                    midiRange: [58, 62],
                    dynamicRange: [0.5, 0.5]
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },

        // 2 Stauchen
        {
            possibleClickActions: [
                {group: 2, actions: [1, 2, 3]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelSchlag1",
                    midiRange: [58, 62],
                    dynamicRange: [1, 1]
                },
                {
                    sample: "barrelSchlag2",
                    midiRange: [58, 62],
                    dynamicRange: [1, 1]
                },
                {
                    sample: "barrelSchlag3",
                    midiRange: [58, 62],
                    dynamicRange: [1, 1]
                },
                {
                    sample: "barrelSchlag4",
                    midiRange: [58, 62],
                    dynamicRange: [1, 1]
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },        

        // 3 Wucherschläge kurz
        {
            possibleClickActions: [
                {group: 6, actions: [1]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelWucherung1",
                    midiRange: [55, 65],
                    dynamicRange: [8, 8]
                },
                {
                    sample: "barrelWucherung2",
                    midiRange: [55, 65],
                    dynamicRange: [8, 8]
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 4 Wucherschläge länger, fade-In
        {
            possibleClickActions: [
                {group: 6, actions: [5]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelWucherung3",
                    midiRange: [55, 65],
                    dynamicRange: [4, 4],
                    fadeIn: 500,
                    fadeOut: 500,
                    endTime: 1800
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 5 Wucherschläge länger, kein fade
        {
            possibleClickActions: [
                {group: 6, actions: [6]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelWucherung3",
                    midiRange: [55, 65],
                    dynamicRange: [4, 4],
                    fadeIn: 200,
                    fadeOut: 500,
                    endTime: 1800
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 6 Wucherschläge länger, fade out
        {
            possibleClickActions: [
                {group: 6, actions: [7]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelWucherung3",
                    midiRange: [55, 65],
                    dynamicRange: [4, 4],
                    fadeIn: 200,
                    fadeOut: 1000,
                    endTime: 1800
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 7 Kräuseln
        {
            possibleClickActions: [
                {group: 3, actions: [1]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelWucherung1",
                    midiRange: [75, 85],
                    dynamicRange: [8, 8],
                    fadeOut: 200,
                    endTime: 300
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 8 Ungetümgeburt
        {
            possibleClickActions: [
                {group: 3, actions: [3]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelUngetuemGeburt",
                    midiRange: [55, 65],
                    dynamicRange: [2, 2],
                    fadeOut: 2200,
                    endTime: 2500
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 8 Ungetüm rotieren, ca. 1200ms
        {
            possibleClickActions: [
                {group: 3, actions: [4, 13]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelUngetuemRotate",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                    fadeIn: 500,
                    fadeOut: 300,
                    endTime: 1200
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 9 Ungetüm rotieren, ca. 900ms
        {
            possibleClickActions: [
                {group: 3, actions: [5, 6]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelUngetuemRotate",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                    fadeIn: 500,
                    fadeOut: 200,
                    endTime: 900
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 10 Ungetüm rotieren, ca. 500ms
        {
            possibleClickActions: [
                {group: 3, actions: [7, 8, 9, 12]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelUngetuemRotate",
                    midiRange: [55, 65],
                    dynamicRange: [1.5, 1.5],
                    fadeIn: 50,
                    fadeOut: 100,
                    endTime: 600
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 11 Ungetüm rotieren, ca. 300ms
        {
            possibleClickActions: [
                {group: 3, actions: [10, 16, 17]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelUngetuemRotate",
                    midiRange: [55, 65],
                    dynamicRange: [2.5, 2.5],
                    startTime: 50,
                    fadeIn: 50,
                    fadeOut: 50,
                    endTime: 400
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 11 Ungetüm rotieren, ca. 175ms
        {
            possibleClickActions: [
                {group: 3, actions: [11, 14]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelUngetuemRotate",
                    midiRange: [55, 65],
                    dynamicRange: [3, 3],
                    startTime: 50,
                    fadeIn: 100,
                    fadeOut: 25,
                    endTime: 325
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 12 Ungetüm rotieren, ca. 1300ms
        {
            possibleClickActions: [
                {group: 3, actions: [15]},
                {group: 12, actions: [5]},
                {group: 15, actions: [1]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelUngetuemRotate",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                    fadeIn: 500,
                    fadeOut: 3300,
                    endTime: 4300
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 13 Snake
        /*{
            possibleClickActions: [
                {group: 5, actions: [1, 4]}
            ],
            alternativeSounds: [
                {
                    sample: "Snake1",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                    fadeOut: 1400,
                    endTime: 15700
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   */

        // 14 Snake reverse
        /*{
            possibleClickActions: [
                {group: 5, actions: [3, 6, 7]}
            ],
            alternativeSounds: [
                {
                    sample: "Snake1-Akzent",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                    fadeOut: 1400,
                    endTime: 10500
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        }, */  

        // 15 Snake schnell
        /*{
            possibleClickActions: [
                {group: 5, actions: [5]}
            ],
            alternativeSounds: [
                {
                    sample: "Snake1-mit-Plong-vorne",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                    fadeOut: 1400,
                    endTime: 15700
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },  */ 

        // 16 Ungetüm rotieren, unbekannte Länger
        {
            possibleClickActions: [
                {group: 4, actions: [1, 2, 3, 4]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelUngetuemRotateKuerzer",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                    fadeIn: 500,
                    fadeOut: 3300,
                    endTime: 4300
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 17 Wucherschläge sehr kurz
        {
            possibleClickActions: [
                {group: 6, actions: [3]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelWucherung1",
                    midiRange: [70, 75],
                    dynamicRange: [8, 8]
                },
                {
                    sample: "barrelWucherung2",
                    midiRange: [70, 75],
                    dynamicRange: [8, 8]
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 17 Wucherschläge sehr kurz, mittig einsetzend
        {
            possibleClickActions: [
                {group: 6, actions: [4]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelWucherung1",
                    midiRange: [70, 75],
                    dynamicRange: [8, 8],
                    startTime: 200,
                    fadeIn: 10
                },
                {
                    sample: "barrelWucherung2",
                    midiRange: [70, 75],
                    dynamicRange: [8, 8],
                    startTime: 200,
                    fadeIn: 10
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 18 Tear Apart
        {
            possibleClickActions: [
                {group: 10, actions: [1]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelTearApart",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1]
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 19 Tear Apart klein
        {
            possibleClickActions: [
                {group: 10, actions: [3, 4, 6]},
                {group: 11, actions: [1, 3, 4, 6]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelSchlag5",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1]
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 20 Environment 14
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2, 3, 4]},
                {group: 2, actions: [1, 2, 3]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]},
                {group: 4, actions: [1, 2, 3, 4]},
                {group: 6, actions: [1, 2, 3, 4, 5, 6, 7]},
                {group: 10, actions: [1, 2, 3, 4, 5, 6]},
                {group: 11, actions: [1, 2, 3, 4, 5, 6]},
                {group: 12, actions: [1, 2, 3, 4, 5, 6, 7]},
                {group: 13, actions: [1, 2, 3, 4, 5, 6, 7]},
                {group: 14, actions: [1]},
                {group: 15, actions: [1, 2]}
            ],
            alternativeSounds: [
                {
                    sample: "../../musiker/audio/danielpling01",
                    midiRange: [40, 46],
                    dynamicRange: [0.3, 0.3],
                },
                {
                    sample: "../../musiker/audio/danielpling02",
                    midiRange: [40, 46],
                    dynamicRange: [0.3, 0.3],
                },
                {
                    sample: "../../musiker/audio/danielpling03",
                    midiRange: [40, 46],
                    dynamicRange: [0.3, 0.3],
                },
                {
                    sample: "../../musiker/audio/danielpling04",
                    midiRange: [40, 46],
                    dynamicRange: [0.3, 0.3],
                },
                {
                    sample: "../../musiker/audio/danielpling05",
                    midiRange: [40, 46],
                    dynamicRange: [0.3, 0.3],
                }
            ],
            allowedInEnvironments: [14]
        },

        // 21 Ungetüm LoopStart
        {
            possibleClickActions: [
                {group: 12, actions: [1]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelUngetuemLoopStart",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                    addToInfinitelyPlayingPlayers: true
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 22 Ungetüm LoopChange
        {
            possibleClickActions: [
                {group: 12, actions: [3, 4]}
            ],
            alternativeSounds: [
                {
                    sample: "barrelUngetuemLoopChange",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                    addToInfinitelyPlayingPlayers: true
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

        // 23 VolumeEmitter
        {
            possibleClickActions: [
                {group: 13, actions: [1, 2, 3, 4, 5, 6, 7]},
                {group: 15, actions: [2]}
            ],
            alternativeSounds: [
                {
                    sample: "../../tamtam/audio/TT pp",
                    midiRange: [65, 80],
                    dynamicRange: [0.08, 0.15]
                }
            ],
            allowedInEnvironments: [1, 2, 13, 15, 16, 17, 18, 19, 20]
        },   

                
    ]

    audioHierarchy = [
        {
            description: "Catch All",
            actionGroups: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
            combiGroups: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]                        // Bei mehreren Combigroups haben die früher genannten Priorität
        }
    ]



}