import * as CSM from "../../../ClickStateManager.js";

export default class Scheinwerfer_Lichtkegel extends CSM.ClickStateManager {

    videoSize = { x: 3350, y: 3350 };               // width and height of the original video material
    clickAreaPosition = { x: 1550, y: 1550 };       // position of Object in original Video in px
    clickAreaSize = { x: 200, y: 200 };             // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;      //Form of the clickable Area
    addAnchorPoints = true;
    mediaPfad = "/spielelemente/scheinwerfer";



    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        /*if (this.clickCount == 1) {this.executeAction(this.findActionNumber(5, 1)); return;}
        if (this.clickCount == 2) {
            this.executeAction(this.findActionNumber(6, 1));
            this.executeCombiAction(this.findCombiActionNumber(0, 1));
            return;
        }
        if (this.clickCount == 3) {
            this.executeAction(this.findActionNumber(6, 2));
            this.executeCombiAction(this.findCombiActionNumber(0, 1));
            return;
        }
        if (this.clickCount == 4) {
            this.executeAction(this.findActionNumber(6, 2));
            this.executeAction(this.findCombiActionNumber(0, 1));
            return;
        }
        if (this.clickCount == 5) {
            this.executeAction(this.findActionNumber(6, 1));
            this.executeAction(this.findCombiActionNumber(0, 1));
            return;
        }
        if (this.clickCount == 6) {this.executeAction(this.findActionNumber(5, 1)); return;}*/

        this.lokalerEskalationswert = 0.8;

        this.findPossibleNextActions();

        // Sonderregeln
        // –––

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();

    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    winkel = 0;

    randomX() {
        let x = 50;
        this.winkel = Math.floor(Math.random()*360);
        switch (true) {
            case (this.winkel < 45):                          // Zwischen 12 Uhr und 1:30 Uhr
                x = (this.winkel / 45 * 45) + 50;
                break;
            case (this.winkel >= 45 && this.winkel < 135):    // Zwischen 1:30 Uhr und 4:30 Uhr
                x = 95;
                break;
            case (this.winkel >= 135 && this.winkel < 225):   // Zwischen 4:30 Uhr und 7:30 Uhr
                x = ((225 - this.winkel) / 90 * 90) + 5;
                break;
            case (this.winkel >= 225 && this.winkel < 315):   // Zwischen 7:30 Uhr und 10:30 Uhr
                x = 5;
                break;
            case (this.winkel > 315):                         // Zwischen 10:30 Uhr und 12 Uhr
                x = ((this.winkel - 315) / 45 * 45) + 5;
                break;
        }
        //console.log("Winkel: " + this.winkel + ", x: " + x);
        return Math.floor(x).toString().padStart(2, '0');
    }
    
    randomY() {
        let y = 50;
        switch (true) {
            case (this.winkel < 45):                          // Zwischen 12 Uhr und 1:30 Uhr
                y = 5;
                break;
            case (this.winkel >= 45 && this.winkel < 135):    // Zwischen 1:30 Uhr und 4:30 Uhr
                y = ((this.winkel - 45) / 90 * 90) + 5;
                break;
            case (this.winkel >= 135 && this.winkel < 225):   // Zwischen 4:30 Uhr und 7:30 Uhr
                y = 95;
                break;
            case (this.winkel >= 225 && this.winkel < 315):   // Zwischen 7:30 Uhr und 10:30 Uhr
                y = ((315 - this.winkel) / 90 * 90) + 5;
                break;
            case (this.winkel > 315):                         // Zwischen 10:30 Uhr und 12 Uhr
                y = 5;
                break;
        }
        //console.log("Winkel: " + this.winkel + ", y: " + y);
        return Math.floor(y).toString().padStart(2, '0');
    }

    randomRotate() {
        let winkel = this.winkel;
        if (this.state.includes("zeigtNachLinks")) {
            //console.log("zeigtNachLinks")
            winkel = this.winkel - 90;
        }
        if (this.state.includes("zeigtNachRechts")) {
            //console.log("zeigtNachRechts")
            winkel = this.winkel + 90;
        }
        if (winkel < 0) {winkel = winkel + 360;}
        if (winkel > 360) {winkel = winkel - 360;}
        return Math.floor((Math.random() * 30 - 15) + winkel).toString().padStart(2, '0');
    }
    
    calculateRotation(winkel: number) {
        if (this.state.includes("zeigtNachLinks")) {
            return winkel;
        }
        if (this.state.includes("zeigtNachRechts")) {
            let rueckgabe = winkel - 180;
            if (rueckgabe < 0) {rueckgabe = rueckgabe + 360;}
            return rueckgabe;
        }
    }

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Init und Exit",               elements: 2,    probability: 0,         krassitaet: 1},
        {name: "1 Aus Tamtam Steigen Start",    elements: 2,    probability: 0,         krassitaet: 1},
        {name: "2 Aus Tamtam Steigen #1",       elements: 7,    probability: 0,         krassitaet: 1},
        {name: "3 Aus Tamtam Steigen #2",       elements: 5,    probability: 0,         krassitaet: 1},
        {name: "4 Auf Klick Drehen",            elements: 12,   probability: 0,         krassitaet: 1},
        {name: "5 Position & Winkel verändern", elements: 1,    probability: 0.85,      krassitaet: 2},
        {name: "6 z-Schwenk",                   elements: 2,    probability: 0.15,      krassitaet: 2},
        {name: "7 Will ausleuchten",            elements: 2,    probability: 0,         krassitaet: 1},
        {name: "8 Will ausleuchten follow",     elements: 2,    probability: 0,         krassitaet: 1},
        {name: "9 Wieder neu reinkommen",       elements: 1,    probability: 0,         krassitaet: 1},
    ]

    combiGroups = [
        {name: "0 Position & Winkel verändern", elements: 1,    probability: 1,     krassitaet: 1,  addableToGroups: [6],    incompatibleCombiGroups: []},
    ];

    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG_frame0001"
                }/*,
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "scheinwerfer_gehaeuse",
                    nrOfInstances: 1,
                    inheritMotherScale: true,
                    targetAction: {group: 0, action: 1}
                }*/
            ],
            allowHover: false,
            allowClick: true,
            addState: ["zeigtNachLinks"]
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG_frame0041"
                },/*
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "scheinwerfer_gehaeuse",
                    nrOfInstances: 1,
                    inheritMotherScale: true,
                    targetAction: {group: 1, action: 1}
                },*/
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_00_$i$_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_00_$i$_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_00_$i$_lichtkegel_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_00_$i$_blur"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 9000,
                    followAction: {group: -31301, action: 1}
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 8400,
                    followAction: {group: 1, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_00_$i$_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_00_$i$_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG",
                    startTime: 1333,
                    playBackRate: -1
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 500,
                    followAction: {group: 2, action: 2}
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG",
                    playBackRate: 1,
                    startVideo: false,
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 500,
                    followAction: {group: 2, action: 3}
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG",
                    playBackRate: -1,
                    startVideo: false,
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 700,
                    followAction: {group: 2, action: 4}
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG",
                    playBackRate: 0.5,
                    startVideo: false,
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 2, action: 5}
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG",
                    playBackRate: -0.75,
                    startVideo: false,
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 2, action: 6}
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 2-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG",
                    playBackRate: 1,
                    startVideo: false,
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 500,
                    followAction: {group: 2, action: 7}
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 2-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG",
                    playBackRate: -1,
                    startVideo: false,
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [],
            addState: ["zeigtNachLinks"]
        },


        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 5000,
                    followAction: {group: 3, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG",
                    startTime: 1333,
                    playBackRate: -1
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 300,
                    followAction: {group: 3, action: 3}
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG",
                    playBackRate: 1,
                    startVideo: false,
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1500,
                    followAction: {group: 3, action: 4}
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG",
                    playBackRate: -1,
                    startVideo: false,
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 700,
                    followAction: {group: 3, action: 5}
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 3-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG",
                    playBackRate: 1,
                    startVideo: false,
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [],
            addState: ["zeigtNachRechts"]
        },

        /////////////////////////////////////////// *** GRUPPE 4 *** ///////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_01_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_01_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_01_03_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_01_04_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        // Action 4-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_01_05_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        // Action 4-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_01_06_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        // Action 4-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_01_07_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        // Action 4-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_01_08_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        // Action 4-9
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_01_09_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        // Action 4-10
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_01_10_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        // Action 4-11
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_01_11_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        // Action 4-12
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_01_12_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 5 *** ///////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    constructNewClass: {
                        className: "scheinwerfer_cssanim_03_$PLACEHOLDER$_absolutePositionX",
                        placeholder: "this.randomX()"
                    }
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    constructNewClass: {
                        className: "scheinwerfer_cssanim_03_$PLACEHOLDER$_absolutePositionY",
                        placeholder: "this.randomY()"
                    }
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    constructNewClass: {
                        className: "scheinwerfer_cssanim_03_$PLACEHOLDER$_rotate",
                        placeholder: "this.randomRotate()"
                    }
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 6 *** ///////////////////////////////////////////
        // Action 6-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG",
                    startTime: 0
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: [],
            addState: ["zeigtNachRechts"],
            demandedState: ["zeigtNachLinks"],
            removeState: ["zeigtNachLinks"]
        },

        // Action 6-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG",
                    startTime: -1,
                    playBackRate: -1
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: [],
            addState: ["zeigtNachLinks"],
            demandedState: ["zeigtNachRechts"],
            removeState: ["zeigtNachRechts"],
        },

        /////////////////////////////////////////// *** GRUPPE 7 *** ///////////////////////////////////////////
        // Action 7-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_04_30_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_04_05_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    constructNewClass: {
                        className: "scheinwerfer_cssanim_04_$PLACEHOLDER$_rotate",
                        placeholder: "this.calculateRotation(240)"
                    }
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_04_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 4000,
                    followAction: {group: 8, action: -31300}
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },

        // Action 7-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_04_70_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_04_05_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    constructNewClass: {
                        className: "scheinwerfer_cssanim_04_$PLACEHOLDER$_rotate",
                        placeholder: "this.calculateRotation(300)"
                    }
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_04_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 4000,
                    followAction: {group: 8, action: -31300}
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },        

        /////////////////////////////////////////// *** GRUPPE 8 *** ///////////////////////////////////////////
        // Action 8-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    constructNewClass: {
                        className: "scheinwerfer_cssanim_05_$PLACEHOLDER$_rotate",
                        placeholder: "this.calculateRotation(240)"
                    }
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },      

        // Action 8-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    constructNewClass: {
                        className: "scheinwerfer_cssanim_05_$PLACEHOLDER$_rotate",
                        placeholder: "this.calculateRotation(300)"
                    }
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },      

        /////////////////////////////////////////// *** GRUPPE 9 *** ///////////////////////////////////////////
        // Action 9-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_01_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_01_opacity"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [],
            addState: ["zeigtNachLinks"]
        },

        // Action 9-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_02_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_02_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_02_opacity"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [],
            addState: ["zeigtNachLinks"]
        },

        // Action 9-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_03_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_03_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_03_opacity"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [],
            addState: ["zeigtNachLinks"]
        },

        // Action 9-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_04_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_04_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_04_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_04_opacity"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [],
            addState: ["zeigtNachLinks"]
        },

        // Action 9-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_05_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_05_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_05_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_05_opacity"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [],
            addState: ["zeigtNachLinks"]
        },

        // Action 9-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Scheinwerfer_Lichtkegel_MG_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_06_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_06_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_06_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_06_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "scheinwerfer_cssanim_06_06_opacity"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [],
            addState: ["zeigtNachLinks"]
        },


    ];

    combiClickActions: Array<CSM.ClickAction> = [
        ///////////////////////////////////////// *** COMBIGROUP 0 *** /////////////////////////////////////////
        // Action 0-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    constructNewClass: {
                        className: "scheinwerfer_cssanim_03_$PLACEHOLDER$_absolutePositionX",
                        placeholder: "this.randomX()"
                    }
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    constructNewClass: {
                        className: "scheinwerfer_cssanim_03_$PLACEHOLDER$_absolutePositionY",
                        placeholder: "this.randomY()"
                    }
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    constructNewClass: {
                        className: "scheinwerfer_cssanim_03_$PLACEHOLDER$_rotate",
                        placeholder: "this.randomRotate()"
                    }
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: []
        },
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.resetActions);



    audioActions: Array<CSM.AudioAction> = [
        // 1 Anschlagen leise
        {
            possibleClickActions: [
                {group: 5, actions: [1]},
                {group: 6, actions: [1, 2]}
            ],
            alternativeSounds: [
                {
                    sample: "../../oelfass/audio/barrelSchlag5",
                    midiRange: [55, 70],
                    dynamicRange: [0.2, 0.2]
                }
            ],
            allowedInEnvironments: [1]
        },   

        // 2 Anschlagen laut
        {
            possibleClickActions: [
                {group: 5, actions: [1]},
                {group: 6, actions: [1, 2]}
            ],
            alternativeSounds: [
                {
                    sample: "../../oelfass/audio/barrelSchlag5",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1]
                }
            ],
            allowedInEnvironments: [2, 13, 14, 15, 16, 17, 18, 19, 20]
        },   



                
    ]

    audioHierarchy = [
        {
            description: "Catch All",
            actionGroups: [5, 6],
            combiGroups: []                        // Bei mehreren Combigroups haben die früher genannten Priorität
        }
    ]



}