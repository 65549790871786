import * as Tone from 'tone';
import * as TeachMartinToneJS from "./TeachMartinToneJS.js";
import Mediator from "./Mediator.js";
import { TextDiv, VideoDiv } from './TextDiv.js';
//import { VideoDiv } from './VideoDiv.js';

const show = (el: HTMLElement, display?: string) => {
    //console.log("ID: " + el);
    el.style.visibility = "visible";
    if (el instanceof HTMLVideoElement || display == "display") {
        el.style.display = "block";
    }
};

const hide = (el: HTMLElement, display?: string) => {
    el.style.visibility = "hidden";
    if (el instanceof HTMLVideoElement || display == "display") {
        el.style.display = "none";
    }
};

const isVisible = (el: HTMLElement) => el.style.visibility === "visible";

export class ClickStateManager {

    dice: number;
    prevActions: number[];
    clickActions: Array<ClickAction>;
    normalClickActions: Array<ClickAction>;
    combiClickActions: Array<ClickAction>;
    resetActions: Array<ClickAction>;
    actionGroups: Array<ActionGroup>;
    combiGroups: Array<CombiGroup>;
    audioActions: Array<AudioAction>;
    audioHierarchy: Array<AudioHierarchieGruppe>;

    lastVideoPlayerOriginalStopTime: number = 0;
    lastVideoPlayerOriginalPlayBackRate: number = 1;
    lastVideoPlayer: HTMLVideoElement;
    eventListenerAppliesTo: string;
    audioPlayerNameOld: string;
    clickCount: number;
    firstClonedObjectEver: boolean = false;
    allowedCSSAnimationTypes: Array<allowedCSSAnimationType>;       // ab einem gewissen Komplexitätsgrad können bestimmte rechenaufwendige Animationstypen (z.B. skew, mix-blend-mode) gesperrt werden.

    // Init Values
    objDiv: HTMLElement;
    objDivBlend: HTMLElement;
    hasBlendAnimations: boolean;
    clickAreaForm: ClickAreaForm;
    clickAreaFormString: string;
    displaySize: Vector2 = { x: 0, y: 0 };
    height: number;
    rotation: number = 0;
    clickNode: HTMLDivElement;
    startImageURI: string;
    startImageNode: HTMLImageElement;
    addAnchorPoints: boolean = false;
    performResetActions: boolean = true;
    prohibitedResetActions: string[] = [];

    mediaTitle: string;
    videoTitles: string[] = [];
    audioTitles: string[] = [];
    activatedOnStart: boolean = false;
    players: { [id: string]: Tone.Player; } = {};
    name: string;

    animationWrapper: HTMLDivElement;
    animationWrapperBlend: HTMLDivElement;
    anchorPoints: Array<HTMLDivElement> = [];
    glowingObjects: Array<string> = [];


    active: boolean = false;
    prepared: boolean = false;
    parentObjectID: string;
    tags: string[];
    videoSize: Vector2;                     //width and height of the original video material
    clickAreaPosition: Vector2;             //position of Object in original Video in px
    clickAreaPositionScaled: Vector2 = {x: 0, y: 0};             //position of Object in original Video in px
    clickAreaSize: Vector2;  
    clickAreaSizeScaled: Vector2 =  {x: 0, y: 0};                 //Size of the clickable Form in the original video in px, radius for ellipses
    mediaPfad: string;

    possibleNextActions: Array<boolean> = [];
    possibleNextCombiGroups: Array<boolean> = [];
    possibleNextSounds: Array<soundBoolean> = [];
    lokalerEskalationswert: number = 0.5;
    state: Array<string> = [];                       // Array to describe current state of object: Zappeln, Dark etc.
    newState: Array<string> = [];                    // Array to describe current NEW state of object: Zappeln, Dark etc.
    currentScale: number = 1;
    blendingActive: boolean = false;

    animationsbaum = ["absolutePositionX", "absolutePositionY", "absoluteScale",
        "relativePositionX", "relativePositionY",
        "relativePositionX2", "relativePositionY2", "relativeScale", "relativeScale2",
        "opacity", "rotate", "rotate3d", "skew", "perspective",
        "brightness", "contrast", "blur", "saturate", "hue-rotate",
        "animation_light", "animation_glow_timer",
        "masterScale", "animation-wrapper"];

    videoList: { [id: string]: VideoInformation; } = {
        "Barrel_Swing": { hasREV: true, duration: 3033 },
        "Barrel_Hammerfass_02": { hasREV: true, duration: 3033 },
        "Barrel_Hammerfass_02_PreLoop_MG": { hasREV: true, duration: 2533 },
        "Barrel_Hammerfass_02_Loop_MG": { hasREV: true, duration: 2667 },
        "Barrel_Hammerfass_03": { hasREV: true, duration: 3033 },
        "Barrel_Snake": { hasREV: true, duration: 17300 },
        "Barrel_VolumeEmitter": { hasREV: true, duration: 10033 },
        "Barrel_VolumeEmitter_Loop": { hasREV: true, duration: 6733 },
        "Barrel_VolumeEmitter_Brodeln": { hasREV: true, duration: 3067 },
        "Barrel_VolumeEmitter_PreLoop": { hasREV: false, duration: 7000 },
        "Barrel_VolumeEmitter_PostLoop": { hasREV: false, duration: 3033 },
        "Barrel_TearApart_PreLoop_MG": { hasREV: true, duration: 15000 },
        "Barrel_TearApart_Loop_MG": { hasREV: true, duration: 23267 },
        "CarolMcGonnell_Spring": { hasREV: true, duration: 15000 },
        "DanielSeroussi_Spring_01": { frameRate: 25, duration: 20040 },
        "Farnbluete_01": { hasREV: true, duration: 10033 },
        "HoriaDumitrache_Veitstanz_01": { frameRate: 25, duration: 3480 },
        "HoriaDumitrache_Veitstanz_02": { frameRate: 25, duration: 3480 },
        "IgorSpallati_Impro": { hasREV: false, frameRate: 25, duration: 41410 },
        "logo": { hasREV: false, duration: 4033 },
        "LukasGothszalk_Veitstanz_01": { hasREV: true, frameRate: 25, duration: 7120 },
        "LukasGothszalk_Veitstanz_02": { frameRate: 25, duration: 22180 },
        "MalikaMaminova_Spring_01": { hasREV: true, frameRate: 25, duration: 7480 },
        "MalikaMaminova_Spring_02": { frameRate: 25, duration: 8080 },
        "Menupunkt_About": { hasREV: false, duration: 1033 },
        "Menupunkt_CallForScores": { hasREV: false, duration: 1033 },
        "Menupunkt_Unterstuetzen": { hasREV: false, duration: 1033 },
        "Menupunkt_Termine": { hasREV: false, duration: 1033 },
        "Menupunkt_Schwelbrandorchester": { hasREV: false, duration: 1033 },
        "Menupunkt_Trailer": { hasREV: false, duration: 1033 },
        "Menupunkt_Kontakt": { hasREV: false, duration: 1033 },
        "Menupunkt_Galerie": { hasREV: false, duration: 1033 },
        "Messerfass_00a": { hasREV: true, duration: 10000 },
        "Messerfass_01": { hasREV: true, duration: 3033 },
        "Messerfass_02": { hasREV: true, duration: 5000 },
        "Messerfass_03": { hasREV: true, duration: 6033 },
        "Messerfass_05": { hasREV: true, duration: 6000 },
        "Messerfass_06": { hasREV: true, duration: 6033 },
        "SaschaFriedl_Spring": { frameRate: 25, duration: 12880 },
        "Scheinwerfer_Lichtkegel_MG": { hasREV: true, duration: 2867 },
        "Scheinwerfer_Gehaeuse_MG": { hasREV: true, duration: 2867 },
        "Skull_01": { hasREV: true, duration: 5033 },
        "Skull_02": { hasREV: true, duration: 7033 },
        "Skull_02_01_MG": { hasREV: true, duration: 7167 },
        "Skull_02_02_MG": { hasREV: true, duration: 7867 },
        "Skull_03": { hasREV: true, duration: 7033 },
        "Skull_03_01_MG": { hasREV: true, duration: 6500 },
        "Skull_03_02_MG": { hasREV: true, duration: 3933 },
        "Skull_04": { hasREV: true, duration: 7033 },
        "Skull_06": { hasREV: true, duration: 5033 },
        "Skull_Light_Rotate": { hasREV: false, duration: 3967 },
        "Tamtam_01": { hasREV: false, duration: 2033 },
        "Tamtam_04": { hasREV: false, duration: 4033 },
        "Tamtam_05_MG": { hasREV: true, duration: 5200 },
        "Tamtam_06_MG": { hasREV: true, duration: 3200 },
        "Tamtam_07": { hasREV: true, duration: 5033 },
        "Tamtam_08": { hasREV: true, duration: 4033 },
        "Tetris_01": { hasREV: false, duration: 20000 },
        "Tetris_02": { hasREV: false, duration: 20000 },
        "Tetris_03": { hasREV: false, duration: 20000 },
        "Tetris_04": { hasREV: false, duration: 20000 },
        "Vase_01": { hasREV: true, duration: 7033 },
        "Vase_02_MG": { hasREV: true, duration: 7533 },
        "Vase_03": { hasREV: true, duration: 8033 },
        "Vase_03_01_MG": { hasREV: true, duration: 7467 },
        "Vase_03_02_MG": { hasREV: true, duration: 1867 },
        "Vase_04": { hasREV: true, duration: 8033 },
        "Vase_05": { hasREV: true, duration: 5033 },
        "Vase_05_Bombe": { hasREV: true, duration: 8033 },
        "VictorGuaita_Veitstanz": { frameRate: 25, duration: 34760 },
        "WillOvercashVeitstanz_01": { hasREV: true, frameRate: 25, duration: 20400 },
        "Wulst": { hasREV: true, duration: 10033 }
    }

    audioList = [
        { sample: "TT Metal", object: "tamtam", midiReference: 60 },
        { sample: "TT ff", object: "tamtam", midiReference: 60 },
        { sample: "TT pp", object: "tamtam", midiReference: 60 },
        { sample: "TT pp kurz", object: "tamtam", midiReference: 60 },
        { sample: "TT ratsch", object: "tamtam", midiReference: 60 },
        { sample: "TT atmo", object: "tamtam", midiReference: 60 },
        { sample: "TT orient", object: "tamtam", midiReference: 60 },
        { sample: "Chinagong", object: "tamtam", midiReference: 69 },
        { sample: "TT orient ff", object: "tamtam", midiReference: 53 },
        { sample: "TT mf fadeout", object: "tamtam", midiReference: 60 },
        { sample: "TT Comb", object: "tamtam", midiReference: 60 },
        { sample: "test1", object: "skull", midiReference: 60 },
        { sample: "test2", object: "skull", midiReference: 60 },
        { sample: "test3", object: "skull", midiReference: 60 },
        { sample: "test4", object: "skull", midiReference: 60 },
        { sample: "test5", object: "skull", midiReference: 60 },
        { sample: "test6", object: "skull", midiReference: 60 },
        { sample: "test7", object: "skull", midiReference: 60 },
        { sample: "test8", object: "skull", midiReference: 60 },
        { sample: "test9", object: "skull", midiReference: 60 },
        { sample: "test10", object: "skull", midiReference: 60 },
    ];

    mediator: Mediator;

    constructor(name: string) {
        this.name = name;
        this.prevActions = [0];
        this.clickCount = 0;
        this.createClickActions();
    }

    protected addListeners() { }

    protected prepareRelatives() {
        if (this.clickActions) {
            this.clickActions.forEach(clickAction => {
                clickAction.singleActions.forEach(singleAction => {
                    switch (singleAction.actionType) {
                        case ActionType.ActivateObject:
                            if (singleAction.targetObject != null) {
                                this.mediator.prepareObject(singleAction.targetObject);
                            }
                    }
                });
            });
        }
    }

    public show(el: HTMLElement, display: string) {
        show(el, display);
    }

    public prepareObject(sourceObject: string): void {
        // Handelt es sich um das erste geklonte Objekt einer Sorte?
        if (!this.mediator.objectTypesEverGenerated.includes(sourceObject)) {
            this.mediator.objectTypesEverGenerated.push(sourceObject);
            this.firstClonedObjectEver = true;
        }

        // Feststellen, ob es Blend-Animationen im Objekt gibt
        this.hasBlendAnimations = false;
        if (this.mediator.blendAnimationsAllowed) {
            this.clickActions.forEach(clickAction => {
                clickAction.singleActions.forEach(singleAction => {
                    let newClass = singleAction.newClass;
                    if (newClass) {
                        if (singleAction.actionType == ActionType.ChangeAnimation && newClass.slice(-1) == "a" && newClass.slice(-9) != "clickArea") {
                            this.hasBlendAnimations = true;
                        }
                    }
                })
            })
        }

        //// Normales ObjectDIV ////
        // create object div wrapper
        this.objDiv = document.createElement("div");
        this.objDiv.id = this.name;
        this.objDiv.classList.add("object");
        this.objDiv.classList.add("blendmode");
        this.objDiv.classList.add(sourceObject);

        // calculate and set positions and dimensions
        this.displaySize.x = window.visualViewport?.width || 1920;
        this.objDiv.style.width = this.displaySize.x + "px";

        this.displaySize.y = this.displaySize.x * this.videoSize.y / this.videoSize.x;
        this.objDiv.style.height = this.displaySize.y + "px";

        let positionX = 0 - (this.displaySize.x / 2);
        this.objDiv.style.left = positionX + "px";

        let positionY = 0 - (this.displaySize.y / 2);
        this.objDiv.style.top = positionY + "px";

        let objectWrapper = this.bySelector(".objectWrapper");
        if (this.parentObjectID == null && objectWrapper) {
            objectWrapper.appendChild(this.objDiv);
            //            document.body.appendChild(this.objDiv);
        } else {
            let parentObj = this.byID(this.parentObjectID);
            parentObj?.appendChild(this.objDiv);
        }

        //// Blend-ObjectDIV ////
        // create object div wrapper
        if (this.hasBlendAnimations && this.mediator.blendAnimationsAllowed) {
            this.objDivBlend = document.createElement("div");
            this.objDivBlend.id = this.name + "-blend";
            this.objDivBlend.classList.add("object");
            this.objDivBlend.classList.add("blendmode");
            this.objDivBlend.classList.add(sourceObject);

            // set positions and dimensions
            this.objDivBlend.style.width = this.displaySize.x + "px";
            this.objDivBlend.style.height = this.displaySize.y + "px";
            this.objDivBlend.style.left = positionX + "px";
            this.objDivBlend.style.top = positionY + "px";
            this.objDivBlend.style.opacity = "0";

            hide(this.objDivBlend, "display");

            if (this.parentObjectID == null && objectWrapper) {
                objectWrapper.appendChild(this.objDivBlend);
                //            document.body.appendChild(this.objDivBlend);
            } else {
                let parentObj = this.byID(this.parentObjectID);
                parentObj?.appendChild(this.objDivBlend);
            }
        }

        // calculate and set click areas
        this.clickAreaPositionScaled.x = this.clickAreaPosition.x * this.displaySize.x / this.videoSize.x;
        this.clickAreaPositionScaled.y = this.clickAreaPosition.y * this.displaySize.y / this.videoSize.y;

        this.clickAreaSizeScaled.x = this.clickAreaSize.x * this.displaySize.x / this.videoSize.x;
        this.clickAreaSizeScaled.y = this.clickAreaSize.y * this.displaySize.y / this.videoSize.y;

        this.clickNode = document.createElement("div");

        switch (this.clickAreaForm) {
            case ClickAreaForm.Circle:
                this.clickNode.classList.add("circle");
                break;
            case ClickAreaForm.Rectangle:
                this.clickNode.classList.add("rect");
                break;
            default:
                break;
        }

        this.clickNode.classList.add("clickArea");
        this.clickNode.style.top = this.clickAreaPositionScaled.y + "px";
        this.clickNode.style.left = this.clickAreaPositionScaled.x + "px";
        this.clickNode.style.width = this.clickAreaSizeScaled.x + "px";
        this.clickNode.style.height = this.clickAreaSizeScaled.y + "px";
        this.clickNode.style.transform = "rotate(" + this.rotation + "deg)";
        if (!this.mediator.allowClicking) { this.clickNode.style.display = "none"; }

        //create HTML
        this.createHTML();

        // bind() keeps the context of "this", otherwise "this" would refer to the htmlElement calling the event
        // https://stackoverflow.com/questions/44970378/es6-class-call-method-from-within-event-handler
        // important: use "mousedown" instead of "click", which fires at mouseup
        this.clickNode.addEventListener('mousedown', this.onMouseDown.bind(this));

        //this.clickNode.addEventListener('dblclick', this.onDoubleClick.bind(this));
    }

    public resize() {
        // calculate and set positions and dimensions for objdiv
        this.displaySize.x = window.visualViewport?.width || 1920;
        this.objDiv.style.width = this.displaySize.x + "px";

        this.displaySize.y = this.displaySize.x * this.videoSize.y / this.videoSize.x;
        this.objDiv.style.height = this.displaySize.y + "px";

        let positionX = 0 - (this.displaySize.x / 2);
        this.objDiv.style.left = positionX + "px";

        let positionY = 0 - (this.displaySize.y / 2);
        this.objDiv.style.top = positionY + "px";

        // set positions and dimensions for blend
        if (this.hasBlendAnimations && this.mediator.blendAnimationsAllowed) {
            this.objDivBlend.style.width = this.displaySize.x + "px";
            this.objDivBlend.style.height = this.displaySize.y + "px";
            this.objDivBlend.style.left = positionX + "px";
            this.objDivBlend.style.top = positionY + "px";
            this.objDivBlend.style.opacity = "0";
        }

        // calculate and set click areas
        this.clickAreaPositionScaled.x = this.clickAreaPosition.x * this.displaySize.x / this.videoSize.x;
        this.clickAreaPositionScaled.y = this.clickAreaPosition.y * this.displaySize.y / this.videoSize.y;

        this.clickAreaSizeScaled.x = this.clickAreaSize.x * this.displaySize.x / this.videoSize.x;
        this.clickAreaSizeScaled.y = this.clickAreaSize.y * this.displaySize.y / this.videoSize.y;

        this.clickNode.style.top = this.clickAreaPositionScaled.y + "px";
        this.clickNode.style.left = this.clickAreaPositionScaled.x + "px";
        this.clickNode.style.width = this.clickAreaSizeScaled.x + "px";
        this.clickNode.style.height = this.clickAreaSizeScaled.y + "px";

        for (let child of this.animationWrapper.children) {
            // Check if the child is an <img> or <video> element
            if (child.tagName === 'IMG' || child.tagName === 'VIDEO') {
                child.width = this.displaySize.x;
                child.height = this.displaySize.y;
            }
        }
    }

    public setMediator(mediator: Mediator): void {
        this.mediator = mediator;
    }

    protected onMouseDown() { };

    protected onDoubleClick() {
        this.executeAction(1);
    };

    protected createClickActions() { };

    rollDice(max: number) {
        return Math.ceil(Math.random() * max);
    }

    checkDice(numbers: number[]) {
        return numbers.includes(this.dice);
    }

    byID(id: string) {
        return document.getElementById(id);
    }

    public bySelector(selector: string) {
        return document.querySelector(selector);
    }


    checkPrevActions(prevActionsRange: number, actionID: number, didActionPlay: boolean, conjunction: boolean) {
        let check = conjunction;

        for (let i = 1; i <= prevActionsRange; i++) {
            if (conjunction)

                check = check && this.prevActions[this.prevActions.length - i] == actionID;

            else

                check = check || this.prevActions[this.prevActions.length - i] == actionID;

        }

        if (didActionPlay)
            return check;
        else
            return !check;
    }

    prevAction() {
        return this.prevActions[this.prevActions.length - 1];
    }

    lastxClicksContainOneOfTheseActions(prevActionsRange: number, actionIDs: Array<number>) {
        let result: boolean;
        result = false;
        for (let i = 1; i <= prevActionsRange; i++) {
            for (let actionID of actionIDs) {
                if (this.prevActions[this.prevActions.length - i] == actionID) { result = true; }
            }
        }
        return result;
    }

    lastxClicksContainOtherThanTheseActions(prevActionsRange: number, actionIDs: Array<number>) {
        let result = false;
        let zwischenergebnis: boolean;

        for (let i = 1; i <= prevActionsRange; i++) {
            zwischenergebnis = false;
            for (let actionID of actionIDs) {
                if (this.prevActions[this.prevActions.length - i] == actionID) { zwischenergebnis = true; }
            }
            if (!zwischenergebnis) { result = true; }
        }
        return result;
    }

    lastxClicksContainTheseActionsHowOften(prevActionsRange: number, actionIDs: Array<number>) {
        let result = 0;
        for (let i = 1; i <= prevActionsRange; i++) {
            for (let actionID of actionIDs) {
                if (this.prevActions[this.prevActions.length - i] == actionID) { result++; }
            }
        }
        return result;
    }

    findActionNumber(gruppenNummer: number, actionNumberInsideGroup: number) {
        let result = -1;
        let aktGroupNum = 0;
        for (let aktGroup of this.actionGroups) {
            if (aktGroupNum < gruppenNummer) {
                result = result + aktGroup.elements;
            }
            aktGroupNum++;
        }
        result = result + actionNumberInsideGroup;
        return result;
    }

    findCombiActionNumber(combiGroupNumber: number, actionNumberInsideCombiGroup: number) {
        let result = -1;
        let aktCombiGroupNum = 0;
        for (let aktCombiGroup of this.combiGroups) {
            if (aktCombiGroupNum < combiGroupNumber) {
                result = result + aktCombiGroup.elements;
            }
            aktCombiGroupNum++;
        }
        result = result + actionNumberInsideCombiGroup;
        return result;
    }

    findGroupAndInnerActionNumber(actionNumber: number) {
        let summe = -1;
        let gruppencount = 0;
        for (let aktGroup of this.actionGroups) {
            if (summe + aktGroup.elements < actionNumber) {
                summe = summe + aktGroup.elements;
            } else {
                return [gruppencount, actionNumber - summe];
            }
            gruppencount++;
        }
        return [0, 2];
    }

    findCombiGroupAndInnerCombiActionNumber(combiActionNumber: number) {
        let summe = -1;
        let gruppencount = 0;
        for (let aktCombiGroup of this.combiGroups) {
            if (summe + aktCombiGroup.elements < combiActionNumber - this.findTotalActionCount() - 1) {
                summe = summe + aktCombiGroup.elements;
            } else {
                return [gruppencount, combiActionNumber - this.findTotalActionCount() - 1 - summe];
            }
            gruppencount++;
        }
        return [0, 0];
    }

    findTotalActionCount() {
        let result = -1;
        for (let aktGroup of this.actionGroups) {
            result = result + aktGroup.elements;
        }
        return result;
    }

    findTotalCombiActionCount() {
        let result = -1;
        for (let aktGroup of this.combiGroups) {
            result = result + aktGroup.elements;
        }
        return result;
    }

    findTotalGroupCount() {
        return this.actionGroups.length;
    }

    findTotalCombiGroupCount() {
        return this.combiGroups.length;
    }

    findPossibleNextActions() {
        this.prohibitedResetActions = [];       // Immer bei einem realen Klick wieder zurücksetzen

        let lastAction = this.prevActions[this.prevActions.length - 1];
        let result: boolean;

        //console.log("LastAction: " + lastAction);
        //console.log("ClickCount: " + this.clickCount);

        this.possibleNextActions.length = this.findTotalActionCount() + 1;
        this.possibleNextActions.fill(true);

        // Wenn in der letzten Aktion impossibleNextActions spezifiziert wurden, setze sie auf "false".
        let currentImpossibleNextActions = this.clickActions[lastAction].impossibleNextActions;
        if (currentImpossibleNextActions) {
            for (let impossibleAction of currentImpossibleNextActions) {
                for (let impossibleActionWithinGroup of impossibleAction.actions) {
                    this.possibleNextActions[this.findActionNumber(impossibleAction.group, impossibleActionWithinGroup)] = false;
                }
            }
        }

        //console.log("Point 1: " + this.possibleNextActions);

        // Wenn in der letzten Aktion possibleNextActions spezifiziert wurden, setze alle anderen auf "false".
        let currentPossibleNextActions = this.clickActions[lastAction].possibleNextActions;
        if (currentPossibleNextActions) {
            for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
                result = false;
                for (let possibleAction of currentPossibleNextActions) {
                    for (let possibleActionWithinGroup of possibleAction.actions) {
                        if (currentAction == this.findActionNumber(possibleAction.group, possibleActionWithinGroup)) {
                            result = true;
                        }
                    }
                }
                if (!result) { this.possibleNextActions[currentAction] = result; }
            }
        }

        //console.log("Point 2: " + this.possibleNextActions);

        let currentImpossiblePrevActions;
        for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
            currentImpossiblePrevActions = this.clickActions[currentAction].impossiblePrevActions;
            if (currentImpossiblePrevActions) {
                for (let impossibleAction of currentImpossiblePrevActions) {
                    for (let impossibleActionWithinGroup of impossibleAction.actions) {
                        if (lastAction == this.findActionNumber(impossibleAction.group, impossibleActionWithinGroup)) {
                            this.possibleNextActions[currentAction] = false;
                        }
                    }
                }
            }
        }

        //console.log("Point 3: " + this.possibleNextActions);

        //console.log("lastActions: " + JSON.stringify(this.prevActions));
        /* Für alle Aktionen currentAction: Wenn dort possiblePrevActions definiert sind und sich die letzte
           Aktion NICHT darunter befindet, setze den Arrayeintrag für currentAction im Array possibleNextActions
           auf false */
        let currentPossiblePrevActions;
        for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
            result = true;
            currentPossiblePrevActions = this.clickActions[currentAction].possiblePrevActions;
            if (currentPossiblePrevActions) {
                result = false;
                for (let possibleAction of currentPossiblePrevActions) {
                    for (let possibleActionWithinGroup of possibleAction.actions) {
                        if (lastAction == this.findActionNumber(possibleAction.group, possibleActionWithinGroup)) {
                            result = true;
                        }
                    }
                }
            }
            if (this.clickActions[currentAction].possiblePrevActionsByVideoPosition) {
                result = false;
                for (let einzelAktionInLastAction of this.clickActions[lastAction].singleActions) {
                    for (let einzelAktionInCurrentAction of this.clickActions[currentAction].singleActions) {
                        if (einzelAktionInLastAction.endTime == einzelAktionInCurrentAction.startTime &&
                            einzelAktionInLastAction.endTime != null &&
                            einzelAktionInLastAction.mediaTitle == einzelAktionInCurrentAction.mediaTitle) {
                            result = true;
                        }
                    }
                }
                //console.log("Aktion " + currentAction + ": " + result)
            }
            if (!result) { this.possibleNextActions[currentAction] = result; }
        }
        //console.log("Point 4: " + this.possibleNextActions);

        // impossiblePrevImages
        let imageDiv = <HTMLImageElement>this.objDiv.querySelector("img");
        let imageSource = imageDiv.classList.item(0);
        if (imageSource) {
            imageSource = imageSource.replace(".webp", "");
            //console.log("replace: " + imageSource);
            //let imageSource = imageSourceWithPath.split("/").slice(-1)[0].slice(0, -5);
            let currentImpossiblePrevImages;
            for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
                currentImpossiblePrevImages = this.clickActions[currentAction].impossiblePrevImages;
                if (currentImpossiblePrevImages) {
                    for (let impossibleImage of currentImpossiblePrevImages) {
                        if (imageSource == impossibleImage) {
                            this.possibleNextActions[currentAction] = false;
                        }
                        if (impossibleImage == "SELF") {
                            for (let singleAction of this.clickActions[currentAction].singleActions) {
                                if (singleAction.actionType == ActionType.ShowImage) {
                                    let mediaTitle = singleAction.mediaTitle;
                                    if (mediaTitle) {
                                        if (mediaTitle == imageSource) {
                                            this.possibleNextActions[currentAction] = false;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // possiblePrevImages
            let currentPossiblePrevImages;
            for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
                result = true;
                currentPossiblePrevImages = this.clickActions[currentAction].possiblePrevImages;
                if (currentPossiblePrevImages) {
                    result = false;
                    for (let possibleImage of currentPossiblePrevImages) {
                        //console.log(imageSource + " <---> " + possibleImage);
                        if (imageSource == possibleImage) {
                            result = true;
                        }
                    }
                }
                if (!result) { this.possibleNextActions[currentAction] = result; }
            }
        }

        let currentGroup: number = -1;
        let allowedInEnvironments;
        let allowed: boolean;
        for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
            currentGroup = this.findGroupAndInnerActionNumber(currentAction)[0];
            allowedInEnvironments = this.actionGroups[currentGroup].allowedInEnvironments;
            allowed = allowedInEnvironments ? allowedInEnvironments.includes(this.mediator.currentEnvironment) : true;
            if (allowed == false) {
                this.possibleNextActions[currentAction] = false;
            }
        }

        var computedStyle = window.getComputedStyle(this.objDiv);

        // forbidIfPositionIs umsetzen
        var left = Math.round(Number(computedStyle.getPropertyValue('left').replace('px', '')) / window.innerWidth * 100);
        var top = Math.round(Number(computedStyle.getPropertyValue('top').replace('px', '')) / window.innerHeight * 100);

        let forbid = this.clickActions[0].forbidIfPositionIs;
        let forbidX = forbid ? forbid.x : -9999;
        let forbidY = forbid ? forbid.y : -9999;
        for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
            forbid = this.clickActions[currentAction].forbidIfPositionIs;
            forbidX = forbid ? forbid.x : -9999;
            forbidY = forbid ? forbid.y : -9999;
            if ((forbidX == left && forbidY == top) ||
                (forbidX == left && forbidY == -9999) ||
                (forbidX == -9999 && forbidY == top)) {
                this.possibleNextActions[currentAction] = false;
            }
        }
        //console.log("Point 5: " + this.possibleNextActions);

        // forbidIfTransformIs umsetzen
        for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
            if (this.clickActions[currentAction].forbidIfTransformIs == computedStyle.getPropertyValue('transform')) {
                this.possibleNextActions[currentAction] = false;
            }
        }

        // forbidIfVideoEnded umsetzen
        let forbidIfVideoEnded;
        for (let currentVideo of this.objDiv.querySelectorAll("video")) {
            if (isVisible(currentVideo)) {
                for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
                    forbidIfVideoEnded = this.clickActions[currentAction].forbidIfVideoEnded;
                    if (forbidIfVideoEnded) {
                        if (currentVideo.id.includes(forbidIfVideoEnded)) {
                            if (currentVideo.currentTime == currentVideo.duration) {
                                this.possibleNextActions[currentAction] = false;
                            }
                        }
                    }
                }
            }
        }

        // allowIfVideoEnded umsetzen
        let allowIfVideoEnded;
        for (let currentVideo of this.objDiv.querySelectorAll("video")) {
            if (isVisible(currentVideo)) {
                for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
                    allowIfVideoEnded = this.clickActions[currentAction].allowIfVideoEnded;
                    if (allowIfVideoEnded) {
                        if (currentVideo.id.includes(allowIfVideoEnded)) {
                            if (currentVideo.currentTime == currentVideo.duration) {
                                this.possibleNextActions[currentAction] = true;
                            }
                        }
                    }
                }
            }
        }

        //console.log("Point 6: " + this.possibleNextActions);

        // state überprüfen
        let demandedState;
        for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
            demandedState = this.clickActions[currentAction].demandedState ? this.clickActions[currentAction].demandedState : [];
            if (demandedState) {
                for (let singleDemandedState of demandedState) {
                    if (!this.state.includes(singleDemandedState)) {
                        this.possibleNextActions[currentAction] = false;
                    }
                }
            }
        }

        //console.log("Point 7: " + this.possibleNextActions);

        // steelDrumState überprüfen
        let demandedSteelDrumState;
        let steeldrum = <HTMLObjectElement>document.querySelector("#steeldrum");
        let steeldrumStyle = window.getComputedStyle(steeldrum);
        let steeldrumFilter = steeldrumStyle.getPropertyValue('filter');
        //console.log(steeldrumFilter.slice(0, 15));
        if (steeldrum) {
            for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
                demandedSteelDrumState = this.clickActions[currentAction].demandedSteelDrumState ? this.clickActions[currentAction].demandedSteelDrumState : "";
                if (demandedSteelDrumState == "dark" && steeldrumFilter.slice(0, 13) == "brightness(1)") {
                    this.possibleNextActions[currentAction] = false;
                }
                if (demandedSteelDrumState == "light" && steeldrumFilter.slice(0, 15) == "brightness(0.1)") {
                    this.possibleNextActions[currentAction] = false;
                }
            }
        }


        // console.log("Point 8: " + this.possibleNextActions);

        if (this.firstClonedObjectEver == true) {
            // minClickCount überprüfen
            let demandedMinClickCount;
            for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
                demandedMinClickCount = this.clickActions[currentAction].minClickCount ? this.clickActions[currentAction].minClickCount : 0;
                if (demandedMinClickCount) {
                    if (this.clickCount < demandedMinClickCount) { this.possibleNextActions[currentAction] = false; }
                }
            }
        }

        // console.log("Point 9: " + this.possibleNextActions);

        // maxClickCount überprüfen
        let demandedMaxClickCount;
        for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
            demandedMaxClickCount = this.clickActions[currentAction].maxClickCount ? this.clickActions[currentAction].maxClickCount : 999999999;
            if (this.firstClonedObjectEver == false && demandedMaxClickCount != 999999999) { demandedMaxClickCount = 0; }
            if (demandedMaxClickCount) {
                if (this.clickCount > demandedMaxClickCount) { this.possibleNextActions[currentAction] = false; }
            }
        }

        // console.log("Point 10: " + this.possibleNextActions);

        //console.log("firstClonedObjectEver: " + this.firstClonedObjectEver);
        // Wenn der Objektname die Form "mother→childtamtam01" hat und somit das erste überhaupt generierte Objekt seiner Art ist:
        //if (this.name.slice(0,12) == "mother→child" && this.name.slice(-2) == "01" && this.mediator.objects[this.name.slice(0,-2).slice(12)]) {
        if (this.firstClonedObjectEver == true) {
            // forceAtClickCounts überprüfen
            let forceAtClickCounts;
            for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
                forceAtClickCounts = this.clickActions[currentAction].forceAtClickCounts;
                if (forceAtClickCounts) {
                    for (let currentForcedClickCount of forceAtClickCounts) {
                        if (currentForcedClickCount == this.clickCount) {
                            for (let currentActionInnerLoop = currentAction; currentActionInnerLoop <= this.findTotalActionCount(); currentActionInnerLoop++) {
                                this.possibleNextActions[currentActionInnerLoop] = false;
                            }
                            this.possibleNextActions[currentAction] = true;
                        }
                    }
                }
            }

            // console.log("Point 11: " + this.possibleNextActions);

            // forbidAtClickCounts überprüfen
            let forbidAtClickCounts;
            for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
                forbidAtClickCounts = this.clickActions[currentAction].forbidAtClickCounts;
                if (forbidAtClickCounts) {
                    for (let currentForbiddenClickCount of forbidAtClickCounts) {
                        if (currentForbiddenClickCount == this.clickCount) {
                            this.possibleNextActions[currentAction] = false;
                        }
                    }
                }
            }

            // console.log("Point 12: " + this.possibleNextActions);

            // forceIfNotHappenedTillClickCount überprüfen
            let forceIfNotHappenedTillClickCount;
            let schongewesen: boolean;
            for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
                forceIfNotHappenedTillClickCount = this.clickActions[currentAction].forceIfNotHappenedTillClickCount;
                if (forceIfNotHappenedTillClickCount) {
                    if (forceIfNotHappenedTillClickCount == this.mediator.globalClickCount) {
                        schongewesen = false;
                        for (let i = 0; i < this.prevActions.length; i++) {
                            //console.log("Vergleiche: " + this.prevActions[i] + " mit " + currentAction);
                            if (this.prevActions[i] == currentAction) {
                                schongewesen = true;
                            }
                        }
                        //console.log("schongewesen: " + schongewesen);
                        if (schongewesen == false) {
                            for (let currentActionInnerLoop = 0; currentActionInnerLoop <= this.findTotalActionCount(); currentActionInnerLoop++) {
                                this.possibleNextActions[currentActionInnerLoop] = false;
                            }
                            this.possibleNextActions[currentAction] = true;
                        }
                    }
                }
            }
        }

        // console.log("Point 13: " + this.possibleNextActions);

        /*let forceIfNotChromeBrowserIsUsed;
        for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
            forceIfNotChromeBrowserIsUsed = this.clickActions[currentAction].forceIfNotChromeBrowserIsUsed;
            if (forceIfNotChromeBrowserIsUsed && this.mediator.chrome == false) {
                for (let currentActionInnerLoop = 0; currentActionInnerLoop <= this.findTotalActionCount(); currentActionInnerLoop++) {
                    this.possibleNextActions[currentActionInnerLoop] = false;
                }
                this.possibleNextActions[currentAction] = true;
            }
        }*/

        // console.log("Point 14: " + this.possibleNextActions);

        // Prüfen, ob Videos geladen sind
        for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
            for (let singleAction of this.clickActions[currentAction].singleActions) {
                if (singleAction.actionType == ActionType.VideoPlayback) {
                    let mediaTitle = singleAction.mediaTitle;
                    if (mediaTitle) {
                        let mediaTitleMitPfad = "media" + this.mediaPfad + "/video/" + singleAction.mediaTitle + ".webm";
                        if (!this.mediator.mediaList[mediaTitleMitPfad].blobURL) {
                            //console.log(mediaTitle + " ist noch nicht geladen!");
                            this.possibleNextActions[currentAction] = false;
                        }
                    }
                }
            }
        }

        // exclusiveExtensions überprüfen

        let extension = this.mediator.returnVideoExtensionForCurrentBrowser();

        for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
            for (let singleAction of this.clickActions[currentAction].singleActions) {
                if (singleAction.actionType == ActionType.VideoPlayback && singleAction.exclusiveExtensions) {
                    if (!singleAction.exclusiveExtensions.includes(extension)) {
                        this.possibleNextActions[currentAction] = false;
                    }
                }
            }
        }

        //console.log("Point 15: " + this.possibleNextActions);
    }

    findPossibleNextCombiGroups() {
        let currentAction = this.prevActions[this.prevActions.length - 1];
        let lastAction = this.prevActions[this.prevActions.length - 2];
        let result: boolean;

        this.possibleNextCombiGroups.length = this.findTotalCombiGroupCount();
        this.possibleNextCombiGroups.fill(true);

        let groupOfCurrentAction = this.findGroupAndInnerActionNumber(currentAction)[0];
        for (let combiGroup = 0; combiGroup < this.combiGroups.length; combiGroup++) {
            if (!this.combiGroups[combiGroup].addableToGroups.includes(groupOfCurrentAction)) {
                this.possibleNextCombiGroups[combiGroup] = false;
            }
        }

        let allowedInEnvironments;
        let allowed: boolean;
        for (let combiGroup = 0; combiGroup < this.combiGroups.length; combiGroup++) {
            allowedInEnvironments = this.combiGroups[combiGroup].allowedInEnvironments;
            allowed = allowedInEnvironments ? allowedInEnvironments.includes(this.mediator.currentEnvironment) : true;
            if (allowed == false) {
                this.possibleNextCombiGroups[combiGroup] = false;
            }
        }

        // steelDrumState überprüfen
        let demandedSteelDrumState;
        let steeldrum = <HTMLObjectElement>document.querySelector("#steeldrum");
        let steeldrumStyle = window.getComputedStyle(steeldrum);
        let steeldrumFilter = steeldrumStyle.getPropertyValue('filter');
        if (steeldrum) {
            for (let combiGroup = 0; combiGroup < this.combiGroups.length; combiGroup++) {
                demandedSteelDrumState = this.combiGroups[combiGroup].demandedSteelDrumState ? this.combiGroups[combiGroup].demandedSteelDrumState : "";
                if (demandedSteelDrumState == "dark" && steeldrumFilter == "brightness(1)") {
                    this.possibleNextCombiGroups[combiGroup] = false;
                }
                if (demandedSteelDrumState == "light" && steeldrumFilter == "brightness(0.1)") {
                    this.possibleNextCombiGroups[combiGroup] = false;
                }
            }
        }


        //console.log(this.possibleNextCombiGroups);
    }

    falsifyAllPossibleNextActionsBut(possibleActions: Array<number>) {
        let donotfalsify: boolean;
        for (let currentAction = 0; currentAction <= this.findTotalActionCount(); currentAction++) {
            donotfalsify = false;
            for (let currentPossibleAction of possibleActions) {
                if (currentAction == currentPossibleAction) { donotfalsify = true; }
            }
            if (!donotfalsify) { this.possibleNextActions[currentAction] = false; }
        }
    }

    applyRepetitionRule(group: number, innerAction: number, dauer: number) {
        let action = this.findActionNumber(group, innerAction);
        if (this.lastxClicksContainOneOfTheseActions(1, [action]) &&
            this.lastxClicksContainOtherThanTheseActions(dauer, [action])) {
            this.falsifyAllPossibleNextActionsBut([action]);
        }
    }

    applyPingPongRule(group1: number, innerAction1: number, group2: number, innerAction2: number, dauer: number) {
        let action1 = this.findActionNumber(group1, innerAction1);
        let action2 = this.findActionNumber(group2, innerAction2);
        if (this.lastxClicksContainOneOfTheseActions(dauer, [action1, action2]) &&
            this.lastxClicksContainOtherThanTheseActions(dauer, [action1, action2])) {
            if (this.prevAction() == action1) {
                this.falsifyAllPossibleNextActionsBut([action2]);
            }
            if (this.prevAction() == action2) {
                this.falsifyAllPossibleNextActionsBut([action1]);
            }
        }
    }

    applyMinCountRule(group: number, innerAction: number, minCount: number) {
        let action = this.findActionNumber(group, innerAction);
        if (this.clickCount < minCount) { this.possibleNextActions[action] = false; }
    }

    extractPossibleNextActions() {
        let result: Array<number> = [];
        for (let currentAction = 0; currentAction < this.possibleNextActions.length; currentAction++) {
            if (this.possibleNextActions[currentAction]) {
                result.push(currentAction);
            }
        }
        return result;
    }

    extractPossibleNextCombiGroups() {
        let result: Array<number> = [];
        for (let currentCombiGroup = 0; currentCombiGroup < this.possibleNextCombiGroups.length; currentCombiGroup++) {
            if (this.possibleNextCombiGroups[currentCombiGroup]) {
                result.push(currentCombiGroup);
            }
        }
        return result;
    }

    calculateNewProbabilityByEskalationswert(probability: number, krassitaet: number, eskalationswert: number) {
        /*switch (krassitaet) {
            case 1:
                probability = probability * (1 - eskalationswert);
                break;
            case 3:
                probability = probability * (1 + eskalationswert);
                break;
        }*/
        let ergebnis = (2 - Math.abs((krassitaet - 2) - ((eskalationswert - 0.5) * 2))) * probability;        // vgl. "Probability Berechnung.numbers"

        //console.log(this.name + ", Krassität: " + krassitaet + ", Eskalationswert: " + eskalationswert + ", Probability vorher " + probability + ", Probability neu: " + ergebnis);
        return ergebnis;
    }

    executeActionByProbabilityCalculation() {
        let possibleNextActionsCountByGroup: Array<number> = [];
        let possibleNextActionsByGroup: Array<Array<number>> = [];
        let probability: number;
        let newProbabilities: Array<number> = [];
        let newProbabilitiesSum: number;
        let zufallszahl: number;
        let cumulatedProbability: number = 0;
        let ausgewaehlteGruppe: number = 0;
        let ausgewaehlteAction: number;

        if (this.extractPossibleNextActions().length == 0) { this.executeAction(1); }
        if (this.extractPossibleNextActions().length == 1) { this.executeAction(this.extractPossibleNextActions()[0]); }
        if (this.extractPossibleNextActions().length >= 2) {

            possibleNextActionsCountByGroup.length = this.findTotalGroupCount();
            possibleNextActionsCountByGroup.fill(0);
            possibleNextActionsByGroup = [...Array(this.findTotalGroupCount())].map(() => []);
            /*possibleNextActionsByGroup.length = this.findTotalGroupCount();
            possibleNextActionsByGroup.fill([]);*/
            newProbabilities.length = this.findTotalGroupCount();

            for (let possibleNextAction of this.extractPossibleNextActions()) {
                possibleNextActionsCountByGroup[this.findGroupAndInnerActionNumber(possibleNextAction)[0]]++;
                possibleNextActionsByGroup[this.findGroupAndInnerActionNumber(possibleNextAction)[0]].push(possibleNextAction);
            }

            for (let currentGroupNumber = 0; currentGroupNumber < this.findTotalGroupCount(); currentGroupNumber++) {
                if (possibleNextActionsCountByGroup[currentGroupNumber] > 0) {
                    probability = this.actionGroups[currentGroupNumber].probability;// / possibleNextActionsCountByGroup[currentGroupNumber];
                } else {
                    probability = 0;
                }
                /*
                switch (this.actionGroups[currentGroupNumber].krassitaet) {
                    case 1:
                        probability = probability * (1 - ((this.lokalerEskalationswert - 0.5) * 2));
                        break;
                    case 3:
                        probability = probability * (1 + ((this.lokalerEskalationswert - 0.5) * 2));
                        break;
                }*/
                probability = this.calculateNewProbabilityByEskalationswert(probability, this.actionGroups[currentGroupNumber].krassitaet, this.lokalerEskalationswert);

                newProbabilities[currentGroupNumber] = probability;
            }

            //console.log("New Probabilities: " + newProbabilities);

            newProbabilitiesSum = newProbabilities.reduce((a, b) => a + b);
            for (let currentGroupNumber = 0; currentGroupNumber < this.findTotalGroupCount(); currentGroupNumber++) {
                newProbabilities[currentGroupNumber] = newProbabilities[currentGroupNumber] / newProbabilitiesSum;
            }

            //console.log("New Probabilities nach Normalisierung: " + newProbabilities);

            zufallszahl = Math.floor(Math.random() * 1000);
            for (let currentGroupNumber = 0; currentGroupNumber < this.findTotalGroupCount(); currentGroupNumber++) {
                cumulatedProbability = cumulatedProbability + (newProbabilities[currentGroupNumber] * 1000);
                if (zufallszahl < cumulatedProbability) { ausgewaehlteGruppe = currentGroupNumber; break; }
            }

            ausgewaehlteAction = Math.floor(Math.random() * possibleNextActionsCountByGroup[ausgewaehlteGruppe]);

            //console.log("Ausgewählte Gruppe: " + ausgewaehlteGruppe + ", Ausgewählte Rohaktion: " + ausgewaehlteAction + 
            ", Ausgewählte Aktion: " + possibleNextActionsByGroup[ausgewaehlteGruppe][ausgewaehlteAction] +
                ", Ausgewählte InnerGroupAktion: " + this.findGroupAndInnerActionNumber(possibleNextActionsByGroup[ausgewaehlteGruppe][ausgewaehlteAction])[1]);


            this.newState = [];
            //console.log("possibleNextActionsByGroup" + possibleNextActionsByGroup[ausgewaehlteGruppe][ausgewaehlteAction]);
            this.executeAction(possibleNextActionsByGroup[ausgewaehlteGruppe][ausgewaehlteAction]);
        }
    }

    nonlinearScale(wert: number, maxIn: number, maxOut: number, potenz: number) {
        return (Math.pow((wert / maxIn), potenz) * maxOut);
    }

    executeCombiGroupsByProbabilityCalculation() {
        //console.log("Entered executeCombiGroupsByProbabilityCalculation");
        let anzahlMoeglicherCombiGroups = this.extractPossibleNextCombiGroups().length;
        let nextCombiGroupCount: number;
        let newProbabilities: Array<number> = [];
        let newProbabilitiesSum: number;
        let probability: number;
        let selectedCombiGroups: Array<number> = [];
        let neueCombiGroupGefunden: boolean;
        let zufallszahl: number;
        let cumulatedProbability: number = 0;
        let ausgewaehlteCombiGroup: number = 0;
        let allCurrentCombiActions: GroupActionContainer[] = [];
        let neuesFestesObjekt = false;

        // Neues festes Objekt erzwingen
        //console.log("PRÜFUNG!");
        //console.log(this.mediator.currentEnvironment);
        //console.log(this.name);
        //console.log(this.findGroupAndInnerActionNumber(this.prevActions[this.prevActions.length - 1])[0]);
        if (this.mediator.currentEnvironment == 0 && this.name == "mother→childtamtam01" &&
            this.findGroupAndInnerActionNumber(this.prevActions[this.prevActions.length - 1])[0] == 12) {
            let innerAction = ["barrel", "vase", "skull"].indexOf(this.mediator.selectedSolidObject);
            //console.log("Neues festes Objekt Nr. " + innerAction);
            //console.log("festes this.mediator.selectedSolidObject: " + this.mediator.selectedSolidObject);
            this.executeCombiAction(this.findCombiActionNumber(8, innerAction + 1));
            allCurrentCombiActions.push({ group: 8, action: innerAction + 1 });
        }

        // console.log("Anzahl wählbarer Combigroups: " + anzahlMoeglicherCombiGroups);
        if (anzahlMoeglicherCombiGroups > 0) {
            nextCombiGroupCount = Math.round(this.nonlinearScale(this.lokalerEskalationswert, 1, this.findTotalCombiGroupCount(), 2));
            if (nextCombiGroupCount > anzahlMoeglicherCombiGroups) { nextCombiGroupCount = anzahlMoeglicherCombiGroups; }
            // console.log("Anzahl zu wählender Combigroups: " + nextCombiGroupCount);
            newProbabilities.length = this.findTotalCombiGroupCount();
            for (let currentCombiGroupNumber = 0; currentCombiGroupNumber < this.findTotalCombiGroupCount(); currentCombiGroupNumber++) {
                probability = this.combiGroups[currentCombiGroupNumber].probability
                probability = this.calculateNewProbabilityByEskalationswert(probability, this.combiGroups[currentCombiGroupNumber].krassitaet, this.lokalerEskalationswert);
                if (!this.possibleNextCombiGroups[currentCombiGroupNumber]) { probability = 0; }
                newProbabilities[currentCombiGroupNumber] = probability;
            }

            //console.log("New Probabilities: " + newProbabilities);

            newProbabilitiesSum = newProbabilities.reduce((a, b) => a + b);
            for (let currentCombiGroupNumber = 0; currentCombiGroupNumber < this.findTotalCombiGroupCount(); currentCombiGroupNumber++) {
                newProbabilities[currentCombiGroupNumber] = newProbabilities[currentCombiGroupNumber] / newProbabilitiesSum;
            }

            //console.log("New Probabilities Normalisiert: " + newProbabilities);

            let versuch = 0;
            for (let currentActionNumber = 1; currentActionNumber <= nextCombiGroupCount; currentActionNumber++) {
                neueCombiGroupGefunden = false;
                versuch = 0;                              // Sicherheitsbegrenzung
                while (neueCombiGroupGefunden == false && versuch < 100) {
                    versuch++;
                    cumulatedProbability = 0;
                    zufallszahl = Math.floor(Math.random() * 1000);
                    for (let currentCombiGroupNumber = 0; currentCombiGroupNumber < this.findTotalCombiGroupCount(); currentCombiGroupNumber++) {
                        cumulatedProbability = cumulatedProbability + (newProbabilities[currentCombiGroupNumber] * 1000);
                        if (zufallszahl < cumulatedProbability) { ausgewaehlteCombiGroup = currentCombiGroupNumber; break; }
                    }
                    // Wenn die fragliche CombiGroup nicht eh schon ausgewählt wurde, sowie
                    // wenn die fragliche CombiGroup nicht inkompatibel mit einer schon ausgewählten ist
                    if (!selectedCombiGroups.includes(ausgewaehlteCombiGroup) && this.combiGroups[ausgewaehlteCombiGroup].incompatibleCombiGroups.filter(value => selectedCombiGroups.includes(value)).length == 0) {
                        neueCombiGroupGefunden = true;
                        selectedCombiGroups.push(ausgewaehlteCombiGroup);
                    }
                }
            }

            // Environment 19 - Düsenjäger erzwingen
            if (this.mediator.currentEnvironment == 19) {
                // Düsenjägergruppe finden
                let duesenjaeger = -1;
                for (let i = 0; i < this.combiGroups.length; i++) {
                    if (this.combiGroups[i].name.includes("Düsenjäger")) {
                        duesenjaeger = i;
                    }
                }
                if (duesenjaeger != -1) {
                    if (!selectedCombiGroups.includes(duesenjaeger)) {
                        selectedCombiGroups.push(duesenjaeger);
                    }
                }
            }

            //            selectedCombiGroups = [3];
            let currentCombiActionNumber: number;
            for (let currentCombiGroupNumber of selectedCombiGroups) {
                currentCombiActionNumber = Math.ceil(Math.random() * this.combiGroups[currentCombiGroupNumber].elements);
                this.executeCombiAction(this.findCombiActionNumber(currentCombiGroupNumber, currentCombiActionNumber));
                // console.log("Start Combi Action Nr.: " + currentCombiGroupNumber + "-" + currentCombiActionNumber);
                allCurrentCombiActions.push({ group: currentCombiGroupNumber, action: currentCombiActionNumber });
            }
        }

        this.mediator.checkAudioEnvironments();
        this.findBestAudioForActionAndCombiActions(this.prevActions[this.prevActions.length - 1], allCurrentCombiActions);
    }

    findBestAudioForActionAndCombiActions(currentActionNumber: number, allCurrentCombiActions: GroupActionContainer[]) {
        let currentAction = <GroupActionContainer>{ group: this.findGroupAndInnerActionNumber(currentActionNumber)[0], action: this.findGroupAndInnerActionNumber(currentActionNumber)[1] };

        // Akkord auswählen
        if (this.mediator.audioEnvironments[this.mediator.currentAudioEnvironment].playChords == true) {
            //console.log("Routine 'Akkord auswählen' ausgewählt");
            this.mediator.selectNewChordSoundFile(0);
        }

        // Generischen Impact-Sound auswählen
        if (this.mediator.audioEnvironments[this.mediator.currentAudioEnvironment].playGenericImpactSounds == true) {
            //console.log("Routine 'Generischen Impact-Sound auswählen' ausgewählt");
            this.mediator.selectNewGenericImpactSound();
        }

        // Berechnen, ob das Objekt gerade wächst oder schrumpft
        let targetScale = 1;
        let targetScaleTime = 0;
        if (this.clickActions[currentActionNumber].tellAudioTargetAbsoluteScale) {
            targetScale = this.clickActions[currentActionNumber].tellAudioTargetAbsoluteScale[0];
            targetScaleTime = this.clickActions[currentActionNumber].tellAudioTargetAbsoluteScale[1];
        }
        for (let currentCombiAction of allCurrentCombiActions) {
            let tellAudioTargetAbsoluteScale = this.combiClickActions[this.findCombiActionNumber(currentCombiAction.group, currentCombiAction.action)].tellAudioTargetAbsoluteScale;
            if (tellAudioTargetAbsoluteScale) {
                targetScale = targetScale * tellAudioTargetAbsoluteScale[0];
                if (tellAudioTargetAbsoluteScale[1] > targetScaleTime) {
                    targetScaleTime = tellAudioTargetAbsoluteScale[1];
                }
            }
        }
        if (targetScaleTime > 0) {
            this.mediator.translateObjectScalingIntoAudio(this.currentScale, targetScale, targetScaleTime)
            //console.log("Ausgangsscaleeee: " + this.currentScale + ", Zielscale: " + targetScale + " Zeit: " + targetScaleTime);
        }


        // Proprietären Impact-Sound auswählen
        if (this.mediator.audioEnvironments[this.mediator.currentAudioEnvironment].playProprietaryImpactSounds == true && this.audioActions) {
            //console.log("Routine 'Proprietären Impact-Sound auswählen' ausgewählt");
            // Lege einen Array an, der für alle Sounds true enthält
            let standardSounds;
            let alternativeSounds;
            this.possibleNextSounds = [];
            for (let i = 0; i < this.audioActions.length; i++) {
                this.possibleNextSounds.push({ standardSounds: [], alternativeSounds: [] });
                standardSounds = this.audioActions[i].alternativeSounds;
                if (standardSounds) {
                    for (let j = 0; j < standardSounds.length; j++) {
                        this.possibleNextSounds[i].standardSounds.push(true);
                    }
                }
                alternativeSounds = this.audioActions[i].alternativeSounds;
                if (alternativeSounds) {
                    for (let j = 0; j < alternativeSounds.length; j++) {
                        this.possibleNextSounds[i].alternativeSounds.push(true);
                    }
                }
            }
            // console.log("possibleNextSounds Point 1: " + JSON.stringify(this.possibleNextSounds, null, "  "));

            // Wähle diejenige Aktion aus, welche mutmaßlich den meisten Einfluss auf den passenden Sound hat.
            // Farbveränderungen gehen über Positionsveränderungen gehen über Formveränderungen. Vgl. Array audioHierarchy.

            let suitableActions: GroupActionContainer[];
            let suitableCombiActions: GroupActionContainer[];
            let selectedAction: GroupActionContainer = { group: -1, action: -1 };
            let selectedActionType = "";
            for (let i = 0; i < this.audioHierarchy.length; i++) {
                suitableActions = [];
                suitableCombiActions = [];
                if (this.audioHierarchy[i].actionGroups?.includes(currentAction.group)) {
                    suitableActions = [currentAction];
                }
                for (let currentCombiAction of allCurrentCombiActions) {
                    if (this.audioHierarchy[i].combiGroups?.includes(currentCombiAction.group)) {
                        suitableCombiActions.push(currentCombiAction);
                    }
                }
                if (suitableActions.length > 0) {
                    selectedAction = suitableActions[0];
                    selectedActionType = "ClickAction";
                    break;
                }
                if (suitableActions.length == 0 && suitableCombiActions.length > 0) {
                    selectedAction = suitableCombiActions[0];
                    selectedActionType = "CombiAction";
                    break;
                }
            }
            //console.log("Maßgebliche Aktion für die Audioauswahl: " + selectedActionType + " " + JSON.stringify(selectedAction));

            let ergebnis: boolean;
            let possibleClickActions;
            let possibleCombiActions;
            for (let i = 0; i < this.audioActions.length; i++) {
                ergebnis = true;
                if (this.audioActions[i].possibleClickActions || this.audioActions[i].possibleCombiActions) {
                    ergebnis = false;
                    possibleClickActions = this.audioActions[i].possibleClickActions;
                    if (possibleClickActions && selectedActionType == "ClickAction") {
                        for (let currentGroupActionsContainer of possibleClickActions) {
                            for (let currentInnerAction of currentGroupActionsContainer.actions) {
                                if (selectedAction.group == currentGroupActionsContainer.group && selectedAction.action == currentInnerAction) {
                                    ergebnis = true;
                                }
                            }
                        }
                    }
                    possibleCombiActions = this.audioActions[i].possibleCombiActions;
                    if (possibleCombiActions && selectedActionType == "CombiAction") {
                        for (let currentGroupActionsContainer of possibleCombiActions) {
                            for (let currentInnerAction of currentGroupActionsContainer.actions) {
                                if (selectedAction.group == currentGroupActionsContainer.group && selectedAction.action == currentInnerAction) {
                                    ergebnis = true;
                                }
                            }
                        }
                    }
                }
                if (ergebnis == false) {
                    for (let j = 0; j < this.possibleNextSounds[i].standardSounds.length; j++) {
                        this.possibleNextSounds[i].standardSounds[j] = false;
                    }
                    for (let j = 0; j < this.possibleNextSounds[i].alternativeSounds.length; j++) {
                        this.possibleNextSounds[i].alternativeSounds[j] = false;
                    }
                }
            }

            // console.log("possibleNextSounds Point 2: " + JSON.stringify(this.possibleNextSounds, null, "  "));

            // Setz alle Sounds auf false, die einen forbidden State ausweisen, welcher Bestandteil des aktuellen States ist
            let forbiddenState;
            for (let i = 0; i < this.audioActions.length; i++) {
                standardSounds = this.audioActions[i].standardSounds;
                if (standardSounds) {
                    for (let j = 0; j < standardSounds.length; j++) {
                        ergebnis = true;
                        forbiddenState = standardSounds[j].forbiddenState;
                        if (forbiddenState) {
                            for (let currentForbiddenState of forbiddenState) {
                                if (this.state.includes(currentForbiddenState)) {
                                    ergebnis = false;
                                }
                            }
                            if (ergebnis == false) {
                                this.possibleNextSounds[i].standardSounds[j] = false;
                            }
                        }
                    }
                }
                alternativeSounds = this.audioActions[i].alternativeSounds;
                if (alternativeSounds) {
                    for (let j = 0; j < alternativeSounds.length; j++) {
                        ergebnis = true;
                        forbiddenState = alternativeSounds[j].forbiddenState;
                        if (forbiddenState) {
                            for (let currentForbiddenState of forbiddenState) {
                                if (this.state.includes(currentForbiddenState)) {
                                    ergebnis = false;
                                }
                            }
                            if (ergebnis == false) {
                                this.possibleNextSounds[i].alternativeSounds[j] = false;
                            }
                        }
                    }
                }
            }

            // console.log("possibleNextSounds Point 3: " + JSON.stringify(this.possibleNextSounds, null, "  "));

            // Setz alle Sounds auf false, die einen demanded State ausweisen, welcher KEIN Bestandteil des aktuellen States ist
            let demandedState;
            for (let i = 0; i < this.audioActions.length; i++) {
                standardSounds = this.audioActions[i].standardSounds;
                if (standardSounds) {
                    for (let j = 0; j < standardSounds.length; j++) {
                        ergebnis = true;
                        demandedState = standardSounds[j].demandedState;
                        if (demandedState) {
                            for (let currentDemandedState of demandedState) {
                                if (!this.state.includes(currentDemandedState)) {
                                    ergebnis = false;
                                }
                            }
                            if (ergebnis == false) {
                                this.possibleNextSounds[i].standardSounds[j] = false;
                            }
                        }
                    }
                }
                alternativeSounds = this.audioActions[i].alternativeSounds;
                if (alternativeSounds) {
                    for (let j = 0; j < alternativeSounds.length; j++) {
                        ergebnis = true;
                        demandedState = alternativeSounds[j].demandedState;
                        if (demandedState) {
                            for (let currentDemandedState of demandedState) {
                                if (!this.state.includes(currentDemandedState)) {
                                    ergebnis = false;
                                }
                            }
                            if (ergebnis == false) {
                                this.possibleNextSounds[i].alternativeSounds[j] = false;
                            }
                        }
                    }
                }
            }

            // console.log("possibleNextSounds Point 4: " + JSON.stringify(this.possibleNextSounds, null, "  "));

            let vorherigeAktion = [0, 0];
            for (let i = 0; i < this.audioActions.length; i++) {
                standardSounds = this.audioActions[i].standardSounds;
                if (standardSounds) {
                    for (let j = 0; j < standardSounds.length; j++) {
                        if (this.possibleNextSounds[i].standardSounds[j] == true && standardSounds[j].afterIdenticalActionOnly == true) {
                            ergebnis = false;
                            vorherigeAktion = this.findGroupAndInnerActionNumber(this.prevActions[this.prevActions.length - 2]);
                            possibleClickActions = this.audioActions[i].possibleClickActions;
                            if (possibleClickActions) {
                                for (let currentPossibleClickAction of possibleClickActions) {
                                    if (currentPossibleClickAction.group == vorherigeAktion[0] && currentPossibleClickAction.actions.includes(vorherigeAktion[1])) {
                                        ergebnis = true;
                                    }
                                }
                            }
                            if (ergebnis == false) {
                                this.possibleNextSounds[i].standardSounds[j] = false;
                            }
                        }
                    }
                }
                alternativeSounds = this.audioActions[i].alternativeSounds;
                if (alternativeSounds) {
                    for (let j = 0; j < alternativeSounds.length; j++) {
                        if (this.possibleNextSounds[i].alternativeSounds[j] == true && alternativeSounds[j].afterIdenticalActionOnly == true) {
                            ergebnis = false;
                            vorherigeAktion = this.findGroupAndInnerActionNumber(this.prevActions[this.prevActions.length - 2]);
                            possibleClickActions = this.audioActions[i].possibleClickActions;
                            if (possibleClickActions) {
                                for (let currentPossibleClickAction of possibleClickActions) {
                                    if (currentPossibleClickAction.group == vorherigeAktion[0] && currentPossibleClickAction.actions.includes(vorherigeAktion[1])) {
                                        ergebnis = true;
                                    }
                                }
                            }
                            if (ergebnis == false) {
                                this.possibleNextSounds[i].alternativeSounds[j] = false;
                            }
                        }
                    }
                }
            }

            // console.log("possibleNextSounds Point 5: " + JSON.stringify(this.possibleNextSounds, null, "  "));

            for (let currentNewState of this.newState) {
                for (let k = 0; k < this.audioActions.length; k++) {
                    if (this.audioActions[k].demandedNewState == currentNewState) {
                        for (let i = 0; i < this.audioActions.length; i++) {
                            if (i != k) {
                                standardSounds = this.audioActions[i].standardSounds;
                                if (standardSounds) {
                                    for (let j = 0; j < standardSounds.length; j++) {
                                        this.possibleNextSounds[i].standardSounds[j] = false;
                                    }
                                }
                                alternativeSounds = this.audioActions[i].alternativeSounds;
                                if (alternativeSounds) {
                                    for (let j = 0; j < alternativeSounds.length; j++) {
                                        this.possibleNextSounds[i].alternativeSounds[j] = false;
                                    }
                                }
                            }
                        }
                        break;
                    }
                }
            }

            // console.log("possibleNextSounds Point 6: " + JSON.stringify(this.possibleNextSounds, null, "  "));

            for (let i = 0; i < this.audioActions.length; i++) {
                let demandedNewState = this.audioActions[i].demandedNewState;
                if (demandedNewState) {
                    ergebnis = false;
                    if (this.newState.includes(demandedNewState)) {
                        ergebnis = true;
                    }
                    if (ergebnis == false) {
                        for (let j = 0; j < this.possibleNextSounds[i].standardSounds.length; j++) {
                            this.possibleNextSounds[i].standardSounds[j] = false;
                        }
                        for (let j = 0; j < this.possibleNextSounds[i].alternativeSounds.length; j++) {
                            this.possibleNextSounds[i].alternativeSounds[j] = false;
                        }
                    }
                }
            }

            // console.log("possibleNextSounds Point 7: " + JSON.stringify(this.possibleNextSounds, null, "  "));

            for (let i = 0; i < this.audioActions.length; i++) {
                if (this.audioActions[i].allowedInEnvironments) {
                    if (!this.audioActions[i].allowedInEnvironments?.includes(this.mediator.currentEnvironment)) {
                        standardSounds = this.audioActions[i].standardSounds;
                        if (standardSounds) {
                            for (let j = 0; j < standardSounds.length; j++) {
                                this.possibleNextSounds[i].standardSounds[j] = false;
                            }
                        }
                        alternativeSounds = this.audioActions[i].alternativeSounds;
                        if (alternativeSounds) {
                            for (let j = 0; j < alternativeSounds.length; j++) {
                                this.possibleNextSounds[i].alternativeSounds[j] = false;
                            }
                        }
                    }
                }
            }

            for (let i = 0; i < this.audioActions.length; i++) {
                if (this.audioActions[i].allowedInAudioEnvironments) {
                    if (!this.audioActions[i].allowedInAudioEnvironments?.includes(this.mediator.currentAudioEnvironment)) {
                        standardSounds = this.audioActions[i].standardSounds;
                        if (standardSounds) {
                            for (let j = 0; j < standardSounds.length; j++) {
                                this.possibleNextSounds[i].standardSounds[j] = false;
                            }
                        }
                        alternativeSounds = this.audioActions[i].alternativeSounds;
                        if (alternativeSounds) {
                            for (let j = 0; j < alternativeSounds.length; j++) {
                                this.possibleNextSounds[i].alternativeSounds[j] = false;
                            }
                        }
                    }
                }
            }

            // Prüfen, ob die Audiofiles geladen sind
            for (let i = 0; i < this.audioActions.length; i++) {
                standardSounds = this.audioActions[i].standardSounds;
                if (standardSounds) {
                    for (let j = 0; j < standardSounds.length; j++) {
                        let filename = this.consolidateFilename("media" + this.mediaPfad + "/audio/" + standardSounds[j].sample + ".ogg");
                        if (!this.mediator.mediaList[filename].bufferLoaded) {
                            this.possibleNextSounds[i].standardSounds[j] = false;
                        }
                    }
                }
                alternativeSounds = this.audioActions[i].alternativeSounds;
                if (alternativeSounds) {
                    for (let j = 0; j < alternativeSounds.length; j++) {
                        let filename = this.consolidateFilename("media" + this.mediaPfad + "/audio/" + alternativeSounds[j].sample + ".ogg");
                        //console.log("filename: " + filename);
                        if (!this.mediator.mediaList[filename].bufferLoaded) {
                            this.possibleNextSounds[i].alternativeSounds[j] = false;
                        }
                    }
                }
            }


            // console.log("possibleNextSounds Point 8: " + JSON.stringify(this.possibleNextSounds, null, "  "));

            // Lies aus, zu wievielen Gruppen die auf TRUE verbliebenen Sounds gehören.
            // (Normalerweise dürfte es jetzt nur noch eine sein).

            let verbliebeneGruppen: number[] = [];
            let gruppeIstVerblieben = false;
            for (let i = 0; i < this.audioActions.length; i++) {
                gruppeIstVerblieben = false;
                standardSounds = this.audioActions[i].standardSounds;
                if (standardSounds) {
                    for (let j = 0; j < standardSounds.length; j++) {
                        if (this.possibleNextSounds[i].standardSounds[j] == true) {
                            gruppeIstVerblieben = true;
                        }
                    }
                }
                alternativeSounds = this.audioActions[i].alternativeSounds;
                if (alternativeSounds) {
                    for (let j = 0; j < alternativeSounds.length; j++) {
                        if (this.possibleNextSounds[i].alternativeSounds[j] == true) {
                            gruppeIstVerblieben = true;
                        }
                    }
                }
                if (gruppeIstVerblieben == true) { verbliebeneGruppen.push(i); }
            }
            //console.log("verbliebeneGruppen: " + JSON.stringify(verbliebeneGruppen));

            let selectedSound: SoundContainer = { sample: "", dynamicRange: [0, 0], midiRange: [60, 60] };
            let selectedGroup: AudioAction = {};
            //console.log(selectedSound);

            if (verbliebeneGruppen.length == 0 && this.mediator.currentEnvironment == 0) {
                verbliebeneGruppen = [this.audioActions.length - 1];
            }

            if (verbliebeneGruppen.length > 0) {
                //console.log("pppp Unterwegs hier!");
                let zufallszahl = Math.floor(Math.random() * 10 * verbliebeneGruppen.length);
                let zufallszahl2 = -1;
                let ausgewaehlteVerbliebeneGruppe = verbliebeneGruppen[Math.floor(zufallszahl / 10)];
                let alternativSoundWaehlen = false;
                if (this.audioActions[ausgewaehlteVerbliebeneGruppe].standardSounds) {
                    selectedGroup = this.audioActions[ausgewaehlteVerbliebeneGruppe];
                    selectedSound = this.audioActions[ausgewaehlteVerbliebeneGruppe].standardSounds[0];
                } else {
                    alternativSoundWaehlen = true;
                }
                // console.log("Vorerst ausgewählter Sound: Gruppe " + ausgewaehlteVerbliebeneGruppe + ", Standardsound")
                if (zufallszahl % 10 >= 7 || this.possibleNextSounds[ausgewaehlteVerbliebeneGruppe].standardSounds[0] == false || alternativSoundWaehlen == true) {
                    if (this.audioActions[ausgewaehlteVerbliebeneGruppe].alternativeSounds) {
                        let verbliebeneAlternativeSounds: Array<number> = [];
                        for (let i = 0; i < this.audioActions[ausgewaehlteVerbliebeneGruppe].alternativeSounds.length; i++) {
                            if (this.possibleNextSounds[ausgewaehlteVerbliebeneGruppe].alternativeSounds[i] == true) {
                                verbliebeneAlternativeSounds.push(i);
                            }
                        }
                        if (verbliebeneAlternativeSounds.length > 0) {
                            selectedGroup = this.audioActions[ausgewaehlteVerbliebeneGruppe];
                            zufallszahl2 = Math.floor(Math.random() * verbliebeneAlternativeSounds.length);
                            selectedSound = this.audioActions[ausgewaehlteVerbliebeneGruppe].alternativeSounds[verbliebeneAlternativeSounds[zufallszahl2]];
                            // console.log("Letztendlich ausgewählter Sound: Gruppe " + ausgewaehlteVerbliebeneGruppe + ", Alternativsound " + zufallszahl2)
                        }
                    }
                }
                //console.log(selectedSound);

                //console.log("pppp selectedSound.sample: " + selectedSound.sample);
                if (selectedSound.sample == "" && this.mediator.currentEnvironment == 0) {
                    //console.log("PPPP kein normales Sample gefunden");
                    selectedGroup = this.audioActions[this.audioActions.length - 1];
                    selectedSound.sample = "TT pp kurz";
                    selectedSound.dynamicRange = [0.02, 0.02];
                    selectedSound.midiRange = [55, 65];
                }

                if (selectedSound.sample != "") {
                    // Info-Element updaten
                    let audioInfoElement = this.bySelector("#audioInfoElement");
                    if (audioInfoElement) {
                        audioInfoElement.innerHTML = audioInfoElement.innerHTML + " Proprietärer ImpactSound: " + selectedSound.sample + "<br>";
                    }

                    // Wenn ein vorheriger Sound ausgeschaltet werden muss, tue dies:
                    let killSamples = selectedGroup.killSamples;
                    if (killSamples && this.mediator.infinitelyPlayingPlayers) {
                        //console.log("Killsamples: Versuche auszuschalten: " + JSON.stringify(killSamples));
                        for (let currentObject of this.mediator.infinitelyPlayingPlayers) {
                            if (currentObject.object == this.name) {
                                //console.log("object: " + currentObject.object);
                                //console.log("currentInfinitelyPlayingPlayers: " + currentObject.players);
                                for (let currentSampleToKill of killSamples) {
                                    //console.log("Killsamples: currentSampleToKill: " + currentSampleToKill);
                                    for (let i = 0; i < currentObject.players.length; i++) {
                                        //console.log("sampleName: " + currentObject.players[i].sampleName);
                                        //console.log("tonePlayer: " + currentObject.players[i].player);
                                        if (currentObject.players[i].sampleName == currentSampleToKill) {
                                            //console.log("Killsamples: gefunden!");
                                            //console.log("Vor dem Killen: " + currentObject.players);
                                            currentObject.players[i].player.stop();
                                            currentObject.players.splice(i);
                                            //console.log("Nach dem Killen: " + currentObject.players);
                                            break;
                                        } else {
                                            //console.log("Killsamples: nicht gefunden!");
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // Den neuen Sound anschalten
                    let pitch = (Math.random() * (selectedSound.midiRange[1] - selectedSound.midiRange[0])) + selectedSound.midiRange[0];
                    let volume = (Math.random() * (selectedSound.dynamicRange[1] - selectedSound.dynamicRange[0])) + selectedSound.dynamicRange[0];
                    let masterVolumeForProprietoryImpactSounds = this.mediator.environments[this.mediator.currentAudioEnvironment].masterVolumeForProprietoryImpactSounds;
                    if (masterVolumeForProprietoryImpactSounds) {
                        volume = volume * masterVolumeForProprietoryImpactSounds;
                    }
                    let dB = Math.log(volume ** 2) / Math.log(10) * 10;
                    let audioPlayerName = this.consolidateFilename("media" + this.mediaPfad + "/audio/" + selectedSound.sample + ".ogg");
                    //console.log("Versuche folgenden Buffer zu spielen Point 1: '" + audioPlayerName + "'");
                    let player: Tone.Player;
                    let offset = selectedSound.startTime ? selectedSound.startTime / 1000 : 0;
                    let stopTime = selectedSound.endTime ? selectedSound.endTime / 1000 : -1;
                    let fadeIn = selectedSound.fadeIn ? selectedSound.fadeIn / 1000 : 0.02;
                    let fadeOut = selectedSound.fadeOut ? selectedSound.fadeOut / 1000 : 0.02;
                    let midiReference = 60;
                    for (let currentAudioListElement of this.audioList) {
                        if (currentAudioListElement.sample == audioPlayerName) {
                            midiReference = currentAudioListElement.midiReference;
                        }
                    }
                    let playBackRate = 2 ** ((pitch - midiReference) / 12);

                    // console.log("Gewählte Midi: " + pitch + ", Referenzmidi: " + midiReference + ", playBackRate: " + playBackRate);
                    // console.log("Stoptime: " + stopTime + ", Fadeout: " + fadeOut);

                    // console.log("audioPlayerState: " + this.mediator.buffers[audioPlayerName].loaded);
                    if (this.mediator.buffers[audioPlayerName]) {
                        if (this.mediator.audioContextStarted) {
                            // console.log("TransportTime on Audio Start: " + Tone.Transport.seconds);
                            //console.log("PPPP Möchte spielen: " + audioPlayerName);
                            //console.log("PPPP Zugehöriger Buffer: " + this.mediator.buffers[audioPlayerName]);
                            //console.log("PPPP Geladen: " + this.mediator.buffers[audioPlayerName].loaded);
                            Tone.Destination.mute = false;
                            player = new Tone.Player(this.mediator.buffers[audioPlayerName]).toDestination();
                            player.volume.value = dB;
                            player.playbackRate = playBackRate;
                            player.fadeIn = fadeIn;
                            player.fadeOut = fadeOut;
                            player.start(0, offset);//.stop("+1");
                            if (stopTime != -1) {
                                // console.log("Stop Scheduled for: " + (stopTime - offset - fadeOut));
                                Tone.Transport.schedule(function (time) {
                                    // console.log("StopPlayer");
                                    player.stop();
                                }, Tone.Transport.seconds + stopTime - offset - fadeOut);
                            }
                            player.onstop = () => {
                                player.dispose();
                            }
                            if (selectedSound.addToInfinitelyPlayingPlayers == true) {
                                let infinitelyPlayingPlayerListeVorhanden = false;
                                for (let currentObject of this.mediator.infinitelyPlayingPlayers) {
                                    if (currentObject.object == this.name) {
                                        infinitelyPlayingPlayerListeVorhanden = true;
                                        currentObject.players.push({ sampleName: selectedSound.sample, player: player });
                                    }
                                }
                                if (infinitelyPlayingPlayerListeVorhanden == false) {
                                    this.mediator.infinitelyPlayingPlayers.push({ object: this.name, players: [{ sampleName: selectedSound.sample, player: player }] });
                                }
                                //console.log(this.mediator.infinitelyPlayingPlayers);
                            }
                        } else {
                            Tone.start().then(() => {
                                Tone.context.lookAhead = 0;
                                //console.log("PPPP Möchte spielen: " + audioPlayerName);
                                //console.log("PPPP Zugehöriger Buffer: " + this.mediator.buffers[audioPlayerName]);
                                //console.log("PPPP Geladen: " + this.mediator.buffers[audioPlayerName].loaded);
                                Tone.Destination.mute = false;
                                player = new Tone.Player(this.mediator.buffers[audioPlayerName]).toDestination();
                                player.volume.value = dB;
                                player.playbackRate = playBackRate;
                                player.fadeIn = fadeIn;
                                player.fadeOut = fadeOut;
                                player.start(0, offset);//.stop("+1");
                                if (selectedSound.addToInfinitelyPlayingPlayers == true) {
                                    let infinitelyPlayingPlayerListeVorhanden = false;
                                    for (let currentObject of this.mediator.infinitelyPlayingPlayers) {
                                        if (currentObject.object == this.name) {
                                            infinitelyPlayingPlayerListeVorhanden = true;
                                            currentObject.players.push({ sampleName: selectedSound.sample, player: player });
                                        }
                                    }
                                    if (infinitelyPlayingPlayerListeVorhanden == false) {
                                        this.mediator.infinitelyPlayingPlayers.push({ object: this.name, players: [{ sampleName: selectedSound.sample, player: player }] });
                                    }
                                }
                                if (stopTime != -1) {
                                    Tone.Transport.schedule(function (time) {
                                        player.stop();
                                    }, Tone.Transport.seconds + stopTime - offset - fadeOut);
                                }

                                player.onstop = () => {
                                    player.dispose();
                                }
                                this.mediator.audioContextStarted = true;
                                Tone.Transport.start();
                            });
                        }
                    }
                } else {
                    let audioInfoElement = this.bySelector("#audioInfoElement");
                    if (audioInfoElement) {
                        audioInfoElement.innerHTML = audioInfoElement.innerHTML + " Kein prorietäres Impact-Sample gefunden.<br>";
                    }
                }
            } else {
                // *** Hier ergänzen: Wenn wird im Environment 0 sind, soll ein Standard TT-pp-Klang anderweitig abgespielt werden!

                if (this.mediator.currentEnvironment == 0) {
                    /*let emergencyAudioPlayer = <HTMLAudioElement>document.querySelector("#mother→childtamtam01→emergency_audio");
                    if (emergencyAudioPlayer) {
                        //console.log("Emergency-Player ausgelöst!");
                        //console.log("Emergency-Player ended: " + emergencyAudioPlayer.ended);
                        //console.log("Emergency-Player paused: " + emergencyAudioPlayer.paused);
                        emergencyAudioPlayer.volume = 0.02;
                        if (!emergencyAudioPlayer.ended && !emergencyAudioPlayer.paused) {
                            emergencyAudioPlayer.currentTime = 0;
                        } else {
                            emergencyAudioPlayer.play();
                        }
                    }*/
                }
                let audioInfoElement = this.bySelector("#audioInfoElement");
                if (audioInfoElement) {
                    audioInfoElement.innerHTML = audioInfoElement.innerHTML + " Kein prorietäres Impact-Sample gefunden (Point 2).<br>";
                }
            }
        }
    }

    executeResetAction(CSSAnimationType: string) {
        //console.log("this.active: " + this.active);
        //if (!this.active) {return;}
        //console.log("executeResetAction for " + CSSAnimationType);
        let diff = 0;
        switch (CSSAnimationType) {
            case "relativeScale": diff = 17; break;
            case "relativePositionX": diff = 16; break;
            case "relativePositionY": diff = 15; break;
            case "absoluteScale": diff = 14; break;
            case "absolutePositionX": diff = 13; break;
            case "absolutePositionY": diff = 12; break;
            case "blend": diff = 11; break;
            case "opacity": diff = 10; break;
            case "rotate": diff = 9; break;
            case "rotate3d": diff = 8; break;
            case "skew": diff = 7; break;
            case "perspective": diff = 6; break;
            case "brightness": diff = 5; break;
            case "contrast": diff = 4; break;
            case "blur": diff = 3; break;
            case "saturate": diff = 2; break;
            case "hue-rotate": diff = 1; break;
        }
        if (diff > 0) {
            //console.log("performResetActions: " + this.performResetActions);
            if (this.performResetActions && !this.prohibitedResetActions.includes(CSSAnimationType)) {
                //console.log("Perform Reset Action for " + CSSAnimationType);
                //console.log("this.name: " + this.name);
                this.prohibitedResetActions.push(CSSAnimationType);     // Zur Vermeidung von Endlosschleifen
                //console.log("prohibitedResetActions plus: " + JSON.stringify(this.prohibitedResetActions));
                this.executeAction(this.clickActions.length - diff);
                //this.prohibitedResetActions.splice(this.prohibitedResetActions.indexOf(CSSAnimationType), 1);
                //console.log("prohibitedResetActions minus: " + JSON.stringify(this.prohibitedResetActions));
            }
        }
    }

    executeFinalAction() {
        //console.log("Finale Aktion hat die Nummer " + (this.clickActions.length - 18));
        if (this.clickActions.length - 18 >= 0) {
            this.executeAction(this.clickActions.length - 18);
        }
    }

    checkAnimationTypes() {
        this.allowedCSSAnimationTypes.forEach(allowedCSSAnimationType => {
            if (this.clickCount == allowedCSSAnimationType.maxClickCount) {
                this.executeResetAction(allowedCSSAnimationType.animationType);
            }
        })

        /*        // Prüfen ob mit dem neuen globalClickCount irgendwelche Animationstypen entfernt werden müssen
                Object.entries(this.objects).forEach(([name, object]) => {
                    if (object.allowedCSSAnimationTypes && object.active) {
                        object.allowedCSSAnimationTypes.forEach(allowedCSSAnimationType => {
                            if (this.globalClickCount == allowedCSSAnimationType.maxGlobalClickCount) {
                                object.executeResetAction(allowedCSSAnimationType.animationType);
                            }
                        })
                    }
                })        */
    }

    executeAction(clickActionIndex: number) {

        if (clickActionIndex <= this.findTotalActionCount()) {
            let gruppe_und_action = this.findGroupAndInnerActionNumber(clickActionIndex);
            if (!(gruppe_und_action[0] == 0 && gruppe_und_action[1] == 1)) {
                this.mediator.updateInfoElement({ name: this.name, action: gruppe_und_action, append: true });
            }
        } else if (clickActionIndex < this.clickActions.length - 18) {
            let combiGruppe_und_action = this.findCombiGroupAndInnerCombiActionNumber(clickActionIndex);
            this.mediator.updateInfoElement({ name: this.name, combiAction: combiGruppe_und_action, append: true });
        }

        //console.log("Execute Action: " + this.objDiv.id + ", Nr. " + clickActionIndex + " / " + this.findGroupAndInnerActionNumber(clickActionIndex));
        //console.log("puschen: " + clickActionIndex + ", " + this.findTotalActionCount() );
        if (clickActionIndex <= this.findTotalActionCount()) {
            this.prevActions.push(clickActionIndex);
        }

        let targetObjectSelector: string;
        let targetObjectDOM: HTMLElement;
        let targetObject: string;
        let targetAction: GroupActionContainer;
        let selector: string;
        let mediaTitle: string;
        let mediaTitlePlain: string;
        let newClass: string;
        let metricLevel: string;

        let addState: string[] = [];
        let removeState: string[] = [];
        if (this.clickActions[clickActionIndex]) {
            addState = this.clickActions[clickActionIndex].addState ? this.clickActions[clickActionIndex].addState : [];
            removeState = this.clickActions[clickActionIndex].removeState ? this.clickActions[clickActionIndex].removeState : [];
        }

        if (addState) {
            for (let newState of addState) {
                if (!this.state.includes(newState)) {
                    this.state.push(newState);
                    this.newState.push(newState);
                    let audioInfoElement = this.bySelector("#audioInfoElement");
                    if (audioInfoElement) {
                        audioInfoElement.innerHTML = "addState: " + newState + "<br>";
                    }
                    //console.log("addState: " + newState);
                }
            }
        }

        if (removeState) {
            for (let stateToRemove of removeState) {
                if (this.state.includes(stateToRemove)) {
                    this.state.splice(this.state.indexOf(stateToRemove), 1);
                    //console.log("removeState: " + stateToRemove);
                }
            }
        }

        //console.log("Status: " + this.state);

        // Klicksperre einrichten, sofern definiert
        const allowClickingToTrue = () => {
            this.mediator.allowClicking = true;
            let allClickAreas = document.querySelectorAll(".clickArea");
            for (let currentClickArea of allClickAreas) {
                currentClickArea.style.display = "block";
            }
        }

        //console.log(this.clickActions[clickActionIndex]);
        //console.log("clickActions: " + this.clickActions);
        //console.log("Ich bin: " + this.objDiv.id);
        //console.log("clickActionIndex: " + clickActionIndex);
        let forbidClicksForNextMilliseconds = this.clickActions[clickActionIndex].forbidClicksForNextMilliseconds;
        if (forbidClicksForNextMilliseconds) {
            this.mediator.allowClicking = false;
            let allObjects = document.querySelectorAll(".object");
            for (let currentObject of allObjects) {
                if (!currentObject.id.includes("menupunkt")) {
                    let currentClickArea = <HTMLElement>currentObject.querySelector(".clickArea");
                    if (currentClickArea) {
                        currentClickArea.style.display = "none";
                    }
                }
            }
            Tone.Transport.schedule(function (time) {
                allowClickingToTrue();
            }, Tone.Transport.seconds + (forbidClicksForNextMilliseconds / 1000));

        }

        this.clickActions[clickActionIndex].singleActions.forEach(singleAction => {

            switch (singleAction.actionType) {
                /////// TeachMartinToneJS //////
                case ActionType.TeachMartinToneJS:
                    TeachMartinToneJS.executeAction(singleAction, this);
                    break;

                /////// PlayAudio //////
                case ActionType.PlayAudio:
                    let audioPlayerName = this.consolidateFilename("media" + this.mediaPfad + "/audio/" + singleAction.mediaTitle + ".ogg");
                    let player: Tone.Player;
                    let volume = singleAction.volume ? singleAction.volume : 1;
                    let dB = Math.log(volume ** 2) / Math.log(10) * 10;
                    let offset = singleAction.startTime ? singleAction.startTime / 1000 : 0;
                    let stopTime = singleAction.endTime ? singleAction.endTime / 1000 : -1;
                    let fadeIn = singleAction.fadeIn ? singleAction.fadeIn / 1000 : 0.02;
                    let fadeOut = singleAction.fadeOut ? singleAction.fadeOut / 1000 : 0.02;
                    //console.log("Versuche folgenden Buffer zu spielen Point 2: '" + audioPlayerName + "'");

                    //console.log("Möchte starten: " + audioPlayerName)
                    // console.log("audioPlayerState: " + this.mediator.buffers[audioPlayerName].loaded);
                    if (this.mediator.audioContextStarted) {
                        //console.log("TransportTime on Audio Start: " + Tone.Transport.seconds);
                        Tone.Destination.mute = false;
                        player = new Tone.Player(this.mediator.buffers[audioPlayerName]).toDestination();
                        player.volume.value = dB;
                        player.fadeIn = fadeIn;
                        player.fadeOut = fadeOut;
                        player.start(0, offset);//.stop("+1");                
                        if (stopTime != -1) {
                            Tone.Transport.schedule(function (time) {
                                player.stop();
                            }, Tone.Transport.seconds + stopTime - offset - fadeOut);
                        }
                        player.onstop = () => {
                            player.dispose();
                        }
                    } else {
                        Tone.start().then(() => {
                            Tone.context.lookAhead = 0;
                            Tone.Destination.mute = false;
                            player = new Tone.Player(this.mediator.buffers[audioPlayerName]).toDestination();
                            player.volume.value = dB;
                            player.fadeIn = fadeIn;
                            player.fadeOut = fadeOut;
                            player.start(0, offset);//.stop("+1");
                            if (stopTime != -1) {
                                Tone.Transport.schedule(function (time) {
                                    player.stop();
                                }, Tone.Transport.seconds + stopTime - offset - fadeOut);
                            }

                            player.onstop = () => {
                                player.dispose();
                            }
                            this.mediator.audioContextStarted = true;
                            Tone.Transport.start();
                        });
                    }
                    // decrease volume of other sound players
                    if (singleAction.playSolo) {
                        let playSoloMuteVolume = singleAction.playSoloMuteVolume ? singleAction.playSoloMuteVolume : 0;
                        Object.entries(this.mediator.objects).forEach(
                            ([id, csm]) => {
                                if (id != this.name) {
                                    Object.entries(csm.players).forEach(
                                        ([id, player]) => {
                                            if (player.state == "started") {
                                                player.volume.rampTo(playSoloMuteVolume, 1);
                                            }
                                        }
                                    )
                                }
                            }
                        );
                    }
                    break;

                ////// ShowImage //////    
                case ActionType.ShowImage:
                    mediaTitle = singleAction.mediaTitle ? "media" + this.mediaPfad + "/img/" + singleAction.mediaTitle + ".webp" : "";
                    selector = "#" + this.objDiv.id + "-img";
                    //console.log("mediaTitle: " + mediaTitle);

                    // hide all videos
                    let alleVideos = document.querySelectorAll("#" + this.name + " .animation-wrapper video");
                    for (let currentVideo of alleVideos) {
                        hide(currentVideo, "display");
                    }

                    let imageDOM = <HTMLImageElement>this.bySelector(selector);

                    //console.log(mediaTitle);
                    //console.log(this.mediator.mediaList[mediaTitle]);
                    let blobURL = this.mediator.mediaList[mediaTitle].blobURL;
                    if (blobURL) {
                        imageDOM.src = blobURL;
                        show(imageDOM, "display")
                    } else {
                        //console.log("Keine BlobURL für " + mediaTitle + " gefunden.");
                        hide(imageDOM, "display")
                    }
                    // MediaTitle als Klassennamen vergeben, da das src-Element nicht abfragbar ist (blob)
                    while (imageDOM.classList.length > 0) {
                        let firstElement = imageDOM.classList.item(0);
                        if (firstElement) {
                            imageDOM.classList.remove(firstElement);
                        }
                    }
                    if (singleAction.mediaTitle) {
                        imageDOM.classList.add(singleAction.mediaTitle);
                    }

                    //imageDOM.src = mediaTitle;
                    if (this.hasBlendAnimations && this.mediator.blendAnimationsAllowed) {
                        let imageDOMBlend = <HTMLImageElement>this.bySelector(selector + "-blend");
                        imageDOMBlend.src = mediaTitle;
                        if (this.blendingActive) {
                            show(imageDOMBlend, "display");
                        } else {
                            hide(imageDOMBlend, "display");
                        }
                    }
                    break;

                ////// VideoPlayback //////    
                case ActionType.VideoPlayback:
                    mediaTitle = singleAction.mediaTitle ? singleAction.mediaTitle : "";
                    selector = singleAction.selector != null ? singleAction.selector : "#" + this.name + "→" + mediaTitle + "-video";

                    let startVideo = true;
                    if (singleAction.startVideo != null) { startVideo = singleAction.startVideo; }
                    let stopVideo = singleAction.stopVideo ? singleAction.stopVideo : false;
                    let loop = singleAction.loop ? singleAction.loop : false;
                    let playBackRateUmkehren = 1;
                    let hasBlendAnimations = this.hasBlendAnimations;

                    ////////////////////////////////////////////////////////////////////////////////////////
                    ////// StartTime und EndTime berechnen, bezogen auf das Ausgangsvideo (Original) ///////
                    ////////////////////////////////////////////////////////////////////////////////////////

                    // Wenn die StartTime durch eine Funktion berechnet wird, tue dies.
                    // Wenn die StartTime die EndTime der letzte Aktion sein soll, verwende diese.
                    // In allen anderen Fällen lies die StartTime aus.
                    // Wenn keine angegeben ist, setze sie auf 0ms.
                    let originalStartTime = -1;
                    if (singleAction.startTimeFunction) {
                        originalStartTime = eval(singleAction.startTimeFunction);
                    } else if (singleAction.startTimePrevious == true) {
                        originalStartTime = this.lastVideoPlayerOriginalStopTime;
                    } else {
                        originalStartTime = singleAction.startTime ? singleAction.startTime : 0;
                    }

                    // Wenn die EndTime per Zufallsgenerator ermittelt wird, ermittle sie.
                    // In allen anderen Fällen lies die EndTime aus.
                    // Wenn keine angegeben ist, setze sie auf vorerst auf -999999. Sobald der Videoplayer definiert ist, wird an Stelle dieser Zahl die duration des Videos ausgelesen.
                    let originalEndTime = -1;
                    if (singleAction.endTimeRandom == true && singleAction.endTimeMin != null && singleAction.endTimeMax != null) {
                        let endTimeGefunden = false;
                        while (!endTimeGefunden) {
                            originalEndTime = Math.floor(Math.random() * (singleAction.endTimeMax - singleAction.endTimeMin) + singleAction.endTimeMin);
                            // Die zufällige Endtime nur akzeptieren, wenn der Abstand zur Starttime mindestens 50ms ist.
                            endTimeGefunden = (Math.abs(originalEndTime - originalStartTime) > 50);
                        }
                        if (originalEndTime < originalStartTime) { playBackRateUmkehren = playBackRateUmkehren * (-1); }
                    } else {
                        originalEndTime = singleAction.endTime ? singleAction.endTime : -999999;
                    }

                    //console.log("originalEndTime: " + originalEndTime);


                    ////////////////////////////////////////////////////////////////////////////////
                    /////// PlayBackRate berechnen, bezogen auf das Ausgangsvideo (Original) ///////
                    ////////////////////////////////////////////////////////////////////////////////

                    // Wenn die PlayBackRate durch eine Funktion berechnet wird, tue dies.
                    // Wenn sie umgedreht werden soll, dreh sie um.
                    // Sonst lies sie aus.
                    let originalPlayBackRate = 1;
                    if (singleAction.playBackRateFunction) {
                        originalPlayBackRate = eval(singleAction.playBackRateFunction);
                    } else if (singleAction.playBackRateCommand == "reverse") {
                        originalPlayBackRate = this.lastVideoPlayerOriginalPlayBackRate * (-1);
                    } else {
                        originalPlayBackRate = singleAction.playBackRate ? singleAction.playBackRate : 1.0;
                    }
                    // Falls zuvor im Zufallsverfahren eine endTime ermittelt wurde, die kleiner ist als die startTime:
                    originalPlayBackRate = originalPlayBackRate * playBackRateUmkehren;

                    // Dem Player je nach Vorzeichen der playBackRate das Vorwärts- oder Rückwärtsvideo zuweisen
                    let videoPlayer: HTMLVideoElement;
                    let videoPlayerBlend: HTMLVideoElement;
                    if (originalPlayBackRate > 0) {
                        videoPlayer = <HTMLVideoElement>this.bySelector(selector);
                        if (this.hasBlendAnimations) {
                            videoPlayerBlend = <HTMLVideoElement>this.bySelector(selector + "-blend");
                        }
                    } else {
                        videoPlayer = <HTMLVideoElement>this.bySelector(selector + "-REV");
                        if (this.hasBlendAnimations) {
                            videoPlayerBlend = <HTMLVideoElement>this.bySelector(selector + "-REV-blend");
                        }
                    }

                    // Den Dummy -999999 ggf. mit der korrekten Endposition ersetzen
                    if (originalEndTime == -999999) {
                        if (originalPlayBackRate > 0) {
                            if (videoPlayer.duration) {
                                originalEndTime = videoPlayer.duration * 1000;
                            } else {
                                //console.log("mediaTitle: " + mediaTitle);
                                let markedDuration = this.videoList[mediaTitle].duration;
                                if (markedDuration) {
                                    originalEndTime = markedDuration;
                                }
                            }
                        } else {
                            originalEndTime = 0;
                        }
                    }


                    /////////////////////////////////////////////////////////////////////////////
                    /////// Reale StartTime und EndTime berechnen, bezogen auf den Player ///////
                    /////////////////////////////////////////////////////////////////////////////
                    let playerStartTime = 0;
                    let playerEndTime = 0;
                    //console.log("playerStartTime Point 0: " + playerStartTime);

                    if (originalPlayBackRate > 0) {
                        playerStartTime = originalStartTime;
                        //console.log("playerStartTime Point 1: " + playerStartTime);
                        playerEndTime = originalEndTime;
                    } else {
                        // -1 bedeutet: Ganz am Ende des Originalvideos starten = am Beginn des REV-Videos
                        if (originalStartTime == -1) {
                            playerStartTime = 0;
                            //console.log("playerStartTime Point 2: " + playerStartTime);
                        } else {
                            if (videoPlayer.duration) {
                                playerStartTime = (videoPlayer.duration * 1000) - originalStartTime;
                                //console.log("playerStartTime Point 3: " + playerStartTime);
                            } else {
                                let markedDuration = this.videoList[mediaTitle].duration;
                                if (markedDuration) {
                                    playerStartTime = markedDuration - originalStartTime;
                                    //console.log("playerStartTime Point 4: " + playerStartTime);
                                    // Dies ist nur ein schneller Hack, weil ein neugeneriertes Objekt offenbar noch nicht sofort auf
                                    // videoPlayer.duration zugreifen kann. Für diejenigen Objekte, die neugeneriert als erstes
                                    // im Rückwärtsgang abgespielt werden und dabei nicht von ganz hinten starten, habe ich daher
                                    // die Duration manuell in eine Variable geschrieben.
                                }
                            }
                        }
                        if (videoPlayer.duration) {
                            playerEndTime = (videoPlayer.duration * 1000) - originalEndTime;
                        } else {
                            let markedDuration = this.videoList[mediaTitle].duration;
                            if (markedDuration) {
                                playerEndTime = markedDuration - originalEndTime;
                            }
                        }
                    }


                    ///////////////////////////////////////////////////
                    /////// Die aktuelle Videoposition auslesen ///////
                    ///////////////////////////////////////////////////
                    let originalCurrentTime = 0;
                    if (!startVideo) {
                        // Wenn die Playbackrate des letzten gespielten Videos positiv war, wird die currentTime
                        // des Vorwärtsvideos ausgelesen, andernfalls die des Rückwärtsvideos.
                        if (this.lastVideoPlayerOriginalPlayBackRate > 0) {
                            originalCurrentTime = this.lastVideoPlayer.currentTime * 1000;
                            //console.log("originalCurrentTime 1: " + originalCurrentTime);
                        } else {
                            let duration: number = 0;
                            if (videoPlayer.duration) {
                                duration = videoPlayer.duration * 1000;
                            } else {
                                let duration_questionmark = this.videoList[mediaTitle].duration;
                                if (duration_questionmark) {
                                    duration = duration_questionmark;
                                }
                            }
                            originalCurrentTime = duration - (this.lastVideoPlayer.currentTime * 1000);
                            //console.log("lastVideoPlayer.currentTime:" + (this.lastVideoPlayer.currentTime * 1000);
                            //console.log("videoPlayer.duration: " + videoPlayer.duration);
                            //console.log("this.lastVideoPlayer.currentTime: " + this.lastVideoPlayer.currentTime);
                            //console.log("originalCurrentTime 2: " + originalCurrentTime);
                        }
                        // Aus der Playbackrate, bezogen auf das Originalvideo, die Playbackrate bezogen auf den Player berechnen
                        if (originalPlayBackRate > 0) {
                            playerStartTime = originalCurrentTime;
                            //console.log("playerStartTime Point 5: " + playerStartTime);
                        } else {
                            let duration: number = 0;
                            if (videoPlayer.duration) {
                                duration = videoPlayer.duration * 1000;
                            } else {
                                let duration_questionmark = this.videoList[mediaTitle].duration;
                                if (duration_questionmark) {
                                    duration = duration_questionmark;
                                }
                            }
                            playerStartTime = duration - originalCurrentTime;
                            //console.log("playerStartTime Point 6: " + playerStartTime);
                        }
                        if (Math.sign(originalPlayBackRate) != Math.sign(this.lastVideoPlayerOriginalPlayBackRate)) {
                            this.lastVideoPlayer.pause();
                            //console.log("pause: " + this.lastVideoPlayer.id);
                        }
                    }

                    let playerPlayBackRate = Math.abs(originalPlayBackRate);

                    ///////////////////////////////////////////////
                    /////// Stop des Videoplayers schedulen ///////
                    ///////////////////////////////////////////////
                    if (singleAction.endTime || singleAction.endTimeRandom == true) {
                        Tone.Transport.schedule(function (time) {
                            Tone.Draw.schedule(function () {
                                videoPlayer.pause();
                                //console.log("paused: " + videoPlayer.id);
                                if (hasBlendAnimations) {
                                    videoPlayerBlend.pause();
                                }
                            }, time)
                        }, Tone.Transport.seconds + (playerEndTime - playerStartTime - 83.333) / (1000 * playerPlayBackRate))
                        //console.log("Schedule end: " + (playerEndTime - playerStartTime - 83.333) / (1000 * playerPlayBackRate));
                    }

                    ////////////////////////////////////////////////////////////////////////
                    /////// Ersetzung des Videoplayers durch ein Standbild schedulen ///////
                    ////////////////////////////////////////////////////////////////////////
                    const hideVideoPlayer = () => {
                        videoPlayer.removeEventListener('ended', hideVideoPlayer);
                        videoPlayer.removeEventListener('pause', hideVideoPlayer);
                        //console.log("Removed Listener for videoPlayer: " + videoPlayer.id );
                        if (this.eventListenerAppliesTo == videoPlayer.id) {
                            let transportStartTime = Tone.Transport.seconds;
                            Tone.Transport.schedule(function (time) {
                                Tone.Draw.schedule(function () {
                                    hide(videoPlayer);
                                }, time)
                            }, transportStartTime + this.mediator.browserOffsetForVideoStop)

                            //hide(videoPlayer);
                            if (hasBlendAnimations) { hide(videoPlayerBlend); }
                            show(IMGDiv, "display");
                            //console.log("Show Image: " + IMGDiv.src);

                            if (hasBlendAnimations && this.blendingActive) {
                                show(IMGBlendDiv, "display");
                            }
                        }
                    }
                    if (this.lastVideoPlayer) {
                        this.lastVideoPlayer.removeEventListener('ended', hideVideoPlayer);
                        this.lastVideoPlayer.removeEventListener('pause', hideVideoPlayer);
                        //console.log("Removed Listener for lastVideoPlayer: " + this.lastVideoPlayer.id );
                    }
                    videoPlayer.addEventListener('ended', hideVideoPlayer);
                    videoPlayer.addEventListener('pause', hideVideoPlayer);
                    this.eventListenerAppliesTo = videoPlayer.id;
                    //console.log("Set Listener for videoPlayer: " + videoPlayer.id);

                    let IMGDiv = <HTMLImageElement>this.bySelector("#" + this.objDiv.id + "-img");
                    let frameRate = 30;
                    let videoInformation = this.videoList[mediaTitle];
                    if (videoInformation) {
                        frameRate = videoInformation.frameRate ? videoInformation.frameRate : 30;
                    }
                    let frame = Math.round(originalEndTime / (1000 / frameRate));
                    if (frame == 0) { frame = 1; }    // Das ist ein bisschen Dirty. Eigentlich müsste ich bei 1 starten und dann prüfen, ob das File für den letzten Frame tatsächlich vorhanden ist oder ob ein Rundungsfehler vorliegt.
                    if (IMGDiv) {
                        let filenameWithoutPath = mediaTitle + "_frame" + frame.toString().padStart(4, '0') + ".webp";
                        let filename = this.consolidateFilename("media" + this.mediaPfad + "/img/" + filenameWithoutPath);
                        //console.log("filename: " + filename);
                        if (!this.mediator.mediaList[filename]) {
                            this.mediator.loadFileNew(filename, "fileIsDemanded");
                        }
                        let blobURL = this.mediator.mediaList[filename].blobURL;
                        if (blobURL) {
                            IMGDiv.src = blobURL;
                        } else {
                            //console.log("Keine BlobURL für " + mediaTitle + " gefunden.");
                        }
                        //IMGDiv.src = "media" + this.mediaPfad + "/img/" + mediaTitle + "_frame" + frame.toString().padStart(4, '0') + ".webp";
                        hide(IMGDiv, "display");
                        // MediaTitle als Klassennamen vergeben, da das src-Element nicht abfragbar ist (blob)
                        while (IMGDiv.classList.length > 0) {
                            let firstElement = IMGDiv.classList.item(0);
                            if (firstElement) {
                                IMGDiv.classList.remove(firstElement);
                            }
                        }
                        if (singleAction.mediaTitle) {
                            IMGDiv.classList.add(filenameWithoutPath);
                        }

                        //console.log("originalEndTime: " + originalEndTime);
                        //console.log("frameRate: " + frameRate);
                        //console.log("frame: " + frame);
                        //console.log("Set and hide Image: " + IMGDiv.src);
                    }
                    if (hasBlendAnimations) {
                        let IMGBlendDiv = <HTMLImageElement>this.bySelector("#" + this.objDiv.id + "-img-blend");
                        if (IMGBlendDiv) {
                            let filenameWithoutPath = mediaTitle + "_frame" + frame.toString().padStart(4, '0') + ".webp";
                            let filename = this.consolidateFilename("media" + this.mediaPfad + "/img/" + filenameWithoutPath);
                            let blobURL = this.mediator.mediaList[filename].blobURL;
                            if (blobURL) {
                                IMGBlendDiv.src = blobURL;
                            } else {
                                //console.log("Keine BlobURL für " + mediaTitle + " gefunden.");
                            }
                            //IMGBlendDiv.src = "media" + this.mediaPfad + "/img/" + mediaTitle + "_frame" + frame.toString().padStart(4, '0') + ".webp";
                            hide(IMGBlendDiv, "display");
                            // MediaTitle als Klassennamen vergeben, da das src-Element nicht abfragbar ist (blob)
                            while (IMGBlendDiv.classList.length > 0) {
                                let firstElement = IMGBlendDiv.classList.item(0);
                                if (firstElement) {
                                    IMGBlendDiv.classList.remove(firstElement);
                                }
                            }
                            if (singleAction.mediaTitle) {
                                IMGBlendDiv.classList.add(filenameWithoutPath);
                            }
                        }
                    }


                    //////////////////////////////////////
                    /////// Followaction schedulen ///////
                    //////////////////////////////////////
                    if (singleAction.followAction) {
                        let followAction = this.findActionNumber(singleAction.followAction.group, singleAction.followAction.action);
                        const addFollowAction = () => {
                            videoPlayer.removeEventListener('ended', addFollowAction);
                            videoPlayer.removeEventListener('pause', addFollowAction);
                            this.executeAction(followAction);
                        }
                        videoPlayer.addEventListener('ended', addFollowAction);
                        videoPlayer.addEventListener('pause', addFollowAction);
                    }


                    ///////////////////////////////////////////////////////////////
                    /////// Alle anderen Videoplayer des Objekts verstecken ///////
                    ///////////////////////////////////////////////////////////////
                    let allVideoPlayers = document.querySelectorAll("#" + this.name + " .animation-wrapper video");
                    for (let currentVideoPlayer of allVideoPlayers) {
                        hide(currentVideoPlayer);
                    }
                    if (hasBlendAnimations) {
                        allVideoPlayers = document.querySelectorAll("#" + this.name + "-blend .animation-wrapper video");
                        for (let currentVideoPlayer of allVideoPlayers) {
                            hide(currentVideoPlayer);
                        }
                    }

                    show(videoPlayer);

                    if (this.blendingActive && this.hasBlendAnimations) {
                        show(videoPlayerBlend);
                    }


                    //console.log("originalStartTime: " + originalStartTime);
                    //console.log("playerStartTime: " + playerStartTime);
                    //console.log("originalEndTime: " + originalEndTime);
                    //console.log("playerEndTime: " + playerEndTime);
                    //////////////////////////////////////////////////
                    /////// Dem Videoplayer die Werte zuweisen ///////
                    //////////////////////////////////////////////////
                    if (startVideo) {
                        playerStartTime = playerStartTime + 33;
                        videoPlayer.loop = loop;
                        if (hasBlendAnimations) { videoPlayerBlend.loop = loop; }
                    } else if (singleAction.loop != null) {
                        videoPlayer.loop = singleAction.loop;
                        if (hasBlendAnimations) { videoPlayerBlend.loop = singleAction.loop; }
                    }

                    //console.log("playerStartTime / 1000.0: " + (playerStartTime / 1000.0));
                    videoPlayer.currentTime = playerStartTime / 1000.0;
                    videoPlayer.playbackRate = playerPlayBackRate;
                    if (hasBlendAnimations) {
                        videoPlayerBlend.currentTime = playerStartTime / 1000.0;
                        videoPlayerBlend.playbackRate = playerPlayBackRate;
                    }

                    if ((videoPlayer.paused || videoPlayer.ended || videoPlayer.currentTime == 0) && stopVideo == false) {
                        videoPlayer.play();
                        if (hasBlendAnimations) { videoPlayerBlend.play(); }
                    }
                    if (stopVideo == true) {
                        videoPlayer.pause();
                        if (hasBlendAnimations) { videoPlayerBlend.pause(); }
                    }
                    this.lastVideoPlayerOriginalStopTime = originalEndTime;
                    this.lastVideoPlayerOriginalPlayBackRate = originalPlayBackRate;
                    this.lastVideoPlayer = videoPlayer;

                    /*
                    // console.log("transport: " + Tone.Transport.seconds);
                    mediaTitle = singleAction.mediaTitle ? "#" + singleAction.mediaTitle + "-video" : "";
                    mediaTitlePlain = singleAction.mediaTitle ? singleAction.mediaTitle : "";

                    selector = singleAction.selector != null ? singleAction.selector : "#" + this.name + "→" + mediaTitlePlain + "-video";

                    let videoPlayerNameFORW = selector == "" ? mediaTitle : selector;
                    let videoPlayerFORW = <HTMLVideoElement>this.bySelector(videoPlayerNameFORW);
                    let videoPlayer = videoPlayerFORW;

                    let videoPlayerNameFORWBlend = selector == "" ? mediaTitle + "-blend" : selector + "-blend";
                    let videoPlayerFORWBlend = <HTMLVideoElement>this.bySelector(videoPlayerNameFORWBlend);
                    let videoPlayerBlend = videoPlayerFORWBlend;

                    let videoPlayerNameREV = videoPlayerNameFORW + "-REV";
                    let videoPlayerNameREVBlend = videoPlayerNameFORW + "-REV-blend";

                    let videoPlayerREV = videoPlayerFORW;
                    let videoPlayerREVBlend = videoPlayerFORWBlend;

                    let videoPlayerINV = videoPlayerFORW;
                    let videoPlayerINVBlend = videoPlayerFORWBlend;

                    let doesREVexist = this.doesREVexist(mediaTitlePlain);

                    let stopVideo = singleAction.stopVideo ? singleAction.stopVideo : false;

                    let startVideo = true;
                    if (singleAction.startVideo != null) { startVideo = singleAction.startVideo; }
                    let currentTime = videoPlayerFORW.currentTime * 1000;
                    //console.log("startTime Point 1: " + startTime);
                    let playBackRate = singleAction.playBackRate ? singleAction.playBackRate : 1.0;
                    if (singleAction.playBackRateFunction) {
                        playBackRate = eval(singleAction.playBackRateFunction);
                    }
/*                    if (singleAction.playBackCurve) {
                        playBackRate = singleAction.playBackCurve[0].playBackRate;
                    }*/

                    /*                    let loop = singleAction.loop ? singleAction.loop : false;
                                        let startTime = singleAction.startTime ? singleAction.startTime : 0;
                                        if (singleAction.startTimeFunction) {
                                            startTime = eval(singleAction.startTimeFunction);
                                        }
                                        let endTime = singleAction.endTime ? singleAction.endTime : (videoPlayerFORW.duration * 1000);
                                        if (singleAction.startTimePrevious == true) {
                                            startTime = this.lastVideoPlayerStopTime;
                                            // console.log("Die Startzeit ist bei: " + startTime);
                                        }
                    
                                        if (singleAction.endTimeRandom == true && singleAction.endTimeMin != null && singleAction.endTimeMax != null) {
                                            let endTimeGefunden = false;
                                            while (!endTimeGefunden) {
                                                endTime = Math.floor(Math.random() * (singleAction.endTimeMax - singleAction.endTimeMin) + singleAction.endTimeMin);
                                                endTimeGefunden = (Math.abs(endTime - startTime) > 50);
                                            }
                                            if (endTime < startTime) { playBackRate = playBackRate * (-1); }
                                            // console.log("Die Endzeit beginnt bei: " + endTime);
                                            // console.log("playBackRate: " + playBackRate);
                                        }
                                        // console.log("startTime Point 2: " + startTime);
                    
                                        if (doesREVexist) {
                                            videoPlayerREV = <HTMLVideoElement>this.bySelector(videoPlayerNameREV);
                                            videoPlayerREVBlend = <HTMLVideoElement>this.bySelector(videoPlayerNameREVBlend);
                    
                                            let oldPlayBackRateSign = 1;
                                            if (isVisible(videoPlayerREV)) { oldPlayBackRateSign = -1; }
                    
                                            if (singleAction.playBackRateCommand == "reverse") {
                                                if (oldPlayBackRateSign == 1) { playBackRate = 0 - videoPlayerFORW.playbackRate; }
                                                if (oldPlayBackRateSign == -1) { playBackRate = videoPlayerREV.playbackRate; }
                                            }
                                            if (playBackRate > 0) {
                                                hide(videoPlayerREV);
                                                videoPlayerINV = videoPlayerREV;
                                                videoPlayerINVBlend = videoPlayerREVBlend;
                                            } else {
                                                // console.log("HIERY!");
                                                // console.log("HIERY! Starttime: " + startTime);
                                                // console.log("HIERY! Endtime: " + endTime);
                                                hide(videoPlayerFORW);
                                                videoPlayer = videoPlayerREV;
                                                videoPlayerBlend = videoPlayerREVBlend;
                                                videoPlayerINV = videoPlayerFORW;
                                                videoPlayerINVBlend = videoPlayerFORWBlend;
                                                if (startTime == -1) {
                                                    startTime = 0;
                                                } else {
                                                    // console.log("startTime Point 2b: " + startTime);
                                                    if (videoPlayer.duration) {
                                                        startTime = (videoPlayer.duration * 1000) - startTime;
                                                    } else {
                                                        startTime = this.videoDurations[mediaTitlePlain] - startTime;
                                                        // Dies ist nur ein schneller Hack, weil ein neugeneriertes Objekt offenbar noch nicht sofort auf
                                                        // videoPlayer.duration zugreifen kann. Für diejenigen Objekte, die neugeneriert als erstes
                                                        // im Rückwärtsgang abgespielt werden und dabei nicht von ganz hinten starten, habe ich daher
                                                        // die Duration manuell in eine Variable geschrieben.
                                                    }
                                                    // console.log("startTime Point 2c: " + startTime);
                                                }
                                                endTime = (videoPlayer.duration * 1000) - endTime;
                                            }
                                            if (!startVideo) {
                                                if (Math.sign(playBackRate) == oldPlayBackRateSign) {
                                                    currentTime = videoPlayer.currentTime * 1000;
                                                } else {
                                                    if (playBackRate > 0) {
                                                        currentTime = (videoPlayer.duration - videoPlayerREV.currentTime) * 1000;
                                                        startTime = currentTime;
                                                    } else {
                                                        currentTime = (videoPlayer.duration - videoPlayerFORW.currentTime) * 1000;
                                                        startTime = currentTime;
                                                    }
                                                }
                                            }
                                        }
                                        //console.log("startTime Point 3: " + startTime);
                                        show(videoPlayer);
                                        show(videoPlayerBlend);
                                        playBackRate = Math.abs(playBackRate);
                                        videoPlayer.playbackRate = playBackRate;
                                        videoPlayerBlend.playbackRate = playBackRate;
                                        // console.log("Videodauer: " + videoPlayer.duration);
                    
                                        // schedule pause if an endTime is defined
                                        if (singleAction.endTime || singleAction.endTimeRandom == true) {
                                            // console.log("startTime " + startTime/1000);
                                            // console.log("endtime " + endTime/1000);
                                            let transportStartTime = Tone.Transport.seconds;
                                            //let calcEndTime = transportStartTime + (endTime-startTime)/1000
                                            Tone.Transport.schedule(function (time) {
                                                //use the time argument to schedule a callback with Tone.Draw
                                                Tone.Draw.schedule(function () {
                                                    videoPlayer.pause();
                                                    videoPlayerBlend.pause();
                                                    // console.log("PauseTime " + videoPlayer.currentTime);
                                                    // console.log("Name: " + videoPlayer.id);
                                                    if (doesREVexist) {
                                                        videoPlayerINV.currentTime = videoPlayer.duration - videoPlayer.currentTime;
                                                        videoPlayerINVBlend.currentTime = videoPlayer.duration - videoPlayer.currentTime;
                                                    }
                                                }, time)
                                            }, transportStartTime + (endTime - startTime - 83.333) / (1000 * playBackRate))
                                        }
                    
                                        // add listener for replacing video player with image after stop
                                        const hideVideoPlayer = () => {
                                            videoPlayer.removeEventListener('ended', hideVideoPlayer);
                                            videoPlayer.removeEventListener('pause', hideVideoPlayer);
                                            hide(videoPlayer);
                                            //console.log("Verstecke Videoplayer: " + videoPlayer.id);
                                            hide(videoPlayerBlend);
                                            //console.log("Verstecke Videoplayer: " + videoPlayerBlend.id);
                                            show(IMGDiv);
                                            //console.log("Zeige Bild: " + IMGDiv.src);
                                        }
                                        videoPlayer.addEventListener('ended', hideVideoPlayer);
                                        videoPlayer.addEventListener('pause', hideVideoPlayer);
                                        let IMGDiv = <HTMLImageElement>this.bySelector("#" + this.objDiv.id + "-img");
                                        let IMGBlendDiv = <HTMLImageElement>this.bySelector("#" + this.objDiv.id + "-img-blend");
                                        let frame: number = 1;
                                        if ((singleAction.playBackRate ? singleAction.playBackRate : 1.0) > 0) {
                                            frame = Math.round(endTime / 33.3333);
                                        } else {
                                            //console.log("endTime: " + endTime);
                                            frame = Math.round(((videoPlayer.duration * 1000) - endTime) / 33.3333);
                                        }
                                        if (IMGDiv) {
                                            IMGDiv.src = "media" + this.mediaPfad + "/img/" + mediaTitlePlain + "_frame" + frame.toString().padStart(4, '0') + ".webp";
                                            //console.log("Weise Bild zu: " + IMGDiv.src);
                                        }
                                        if (IMGBlendDiv) {
                                            IMGBlendDiv.src = "media" + this.mediaPfad + "/img/" + mediaTitlePlain + "_frame" + frame.toString().padStart(4, '0') + ".webp";
                                            //console.log("Weise BlendBild zu: " + IMGBlendDiv.src);
                                        }
                    
                    
                                        // add listener for media ended event
                                        if (singleAction.followAction) {
                                            let followAction = this.findActionNumber(singleAction.followAction.group, singleAction.followAction.action);
                    
                                            const addFollowAction = () => {
                                                videoPlayer.removeEventListener('ended', addFollowAction);
                                                videoPlayer.removeEventListener('pause', addFollowAction);
                                                this.executeAction(followAction);
                                            }
                                            videoPlayer.addEventListener('ended', addFollowAction);
                                            videoPlayer.addEventListener('pause', addFollowAction);
                                        }
                    
                                        // hide all images
                                        let allImages = document.querySelectorAll("#" + this.name + " .animation-wrapper img");
                                        for (let currentImage of allImages) {
                                            hide(currentImage);
                                        }
                                        allImages = document.querySelectorAll("#" + this.name + "-blend .animation-wrapper img");
                                        for (let currentImage of allImages) {
                                            hide(currentImage);
                                        }
                    
                                        // hide previuos videoPlayer
                                        let videoPlayerFORWOld = <HTMLVideoElement>this.bySelector(this.videoPlayerNameFORWOld);
                                        let videoPlayerREVOld = <HTMLVideoElement>this.bySelector(this.videoPlayerNameREVOld);
                                        let videoPlayerFORWOldBlend = <HTMLVideoElement>this.bySelector(this.videoPlayerNameFORWOldBlend);
                                        let videoPlayerREVOldBlend = <HTMLVideoElement>this.bySelector(this.videoPlayerNameREVOldBlend);
                                        if (videoPlayerNameFORW != this.videoPlayerNameFORWOld) {
                    /*                        if (this.startImageURI) {
                                                hide(this.startImageNode);
                                                // console.log("Startbild versteckt! - " + this.name);
                                            }*//*
                    if (videoPlayerFORWOld != null) {
                        hide(videoPlayerFORWOld);
                        videoPlayerFORWOld.pause();
                        videoPlayerFORWOld.currentTime = 0;
                    }
                    if (videoPlayerREVOld != null) {
                        hide(videoPlayerREVOld);
                        videoPlayerREVOld.pause();
                        videoPlayerREVOld.currentTime = 0;
                    }
                    if (videoPlayerFORWOldBlend != null) {
                        if (isVisible(videoPlayerFORWOldBlend)) {
                            show(videoPlayerBlend);              // Wenn beim Vorgängervideo der Blend-Player noch sichtbar ist, soll er das beim jetzigen Nachfolgevideo auch sein. Dann wird der Blend-Player nämlich offenbar gerade benötigt!
                        }
                        hide(videoPlayerFORWOldBlend);
                        videoPlayerFORWOldBlend.pause();
                        videoPlayerFORWOldBlend.currentTime = 0;
                    }
                    if (videoPlayerREVOldBlend != null) {
                        if (isVisible(videoPlayerREVOldBlend)) {
                            show(videoPlayerBlend);
                        }
                        hide(videoPlayerREVOldBlend);
                        videoPlayerREVOldBlend.pause();
                        videoPlayerREVOldBlend.currentTime = 0;
                    }
                }

                if (startVideo) {
                    currentTime = startTime + 33;
                    videoPlayer.loop = loop;
                    videoPlayerBlend.loop = loop;
                } else {
                    if (singleAction.loop != null) {
                        videoPlayer.loop = singleAction.loop;
                        videoPlayerBlend.loop = singleAction.loop;
                    }
                }
                // console.log("startTime Point 4: " + startTime);
                videoPlayer.currentTime = currentTime / 1000.0;
                videoPlayerBlend.currentTime = currentTime / 1000.0;
                if ((videoPlayer.paused || videoPlayer.ended || videoPlayer.currentTime == 0) && stopVideo == false) {
                    videoPlayer.play();
                    videoPlayerBlend.play();
                    // console.log("Playing: " + videoPlayer.id);
                    // console.log("Playing: " + videoPlayerBlend.id);
                }
                if (stopVideo == true) {
                    videoPlayer.pause();
                    videoPlayerBlend.pause();
                }
                this.videoPlayerNameFORWOld = videoPlayerNameFORW;
                this.videoPlayerNameREVOld = videoPlayerNameREV;
                this.videoPlayerNameFORWOldBlend = videoPlayerNameFORWBlend;
                this.videoPlayerNameREVOldBlend = videoPlayerNameREVBlend;
                this.lastVideoPlayerStopTime = endTime;
                if (videoPlayer.id.includes("REV")) {
                    this.lastVideoPlayerStopTime = (videoPlayer.duration * 1000.0) - endTime;
                }*/
                    break;


                ////// Activate Object //////    
                case ActionType.ActivateObject:
                    targetObject = singleAction.targetObject ? singleAction.targetObject.replaceAll('$$$', this.name) : "";
                    targetAction = singleAction.targetAction ? singleAction.targetAction : { group: 0, action: 1 };
                    if (this.byID(targetObject) == null && this.mediator.objects[targetObject] instanceof TextDiv) {
                        let object = <TextDiv>this.mediator.objects[targetObject];
                        //console.log("prepared lu " + targetObject + "with targetAction: " + JSON.stringify(targetAction));
                        object.prepareObject().then(() => { object.onActivation(targetAction) });
                        //setTimeout(() => object.onActivation(targetAction), 500);
                        //.then(() => { object.onActivation(targetAction) });
                        object.testemich(targetAction);
                        //object.onActivation(targetAction);
                    } else if (this.byID(targetObject) == null && this.mediator.objects[targetObject] instanceof VideoDiv) {
                        let object = <VideoDiv>this.mediator.objects[targetObject];
                        object.prepareObject().then(() => { object.onActivation(targetAction) });
                        //console.log("prepared lu " + targetObject);
                    } else {
                        this.mediator.activateObject(targetObject, targetAction);
                    }
                    break;

                ////// Telecontrol Object //////    
                case ActionType.TelecontrolObject:
                    targetObject = singleAction.targetObject ? singleAction.targetObject.replaceAll('$$$', this.name) : "";
                    targetAction = singleAction.targetAction ? singleAction.targetAction : { group: 0, action: 1 };
                    this.mediator.activateObject(targetObject, targetAction);
                    break;

                ////// Generate Instances //////
                case ActionType.GenerateInstances:
                    targetObject = singleAction.targetObject ? singleAction.targetObject : "";
                    targetAction = singleAction.targetAction ? singleAction.targetAction : { group: 0, action: 1 };
                    let nrOfInstances = singleAction.nrOfInstances ? singleAction.nrOfInstances : 0;
                    let neuenummern: number[] = [];
                    let startpunkt = 0;
                    //console.log(this.mediator.objects);
                    //console.log(targetObject);
                    for (let i = 1; i <= 1000; i++) {
                        if (document.getElementById(this.name + "→child" + this.mediator.objects[targetObject].name + i.toString().padStart(2, '0')) == null) {
                            neuenummern.push(i);
                            if (neuenummern.length == nrOfInstances) { break; }
                        }
                    }
                    let inheritMotherScale = singleAction.inheritMotherScale != null ? singleAction.inheritMotherScale : true;
                    let j = 0;
                    for (let i of neuenummern) {
                        j++;
                        this.mediator.copyObject(this.mediator.objects[targetObject], this.name, this.name + "→child" + this.mediator.objects[targetObject].name + i.toString().padStart(2, '0'), targetAction, j, inheritMotherScale);
                        //console.log(this.name + "→child" + this.mediator.objects[targetObject].name + i.toString().padStart(2, '0'));
                    }
                    break;

                ////// Show Element //////    
                case ActionType.ShowElement:
                    targetObjectSelector = singleAction.targetObjectSelector ? singleAction.targetObjectSelector.replaceAll('$$$', this.name) : "#" + this.name;
                    //console.log(this.name + " tries to show " + targetObjectSelector);
                    targetObjectDOM = <HTMLElement>this.bySelector(targetObjectSelector);
                    show(targetObjectDOM);
                    break;

                ////// Hide Element //////    
                case ActionType.HideElement:
                    targetObjectSelector = singleAction.targetObjectSelector ? singleAction.targetObjectSelector.replaceAll('$$$', this.name) : "#" + this.name;
                    targetObjectDOM = <HTMLElement>this.bySelector(targetObjectSelector);
                    //console.log("trying to remove " + this.name);
                    targetObjectDOM.remove();
                    if (this.hasBlendAnimations) {
                        let targetObjectDOMBlend = <HTMLElement>this.bySelector(targetObjectSelector + "-blend");
                        targetObjectDOMBlend.remove();
                    }
                    if (targetObjectSelector == "#" + this.name) {
                        this.mediator.gameObjectsCurrentlyExisting.splice(this.mediator.gameObjectsCurrentlyExisting.indexOf(this.name), 1)
                    }
                    this.removeHTML();
                    /*                    hide(targetObjectDOM);
                                        let videoElements = targetObjectDOM.querySelectorAll("video");
                                        for (let videoElement of videoElements) {
                                            hide(videoElement);
                                        }
                                        if (this.clickNode) {
                                            this.clickNode.remove();
                                        }*/
                    break;

                ////// Add Class //////
                /*case ActionType.AddClass:
                    targetObjectSelector = singleAction.targetObjectSelector ? singleAction.targetObjectSelector : "";
                    newClass = singleAction.newClass ? singleAction.newClass : "";
                    let removeAtEnd = singleAction.removeAtEnd ? singleAction.removeAtEnd : false;
                    targetObjectDOM = <HTMLElement>this.bySelector(targetObjectSelector);
                    if (targetObjectDOM == null) {
                        // console.log("couldn't find " + targetObjectSelector);
                    }
                    targetObjectDOM.classList.add(newClass);
                    if (removeAtEnd) {
                        targetObjectDOM.addEventListener("animationend", this.removeClassAtEnd.bind(this, targetObjectDOM, newClass), false);
                    }
                    break;
*/

                ////// Remove Class //////
                case ActionType.RemoveClass:
                    targetObjectSelector = singleAction.targetObjectSelector ? singleAction.targetObjectSelector : "";
                    let oldClass = singleAction.newClass ? singleAction.newClass : "";
                    targetObjectDOM = <HTMLElement>this.bySelector(targetObjectSelector);
                    if (targetObjectDOM == null) {
                        // console.log("couldn't find" + targetObjectSelector);
                    }
                    targetObjectDOM.classList.remove(oldClass);

                    break;

                ////// Change Animation //////
                case ActionType.ChangeAnimation:
                    targetObjectSelector = singleAction.targetObjectSelector ? singleAction.targetObjectSelector.replaceAll('$$$', this.name) : "#" + this.name;
                    let targetObjectSelectorBlend = targetObjectSelector + "-blend";
                    let targetObjectDOMBlend;
                    newClass = singleAction.newClass ? singleAction.newClass : "";
                    if (singleAction.constructNewClass) {
                        let placeholder = eval(singleAction.constructNewClass.placeholder);
                        newClass = singleAction.constructNewClass.className.replace("$PLACEHOLDER$", placeholder);
                        //console.log("constructedNewClass: " + newClass);
                    }

                    // Bestimmung des Namens des AnimationDIVs
                    let animationDiv = singleAction.animationDiv ? singleAction.animationDiv : newClass.slice(newClass.lastIndexOf("_") + 1);

                    // Klassennamen, die auf "a" enden, bedeuten eine Blend-Animation
                    let isBlendAnimation = newClass.slice(-1) == "a" && newClass.slice(-9) != "clickArea";
                    if (isBlendAnimation) { break; }

                    // Große zappelnde Objekte sollen zur Ressourcenschonung nicht verformt werden.
                    if (this.state.includes("large") && this.state.includes("zappeln") &&
                        (animationDiv == "skew" || animationDiv == "rotate3d")) {
                        //console.log("Dem " + this.objDiv.id + " wurde die Animation " + animationDiv + " verweigert. Grund: State=large.");
                        break;
                    }

                    if (animationDiv == "rotate3d") { console.log("Hier ist rotate3d noch da!"); }

                    // Prüfen, ob die gewünschte Animation zugelassen ist.
                    let isAllowed = true;
                    if (this.allowedCSSAnimationTypes) {
                        this.allowedCSSAnimationTypes.forEach(allowedCSSAnimationType => {
                            if (allowedCSSAnimationType.maxClickCount) {
                                if (animationDiv == allowedCSSAnimationType.animationType &&
                                    this.clickCount > allowedCSSAnimationType.maxClickCount) {
                                    isAllowed = false;
                                }
                                if (isBlendAnimation && allowedCSSAnimationType.animationType == "blend" &&
                                    this.clickCount > allowedCSSAnimationType.maxClickCount) {
                                    isAllowed = false;
                                }
                            }
                            if (allowedCSSAnimationType.maxGlobalClickCount) {
                                if (animationDiv == allowedCSSAnimationType.animationType &&
                                    this.mediator.globalClickCount > allowedCSSAnimationType.maxGlobalClickCount) {
                                    isAllowed = false;
                                }
                                if (isBlendAnimation && allowedCSSAnimationType.animationType == "blend" &&
                                    this.mediator.globalClickCount > allowedCSSAnimationType.maxGlobalClickCount) {
                                    isAllowed = false;
                                }
                            }
                        })
                    }
                    //console.log("Möchte: " + animationDiv + ". Erlaubt: " + isAllowed + " bei GlobalClickCount " + this.mediator.globalClickCount);
                    if (!isAllowed) { break; }

                    //console.log("targetObjectSelector: " + targetObjectSelector);
                    //console.log("animationDiv: " + animationDiv);

                    if (isBlendAnimation) { this.blendingActive = true; }

                    // DOM für die Animationen im Haupt-Div sowie für die Kopie im Blend-Div
                    //if (animationDiv != "environment" && animationDiv != "steeldrum" && animationDiv != "clickArea" && animationDiv != "textdiv" && !isBlendAnimation) {
                    //console.log("Hier? " + targetObjectSelector);
                    targetObjectDOM = <HTMLElement>this.bySelector(targetObjectSelector + " ." + animationDiv);
                    targetObjectDOMBlend = <HTMLElement>this.bySelector(targetObjectSelectorBlend + " ." + animationDiv);
                    //}
                    if (animationDiv == "environment") {
                        let classOhneEnv = newClass.slice(0, newClass.lastIndexOf("_"));
                        let environmentAnimationDiv = singleAction.animationDiv ? singleAction.animationDiv : classOhneEnv.slice(classOhneEnv.lastIndexOf("_") + 1);

                        //console.log("Halte Ausschau nach .mother_" + environmentAnimationDiv);
                        targetObjectDOM = <HTMLElement>this.bySelector(".mother_" + environmentAnimationDiv);
                        for (let klasse of targetObjectDOM.classList) {
                            if (klasse.includes("cssanim")) { targetObjectDOM.classList.remove(klasse); }
                        }
                        targetObjectDOM.classList.add(newClass);
                        break;
                    }
                    if (animationDiv == "steeldrum") {
                        targetObjectDOM = <HTMLElement>this.bySelector("#steeldrum");
                    }
                    if (animationDiv == "clickArea") {
                        targetObjectDOM = <HTMLElement>this.bySelector(targetObjectSelector + " .clickArea");
                    }
                    if (animationDiv == "textdiv" || animationDiv == "videodiv") {
                        targetObjectDOM = <HTMLElement>this.bySelector(targetObjectSelector);

                    }
                    if (isBlendAnimation) {
                        targetObjectDOM = <HTMLElement>this.bySelector(targetObjectSelector + " .animation-wrapper");
                        targetObjectDOMBlend = <HTMLElement>this.bySelector(targetObjectSelectorBlend + " .animation-wrapper");
                    }

                    // Initialisierung möglicherweise benötigter Dach-DOMs für Blend-Animationen (müssen wegen z-Index ganz außen stehen)
                    let targetObjectDOMRoof = <HTMLElement>this.bySelector(targetObjectSelector);
                    let targetObjectDOMBlendRoof = <HTMLElement>this.bySelector(targetObjectSelector);  // Nur zur Initialisierung
                    if (targetObjectDOMBlend) { targetObjectDOMBlendRoof = <HTMLDivElement>this.bySelector(targetObjectSelectorBlend); }

                    if (isBlendAnimation) {
                        show(targetObjectDOMBlendRoof, "display");
                        // ggf. korrespondierendes Video im Blend-DIV synchronisieren und sichtbar machen
                        for (let currentVideo of document.querySelectorAll(targetObjectSelector + " .animation-wrapper video")) {
                            if (isVisible(currentVideo)) {
                                let correspondingBlendVideo = document.querySelector(targetObjectSelectorBlend + " #" + currentVideo.id + "-blend");
                                show(correspondingBlendVideo);
                            }
                        }
                        // ggf. korrespondierendes Bild im Blend-DIV sichtbar machen
                        for (let currentImage of document.querySelectorAll(targetObjectSelector + " .animation-wrapper img")) {
                            if (isVisible(currentImage)) {
                                let correspondingBlendImage = document.querySelector(targetObjectSelectorBlend + " #" + currentImage.id + "-blend");
                                show(correspondingBlendImage);
                            }
                        }
                    }

                    let playBackRateAnim = singleAction.playBackRate;
                    let playBackRateCommand = singleAction.playBackRateCommand;

                    if (playBackRateCommand == "reverse" && targetObjectDOM.getAnimations()[0]) {
                        targetObjectDOM.getAnimations()[0].playbackRate = 0 - targetObjectDOM.getAnimations()[0].playbackRate;
                        if (targetObjectDOMBlend && this.hasBlendAnimations && targetObjectDOMBlend.getAnimations()[0]) {
                            targetObjectDOMBlend.getAnimations()[0].playbackRate = 0 - targetObjectDOMBlend.getAnimations()[0].playbackRate;
                        }
                        break;
                    }

                    //console.log("singleAction.startAnimation: " + singleAction.startAnimation);
                    //console.log("targetObjectDOM.getAnimations()[0]: " + targetObjectDOM.getAnimations()[0]);
                    //console.log("playBackRateAnim: " + playBackRateAnim);
                    if (singleAction.startAnimation == false && targetObjectDOM.getAnimations()[0] && playBackRateAnim) {
                        //console.log("targetObjectDOM: " + targetObjectDOM.id);
                        targetObjectDOM.getAnimations()[0].playbackRate = playBackRateAnim;
                        if (targetObjectDOMBlend) {
                            targetObjectDOMBlend.getAnimations()[0].playbackRate = playBackRateAnim;
                        }
                        break;
                    }

                    //console.log("targetObjectDOM: " + targetObjectDOM);
                    if (newClass != "" && targetObjectDOM != null) {
                        // Die realen aktuellen Werte für Position, Größe und die anderen Modifikationen ermitteln
                        var computedStyle = window.getComputedStyle(targetObjectDOM);
                        var computedStyleRoof = window.getComputedStyle(targetObjectDOMRoof);
                        var left = computedStyle.getPropertyValue('left');
                        var top = computedStyle.getPropertyValue('top');
                        var transform = computedStyle.getPropertyValue('transform');
                        var filter = computedStyle.getPropertyValue('filter');
                        var blend = computedStyleRoof.getPropertyValue('mix-blend-mode');
                        if (animationDiv == "absoluteScale") {
                            this.currentScale = parseFloat(transform.slice(7).split(",")[0]);
                            if (isNaN(this.currentScale)) { this.currentScale = 1; }
                        }

                        // Alle alten Animationen, d.h. alle Klassen, die das Wort "cssanim" enthalten, entfernen
                        for (let klasse of targetObjectDOM.classList) {
                            if (klasse.includes("cssanim")) {
                                targetObjectDOM.classList.remove(klasse);
                                if (targetObjectDOMBlend) { targetObjectDOMBlend.classList.remove(klasse); }
                            }
                        }

                        // Falls es um einen Blend-Mode geht, ebenfalls alle alten Animationen entfernen
                        if (isBlendAnimation) {
                            for (let klasse of targetObjectDOMBlendRoof.classList) {
                                // console.log("Vom BlendRoof: " + klasse);
                                if (klasse.includes("cssanim")) { targetObjectDOMBlendRoof.classList.remove(klasse); }
                            }
                            for (let klasse of targetObjectDOMRoof.classList) {
                                // console.log("Vom Roof: " + klasse);
                                if (klasse.includes("cssanim")) { targetObjectDOMRoof.classList.remove(klasse); }
                            }
                        }

                        // Die realen aktuellen Werte für Position, Größe und die anderen Modifikationen als
                        // Ausgangswerte für die nächste Animation zuweisen
                        if (left != "0px") { targetObjectDOM.style.left = left; if (targetObjectDOMBlend) { targetObjectDOMBlend.style.left = left; } }
                        if (top != "0px") { targetObjectDOM.style.top = top; if (targetObjectDOMBlend) { targetObjectDOMBlend.style.top = top; } }
                        if (transform != "none") { targetObjectDOM.style.transform = transform; if (targetObjectDOMBlend) { targetObjectDOMBlend.style.transform = transform; } }
                        if (filter != "none") { targetObjectDOM.style.filter = filter; if (targetObjectDOMBlend) { targetObjectDOMBlend.style.filter = filter; } }
                        if (blend != "none" && blend != "normal") {
                            // console.log("Added: " + "mixBlendMode_cssanim_" + blend);
                            targetObjectDOMRoof.classList.add("mixBlendMode_cssanim_" + blend);
                        }

                        // Nächste Animation starten
                        targetObjectDOM.offsetHeight;   // For re-starting the same animation in case - see: https://stackoverflow.com/questions/6268508/restart-animation-in-css3-any-better-way-than-removing-the-element
                        targetObjectDOM.classList.add(newClass);
                        if (targetObjectDOMBlend && !isBlendAnimation) {
                            targetObjectDOMBlend.offsetHeight;   // For re-starting the same animation in case - see: https://stackoverflow.com/questions/6268508/restart-animation-in-css3-any-better-way-than-removing-the-element
                            targetObjectDOMBlend.classList.add(newClass);
                        }

                        let removeAtEnd = singleAction.removeAtEnd ? singleAction.removeAtEnd : false;
                        if (removeAtEnd) {
                            targetObjectDOM.addEventListener("animationend", this.removeClassAtEnd.bind(this, targetObjectDOM, newClass), false);
                            if (targetObjectDOMBlend) {
                                targetObjectDOMBlend.addEventListener("animationend", this.removeClassAtEnd.bind(this, targetObjectDOMBlend, newClass), false);
                            }
                        }

                        //targetObjectDOM.addEventListener("animationend", this.checkPosition.bind(this, this, targetObjectDOM, animationDiv), false);                    

                        // Aufgehobene Animationen (z.B. matrix(1,0,0,1,0,0) am Ende entfernen
                        const deleteEmptyAnimations = (targetObjectDOM: HTMLElement, className: string) => {
                            targetObjectDOM.removeEventListener("animationend", deleteEmptyAnimations.bind(this, targetObjectDOM, className), false);
                            var computedStyle = window.getComputedStyle(targetObjectDOM);
                            var transform = computedStyle.getPropertyValue('transform');
                            var filter = computedStyle.getPropertyValue('filter');
                            //console.log(className + " transform: " + transform);
                            //console.log(className + " filter: " + filter);
                            if (transform == "matrix(1, 0, 0, 1, 0, 0)") {
                                targetObjectDOM.classList.remove(className);
                                targetObjectDOM.style.removeProperty("transform");
                            }
                            if (filter == "brightness(1)" || filter == "contrast(1)" ||
                                filter == "hue-rotate(0deg)" || filter == "saturate(1)" ||
                                filter == "blur(0px)") {
                                targetObjectDOM.classList.remove(className);
                                targetObjectDOM.style.removeProperty("filter");
                            }
                        }

                        targetObjectDOM.addEventListener("animationend", deleteEmptyAnimations.bind(this, targetObjectDOM, newClass), false);

                        if (newClass.includes("scheinwerfer_cssanim") && !this.objDiv.id.includes("gehaeuse")) {
                            let glowTimerDOM = <HTMLElement>this.bySelector(targetObjectSelector + " .animation_glow_timer");
                            glowTimerDOM.addEventListener("animationiteration", this.glow.bind(this, targetObjectSelector), false);
                        }

                        const finishBlending = (targetObjectDOMBlend: HTMLElement, targetObjectDOM: HTMLElement, targetObjectDOMRoof: HTMLElement, className: string) => {
                            targetObjectDOMBlendRoof.removeEventListener("animationend", finishBlending.bind(this, targetObjectDOMBlendRoof, targetObjectDOM, targetObjectDOMRoof, singleAction.newClass.slice(0, -1)), false);
                            targetObjectDOMBlend.classList.remove(className + "b");
                            targetObjectDOM.classList.remove(className + "a");
                            for (let klasse of targetObjectDOMRoof.classList) {         // Keine richtig saubere Lösung! Wenn ich dem Roof-Div nicht gewaltsam alle Animationen wegnehme, behält er alle EventListener und ruft die dutzendfach auf...
                                // Sollte es Probleme mit den BlendModes geben, muss ich mir das hier noch genauer anschaun!
                                if (klasse.includes("cssanim")) { targetObjectDOMRoof.classList.remove(klasse); }
                            }
                            targetObjectDOMRoof.classList.add(className + "c");
                            for (let currentVideo of targetObjectDOMBlend.querySelectorAll("video")) {
                                hide(currentVideo);
                            }
                            for (let currentImage of targetObjectDOMBlend.querySelectorAll("img")) {
                                hide(currentImage, "display");
                            }
                            hide(targetObjectDOMBlendRoof, "display");
                            this.blendingActive = false;
                        }

                        // Nächste Animation für die Blend-DIVS starten
                        if (isBlendAnimation) {
                            targetObjectDOMBlendRoof.offsetHeight;   // For re-starting the same animation in case - see: https://stackoverflow.com/questions/6268508/restart-animation-in-css3-any-better-way-than-removing-the-element
                            targetObjectDOMBlendRoof.classList.add(newClass.slice(0, -1) + "b");
                            //console.log("Added Class " + newClass.slice(0, -1) + "b" + " to " + targetObjectDOMBlendRoof.id);
                            targetObjectDOMBlendRoof.addEventListener("animationend", finishBlending.bind(this, targetObjectDOMBlendRoof, targetObjectDOM, targetObjectDOMRoof, singleAction.newClass.slice(0, -1)), false);
                        }
                    }

                    if (singleAction.newProperties) {
                        for (let newProperty of singleAction.newProperties) {
                            let value = eval(newProperty.value);
                            eval("targetObjectDOM.style." + newProperty.property + " = '" + value + "';");
                        }

                    }
                    if (playBackRateAnim && targetObjectDOM.getAnimations()[0]) {
                        targetObjectDOM.getAnimations()[0].playbackRate = playBackRateAnim;
                        if (targetObjectDOMBlend) {
                            targetObjectDOMBlend.getAnimations()[0].playbackRate = playBackRateAnim;
                        }
                    }
                    if (singleAction.metricLevel) {
                        targetObjectDOM.style.animationDuration = (this.mediator.currentMeter.getTimeTillNext(singleAction.metricLevel) * 2).toString() + "ms";
                        if (targetObjectDOMBlend) {
                            targetObjectDOMBlend.style.animationDuration = (this.mediator.currentMeter.getTimeTillNext(singleAction.metricLevel) * 2).toString() + "ms";
                        }
                        // console.log("Bis zur nächsten Eins: " + this.mediator.currentMeter.getTimeTillNext(singleAction.metricLevel));
                    }
                    break;

                ////// Change Position //////
                case ActionType.ChangePosition:
                    targetObjectSelector = singleAction.targetObjectSelector ? singleAction.targetObjectSelector : "";
                    targetObjectDOM = <HTMLElement>this.bySelector(targetObjectSelector);
                    let copyObjectSelector = singleAction.copyObjectSelector ? singleAction.copyObjectSelector : "";
                    let copyObjectDOM = <HTMLElement>this.bySelector(copyObjectSelector);
                    let offsetX = singleAction.offsetX ? singleAction.offsetX : 0;
                    let offsetY = singleAction.offsetY ? singleAction.offsetY : 0;
                    let new_vw = (Number(window.getComputedStyle(copyObjectDOM).getPropertyValue('left').replace('px', '')) / window.innerWidth * 100 + offsetX).toString() + "vw";
                    let new_vh = (Number(window.getComputedStyle(copyObjectDOM).getPropertyValue('top').replace('px', '')) / window.innerHeight * 100 + offsetY).toString() + "vh";
                    targetObjectDOM.style.left = new_vw;
                    targetObjectDOM.style.top = new_vh;
                    break

                ////// Wait //////
                case ActionType.Wait:
                    if (singleAction.followAction) {
                        let followAction = this.findActionNumber(singleAction.followAction.group, singleAction.followAction.action);
                        let duration = singleAction.endTime ? singleAction.endTime : 1000;
                        let aktClickCount = this.clickCount;
                        let icke = this;

                        if (this.mediator.audioContextStarted) {
                            Tone.Transport.schedule(function (time) {
                                Tone.Draw.schedule(function () {
                                    //console.log("Soll Follow-Action spielen, aber nur bei ClickCount: " + aktClickCount + ". Aktueller ClickCount: " + icke.clickCount);
                                    if (aktClickCount == icke.clickCount) {
                                        icke.executeAction(followAction)
                                    }
                                }, time)
                            }, Tone.Transport.seconds + duration / 1000)
                        } else {
                            Tone.start().then(() => {
                                Tone.context.lookAhead = 0;
                                Tone.Transport.schedule(function (time) {
                                    Tone.Draw.schedule(function () {
                                        //console.log("Soll Follow-Action spielen, aber nur bei ClickCount: " + aktClickCount + ". Aktueller ClickCount: " + icke.clickCount);
                                        if (aktClickCount == icke.clickCount) {
                                            icke.executeAction(followAction)
                                        }
                                    }, time)
                                }, Tone.Transport.seconds + duration / 1000)
                                this.mediator.audioContextStarted = true;
                                Tone.Transport.start();
                            });
                        }
                    }
                    if (singleAction.followCombiAction) {
                        let followCombiAction = this.findCombiActionNumber(singleAction.followCombiAction.group, singleAction.followCombiAction.action);
                        let duration = singleAction.endTime ? singleAction.endTime : 1000;
                        setTimeout(() => this.executeCombiAction(followCombiAction), duration);
                    }
                    break;

                ////// StartMeter //////
                case ActionType.StartMeter:
                    mediaTitle = singleAction.mediaTitle ? singleAction.mediaTitle : "";
                    this.mediator.currentMeter = this.mediator.meters[mediaTitle];
                    this.mediator.currentMeter.startMeter();
                    // console.log("Started Meter: " + mediaTitle);
                    break;

                ////// PlayAudioTillNextOne //////
                case ActionType.PlayAudioTillNextOne:
                    mediaTitle = singleAction.mediaTitle ? singleAction.mediaTitle : "";
                    let mediaTitleAttack = singleAction.mediaTitleAttack ? singleAction.mediaTitleAttack : "";
                    metricLevel = singleAction.metricLevel ? singleAction.metricLevel : "Primary";

                    Tone.Destination.mute = false;
                    let playerTillNextOne = new Tone.Player(this.mediator.buffers[mediaTitle]).toDestination();
                    if (mediaTitleAttack != "") {
                        let playerAttack = new Tone.Player(this.mediator.buffers[mediaTitleAttack]).toDestination();
                        this.mediator.currentMeter.startPlayerTillNext(metricLevel, playerTillNextOne, playerAttack);
                    } else {
                        this.mediator.currentMeter.startPlayerTillNext(metricLevel, playerTillNextOne);
                    }
                    break;

                ////// PlayScore //////
                case ActionType.PlayScore:
                    mediaTitle = singleAction.mediaTitle ? singleAction.mediaTitle : "";
                    metricLevel = singleAction.metricLevel ? singleAction.metricLevel : "Primary";
                    let wartezeitAnfang = this.mediator.currentMeter.getTimeTillNext(metricLevel);
                    //console.log("wartezeitAnfang: " + wartezeitAnfang);
                    //console.log("Seit MeterStart vergangen: " + this.mediator.currentMeter.milliSecondsSinceStart());

                    let scoreEventCount = 0;            // Jedes ausgeführte Event (inkl. repeats), wird beim Loop zurückgesetzt
                    let scoreEventIndex = 0;            // Jedes einzeln notierte Event (exkl. repeats), wird beim Loop zurückgesetzt
                    let absoluteScoreEventCount = 0;    // Jedes ausgeführte Event (inkl. repeats), wird beim Loop NICHT zurückgesetzt
                    let absoluteScoreEventIndex = 0;    // Jedes einzeln notierte Event (exkl. repeats), wird beim Loop NICHT zurückgesetzt
                    let newEventStartedAt = 0;
                    this.mediator.currentScore = this.mediator.scores[mediaTitle];
                    let firstEvent = this.mediator.currentScore.scoreFile.events[0];
                    let currentEvent = this.mediator.currentScore.scoreFile.events[0];
                    let nextEvent = this.mediator.currentScore.scoreFile.events[0];     // Nur zur Initialisierung des Typs
                    let loopScore = this.mediator.currentScore.scoreFile.loop;

                    const playNextEvent = () => {
                        //console.log("Seit MeterStart / TillNextOne: ");
                        //console.log(Math.round(this.mediator.currentMeter.milliSecondsSinceStart()) + " " + Math.round(this.mediator.currentMeter.getTimeTillNext("Primary")));
                        //console.log("getTimeTillNextPrimary: " + this.mediator.currentMeter.getTimeTillNext("Primary"));
                        //console.log("Seit MeterStart vergangen: " + this.mediator.currentMeter.milliSecondsSinceStart());
                        this.mediator.updateInfoElement({ action: [0, 0], append: false });
                        let currentRepeat = currentEvent.repeat ? currentEvent.repeat : 1;
                        let objectCount = 1;
                        let currentObject = "";
                        for (let currentAction of currentEvent.singleActions) {
                            objectCount = currentAction.objectCount ? currentAction.objectCount : 1;
                            for (let i = 1; i <= objectCount; i++) {
                                currentObject = currentAction.object.replaceAll('$$$', this.name).replaceAll('$i$', i.toString().padStart(2, '0'));
                                // console.log(scoreEventCount + " - " + scoreEventIndex + ": Spiele im Objekt '" + currentObject + "' die Aktion " + JSON.stringify(currentAction.action));
                                this.mediator.objects[currentObject].executeAction(this.mediator.objects[currentObject].findActionNumber(currentAction.action.group, currentAction.action.action));
                            }
                        }
                        let isNextEvent = false;
                        if (currentRepeat > scoreEventCount - newEventStartedAt + 1) {
                            scoreEventCount++;
                            absoluteScoreEventCount++;
                            nextEvent = currentEvent;
                            isNextEvent = true;
                        } else if (this.mediator.currentScore.scoreFile.events.length > scoreEventIndex + 1) {
                            scoreEventCount++;
                            scoreEventIndex++;
                            absoluteScoreEventCount++;
                            absoluteScoreEventIndex++;
                            nextEvent = this.mediator.currentScore.scoreFile.events[scoreEventIndex];
                            newEventStartedAt = scoreEventCount;
                            isNextEvent = true;
                        }
                        if (isNextEvent == true || loopScore == true) {
                            let dauer = currentEvent.duration ? currentEvent.duration : [0, 0, 1]     // Das ist nur vorläufig. Später muss ich mich noch um die absoluteTime kümmern und daraus die korrekte Dauer berechnen.
                            //                            let wartezeit = this.mediator.currentMeter.getTimeTillNext("Tertiary") * dauer[2];      // Ebenfalls vereinfacht, vgl. "PlayScore Pseudocode.txt"
                            let wartezeit = (60000 / this.mediator.currentBPM / 4) * dauer[2];      // Ebenfalls vereinfacht, vgl. "PlayScore Pseudocode.txt"
                            // Außerdem setze ich hier einfach Tertiaries mit Sechzehnteln gleich, und als currentBPMUnit setze ich Viertel voraus. Das geht eigentlich nicht so.
                            //wartezeit = this.mediator.currentMeter.getTimeTillNext("Tertiary") + (60000 / this.mediator.currentBPM / 4) * (dauer[2] - 1);
                            // console.log("wartezeit: " + wartezeit);
                            wartezeit = wartezeit - 25;             // QuickFix, weil es sich immer nach hinten verschiebt
                            let transportStartTime = Tone.Transport.seconds;
                            Tone.Transport.schedule(function (time) {
                                Tone.Draw.schedule(function () {
                                    if (isNextEvent == true) { currentEvent = nextEvent; }
                                    if (isNextEvent == false && loopScore == true) {
                                        scoreEventCount = 0;
                                        scoreEventIndex = 0;
                                        newEventStartedAt = 0;
                                        currentEvent = firstEvent;
                                    }
                                    playNextEvent();
                                }, time)
                            }, transportStartTime + (wartezeit / 1000))
                        }
                    }

                    let transportStartTime = Tone.Transport.seconds;
                    Tone.Transport.schedule(function (time) {
                        Tone.Draw.schedule(function () {
                            playNextEvent();
                        }, time)
                    }, transportStartTime + (wartezeitAnfang / 1000))

                    break;

                case ActionType.StartNewEnvironment:
                    //console.log("Bin icke dit?! " + this.name);
                    this.mediator.startNewEnvironment();
                    break;

                case ActionType.EvalFunction:
                    targetObject = singleAction.targetObject ? singleAction.targetObject.replaceAll('$$$', this.name) : "";
                    let funktion = singleAction.function ? singleAction.function : "";
                    eval("this.mediator.objects['" + targetObject + "']." + funktion + "();");
                    break;

                ////// ScrollIntoView //////
                case ActionType.ScrollIntoView:
                    //console.log("Speed - Scrollintoview");
                    let classname = singleAction.newClass ? singleAction.newClass : "";
                    //console.log("Speed: " + classname)
                    let obj = document.querySelector("." + classname);
                    //console.log("Speed: " + obj)
                    //console.log(obj);
                    if (obj) {
                        obj.scrollIntoView({behavior: "smooth"});
                    }
                    break;

                case ActionType.StartTrack:

                    break;
                case ActionType.PlayVideoFullscreen:

                    break;
                default:
                    break;
            }

        });
    }

    executeCombiAction(clickActionIndex: number) {
        let newClickActionIndex: number;
        newClickActionIndex = clickActionIndex + this.findTotalActionCount() + 1;
        this.executeAction(newClickActionIndex);
    }

    removeClassAtEnd(targetObjectDOM: HTMLElement, newClass: string) {
        let animationDIV = newClass.slice(newClass.lastIndexOf("_") + 1);
        let animationGlowTimerDOM = document.querySelector("#" + this.objDiv.id + " .animation_glow_timer");
        let absoluteScaleDOM = <HTMLElement>document.querySelector("#" + this.objDiv.id + " .absoluteScale");
        let absolutePositionXDOM = <HTMLElement>document.querySelector("#" + this.objDiv.id + " .absolutePositionX");
        let absolutePositionYDOM = <HTMLElement>document.querySelector("#" + this.objDiv.id + " .absolutePositionY");
        if (animationGlowTimerDOM != null) {
            var myStyle = animationGlowTimerDOM.getBoundingClientRect();

            let myScale = myStyle.width / window.innerWidth;
            let myWidth = myStyle.width;
            let myHeight = myStyle.height;

            let myLeftUpperCornerX = myStyle.x;
            let myLeftUpperCornerY = myStyle.y;
            let myCenterX = myLeftUpperCornerX + (myWidth / 2);
            let myCenterY = myLeftUpperCornerY + (myHeight / 2);

            let translateX = myCenterX / window.innerWidth * 100;
            let translateY = myCenterY / window.innerHeight * 100;
            let translateXString = "translateX(" + translateX + "vw)";
            let translateYString = "translateY(" + translateY + "vh)";

            if (animationDIV == "relativePositionX") { absolutePositionXDOM.style.transform = translateXString; }
            if (animationDIV == "relativePositionY") { absolutePositionYDOM.style.transform = translateYString; }
            if (animationDIV == "relativeScale") { absoluteScaleDOM.style.transform = "scale(" + myScale + ")"; }

        }
        targetObjectDOM.classList.remove(newClass);
    }

    // Am Ende der Animation prüfen, ob das Element aus dem Bildschirm geschossen wurde,
    // sonst Reset-Animation spielen:
    checkPosition = (object: ClickStateManager, targetObjectDOM: HTMLElement, animationDiv: string) => {
        let id = targetObjectDOM.closest(".object").id;
        //targetObjectDOM.removeEventListener("animationend", this.checkPosition.bind(this, object, targetObjectDOM, animationDiv), false);
        //console.log("!!!CHECKPOSITION!!! " + id + ", " + animationDiv);
        let displayWidth = window.visualViewport?.width || 1920;
        let displayHeight = window.visualViewport?.height || 1280;
        let clickArea = document.querySelector("#" + id + " .clickArea");
        let checkPositionAtEnd = object.clickActions[object.prevActions[object.prevActions.length - 1]].checkPositionAtEnd;
        //console.log("checkPositionAtEnd: " + checkPositionAtEnd);
        if (clickArea) {
            if (clickArea.style.display != "none") {
                if (checkPositionAtEnd != false) {
                    let ca = clickArea.getBoundingClientRect();
                    //console.log(ca.left + " " + ca.right + " " + ca.top + " " + ca.bottom);
                    if (ca.left > displayWidth * 0.95 || ca.right < displayWidth * 0.05 ||
                        ca.top > displayHeight * 0.95 || ca.bottom < displayHeight * 0.05) {
                        //console.log("Rückholung " + id + ", " + animationDiv + ": " + ca.left + ", " + ca.right + ", " + ca.top + ", " + ca.bottom);
                        object.executeResetAction(animationDiv);
                    }
                }
            }
        }
    }

    glow(scheinwerferSelector: string) {
        let posX = 0;
        let posY = 0;
        let imin = 0;
        let imax = 0;
        let currentObjectRoot;
        let currentObjectID = "";
        let glowingObjectsSOLL: Array<string> = [];
        let rotationZAchse = 0;

        // Scheinwerferrotation auf der z-Achse prüfen
        let scheinwerferIMG = <HTMLImageElement>document.querySelector(scheinwerferSelector + " img");
        if (isVisible(scheinwerferIMG)) {
            let frame = parseInt(scheinwerferIMG.src.slice(-9, -5));
            rotationZAchse = frame / 86 * 100;
        } else {
            rotationZAchse = 50;
        }

        if (rotationZAchse < 10) {
            imin = 0;
            imax = 9;
        } else if (rotationZAchse > 90) {
            imin = 11;
            imax = 20;
        } else {
            return;
        }

        // Alle Objekte glühen lassen, die von einem Ankerpunkt berührt werden
        for (let i = imin; i <= imax; i++) {      // Wenn das Scheinwerfervideo am anderen Ende steht, muss von 11 bis 20 iteriert werden!!!
            posX = this.anchorPoints[i].getBoundingClientRect().left;
            posY = this.anchorPoints[i].getBoundingClientRect().top;
            for (let currentObject of document.elementsFromPoint(posX, posY)) {
                if (currentObject.classList.contains("clickArea")) {
                    currentObjectRoot = currentObject.closest(".object");
                    currentObjectID = currentObjectRoot ? currentObjectRoot.id : "";
                    if (!glowingObjectsSOLL.includes(currentObjectID)) {
                        glowingObjectsSOLL.push(currentObjectID);
                    }
                    if (!this.glowingObjects.includes(currentObjectID)) {
                        let currentObjectLightDIV = currentObject.closest(".animation_light");
                        if (currentObjectLightDIV) {
                            currentObjectLightDIV.classList.remove("scheinwerfer_cssanim_dont_glow");
                            currentObjectLightDIV.classList.add("scheinwerfer_cssanim_glow");
                            //currentObjectLightDIV.getAnimations()[0].playbackRate = 1;
                            this.glowingObjects.push(currentObjectID);
                            // console.log("Glühen: " + currentObjectID);
                        }
                    }
                }
            }
        }

        // Alle Objekte entglühen, die nicht mehr von einem Ankerpunkt berührt werden
        for (let currentObject of this.glowingObjects) {
            if (!glowingObjectsSOLL.includes(currentObject)) {
                // console.log("Entglühen: " + currentObject);
                let currentObjectLightDIV = <HTMLElement>this.bySelector("#" + currentObject + " .animation_light");
                if (currentObjectLightDIV) {
                    currentObjectLightDIV.getAnimations()[0].playbackRate = -1;
                    currentObjectLightDIV.classList.remove("scheinwerfer_cssanim_glow");
                    currentObjectLightDIV.classList.add("scheinwerfer_cssanim_dont_glow");
                }
            }
        }
        this.glowingObjects = glowingObjectsSOLL;

    }

    buildAnimationDivTree(divNames: string[], blend: String) {
        let animationDivTree: Array<HTMLDivElement> = [];
        let i = 0;
        for (let currentDivName of divNames) {
            animationDivTree[i] = document.createElement("div");
            animationDivTree[i].classList.add(currentDivName);
            animationDivTree[i].addEventListener("animationend", this.checkPosition.bind(this, this, animationDivTree[i], currentDivName), false);
            if (i == 0) {
                if (blend == "blend" && this.hasBlendAnimations) {
                    this.objDivBlend.prepend(animationDivTree[i]);
                } else {
                    this.objDiv.prepend(animationDivTree[i]);
                }
            } else {
                animationDivTree[i - 1].append(animationDivTree[i]);
            }
            i++;
        }
        return animationDivTree[i - 1];
    }

    protected consolidateFilename(filename: string) {
        let filenameArray = filename.split("/");
        let i = 0;
        while (filenameArray.includes("..") && i < 20) {
            let position = filenameArray.indexOf("..");
            filenameArray.splice(position - 1, 2);
            i++;
        }
        return filenameArray.join("/");;
    }

    protected createHTML() {
        // Animationsbaum bauen
        this.animationWrapper = this.buildAnimationDivTree(this.animationsbaum, "");
        if (this.hasBlendAnimations) {
            this.animationWrapperBlend = this.buildAnimationDivTree(this.animationsbaum, "blend");
        }

        if (this.addAnchorPoints) {
            for (let i = 0; i <= 20; i++) {
                this.anchorPoints[i] = document.createElement("div");
                this.anchorPoints[i].classList.add("anchor-point-" + (i * 5));
                this.animationWrapper.append(this.anchorPoints[i]);
            }
        }

        let imageNode = document.createElement("img");
        imageNode.id = this.objDiv.id + "-img";
        imageNode.width = this.displaySize.x;
        imageNode.height = this.displaySize.y;
        this.animationWrapper.append(imageNode);

        if (this.hasBlendAnimations) {
            let imageNodeBlend = document.createElement("img");
            imageNodeBlend.id = this.objDiv.id + "-img-blend";
            imageNodeBlend.width = this.displaySize.x;
            imageNodeBlend.height = this.displaySize.y;
            this.animationWrapperBlend.append(imageNodeBlend);
        }

        // Konventionelles Emergency-Audio für Tamtam bauen (falls am Anfang noch kein Klang geladen hat, unter Firefox)
        /*if (this.name == "mother→childtamtam01") {
            let emergencyAudioNode = document.createElement("audio");
            emergencyAudioNode.id = "mother→childtamtam01→emergency_audio";
            emergencyAudioNode.preload = "auto";
            emergencyAudioNode.src = "media/spielelemente/tamtam/audio/TT pp kurz.ogg";
            this.animationWrapper.append(emergencyAudioNode);
        }*/

        /*if (this.audioActions) {
            this.audioActions.forEach(audioAction => {
                let mediaTitle: string;
                let standSounds = audioAction.standardSounds ? audioAction.standardSounds : []
                let altSounds = audioAction.alternativeSounds ? audioAction.alternativeSounds : [];
                let allSounds = standSounds.concat(altSounds);
                allSounds.forEach(singleSound => {
                    mediaTitle = singleSound.sample != null ? singleSound.sample : "";
                    if (!this.audioTitles.includes(mediaTitle)) {
                        this.audioTitles.push(mediaTitle);
                        //console.log("mediaTitle: " + mediaTitle);
                        //console.log("this.mediaPfad: " + this.mediaPfad);
                        let filename = this.consolidateFilename("media" + this.mediaPfad + "/audio/" + mediaTitle + ".ogg");
                        //console.log("filename: " + filename);
                        let blobURL = this.mediator.mediaList[filename].blobURL;
                        if (blobURL) {
                            //console.log("Dem Objekt " + this.name + " die BlobURL für " + mediaTitle + " hinzugefügt.");
                            let buffer = new Tone.Buffer(blobURL);
                            this.mediator.buffers[mediaTitle] = buffer;
                        } else {
                            //console.log("Keine BlobURL für " + mediaTitle + " gefunden.");
                        }
                
                        //let mediaUrl = window.location.origin + "/media" + this.mediaPfad + "/audio/" + mediaTitle + ".ogg";
                        //let buffer = new Tone.Buffer(mediaUrl);
                        //this.mediator.buffers[mediaTitle] = buffer;
                        //console.log("Player erstellt für: " + mediaTitle)
                    }
                });
            });
        }*/

        this.clickActions.forEach(clickAction => {
            clickAction.singleActions.forEach(singleAction => {
                let mediaTitle: string;
                let selector;

                switch (singleAction.actionType) {

                    /////// PlayAudio //////
                    case ActionType.TeachMartinToneJS:
                    case ActionType.PlayAudioTillNextOne:
                    case ActionType.PlayAudio:
                        mediaTitle = singleAction.mediaTitle != null ? singleAction.mediaTitle : "";
                        if (!this.audioTitles.includes(mediaTitle)) {
                            //console.log("mediaTitle: " + mediaTitle);
                            //console.log("this.mediaPfad: " + this.mediaPfad);
                            //console.log("Pfad: " + "media" + this.mediaPfad + "/audio/" + mediaTitle + ".ogg");
                            //console.log("Pfad: " + "media/spielelemente/audio/TT Orient.ogg");
                            //console.log(this.mediator.mediaList["media" + this.mediaPfad + "/audio/" + mediaTitle + ".ogg"]);
                            //console.log(this.mediator.mediaList["media/spielelemente/audio/TT Orient.ogg"]);
                            this.audioTitles.push(mediaTitle);
                            let filename = this.consolidateFilename("media" + this.mediaPfad + "/audio/" + mediaTitle + ".ogg");
                            let blobURL = this.mediator.mediaList[filename].blobURL;
                            if (blobURL) {
                                let buffer = new Tone.Buffer(blobURL);
                                this.mediator.buffers[mediaTitle] = buffer;
                            } else {
                                //console.log("Keine BlobURL für " + mediaTitle + " gefunden.");
                            }
                            //let mediaUrl = window.location.origin + "/media" + this.mediaPfad + "/audio/" + mediaTitle + ".ogg";
                            //let buffer = new Tone.Buffer(mediaUrl);
                            //this.mediator.buffers[mediaTitle] = buffer;
                            //console.log("Player erstellt für: " + mediaTitle)
                        }

                        let mediaTitleAttack = singleAction.mediaTitleAttack != null ? singleAction.mediaTitleAttack : "";
                        if (!this.audioTitles.includes(mediaTitleAttack) && mediaTitleAttack != "") {
                            this.audioTitles.push(mediaTitleAttack);
                            let filename = this.consolidateFilename("media" + this.mediaPfad + "/audio/" + mediaTitleAttack + ".ogg");
                            let blobURL = this.mediator.mediaList[filename].blobURL;
                            if (blobURL) {
                                let buffer = new Tone.Buffer(blobURL);
                                this.mediator.buffers[mediaTitleAttack] = buffer;
                            } else {
                                //console.log("Keine BlobURL für " + mediaTitleAttack + " gefunden.");
                            }

                            //let mediaUrl = window.location.origin + "/media" + this.mediaPfad + "/audio/" + mediaTitleAttack + ".ogg";
                            //let buffer = new Tone.Buffer(mediaUrl);
                            //this.mediator.buffers[mediaTitleAttack] = buffer;
                            //console.log("Player erstellt für: " + mediaTitleAttack)
                        }

                        break;

                    ////// VideoPlayback //////   
                    case ActionType.VideoPlayback:
                        //                        if (singleAction.loadOnStart) {
                        if (true) {
                            // console.log("Loaded video '" + singleAction.mediaTitle + "' for " + this.name);
                            //                            this.createVideoHTML(singleAction, "none");
                            this.createVideoHTML(singleAction, "auto");
                        } else {
                            window.addEventListener('load', this.createVideoHTML.bind(this, singleAction, "auto"));
                        }
                        break;

                    default:
                        break;
                }
            });
        });
    }

    protected audioLoaded(mediaTitle: string) {
        // console.log("Loaded Audio in Buffer from url " + mediaTitle);
    }

    protected createVideoHTML(singleAction: SingleActionContainer, preload: HTMLMediaElement["preload"]) {
        const mediaTitle = singleAction.mediaTitle != null ? singleAction.mediaTitle : "";
        const selector = singleAction.selector != null ? singleAction.selector.replaceAll('$$$', this.name) : "#" + this.name + "→" + mediaTitle + "-video";
        if (this.videoTitles.includes(mediaTitle)) {
            return;
        }
        this.videoTitles.push(mediaTitle);

        let extension = this.mediator.returnVideoExtensionForCurrentBrowser();

        if (singleAction.exclusiveExtensions) {
            if (!singleAction.exclusiveExtensions.includes(extension)) {
                return;
            }
        }

        // Vorwärtsversion des Videos
        let videoNode = document.createElement("video");
        videoNode.id = selector == "" ? mediaTitle + "-video" : selector.slice(1);
        videoNode.playsInline = true;
        videoNode.preload = preload;
        let blobURL = this.mediator.mediaList["media" + this.mediaPfad + "/video/" + mediaTitle + extension].blobURL;
        if (blobURL) {
            videoNode.src = blobURL;
        } else {
            //console.log("Keine BlobURL für " + mediaTitle + " gefunden.");
        }

        videoNode.style.width = this.displaySize.x + "px";
        videoNode.style.height = this.displaySize.y + "px"
        videoNode.style.transform = "rotate(" + this.rotation + "deg)";
        videoNode.addEventListener("loadeddata", (event) => {
            /* console.log(
                "Yay! The readyState just increased to  " +
                "HAVE_CURRENT_DATA or greater for the first time.",
            ); */
        });
        this.animationWrapper.append(videoNode);

        // Vorwärtsversion des Videos / Blend-Mode                        
        if (this.hasBlendAnimations) {
            let videoNode_blend = document.createElement("video");
            videoNode_blend.id = selector == "" ? mediaTitle + "-video-blend" : selector.slice(1) + "-blend";
            videoNode_blend.playsInline = true;
            let blobURL = this.mediator.mediaList["media" + this.mediaPfad + "/video/" + mediaTitle + extension].blobURL;
            if (blobURL) {
                videoNode_blend.src = blobURL;
            } else {
                //console.log("Keine BlobURL für " + mediaTitle + " gefunden.");
            }

            videoNode_blend.style.width = this.displaySize.x + "px";
            videoNode_blend.style.height = this.displaySize.y + "px"
            videoNode_blend.style.transform = "rotate(" + this.rotation + "deg)";
            hide(videoNode_blend);
            this.animationWrapperBlend.append(videoNode_blend);
        }

        // Rückwärtsversion des Videos
        if (this.videoList[mediaTitle]?.hasREV) {
            let videoNodeREV = document.createElement("video");
            videoNodeREV.id = selector == "" ? mediaTitle + "-video-REV" : selector.slice(1) + "-REV";
            videoNodeREV.playsInline = true;
            let blobURL = this.mediator.mediaList["media" + this.mediaPfad + "/video/" + mediaTitle + "_REV" + extension].blobURL;
            if (blobURL) {
                videoNodeREV.src = blobURL;
            } else {
                //console.log("Keine BlobURL für " + mediaTitle + " gefunden.");
            }

            videoNodeREV.style.width = this.displaySize.x + "px";
            videoNodeREV.style.height = this.displaySize.y + "px"
            videoNodeREV.style.transform = "rotate(" + this.rotation + "deg)";
            hide(videoNodeREV);
            this.animationWrapper.append(videoNodeREV);

            // Rückwärtsversion des Videos / Blend-Mode                        

            if (this.hasBlendAnimations) {
                let videoNodeREV_blend = document.createElement("video");
                videoNodeREV_blend.id = selector == "" ? mediaTitle + "-video-REV-blend" : selector.slice(1) + "-REV-blend";
                videoNodeREV_blend.playsInline = true;
                let blobURL = this.mediator.mediaList["media" + this.mediaPfad + "/video/" + mediaTitle + "_REV" + extension].blobURL;
                if (blobURL) {
                    videoNodeREV_blend.src = blobURL;
                } else {
                    //console.log("Keine BlobURL für " + mediaTitle + " gefunden.");
                }

                videoNodeREV_blend.style.width = this.displaySize.x + "px";
                videoNodeREV_blend.style.height = this.displaySize.y + "px"
                videoNodeREV_blend.style.transform = "rotate(" + this.rotation + "deg)";
                hide(videoNodeREV_blend);
                this.animationWrapperBlend.append(videoNodeREV_blend);
            }
        }
    }

    removeHTML() {
        //console.log("tried to remove " + this.name);
        this.active = false;
        this.objDiv.remove();
    }

    public testemich(targetAction: GroupActionContainer) {
        //console.log("Gestestet im CSM: " + targetAction);
    }

    public onActivation(targetAction: GroupActionContainer) {
        this.animationWrapper.appendChild(this.clickNode);
        if (this.name.includes("lichtkegel")) {
            this.objDiv.style.zIndex = "99999";
            if (this.hasBlendAnimations) {
                this.objDivBlend.style.zIndex = "99999";
            }
        } else {
            this.objDiv.style.zIndex = this.mediator.zIndexCounter.toString();
            if (this.hasBlendAnimations) {
                this.objDivBlend.style.zIndex = this.mediator.zIndexCounter.toString();
            }
            this.mediator.zIndexCounter++;
        }
        show(this.objDiv);
        this.mediator.activeObject = this;
        this.addListeners();
        this.executeAction(this.findActionNumber(targetAction.group, targetAction.action));
        this.prepareRelatives();        // Das stand vorher zwei Zeilen früher - schaun, ob es Probleme gibt!
        this.active = true;
    }

    videoEnded(sourceElement: HTMLElement, followAction: number) {
        this.executeAction(followAction);
    }


}


export type ClickAction = {
    singleActions: Array<SingleActionContainer>;
    allowHover: boolean;
    allowClick: boolean;
    minClickCount?: number;
    maxClickCount?: number;
    forceAtClickCounts?: number[];
    forbidAtClickCounts?: number[];
    forceIfNotHappenedTillClickCount?: number;
    possiblePrevActions?: Array<GroupActionsContainer>;
    possibleNextActions?: Array<GroupActionsContainer>;
    impossiblePrevActions?: Array<GroupActionsContainer>;
    impossibleNextActions?: Array<GroupActionsContainer>;
    possiblePrevActionsByVideoPosition?: boolean;
    possiblePrevImages?: string[];
    impossiblePrevImages?: string[];
    forbidIfPositionIs?: Vector2;
    forbidIfTransformIs?: string;
    forbidIfVideoEnded?: string;
    allowIfVideoEnded?: string;
    addState?: Array<string>;
    removeState?: Array<string>;
    demandedState?: Array<string>;
    demandedSteelDrumState?: string;
    tellAudioTargetAbsoluteScale?: number[];
    forceIfNotChromeBrowserIsUsed?: boolean;
    forceIfMobileIsUsed?: boolean;
    forbidClicksForNextMilliseconds?: number;
    checkPositionAtEnd?: boolean;
    minGPUScore?: number;
}

export type AudioAction = {
    possibleClickActions?: Array<GroupActionsContainer>;
    possibleCombiActions?: Array<GroupActionsContainer>;
    demandedNewState?: string;
    standardSounds?: Array<SoundContainer>;
    alternativeSounds?: Array<SoundContainer>;
    allowedInEnvironments?: number[];
    allowedInAudioEnvironments?: number[];
    killSamples?: string[];
}

export type SoundContainer = {
    sample: string;
    midiRange: number[];
    dynamicRange: number[];
    afterIdenticalActionOnly?: boolean;
    demandedState?: string[];
    forbiddenState?: string[];
    voices?: number;
    startTime?: number;
    endTime?: number;
    fadeIn?: number;
    fadeOut?: number;
    addToInfinitelyPlayingPlayers?: boolean;
}

export type AudioHierarchieGruppe = {
    description?: string;
    actionGroups?: number[];
    combiGroups?: number[];
}

export type soundBoolean = {
    standardSounds: Array<boolean>;
    alternativeSounds: Array<boolean>;
}


export type GroupActionsContainer = {
    group: number;
    actions: Array<number>;
}

export type GroupActionContainer = {
    group: number;
    action: number;
}

export type PropertyContainer = {
    property: string;
    value: string;
}

export type ActionGroup = {
    name: string;
    elements: number;
    probability: number;
    krassitaet: number;
    allowedInEnvironments?: Array<number>;
}

export type CombiGroup = {
    name: string;
    elements: number;
    probability: number;
    krassitaet: number;
    addableToGroups: Array<number>;
    incompatibleCombiGroups: Array<number>;
    allowedInEnvironments?: Array<number>;
    demandedSteelDrumState?: string;
}

export type PlayBackTurningPointContainer = {
    time: number;
    playBackRate: number;
}

export type SingleActionContainer = {
    actionType: ActionType;
    mediaTitle?: string;
    mediaTitleAttack?: string;
    loop?: boolean;
    startVideo?: boolean;
    stopVideo?: boolean;
    startTime?: number;                 //startTime of Video if ActionType is VideoPlayback in ms
    endTime?: number;                   //endTime of Video if ActionType is VideoPlayback in ms
    playBackRate?: number;              //playback speed if ActionType is VideoPlayback or Audio
    playSolo?: boolean;                 //defines wether other objects' audio playing at the same time shall be faded down if ActionType is PlayAudio
    playSoloMuteVolume?: number;         //the actual value for other objects to be faded down to if playSolo is true and if ActionType is PlayAudio
    startAnimation?: boolean;
    volume?: number;
    fadeIn?: number;
    fadeOut?: number;
    newClass?: string;
    constructNewClass?: { className: string, placeholder: string };
    removeAtEnd?: boolean;
    forceZIndex?: number;
    targetObject?: string;
    targetObjectSelector?: string;
    targetAction?: GroupActionContainer;
    followAction?: GroupActionContainer;
    followCombiAction?: GroupActionContainer;
    selector?: string;
    playBackRateCommand?: string;
    playBackRateFactor?: number;
    copyObjectSelector?: string;
    offsetX?: number;
    offsetY?: number;
    nrOfInstances?: number;
    inheritMotherScale?: boolean;
    animationDiv?: string;
    newProperties?: Array<PropertyContainer>;
    playBackRateFunction?: string;
    startTimeFunction?: string;
    involvesBlendModes?: boolean;
    startTimePrevious?: boolean;
    endTimeRandom?: boolean;
    endTimeMin?: number;
    endTimeMax?: number;
    loadOnStart?: boolean;
    metricLevel?: string;
    function?: string;
    exclusiveExtensions?: string[];
}

export type allowedCSSAnimationType = {
    animationType: string;
    maxClickCount?: number;
    maxGlobalClickCount?: number;
}

export type VideoInformation = {
    hasREV?: boolean;
    duration?: number;
    frameRate?: number;
}


export type Vector2 = {
    x: number;
    y: number;
}

export type dimensions = {
    size: number;
    position: Vector2;
    videoSize: Vector2;
    clickAreaPosition: Vector2;
    clickAreaSize: Vector2;
}

export enum ClickAreaForm {
    Circle,
    Rectangle
}

//enumeration of all possible clickActions
export enum ActionType {
    PlayAudio,
    VideoPlayback,
    PlayVideo,
    ActivateObject,
    TelecontrolObject,
    ShowElement,
    HideElement,
    AddClass,
    RemoveClass,
    StartTrack,
    PlayVideoFullscreen,
    ChangeVideoProperties,
    ChangeAnimation,
    Wait,
    ChangePosition,
    GenerateInstances,
    ShowImage,
    TeachMartinToneJS,
    StartMeter,
    PlayAudioTillNextOne,
    PlayScore,
    StartNewEnvironment,
    EvalFunction,
    ScrollIntoView
}