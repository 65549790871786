import * as CSM from "../../../ClickStateManager.js";

export default class Vase extends CSM.ClickStateManager {

    videoSize = { x: 1200, y: 1200 };               // width and height of the original video material
    clickAreaPosition = { x: 430, y: 575 };         // position of Object in original Video in px
    clickAreaSize = { x: 280, y: 410 };             // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;    // Form of the clickable Area
    mediaPfad = "/spielelemente/vase";


    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

        /*this.executeAction(this.findActionNumber(3, 1));
        this.findBestAudioForActionAndCombiActions(this.prevActions[this.prevActions.length - 1], []);
        return;

        if (this.clickCount == 1) {this.executeAction(this.findActionNumber(9, 1));}
        if (this.clickCount == 2) {this.executeAction(this.findActionNumber(9, 2));}
        return;

        if (this.clickCount == 1) {
            this.executeAction(this.findActionNumber(9, 1));
//            this.executeCombiAction(this.findCombiActionNumber(0, 6));
//            this.findBestAudioForActionAndCombiActions(this.prevActions[this.prevActions.length - 1], [{group: 4, action: 3}]);
        }
        if (this.clickCount == 2) {this.executeAction(this.findActionNumber(1, 11));}
        if (this.clickCount == 3) {this.executeAction(this.findActionNumber(1, 11));}
        if (this.clickCount == 4) {this.executeAction(this.findActionNumber(1, 13));}
        this.findBestAudioForActionAndCombiActions(this.prevActions[this.prevActions.length - 1], []);
        return;

        if (this.clickCount == 2) {
            this.executeAction(this.findActionNumber(2, 2));
            this.findBestAudioForActionAndCombiActions(this.prevActions[this.prevActions.length - 1], []);
            return;
        }

        /*if (this.clickCount == 1) {this.executeAction(this.findActionNumber(7, 1)); return;}
        if (this.clickCount == 2) {this.executeAction(this.findActionNumber(7, 2)); return;}
        if (this.clickCount == 3) {this.executeAction(this.findActionNumber(7, 3)); return;}
        if (this.clickCount == 4) {this.executeAction(this.findActionNumber(7, 4)); return;}
        if (this.clickCount == 5) {this.executeAction(this.findActionNumber(7, 5)); return;}
        if (this.clickCount == 6) {this.executeAction(this.findActionNumber(7, 6)); return;}*/

        this.lokalerEskalationswert = 0.15 + this.clickCount / 60;
        if (this.lokalerEskalationswert > 0.9) {this.lokalerEskalationswert = 0.9;}

        this.findPossibleNextActions();

        // Sonderregeln
        /*this.applyPingPongRule(1, 8, 1, 9, 6);
        this.applyMinCountRule(2, 1, 10);
        this.applyMinCountRule(2, 3, 10);*/

        //console.log("Possible Next Actions: " + this.possibleNextActions);

        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-3)
        {name: "0 Init und Exit",             elements: 2,    probability: 0,     krassitaet: 1, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "1 Tranchenweises Abspielen",  elements: 13,   probability: 0.7,  krassitaet: 1, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "2 Kleine Vasen",              elements: 7,    probability: 0/*.1*/,   krassitaet: 2, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "3 Vase fällt vom Himmel",     elements: 3,    probability: 0.1,   krassitaet: 2, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "4 Zackzack",                  elements: 8,    probability: 0.4,   krassitaet: 2, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "5 Bombe",                     elements: 2,    probability: 0.0/*5*/,  krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "6 Langsam einfaden",          elements: 1,    probability: 0.05,  krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "7 Vasengroove",               elements: 10,   probability: 0.05,  krassitaet: 3, allowedInEnvironments: [4, 9] },
        {name: "8 Komplettrotation",          elements: 4,    probability: 0.2,  krassitaet: 1, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "9 Hin- und Herspringen",      elements: 4,    probability: 0.1,  krassitaet: 2, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "10 Umherirren",               elements: 3,    probability: 0.1,  krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "11 Zittern",                  elements: 4,    probability: 0.1,  krassitaet: 2, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
    ]

    combiGroups = [
        {name: "0 Verformungen",               elements: 6,    probability: 0.6,   krassitaet: 1,  addableToGroups: [1, 4, 8, 9, 10, 11],       incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "1 Herumschweben",              elements: 2,    probability: 0.05,  krassitaet: 3,  addableToGroups: [1, 4, 8, 11],       incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "2 Blendmodes Light Steeldrum", elements: 5,    probability: 0.05,   krassitaet: 3,  addableToGroups: [1, 2, 4, 5], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20], demandedSteelDrumState: "light" },
        {name: "3 Blendmodes Dark Steeldrum",  elements: 6,    probability: 0.05,   krassitaet: 3,  addableToGroups: [1, 2, 4, 5], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20], demandedSteelDrumState: "dark" },
        {name: "4 Düsenjäger",                 elements: 6,    probability: 0.3,   krassitaet: 1,  addableToGroups: [1, 4, 8, 9, 11],       incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "5 Größenveränderungen",        elements: 6,    probability: 0.6,   krassitaet: 1,  addableToGroups: [1, 2, 4, 8, 11],    incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "6 Rotationen",                 elements: 6,    probability: 0.6,   krassitaet: 1,  addableToGroups: [1, 4, 11],       incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "7 Farbeffekte",                elements: 6,    probability: 0.3,   krassitaet: 2,  addableToGroups: [1, 2, 4, 5, 8, 9, 10, 11], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
    ];

    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Vase_01_frame0001"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  1000 + (7 * 33.33),
                    endTime:    1000 + (24 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_01_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0001", "Vase_01_frame0037", "Vase_01_frame0099", "Vase_01_frame0150", "Vase_04_frame0021", "Vase_05_frame0001"]
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  1000 + (24 * 33.33),
                    endTime:    1000 + (7 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0054", "Vase_01_frame0055", "Vase_01_frame0056"]
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  1000 + (24 * 33.33),
                    endTime:    2000 + (20 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_03_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0054", "Vase_01_frame0055", "Vase_01_frame0056"]
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  2000 + (20 * 33.33),
                    endTime:    1000 + (26 * 33.33),        // aus irgendeinem Grund muss hier 26 statt 24 stehen, sonst ruckelt es
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_01_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0080"]
        },

        // Action 1-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  2000 + (20 * 33.33),
                    endTime:    1000 + (7 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0080"]
        },

        // Action 1-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  2000 + (20 * 33.33),
                    endTime:    3000 + (9 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0080"]
        },

        // Action 1-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  2000 + (20 * 33.33),
                    endTime:    3000 + (15 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_04_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0080"]
        },

        // Action 1-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  3000 + (15 * 33.33),
                    endTime:    3000 + (7 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_05_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0105"]
        },

        // Action 1-9
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  3000 + (7 * 33.33),
                    endTime:    3000 + (15 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_04_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0097"]
        },

        // Action 1-10
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  3000 + (7 * 33.33),
                    endTime:    4000 + (14 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_06_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0097"]
        },

        // Action 1-11
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  3000 + (9 * 33.33),
                    endTime:    4000 + (14 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_06_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0099"]
        },

        // Action 1-12
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  4000 + (14 * 33.33),
                    endTime:    5000 + (0 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0134", "Vase_01_frame0136"]
        },

        // Action 1-13
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  5000 + (0 * 33.33),
                    endTime:    4000 + (16 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_06_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0001", "Vase_01_frame0037", "Vase_01_frame0099", "Vase_01_frame0150", "Vase_04_frame0021", "Vase_05_frame0001"]
        },

        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",           // eigentlich 02_MG, aber die soll nicht geladen werden
//                    mediaTitle: "Vase_02_MG",
                    startTime:  500,
                    endTime:    4000 + (17 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_07_clickArea"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_07_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2, 5, 6, 12]},
                {group: 2, actions: [2, 6, 7]},
                {group: 3, actions: [2]},
                {group: 4, actions: [2]}
            ]
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",           // eigentlich 02_MG, aber die soll nicht geladen werden
//                    mediaTitle: "Vase_02_MG",
                    startTime:  4000 + (17 * 33.33),
                    playBackRate: -2,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [{group: 2, actions: [1]}]
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",           // eigentlich 03_01_MG, aber die soll nicht geladen werden
//                    mediaTitle: "Vase_03_01_MG",
                    startTime:  500,
                    playBackRate: 1,
                    loop: false,
                    followAction: {group: 2, action: 4}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_08_clickArea"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_07_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2, 5, 6, 12]},
                {group: 2, actions: [2, 6, 7]},
                {group: 3, actions: [2]},
                {group: 4, actions: [2]}
            ]
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",           // eigentlich 03_02_MG, aber die soll nicht geladen werden
//                    mediaTitle: "Vase_03_02_MG",
                    playBackRate: 1,
                    loop: true
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [3]}
            ]
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",           // eigentlich 03_02_MG, aber die soll nicht geladen werden
//                    mediaTitle: "Vase_03_02_MG",
                    playBackRateCommand: "reverse",
                    startVideo: false,
                    loop: false,
                    followAction: {group: 2, action: 6}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [{group: 2, actions: [4, 5]}]
        },

        // Action 2-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",           // eigentlich 03_01_MG, aber die soll nicht geladen werden
//                    mediaTitle: "Vase_03_01_MG",
                    playBackRate: -1,
                    startTime: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [5]}
            ]
        },

        // Action 2-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",           // eigentlich 03_01_MG, aber die soll nicht geladen werden
//                    mediaTitle: "Vase_03_01_MG",
                    playBackRateCommand: "reverse",
                    startVideo: false,
                    loop: false
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [{group: 2, actions: [3]}]
        },


        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_05",
                    playBackRate: 1,
                    startTime: 0,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_02_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_09_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_05",
                    startTime: -1,
                    playBackRate: -8,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [1, 3]},
                {group: 5, actions: [1]}
            ],
            possiblePrevImages: ["Vase_05_frame0151"]
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_05",
                    startTime: 3000,
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [1]},
                {group: 5, actions: [1]}
        ]
        },
    
        /////////////////////////////////////////// *** GRUPPE 4 *** ///////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_04",
                    startTime:  0 + (21 * 33.33),
                    endTime:    2000 + (6 * 33.33),
                    playBackRate: 10,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_03_01_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_01_frame0001", "Vase_01_frame0037", "Vase_01_frame0099", "Vase_01_frame0150", "Vase_04_frame0021", "Vase_05_frame0001"]
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_04",
                    startTime:  2000 + (6 * 33.33),
                    endTime:    0 + (21 * 33.33),
                    playBackRate: -10,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_03_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_04_frame0066", "Vase_04_frame0085"]
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_04",
                    startTime:  2000 + (9 * 33.33),
                    endTime:    3000 + (4 * 33.33),
                    playBackRate: 5,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_03_03_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_04_frame0066", "Vase_04_frame0085"]
        },
        
        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_04",
                    startTime:  3000 + (4 * 33.33),
                    endTime:    2000 + (25 * 33.33),
                    playBackRate: -2,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_03_01_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_04_frame0094"]
        },

        // Action 4-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_04",
                    startTime:  3000 + (4 * 33.33),
                    endTime:    4000 + (5 * 33.33),
                    playBackRate: 5,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_03_04_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_04_frame0094"]
        },

        // Action 4-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_04",
                    startTime:  4000 + (5 * 33.33),
                    endTime:    3000 + (4 * 33.33),
                    playBackRate: -5,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_03_03_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_04_frame0125"]
        },

        // Action 4-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_04",
                    startTime:  4000 + (5 * 33.33),
                    endTime:    5000,
                    playBackRate: 5,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_03_05_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_04_frame0125"]
        },

        // Action 4-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_04",
                    startTime:  5000,
                    endTime:    4000 + (5 * 33.33),
                    playBackRate: -5,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_03_04_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["Vase_04_frame0150"]
        },

        /////////////////////////////////////////// *** GRUPPE 5 *** ///////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_05",
                    startTime:  0 + (18 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "bombe",
                    nrOfInstances: 1,
                    inheritMotherScale: true,
                    targetAction: {group: 0, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 5-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_05",
                    startTime:  0 + (18 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "bombe",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 3}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 6 *** ///////////////////////////////////////////
        // Action 6-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Vase_01_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_07_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_07_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_07_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_07_opacity"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 7 *** ///////////////////////////////////////////
        // Action 7-1
        {
            singleActions: [           
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Vase_01_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv:	"absolutePositionX",
                    newProperties:	[{property: "transform", value: "this.vasenGrooveInitialX()"}]            
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_11_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_11_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 7-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  1000 + (7 * 33.33),
                    endTime:    1000 + (17 * 33.33),
                    playBackRate: 4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_01_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 7-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  1000 + (17 * 33.33),
                    endTime:    1000 + (7 * 33.33),
                    playBackRate: -4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 7-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  5000 + (0 * 33.33),
                    endTime:    4000 + (27 * 33.33),
                    playBackRate: -4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_06_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 7-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  4000 + (24 * 33.33),
                    endTime:    5000 + (0 * 33.33),
                    playBackRate: 4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },


        // Action 7-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  5000 + (0 * 33.33),
                    endTime:    5000 + (6 * 33.33),
                    playBackRate: 4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 7-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_01",
                    startTime:  5000 + (6 * 33.33),
                    endTime:    5000 + (2 * 33.33),
                    playBackRate: -3,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },        

        // Action 7-8
        {
            singleActions: [
                {
                    actionType:	CSM.ActionType.ChangeAnimation,
                    animationDiv:	"absolutePositionX",
                    newProperties:	[{property: "transform", value: "this.vasenGrooveMoveSidewards()"}]            
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 7-9
        {
            singleActions: [
                {
                    actionType:	CSM.ActionType.ChangeAnimation,
                    animationDiv:	"absolutePositionX",
                    newProperties:	[{property: "transform", value: "this.vasenGrooveMoveSidewards()"}]            
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_11_01_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_11_01_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 7-10
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.PlayAudio,
                    mediaTitle: "VasenPling1",
                    volume: 0.5
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 8 *** ///////////////////////////////////////////
        // Action 8-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_12_01_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_12_01_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_12_01_blur"             // Nur ausnahmsweise hier, gehört eigentlich nicht zum Blur, muss aber in der Kette NACH rotate stehen
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6
        },        

        // Action 8-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_12_02_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_12_02_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_12_02_blur"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6
        },        

        // Action 8-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_12_03_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_12_03_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_12_03_blur"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6
        },        

        // Action 8-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_12_04_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_12_04_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_12_04_blur"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6
        },        

        /////////////////////////////////////////// *** GRUPPE 9 *** ///////////////////////////////////////////
        // Action 9-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_01_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_01_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_01_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true,
            possibleNextActions: [
                {group: 9, actions: [2, 4]}
            ],
            minClickCount: 12
        },        

        // Action 9-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_02_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_02_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_02_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 9, actions: [1, 3]}
            ],
            minClickCount: 12
        },        

        // Action 9-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_03_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_03_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_03_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true,
            possibleNextActions: [
                {group: 9, actions: [2, 4]}
            ],
            minClickCount: 12
        },        

        // Action 9-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_04_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_04_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_13_04_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 9, actions: [1, 3]}
            ],
            minClickCount: 12
        },        

        /////////////////////////////////////////// *** GRUPPE 10 *** ///////////////////////////////////////////
        // Action 10-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_14_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_14_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_14_02_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_14_02_rotate"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 300,
                    followAction: {group: 10, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 16
        },        

        // Action 10-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_14_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_14_03_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_14_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_14_03_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 10, actions: [1]}
            ],
            possibleNextActions: [
                {group: 10, actions: [3]}
            ],
            addState: ["wandern"]
        },       
        
        // Action 10-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_14_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_14_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_14_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_14_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 10, actions: [2]}
            ],
            removeState: ["wandern"]
        },        

        /////////////////////////////////////////// *** GRUPPE 11 *** ///////////////////////////////////////////
        // Action 11-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_15_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 5
        },        

        // Action 11-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_15_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 5
        },        

        // Action 11-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_16_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 11,
            addState: ["zittern"],
            demandedState: ["wandern"]
        },        

        // Action 11-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_16_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 11,
            addState: ["zittern"],
            demandedState: ["wandern"]
        },        

        // Action 11-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_16_01_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_16_01_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            demandedState: ["zittern"],
            removeState: ["zittern"]
        },        




    ];

    vasenGrooveX = parseInt(this.name.slice(-2)) * 15;

    vasenGrooveInitialX() {
        //console.log("Ich bin die Vase und heiße " + this.name + " Ende ist: '" + parseInt(this.name.slice(-2)) + "'");
        this.vasenGrooveX = parseInt(this.name.slice(-2)) * 15;
        //console.log("vasenGrooveX: " + this.vasenGrooveX);
        return "translateX(" + this.vasenGrooveX + "vw)";
    }

    vasenGrooveMoveSidewards() {
        this.vasenGrooveX = this.vasenGrooveX + 7;
        return "translateX(" + this.vasenGrooveX + "vw)"
    }


    combiClickActions: Array<CSM.ClickAction> = [
        ///////////////////////////////////////// *** COMBIGROUP 0 *** /////////////////////////////////////////
        // Action 0-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_01_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_02_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_03_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_04_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_05_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_06_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 1 *** /////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_01_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_01_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["wandern"]
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_02_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_02_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            removeState: ["wandern"]
        },


        ///////////////////////////////////////// *** COMBIGROUP 2 *** /////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_01a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_01_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_02a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_02_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_03a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_03_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_04a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_04_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_05a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_05_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 3 *** /////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_06a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_06_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_07a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_07_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_08a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_08_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_09a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_09_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_10a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_10_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_11a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_11_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },


        ///////////////////////////////////////// *** COMBIGROUP 4 *** /////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 5 *** /////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_01_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_02_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_03_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_04_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_05_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_06_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 6 *** /////////////////////////////////////////
        // Action 6-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_01_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_01_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_02_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_02_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_03_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_03_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_03_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_04_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_04_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_04_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_05_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_05_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_05_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 7 *** /////////////////////////////////////////
        // Action 7-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_01_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_01_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 7-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_02_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_02_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 7-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_03_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_03_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 7-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_04_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_04_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 7-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_05_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_05_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 7-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_06_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_06_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

    ];
    

    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);

    audioActions: Array<CSM.AudioAction> = [
        // 1 Normales Anschlagen
        {
            possibleClickActions: [
                {group: 1, actions: [3, 4, 5, 6, 7, 8, 9]},
                {group: 4, actions: [1, 2, 3, 4, 5, 6, 7, 8]},
                {group: 10, actions: [1, 2, 3]}
            ],
            alternativeSounds: [
                {
                    sample: "VasenPling1",
                    midiRange: [40, 65],
                    dynamicRange: [0.06, 0.2],
                },
                {
                    sample: "VasenPling2",
                    midiRange: [40, 65],
                    dynamicRange: [0.06, 0.2],
                },
                {
                    sample: "VasenPling3",
                    midiRange: [40, 65],
                    dynamicRange: [0.06, 0.2],
                },
                {
                    sample: "VasenPling4",
                    midiRange: [40, 65],
                    dynamicRange: [0.06, 0.2],
                },
                {
                    sample: "VasenPling5",
                    midiRange: [40, 65],
                    dynamicRange: [0.06, 0.2],
                },
                {
                    sample: "VasenPling6",
                    midiRange: [40, 65],
                    dynamicRange: [0.06, 0.2],
                },
            ],
            allowedInEnvironments: [0, 1, 10],
            killSamples: ["VasenSprudeln"]
        },   

        // 2 Sprudeln
        /*{
            possibleClickActions: [
                {group: 2, actions: [3]}
            ],
            alternativeSounds: [
                {
                    sample: "VasenSprudeln",
                    midiRange: [60, 60],
                    dynamicRange: [0.8, 0.8],
                    fadeIn: 4000,
                    addToInfinitelyPlayingPlayers: true
                }
            ],
            allowedInEnvironments: [0, 1, 10]
        }, 

        // 3 Sprudeln Reverse
        {
            possibleClickActions: [
                {group: 2, actions: [2, 5]}
            ],
            alternativeSounds: [
                {
                    sample: "VasenPling1",
                    midiRange: [40, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "VasenPling2",
                    midiRange: [40, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "VasenPling3",
                    midiRange: [40, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "VasenPling4",
                    midiRange: [40, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "VasenPling5",
                    midiRange: [40, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "VasenPling6",
                    midiRange: [40, 65],
                    dynamicRange: [0.8, 0.8],
                },
            ],
            allowedInEnvironments: [0, 1, 10],
            killSamples: ["VasenSprudeln"]
        },   

        // 4 Sprudeln bisschen
        {
            possibleClickActions: [
                {group: 2, actions: [1]}
            ],
            alternativeSounds: [
                {
                    sample: "VasenSprudeln",
                    midiRange: [68, 75],
                    dynamicRange: [0.1, 0.1],
                    fadeIn: 1500,
                    fadeOut: 2300,
                    endTime: 3800,
                    addToInfinitelyPlayingPlayers: true
                }
            ],
            allowedInEnvironments: [0, 1, 10]
        }, */  

        // 5 Verbiegen#1
        {
            possibleClickActions: [
                {group: 1, actions: [1]},
                {group: 8, actions: [1, 2, 3, 4]}
            ],
            alternativeSounds: [
                {
                    sample: "VasenPling10",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling11",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                    
                },
                {
                    sample: "VasenPling12",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling13",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling15",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling16",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling18",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
            ],
            allowedInEnvironments: [0, 1, 10],
            killSamples: ["VasenSprudeln"]
        },   

        // 6 Verbiegen#2
        {
            possibleClickActions: [
                {group: 1, actions: [2]}
            ],
            alternativeSounds: [
                {
                    sample: "VasenPling12",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling13",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                }
            ],
            allowedInEnvironments: [0, 1, 10],
            killSamples: ["VasenSprudeln"]
        },   

        // 7 Verbiegen#3
        {
            possibleClickActions: [
                {group: 1, actions: [10, 11]}
            ],
            alternativeSounds: [
                {
                    sample: "VasenPling14",
                    midiRange: [43, 45],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling19",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                    
                },
                {
                    sample: "VasenPling20",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling21",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                }
            ],
            allowedInEnvironments: [0, 1, 10],
            killSamples: ["VasenSprudeln"]
        },   

        // 8 Verbiegen#4
        {
            possibleClickActions: [
                {group: 1, actions: [12]}
            ],
            alternativeSounds: [
                {
                    sample: "VasenPling10",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling16",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                    
                }
            ],
            allowedInEnvironments: [0, 1, 10],
            killSamples: ["VasenSprudeln"]
        },   

        // 9 Verbiegen#5
        {
            possibleClickActions: [
                {group: 1, actions: [13]}
            ],
            alternativeSounds: [
                {
                    sample: "VasenPling11",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                    
                },
                {
                    sample: "VasenPling13",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling14",
                    midiRange: [59, 61],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling15",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling16",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling17",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling18",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
            ],
            allowedInEnvironments: [0, 1, 10],
            killSamples: ["VasenSprudeln"]
        },   

        // 10 Düsenjäger
        {
            possibleCombiActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]}
            ],
            alternativeSounds: [
                {
                    sample: "VasenPling11",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling12",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling13",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling16",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling18",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
            ],
            allowedInEnvironments: [0, 1, 10],
            killSamples: ["VasenSprudeln"]
        },   

        // 11 Zittern
        {
            possibleClickActions: [
                {group: 11, actions: [1, 2, 3, 4]}
            ],
            alternativeSounds: [
                {
                    sample: "VasenPling22",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                },
                {
                    sample: "VasenPling23",
                    midiRange: [50, 70],
                    dynamicRange: [0.03, 0.1],
                }
            ],
            allowedInEnvironments: [0, 1, 10],
            killSamples: ["VasenSprudeln"]
        },   

        // 12 Hin- und Herspringen
        {
            possibleClickActions: [
                {group: 9, actions: [1, 2, 3, 4]}
            ],
            alternativeSounds: [
                {
                    sample: "VasenPling1-delay",
                    midiRange: [58, 62],
                    dynamicRange: [0.06, 0.2],
                },
                {
                    sample: "VasenPling2-delay",
                    midiRange: [58, 62],
                    dynamicRange: [0.06, 0.2],
                },
                {
                    sample: "VasenPling3-delay",
                    midiRange: [58, 62],
                    dynamicRange: [0.06, 0.2],
                },
                {
                    sample: "VasenPling4-delay",
                    midiRange: [58, 62],
                    dynamicRange: [0.06, 0.2],
                },
                {
                    sample: "VasenPling5-delay",
                    midiRange: [58, 62],
                    dynamicRange: [0.06, 0.2],
                },
                {
                    sample: "VasenPling6-delay",
                    midiRange: [58, 62],
                    dynamicRange: [0.06, 0.2]
                }
            ],
            allowedInEnvironments: [0, 1, 10],
            killSamples: ["VasenSprudeln"]
        },   

        // 13 Environment 13
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]},
                {group: 4, actions: [1, 2, 3, 4, 5, 6, 7, 8]},
                {group: 8, actions: [1, 2, 3, 4]},
                {group: 9, actions: [1, 2, 3, 4]},
                {group: 10, actions: [1, 2, 3]},
                {group: 11, actions: [1, 2, 3, 4]},
            ],
            possibleCombiActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]}
            ],
            standardSounds: [
                {
                sample: "VasenPling34",
                midiRange: [40, 60],
                dynamicRange: [0.35, 0.35],
                }
            ],
            alternativeSounds: [
                {
                    sample: "VasenPling24",
                    midiRange: [50, 65],
                    dynamicRange: [0.2, 0.2],
                },
                {
                    sample: "VasenPling25",
                    midiRange: [55, 65],
                    dynamicRange: [0.2, 0.2],
                },
                {
                    sample: "VasenPling26",
                    midiRange: [45, 60],
                    dynamicRange: [0.2, 0.2],
                },
                {
                    sample: "VasenPling27",
                    midiRange: [45, 65],
                    dynamicRange: [0.3, 0.3],
                },
                {
                    sample: "VasenPling28",
                    midiRange: [55, 65],
                    dynamicRange: [0.2, 0.2],
                },
                {
                    sample: "VasenPling29",
                    midiRange: [50, 65],
                    dynamicRange: [0.2, 0.2],
                },
                {
                    sample: "VasenPling30",
                    midiRange: [55, 65],
                    dynamicRange: [0.2, 0.2],
                },
                {
                    sample: "VasenPling31",
                    midiRange: [45, 60],
                    dynamicRange: [0.2, 0.2],
                },
                {
                    sample: "VasenPling32",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4],
                },
                {
                    sample: "VasenPling33",
                    midiRange: [55, 65],
                    dynamicRange: [0.3, 0.3],
                },
                {
                    sample: "VasenPling35",
                    midiRange: [45, 60],
                    dynamicRange: [0.2, 0.2],
                },
                {
                    sample: "VasenPling36",
                    midiRange: [40, 60],
                    dynamicRange: [0.2, 0.2],
                },
            ],
            allowedInEnvironments: [2, 13, 15, 16, 17, 18, 19, 20],
            killSamples: ["VasenSprudeln"]
        },   

        // 14 Zerbrechen
        {
            possibleClickActions: [
                {group: 3, actions: [1]}
            ],
            alternativeSounds: [
                {
                    sample: "zerbrechen01",
                    midiRange: [60, 60],
                    dynamicRange: [0.4, 0.4],
                    startTime: 120
                }
            ]
        },   

        // 15 Environment 14
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]},
                {group: 4, actions: [1, 2, 3, 4, 5, 6, 7, 8]},
                {group: 8, actions: [1, 2, 3, 4]},
                {group: 9, actions: [1, 2, 3, 4]},
                {group: 10, actions: [1, 2, 3]},
                {group: 11, actions: [1, 2, 3, 4]},
            ],
            possibleCombiActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]}
            ],
            alternativeSounds: [
                {
                    sample: "../../musiker/audio/danielpling01",
                    midiRange: [47, 53],
                    dynamicRange: [0.3, 0.3],
                },
                {
                    sample: "../../musiker/audio/danielpling02",
                    midiRange: [47, 53],
                    dynamicRange: [0.3, 0.3],
                },
                {
                    sample: "../../musiker/audio/danielpling03",
                    midiRange: [47, 53],
                    dynamicRange: [0.3, 0.3],
                },
                {
                    sample: "../../musiker/audio/danielpling04",
                    midiRange: [47, 53],
                    dynamicRange: [0.3, 0.3],
                },
                {
                    sample: "../../musiker/audio/danielpling05",
                    midiRange: [47, 53],
                    dynamicRange: [0.3, 0.3],
                }
            ],
            allowedInEnvironments: [14],
            killSamples: ["VasenSprudeln"]
        },   

    ]

    audioHierarchy = [
        {
            description: "Positionsveränderungen",
            combiGroups: [4]                        // Bei mehreren Combigroups haben die früher genannten Priorität
        },
        {
            description: "Formveränderungen",
            actionGroups: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            combiGroups: [0, 1, 2, 3, 5, 6, 7]                        // Bei mehreren Combigroups haben die früher genannten Priorität
        }
    ]



}