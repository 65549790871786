import * as CSM from "../ClickStateManager.js";

export default class MOTHER extends CSM.ClickStateManager {

    activatedOnStart = true;

    videoSize = { x: 0, y: 0 };                 // width and height of the original video material
    clickAreaPosition = { x: 0, y: 0 };         // position of Object in original Video in px
    clickAreaSize = { x: 0, y: 0 };             // Size of the clickable Form in the original video in px, radius for ellipses

    clickAreaForm = CSM.ClickAreaForm.Rectangle;       //Form of the clickable Area
    
    startImageURI = "";
    mediaPfad = "/spielelemente";

    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {}

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Enthält keien neuen Objekte? --> dann probability = 0, Krassitätswert (1-5)
        {name: "0 Init",                                 elements: 3,    probability: 0,     krassitaet: 1},
        {name: "1 Neue Elemente",                        elements: 3,    probability: 0.5,     krassitaet: 1,   allowedInEnvironments: [1, 2, 11, 12, 13, 19, 20] },
        {name: "2 Neue CombiEnvironments Form",          elements: 7,    probability: 0,     krassitaet: 1,   allowedInEnvironments: [19, 20] },
        {name: "3 Neue Konzertvideos",                   elements: 2,    probability: 0,     krassitaet: 1,   allowedInEnvironments: [3] },
        {name: "4 Neue Partituren",                      elements: 3,    probability: 0,     krassitaet: 1,   allowedInEnvironments: [4] },
        {name: "5 Neue Environments",                    elements: 8,    probability: 0,     krassitaet: 1},
        {name: "6 Musiker",                              elements: 7,    probability: 1,     krassitaet: 1,   allowedInEnvironments: [2, 11, 12, 14, 15, 16, 17, 18] },
        {name: "7 Tetris",                               elements: 2,    probability: 0.1,     krassitaet: 1,   allowedInEnvironments: [11, 12, 19, 20] },
        {name: "8 Explosiv",                             elements: 1,    probability: 0.2,     krassitaet: 1,   allowedInEnvironments: [12, 19, 20] },
        {name: "9 Neue zappelnde Elemente",              elements: 1,    probability: 0.5,     krassitaet: 1,   allowedInEnvironments: [19, 20] },
        {name: "10 Neue CombiEnvironments Farbe",        elements: 4,    probability: 0,     krassitaet: 1,   allowedInEnvironments: [19, 20] },
        {name: "11 Neue CombiEnvironments Helligkeit",   elements: 3,    probability: 0,     krassitaet: 1,   allowedInEnvironments: [19, 20] },
        {name: "12 Neue CombiEnvironments Invertierung", elements: 2,    probability: 0,     krassitaet: 1,   allowedInEnvironments: [19, 20] },
        {name: "13 Neue CombiEnvironments Sättigung",    elements: 3,    probability: 0,     krassitaet: 1,   allowedInEnvironments: [19, 20] },
        {name: "14 Neue CombiEnvironments Blur",         elements: 2,    probability: 0,     krassitaet: 1,   allowedInEnvironments: [19, 20] },
        {name: "15 Neue Scheinwerfer",                   elements: 6,    probability: 0.2,     krassitaet: 1,   allowedInEnvironments: [19, 20] },
        {name: "16 Direktlinks",                         elements: 5,    probability: 0,       krassitaet: 1,   allowedInEnvironments: [0] },
        //{name: "16 Neue Musiker",                        elements: 1,    probability: 0.2,     krassitaet: 1,   allowedInEnvironments: [19, 20] },
    ];


    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                /*{
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "testsquare",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                },*/
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "logo",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "menupunkt_about",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "menupunkt_callforscores",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "menupunkt_galerie",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "menupunkt_kontakt",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "menupunkt_schwelbrandorchester",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "menupunkt_termine",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "menupunkt_trailer",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "menupunkt_unterstuetzen",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                }/*,
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "musikerspielelement_igorspallati",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "scheinwerfer_lichtkegel",
                    nrOfInstances: 1,
                    targetAction: {group: 9, action: 6}
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "barrel",
                    nrOfInstances: 1,
                    inheritMotherScale: false,
                    targetAction: {group: 0, action: 1}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_01_02_hue-rotate_environment"
                }*/
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Init Tamtam for Chrome, Edge, Opera)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "tamtam",
                    nrOfInstances: 1,
                    targetAction: {group: 13, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3 (Init Tamtam for Firefox)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "tamtam",
                    nrOfInstances: 1,
                    targetAction: {group: 13, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "vase",
                    nrOfInstances: 1,
                    targetAction: {group: 3, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-2
        /*{
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "tamtam",
                    nrOfInstances: 1,
                    targetAction: {group: 10, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "skull",
                    nrOfInstances: 1,
                    targetAction: {group: 5, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        },*/
        
        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "skull",
                    nrOfInstances: 1,
                    targetAction: {group: 6, action: 5}
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 1-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "barrel",
                    nrOfInstances: 1,
                    targetAction: {group: 14, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_00_00_transform_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_00_01_transform_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_00_02_transform_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_00_03_transform_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_00_04_transform_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 2-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_00_05_transform_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 2-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_00_06_transform_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        
        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "soundtrack2019",
                    nrOfInstances: 1,
                    targetAction: {group: 1, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "autogamie2019",
                    nrOfInstances: 1,
                    targetAction: {group: 1, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 4 *** ///////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "vase",
                    nrOfInstances: 2,
                    inheritMotherScale: false,
                    targetAction: {group: 7, action: 1}
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 5000,
                    followAction: {group: 4, action: 3}
                }
                
                
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },
       
        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.StartMeter,
                    mediaTitle: "WillOvercashMeter"
                },
                {
                    actionType:     CSM.ActionType.PlayScore,
                    mediaTitle:     "Fassballett",
                    metricLevel:    "Primary"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType:     CSM.ActionType.StartMeter,
                    mediaTitle:     "Groove"
                },
                {
                    actionType:     CSM.ActionType.PlayScore,
                    mediaTitle:     "Vasengroove",
                    metricLevel:    "LoopStart"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 5 *** ///////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "steeldrum",
                    newClass: "steeldrum_cssanim_02"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "steeldrum",
                    newClass: "steeldrum_cssanim_03"
                },
                {
                    actionType: CSM.ActionType.PlayAudio,
                    mediaTitle: "TT orient root"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 6, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "steeldrum",
                    newClass: "steeldrum_cssanim_03"
                },
                {
                    actionType: CSM.ActionType.PlayAudio,
                    mediaTitle: "TT orient root"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 6, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "steeldrum",
                    newClass: "steeldrum_cssanim_03"
                },
                {
                    actionType: CSM.ActionType.PlayAudio,
                    mediaTitle: "TT orient root"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 6, action: 3}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "steeldrum",
                    newClass: "steeldrum_cssanim_03"
                },
                {
                    actionType: CSM.ActionType.PlayAudio,
                    mediaTitle: "TT orient root"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 6, action: 4}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_06_hue-rotate_environment"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_06_brightness_environment"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_06_saturate_environment"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_06_blur_environment"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "steeldrum",
                    newClass: "steeldrum_cssanim_04"
                },
                {
                    actionType: CSM.ActionType.PlayAudio,
                    mediaTitle: "TT pp root",
                    volume: 1
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 6, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_07_hue-rotate_environment"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_07_brightness_environment"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_07_saturate_environment"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_07_blur_environment"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "steeldrum",
                    newClass: "steeldrum_cssanim_05"
                },
                {
                    actionType: CSM.ActionType.PlayAudio,
                    mediaTitle: "Malika_11",
                    volume: 1
                }/*,
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 100,
                    followAction: {group: 6, action: 4}
                }*/
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 6, action: 7}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 6 *** ///////////////////////////////////////////
        // Action 6-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "musikerspielelement_willovercash",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                },
                {
                    actionType: CSM.ActionType.TelecontrolObject,
                    targetObject: "mother→childtamtam01→childscheinwerfer_lichtkegel01",
                    targetAction: {group: 7, action: 1}
                },
                {
                    actionType: CSM.ActionType.TelecontrolObject,
                    targetObject: "mother→childtamtam01→childscheinwerfer_lichtkegel02",
                    targetAction: {group: 7, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "musikerspielelement_danielseroussi",
                    nrOfInstances: 1,
                    targetAction: {group: 2, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "musikerspielelement_lukasgothszalk",
                    nrOfInstances: 1,
                    targetAction: {group: 3, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "musikerspielelement_malikamaminova",
                    nrOfInstances: 1,
                    targetAction: {group: 4, action: 1}
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "musikerspielelement_carolmcgonnell",
                    nrOfInstances: 1,
                    targetAction: {group: 1, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "musikerspielelement_horiadumitrache",
                    nrOfInstances: 1,
                    targetAction: {group: 1, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "wulst",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },


        /////////////////////////////////////////// *** GRUPPE 7 *** ///////////////////////////////////////////
        // Action 7-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "tetris",
                    nrOfInstances: 20,
                    inheritMotherScale: false,
                    targetAction: {group: 0, action: 1}
                }/*,
                {
                    actionType: CSM.ActionType.PlayAudio,
                    mediaTitle: "../impactsounds/NepalTempleWabern"
                }*/
                
            ],
            minGPUScore: 7,
            allowHover: false,
            allowClick: true
        },
        
        // Action 7-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "skulllight",
                    nrOfInstances: 20,
                    inheritMotherScale: false,
                    targetAction: {group: 1, action: 1}
                }/*,
                {
                    actionType: CSM.ActionType.PlayAudio,
                    mediaTitle: "../impactsounds/NepalTempleWabern"
                }*/
            ],
            minGPUScore: 8,
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 8 *** ///////////////////////////////////////////
        // Action 8-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "bombe",
                    nrOfInstances: 1,
                    targetAction: {group: 1, action: 3}
                },
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "tamtam",
                    nrOfInstances: 1,
                    targetAction: {group: 16, action: 1}
                }
            ],
            minGPUScore: 5,
            allowHover: false,
            allowClick: true
        },
        
        /////////////////////////////////////////// *** GRUPPE 9 *** ///////////////////////////////////////////
        // Action 9-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "skull",
                    nrOfInstances: 1,
                    inheritMotherScale: false,
                    targetAction: {group: 7, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        },         
        
        /*// Action 9-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "wulst",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },*/

        /////////////////////////////////////////// *** GRUPPE 10 *** ///////////////////////////////////////////
        // Action 10-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_01_00_hue-rotate_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 10-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_01_01_hue-rotate_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 10-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_01_02_hue-rotate_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 10-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_01_03_hue-rotate_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        /////////////////////////////////////////// *** GRUPPE 11 *** ///////////////////////////////////////////
        // Action 11-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_02_00_brightness_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 11-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_02_01_brightness_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 11-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_02_02_brightness_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        /////////////////////////////////////////// *** GRUPPE 12 *** ///////////////////////////////////////////
        // Action 12-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_03_00_invert_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 12-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_03_01_invert_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        /////////////////////////////////////////// *** GRUPPE 13 *** ///////////////////////////////////////////
        // Action 13-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_04_00_saturate_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 13-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_04_01_saturate_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 13-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_04_02_saturate_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        /////////////////////////////////////////// *** GRUPPE 14 *** ///////////////////////////////////////////
        // Action 14-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_05_00_blur_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 14-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_05_01_blur_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        /////////////////////////////////////////// *** GRUPPE 15 *** ///////////////////////////////////////////
        // Action 15-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "scheinwerfer_lichtkegel",
                    nrOfInstances: 1,
                    targetAction: {group: 9, action: 1}
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 15-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "scheinwerfer_lichtkegel",
                    nrOfInstances: 1,
                    targetAction: {group: 9, action: 2}
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 15-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "scheinwerfer_lichtkegel",
                    nrOfInstances: 1,
                    targetAction: {group: 9, action: 3}
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 15-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "scheinwerfer_lichtkegel",
                    nrOfInstances: 1,
                    targetAction: {group: 9, action: 4}
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 15-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "scheinwerfer_lichtkegel",
                    nrOfInstances: 1,
                    targetAction: {group: 9, action: 5}
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 15-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "scheinwerfer_lichtkegel",
                    nrOfInstances: 1,
                    targetAction: {group: 9, action: 6}
                },
            ],
            allowHover: false,
            allowClick: true
        },      

        /////////////////////////////////////////// *** GRUPPE 16 *** ///////////////////////////////////////////
        // Action 16-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.EvalFunction,
                    targetObject: "menupunkt_unterstuetzen",
                    function: "openImmediately"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 16-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ActivateObject,
                    targetObject: "textfenster_erfolgsseite"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 16-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ActivateObject,
                    targetObject: "textfenster_doubleoptinseite"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 16-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ActivateObject,
                    targetObject: "videofenster_veitstanzmusikvideo"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 16-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ActivateObject,
                    targetObject: "produktion_steigdurchdastamtam"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 16 *** ///////////////////////////////////////////
        // Action 16-1
        /*{
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "musikerspielelement_igorspallati",
                    nrOfInstances: 1,
                    targetAction: {group: 0, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },*/



    ];

    combiClickActions: Array<CSM.ClickAction> = [];

    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "tamtam",
                    nrOfInstances: 1,
                    targetAction: {group: 19, action: 1}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_00_00_transform_environment"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_01_00_hue-rotate_environment"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_02_00_brightness_environment"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_03_00_invert_environment"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_04_00_saturate_environment"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "mother_cssanim_05_00_blur_environment"
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);

}