import { TextDiv } from "../../../TextDiv.js";
import * as CSM from "../../../ClickStateManager.js";
import Musicians from "../../../musicians.js";

export default class Textfenster_Schwelbrandorchester extends TextDiv {
    textURI = "Textfenster/Textfenster_Schwelbrandorchester.html";
    textURI_mobile = "Textfenster/Textfenster_Schwelbrandorchester_mobile.html";
    title = "Schwelbrandorchester";
    width = 100;                                   //object size in percent of screenwidth
    height = 100;
    position = { x: 0, y: 0 };                  //top left position of video object in percent of screen width and screen height
    musicians: Musicians;

    constructor(name: string){
        super(name);
    }

    public onActivation(targetAction: CSM.GroupActionContainer): void {
        super.onActivation(targetAction);
        setTimeout(()=>{
            let musicians = new Musicians(this.mediator);
        },1000);
    }

    onMouseDown() {
        this.clickCount++;
        this.executeAction(1);
    }
    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Catch All",             elements: 4,    probability: 0,     krassitaet: 1, allowedInEnvironments: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
    ];

    combiGroups = [];

    clickActions = [

        // Action 0-1 - Init Action
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement,
                    targetObjectSelector: "#textfenster_schwelbrandorchester"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_03_textdiv"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_04_textdiv"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 150,
                    followAction: {group: 0, action: 3}
                }                
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement,
                    targetObjectSelector: "#textfenster_schwelbrandorchester"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement,
                    targetObjectSelector: "#textfenster_schwelbrandorchester"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_01_textdiv"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ]

}