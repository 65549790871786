import * as CSM from "../../../ClickStateManager.js";

export default class Trailer extends CSM.ClickStateManager {

    videoSize = { x: 1920, y: 1080 };               // width and height of the original video material
    clickAreaPosition = { x: 0, y: 0 };         // position of Object in original Video in px
    clickAreaSize = { x: 1920, y: 1080 };             // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;       //Form of the clickable Area
    mediaPfad = "/produktionen/2019 aseven";


    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});

        this.lokalerEskalationswert = 0.5;
        
        this.findPossibleNextActions();

        // Sonderregeln

        console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "Init und Exit",                 elements: 3,    probability: 1,     krassitaet: 1},
        {name: "Play     ",                     elements: 1,    probability: 1,     krassitaet: 1}
    ];

    combiGroups = [];


    normalClickActions = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Trailer_Still"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_01_textdiv"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_02_textdiv"
                },
                /*{
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Trailer",
                    stopVideo: true
                },*/
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 0, action: 3}
                }                
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1]}
            ]

        },
        
        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                /*{
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Trailer"
                }*/
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]}
            ]
        },

    ];

    combiClickActions = [];

    clickActions = this.normalClickActions.concat(this.combiClickActions);

}