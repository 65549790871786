import * as CSM from "../../../ClickStateManager.js";

export default class Logo extends CSM.ClickStateManager {

    videoSize = { x: 1280, y: 720 };               // width and height of the original video material
    clickAreaPosition = { x: 225, y: 280 };        // position of Object in original Video in px
    clickAreaSize = { x: 785, y: 160 };            // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;   //Form of the clickable Area
    mediaPfad = "/spielelemente/logo"


    onMouseDown() {
        if (this.mediator.isLogoLocked) {return;}
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});

        //// Routine zum Auslesen der Objektzustände -- später wieder löschen!! ////

        /*console.log("hyir");
        //console.log("ID\tVisibility\tDisplay\tLeft\tTop\tWidth\tHeight\tLeft Cl.Ar.\tTop Cl.Ar\tWidth Cl.Ar.\tHeight Cl.Ar.\tOpacity\tBlendmode\tRotate\tRotate3d\tSkew\tPerspective\tBrightness\tContrast\tBlur\tSaturate\tHue-rotate")
        let alleDIVs = document.querySelectorAll(".objectWrapper > div");
        for (let currentDIV of alleDIVs) {
            let id = currentDIV.id;
            if (!id.includes("childmenupunkt") && !id.includes("textfenster") && !id.includes("warnhinweis") && !id.includes("trailer") && id!="mother") {
                let visibility = currentDIV.style.visibility;
                let display = currentDIV.style.display;
                let blendmode = window.getComputedStyle(currentDIV).getPropertyValue('mix-blend-mode').toString().replace(".", ",");
                let animationWrapper = currentDIV.querySelector(".animation-wrapper")
                let rect = animationWrapper.getBoundingClientRect();
                let left = rect.left.toString().replace(".", ",");
                let right = rect.right.toString().replace(".", ",");
                let width = (rect.right - rect.left).toString().replace(".", ",");
                let top = rect.top.toString().replace(".", ",");
                let bottom = rect.bottom.toString().replace(".", ",");
                let height = (rect.bottom - rect.top).toString().replace(".", ",");
                let clickArea = currentDIV.querySelector(".clickArea");
                let leftCA = "";
                let rightCA = "";
                let widthCA = "";
                let topCA = "";
                let bottomCA = "";
                let heightCA = "";
                if (clickArea) {
                    let rectCA = clickArea.getBoundingClientRect();
                    leftCA = rectCA.left.toString().replace(".", ",");
                    rightCA = rectCA.right.toString().replace(".", ",");
                    widthCA = (rectCA.right - rectCA.left).toString().replace(".", ",");
                    topCA = rectCA.top.toString().replace(".", ",");
                    bottomCA = rectCA.bottom.toString().replace(".", ",");
                    heightCA = (rectCA.bottom - rectCA.top).toString().replace(".", ",");
                }
                let opacityDIV = currentDIV.querySelector(".opacity");
                let opacity = window.getComputedStyle(opacityDIV).getPropertyValue('opacity').toString().replace(".", ",");

                let rotateDIV = currentDIV.querySelector(".rotate");
                let rotate = window.getComputedStyle(rotateDIV).getPropertyValue('transform').toString().replace(".", ",");
                let rotate3dDIV = currentDIV.querySelector(".rotate3d");
                let rotate3d = window.getComputedStyle(rotate3dDIV).getPropertyValue('transform').toString().replace(".", ",");
                let skewDIV = currentDIV.querySelector(".skew");
                let skew = window.getComputedStyle(skewDIV).getPropertyValue('transform').toString().replace(".", ",");
                let perspectiveDIV = currentDIV.querySelector(".perspective");
                let perspective = window.getComputedStyle(perspectiveDIV).getPropertyValue('transform').toString().replace(".", ",");
                let brightnessDIV = currentDIV.querySelector(".brightness");
                let brightness = window.getComputedStyle(brightnessDIV).getPropertyValue('filter').toString().replace(".", ",");
                let contrastDIV = currentDIV.querySelector(".contrast");
                let contrast = window.getComputedStyle(contrastDIV).getPropertyValue('filter').toString().replace(".", ",");
                let blurDIV = currentDIV.querySelector(".blur");
                let blur = window.getComputedStyle(blurDIV).getPropertyValue('filter').toString().replace(".", ",");
                let saturateDIV = currentDIV.querySelector(".saturate");
                let saturate = window.getComputedStyle(saturateDIV).getPropertyValue('filter').toString().replace(".", ",");
                let hueRotateDIV = currentDIV.querySelector(".hue-rotate");
                let hueRotate = window.getComputedStyle(hueRotateDIV).getPropertyValue('filter').toString().replace(".", ",");
                //console.log(id + "\t" + visibility + "\t" + display + "\t" + left + "\t" + top + "\t" + (width) + "\t" + (height) + "\t" + leftCA + "\t" + topCA + "\t" + (widthCA) + "\t" + (heightCA) + "\t" + opacity + "\t" + blendmode + "\t" + rotate + "\t" + rotate3d + "\t" + skew + "\t" + perspective + "\t" + brightness + "\t" + contrast + "\t" + blur + "\t" + saturate + "\t" + hueRotate);
            }
        }*/

        this.lokalerEskalationswert = 0;

        this.findPossibleNextActions();

        // Sonderregeln
        // –––

        this.executeActionByProbabilityCalculation();
        return;

        this.executeAction(this.findActionNumber(1, 8));
        return;
        if (this.clickCount == 1) {this.executeAction(this.findActionNumber(1, 5));}
        if (this.clickCount == 2) {this.executeAction(this.findActionNumber(1, 6));}

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "Init und Exit",         elements: 3,    probability: 0,     krassitaet: 1},
        {name: "Drehen",                elements: 8,    probability: 1,     krassitaet: 1}
    ];

    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "logo_frame0001"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit) -- no real exit action - our logo may not be killed!
        {
            singleActions: [

            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3 (Zurückformen)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_07_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_07_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "logo",
                    playBackRate: 4,
                    exclusiveExtensions: [".webm"]
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "logo",
                    playBackRate: -4,
                    exclusiveExtensions: [".webm"]
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_03_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfTransformIs: "matrix(1, 0.0874887, -1, 1, 0, 0)"
        },

        // Action 1-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfTransformIs: "matrix(1, -0.0699268, 1, 1, 0, 0)"
        },

        // Action 1-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_05_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_06_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_06_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_06_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_06_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    combiClickActions: Array<CSM.ClickAction> = [];

    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_07_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_07_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_07_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "logo_cssanim_07_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1500,
                    followAction: {group: 0, action: 3}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);




}