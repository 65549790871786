import * as CSM from "../../../ClickStateManager.js";
import { TextDiv } from "../../../TextDiv.js"

export default class TextDivGalerie extends TextDiv {

    currentImage = 0;
    images: Array<{
        filename: string,
        blobURL?: string
    }>

    constructor(name: string) {
        super(name);
        
    }

    isEven(zahl: number) { return Math.floor(zahl / 2) == zahl / 2; }
    isOdd(zahl: number)  { return !this.isEven(zahl); }

    loadImageNr(zahl: number) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', this.images[zahl].filename, true);
        xhr.responseType = 'blob';

        const addBlob = function(xhr: XMLHttpRequest, zahl: number) {
            if (xhr.status == 200) {
                var blob = xhr.response;
                var URL = (window.webkitURL || window.URL).createObjectURL(blob);
                this.images[zahl].blobURL = URL;
                console.log("Loaded Image Nr. " + zahl);
                if (zahl == this.currentImage) {
                    let imageDOM = <HTMLImageElement>document.querySelector("#bildergalerie");
                    imageDOM.src = URL;
                }
            } 
        };

        xhr.addEventListener("load", addBlob.bind(this, xhr, zahl))
        xhr.send();
    }

    loadImages() {
        let j = 0;
        for (let i = 0; i < this.images.length; i++) {
            if (this.isEven(i)) {j = i / 2;}
            if (this.isOdd(i))  {j = this.images.length - ((i + 1) / 2);}
            this.loadImageNr(j);
        }
    }

    public onActivation(targetAction?: CSM.GroupActionContainer) {
        super.onActivation(targetAction);
        this.loadImages();
    }

    replaceImages(imageDOM: HTMLImageElement, imageDOMReplacement: HTMLImageElement, url: string) {
        imageDOM.src = url;
        imageDOM.classList.remove("bildergalerie_swipeleft", "bildergalerie_swiperight");
        imageDOMReplacement.classList.remove("bildergalerie_replacement_swipeleft", "bildergalerie_replacement_swiperight");
    }

    bildergalerie_forward() {
        this.currentImage++;
        if (this.currentImage >= this.images.length) { this.currentImage = 0; }
        let imageDOM = <HTMLImageElement>document.querySelector("#bildergalerie");
        let imageDOMReplacement = <HTMLImageElement>document.querySelector("#bildergalerie_replacement");
        let blobURL = this.images[this.currentImage].blobURL;
        if (imageDOM && imageDOMReplacement && blobURL) {
            imageDOMReplacement.src = blobURL;
            imageDOM.classList.add("bildergalerie_swipeleft");
            imageDOMReplacement.classList.add("bildergalerie_replacement_swipeleft");
            setTimeout(this.replaceImages.bind(this, imageDOM, imageDOMReplacement, blobURL), 200);
//            imageDOM.addEventListener("animationend", this.replaceImages.bind(this, imageDOM, imageDOMReplacement, this.images[this.currentImage]), false);
        }
    }

    bildergalerie_back() {
        this.currentImage--;
        if (this.currentImage < 0) { this.currentImage = this.images.length - 1; }
        let imageDOM = <HTMLImageElement>document.querySelector("#bildergalerie");
        let imageDOMReplacement = <HTMLImageElement>document.querySelector("#bildergalerie_replacement");
        let blobURL = this.images[this.currentImage].blobURL;
        if (imageDOM && imageDOMReplacement && blobURL) {
            imageDOMReplacement.src = blobURL;
            imageDOM.classList.add("bildergalerie_swiperight");
            imageDOMReplacement.classList.add("bildergalerie_replacement_swiperight");
            setTimeout(this.replaceImages.bind(this, imageDOM, imageDOMReplacement, blobURL), 200);
//            imageDOM.addEventListener("animationend", this.replaceImages.bind(this, imageDOM, imageDOMReplacement, this.images[this.currentImage]), false);
        }
    }

    bildergalerie_close() {
        this.executeAction(1);
    }  
}