import * as CSM from "../../../ClickStateManager.js";

export default class Scheinwerfer_Gehaeuse extends CSM.ClickStateManager {

    //activatedOnStart = true;

    dimensionsDefault: CSM.dimensions = {
        size: 100,                                   //object size in percent of screenwidth
        position: { x: 0, y: 0 },                 //top left position of video object in percent of screen width and screen height
        videoSize: { x: 3350, y: 3350 },            //width and height of the original video material
        clickAreaPosition: { x: 1520, y: 1520 },     //position of Object in original Video in px
        clickAreaSize: { x: 0, y: 0 }           //Size of the clickable Form in the original video in px, radius for ellipses
    }

    dimensionsMobile: CSM.dimensions = {
        size: 50,                                   //object size in percent of screenwidth
        position: { x: 0, y: 10 },                 //top left position of video object in percent of screen width and screen height
        videoSize: { x: 2048, y: 1024 },            //width and height of the original video material
        clickAreaPosition: { x: 1180, y: 300 },     //position of Object in original Video in px
        clickAreaSize: { x: 533, y: 533 }           //Size of the clickable Form in the original video in px, radius for ellipses
    }
             
    clickAreaForm = CSM.ClickAreaForm.Rectangle;      //Form of the clickable Area
    mediaPfad = "/spielelemente/scheinwerfer";


    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "Init und Exit",         elements: 2,    probability: 0,     krassitaet: 1},
        {name: "Catch All",             elements: 7,    probability: 0.5,     krassitaet: 1}
    ]

    combiGroups = [
    ];

    normalClickActions = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Scheinwerfer_Gehaeuse_MG_frame0001"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Scheinwerfer_Gehaeuse_MG_frame0041"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "primary",
                    newClass: "scheinwerfer_cssanim_00_x"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "secondary",
                    newClass: "scheinwerfer_cssanim_00_y"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "tertiary",
                    newClass: "scheinwerfer_cssanim_00_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "quarternary",
                    newClass: "scheinwerfer_cssanim_00_scale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "combiGroup_primary",
                    newClass: "scheinwerfer_cssanim_00_brightness_gehaeuse"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "combiGroup_secondary",
                    newClass: "scheinwerfer_cssanim_00_blur"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 9000,
                    followAction: {group: 1, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        },


        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Gehaeuse_MG",
                    startTime: 1333,
                    playBackRate: -1
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 500,
                    followAction: {group: 1, action: 3}
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Gehaeuse_MG",
                    playBackRate: 1,
                    startVideo: false,
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 500,
                    followAction: {group: 1, action: 4}
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Gehaeuse_MG",
                    playBackRate: -1,
                    startVideo: false,
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 700,
                    followAction: {group: 1, action: 5}
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Gehaeuse_MG",
                    playBackRate: 0.5,
                    startVideo: false,
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 1, action: 6}
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Gehaeuse_MG",
                    playBackRate: -0.75,
                    startVideo: false,
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 1, action: 7}
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Gehaeuse_MG",
                    playBackRate: 1,
                    startVideo: false,
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 500,
                    followAction: {group: 1, action: 8}
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Gehaeuse_MG",
                    playBackRate: -1,
                    startVideo: false,
                }
            ],
            allowHover: false,
            allowClick: true
        },



        /////////////////////////////////////////// *** GRUPPE X *** ///////////////////////////////////////////
        // Action X-1           -- Testschwenk
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "secondary",
                    newClass: "scheinwerfer_cssanim_xx_x_y_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action X-2           -- z-Achsen-Schwenk (Video abspielen)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Scheinwerfer_Gehaeuse_MG"
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    combiClickActions = [

    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions);

}