import musiciansData from './musiciansData.js'
import Mediator from './Mediator.js';
import { ClickStateManager } from './ClickStateManager.js';
import { TextDiv } from './TextDiv.js';

export default class Musicians {

    private musicians: { [key: string]: string };
    private musicians_svg: HTMLObjectElement | null = null;
    private mediator: Mediator;

    constructor(mediator: Mediator) {
        this.mediator = mediator;
        this.musicians = musiciansData;
        let svgString = mediator.isMobileCheck() ? "#musikersvg_mobile" : "#musikersvg";
        this.musicians_svg = document.querySelector<HTMLObjectElement>(svgString);

        

        //console.log(this.musicians_svg);

        for (const [id, objectName] of Object.entries(this.musicians)) {
            const clickArea = this.musicians_svg?.contentDocument?.getElementById(id);
            if (clickArea) {
                if (!this.mediator.clientIsMobile) {
                    clickArea.addEventListener('mouseenter', () => this.handleMouseenter(objectName));
                    clickArea.addEventListener('mouseleave', () => this.handleMouseleave(objectName));
                }
                clickArea.addEventListener('click', () => this.handleClick(objectName));
            } else {
                console.warn(`Click area with id ${id} not found`);
            }
        }

        let musiciansTextfenster = document.getElementById("textfenster_schwelbrandorchester");
        if (musiciansTextfenster) {
            musiciansTextfenster.addEventListener('click', () => {
                if (this.mediator.activeMusician != "") {
                    this.mediator.objects[this.mediator.activeMusician].executeAction(1);
                    this.mediator.activeMusician = "";
                }
            });
        }
    }

    private handleMouseenter(objectName: string): void {
        if (document.getElementById(objectName) == null)
            this.mediator.objects[objectName].prepareObject(objectName);
    }

    private handleMouseleave(objectName: string): void {
        if (this.mediator.objects[objectName].active == false)
            this.mediator.objects[objectName].removeHTML();
    }

    private removeHTML(activeMusician: string) {
        this.mediator.objects[activeMusician].removeHTML();
    }

    private handleClick(objectName: string): void {
        let targetObject = <TextDiv>this.mediator.objects[objectName];
        if (this.mediator.clientIsMobile) {
            targetObject.prepareObject().then(()=>targetObject.onActivation({ group: 0, action: 1 }));
        }
        else {
            targetObject.onActivation({ group: 0, action: 1 });
        }
    }
}