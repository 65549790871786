// import Utilities
import Mediator from "./Mediator.js";
//import initMeters from "./MeterManager.js";
//import initScores from "./ScoreManager.js";
import activateSwipes from "./Swipe.js";
import Steeldrum from "./Steeldrum.js";
//import Musicians from "./musicians.js";

// import Objects
import MOTHER from "./objects/MOTHER.js";
import TamTam from "./objects/Spielelemente/Gegenstaende/TamTam.js";
import Wulst from "./objects/Spielelemente/Gegenstaende/Wulst.js";
import Barrel from "./objects/Spielelemente/Gegenstaende/Barrel.js";
import Snake from "./objects/Spielelemente/Gegenstaende/Snake.js";
import Ballon from "./objects/Spielelemente/Gegenstaende/Ballon.js";
import Bombe from "./objects/Spielelemente/Gegenstaende/Bombe.js";
/*import Farnbluete_01 from "./objects/Spielelemente/Texturen/Farnbluete_01.js";
import Farnbluete_02 from "./objects/Spielelemente/Texturen/Farnbluete_02.js";
import Farnbluete_03 from "./objects/Spielelemente/Texturen/Farnbluete_03.js";
import Farnbluete_04 from "./objects/Spielelemente/Texturen/Farnbluete_04.js";
import Farnbluete_06 from "./objects/Spielelemente/Texturen/Farnbluete_06.js";
import Farnbluete_07 from "./objects/Spielelemente/Texturen/Farnbluete_07.js";
import Farnbluete_08 from "./objects/Spielelemente/Texturen/Farnbluete_08.js";
import Farnbluete_09 from "./objects/Spielelemente/Texturen/Farnbluete_09.js";*/
import Glaskugel from "./objects/Spielelemente/Gegenstaende/Glaskugel.js";
import Logo from "./objects/Spielelemente/Logo/Logo.js";
//import Messerfass from "./objects/Spielelemente/Gegenstaende/Messerfass.js";
import Paket from "./objects/Spielelemente/Gegenstaende/Paket.js";
import Scheinwerfer_Gehaeuse from "./objects/Spielelemente/Scheinwerfer/Scheinwerfer_Gehaeuse.js";
import Scheinwerfer_Lichtkegel from "./objects/Spielelemente/Scheinwerfer/Scheinwerfer_Lichtkegel.js";
import Skull from "./objects/Spielelemente/Gegenstaende/Skull.js";
import SkullLight from "./objects/Spielelemente/Gegenstaende/SkullLight.js";
import TestSquare from "./objects/Spielelemente/Gegenstaende/TestSquare.js";
import Tetris from "./objects/Spielelemente/Gegenstaende/Tetris.js";
import Vase from "./objects/Spielelemente/Gegenstaende/Vase.js";

// import Musiker-Spielelemente
import MusikerSpielelement_WillOvercash from "./objects/Spielelemente/MusikerSpielelemente/MusikerSpielelement_WillOvercash.js";
import MusikerSpielelement_DanielSeroussi from "./objects/Spielelemente/MusikerSpielelemente/MusikerSpielelement_DanielSeroussi.js";
import MusikerSpielelement_HoriaDumitrache from "./objects/Spielelemente/MusikerSpielelemente/MusikerSpielelement_HoriaDumitrache.js";
import MusikerSpielelement_LukasGothszalk from "./objects/Spielelemente/MusikerSpielelemente/MusikerSpielelement_LukasGothszalk.js";
import MusikerSpielelement_MalikaMaminova from "./objects/Spielelemente/MusikerSpielelemente/MusikerSpielelement_MalikaMaminova.js";
/*import MusikerSpielelement_OliviaPalmerBaker from "./objects/Spielelemente/MusikerSpielelemente/MusikerSpielelement_OliviaPalmerBaker.js";
import MusikerSpielelement_SaschaFriedl from "./objects/Spielelemente/MusikerSpielelemente/MusikerSpielelement_SaschaFriedl.js";
import MusikerSpielelement_VictorGuaita from "./objects/Spielelemente/MusikerSpielelemente/MusikerSpielelement_VictorGuaita.js";*/
import MusikerSpielelement_CarolMcGonnell from "./objects/Spielelemente/MusikerSpielelemente/MusikerSpielelement_CarolMcGonnell.js";
//import MusikerSpielelement_DansHands from "./objects/Spielelemente/MusikerSpielelemente/MusikerSpielelement_DansHands.js";
//import MusikerSpielelement_IgorSpallati from "./objects/Spielelemente/MusikerSpielelemente/MusikerSpielelement_IgorSpallati.js";

// import Schwelbrandvideos
/*import Soundtrack2019 from "./objects/Spielelemente/Schwelbrandvideos/Soundtrack2019.js";
import Autogamie2019 from "./objects/Spielelemente/Schwelbrandvideos/Autogamie2019.js";
import Veitstanz2019 from "./objects/Spielelemente/Schwelbrandvideos/Veitstanz2019.js";
import Metallics2019 from "./objects/Spielelemente/Schwelbrandvideos/Metallics2019.js";*/

// import Menupunkte
import Menupunkt_About from "./objects/Infoelemente/Hauptmenupunkte/Menupunkt_About.js";
import Menupunkt_CallForScores from "./objects/Infoelemente/Hauptmenupunkte/Menupunkt_CallForScores.js";
import Menupunkt_Galerie from "./objects/Infoelemente/Hauptmenupunkte/Menupunkt_Galerie.js";
import Menupunkt_Kontakt from "./objects/Infoelemente/Hauptmenupunkte/Menupunkt_Kontakt.js";
import Menupunkt_Schwelbrandorchester from "./objects/Infoelemente/Hauptmenupunkte/Menupunkt_Schwelbrandorchester.js";
import Menupunkt_Termine from "./objects/Infoelemente/Hauptmenupunkte/Menupunkt_Termine.js";
import Menupunkt_Trailer from "./objects/Infoelemente/Hauptmenupunkte/Menupunkt_Trailer.js";
import Menupunkt_Unterstuetzen from "./objects/Infoelemente/Hauptmenupunkte/Menupunkt_Unterstuetzen.js";

// import Textfenster
import Textfenster_About from "./objects/Infoelemente/Textfenster/Textfenster_About.js";
import Textfenster_Termine from "./objects/Infoelemente/Textfenster/Textfenster_Termine.js";
import Textfenster_CallForScores from "./objects/Infoelemente/Textfenster/Textfenster_CallForScores.js";
import Textfenster_Galerie from "./objects/Infoelemente/Textfenster/Textfenster_Galerie.js";
import Textfenster_Kontakt from "./objects/Infoelemente/Textfenster/Textfenster_Kontakt.js";
import Textfenster_Schwelbrandorchester from "./objects/Infoelemente/Textfenster/Textfenster_Schwelbrandorchester.js";
import Trailer from "./objects/Infoelemente/Trailer/Trailer.js";
import Textfenster_Unterstuetzen from "./objects/Infoelemente/Textfenster/Textfenster_Unterstuetzen.js";
import Textfenster_Galerie_Veitstanz_MakingOf from "./objects/Infoelemente/Textfenster/Textfenster_Galerie_Veitstanz_MakingOf.js";
import Textfenster_Galerie_Spring_MakingOf from "./objects/Infoelemente/Textfenster/Textfenster_Galerie_Spring_MakingOf.js";
import Textfenster_Erfolgsseite from "./objects/Infoelemente/Textfenster/Textfenster_Erfolgsseite.js";
import Textfenster_DoubleOptInSeite from "./objects/Infoelemente/Textfenster/Textfenster_DoubleOptInSeite.js";

// import Videofenster
import Videofenster_Trailer from "./objects/Infoelemente/Videofenster/Videofenster_Trailer.js";
import Videofenster_Autogamie2019 from "./objects/Infoelemente/Videofenster/Videofenster_Autogamie2019.js";
import Videofenster_DiatonischeSinfonie2023 from "./objects/Infoelemente/Videofenster/Videofenster_DiatonischeSinfonie2023.js";
import Videofenster_Farnbluete from "./objects/Infoelemente/Videofenster/Videofenster_Farnbluete.js";
import Videofenster_Metallics2019 from "./objects/Infoelemente/Videofenster/Videofenster_Metallics2019.js";
import Videofenster_Metallics2023 from "./objects/Infoelemente/Videofenster/Videofenster_Metallics2023.js";
import Videofenster_Spring2020 from "./objects/Infoelemente/Videofenster/Videofenster_Spring2020.js";
import Videofenster_Veitstanz2019 from "./objects/Infoelemente/Videofenster/Videofenster_Veitstanz2019.js";
import Videofenster_VeitstanzMusikvideo from "./objects/Infoelemente/Videofenster/Videofenster_VeitstanzMusikvideo.js";
import Videofenster_Velocity2019 from "./objects/Infoelemente/Videofenster/Videofenster_Velocity2019.js";

// import Produktionen
import Produktion_ASeven2019 from "./objects/Infoelemente/Produktionen/Produktion_ASeven2019.js";
import Produktion_Debut from "./objects/Infoelemente/Produktionen/Produktion_Debut.js";
import Produktion_Farnbluete from "./objects/Infoelemente/Produktionen/Produktion_Farnbluete.js";
import Produktion_Freeze2020 from "./objects/Infoelemente/Produktionen/Produktion_Freeze2020.js";
import Produktion_Overkill from "./objects/Infoelemente/Produktionen/Produktion_Overkill.js";
import Produktion_Plasma from "./objects/Infoelemente/Produktionen/Produktion_Plasma.js";
import Produktion_Radiodebut from "./objects/Infoelemente/Produktionen/Produktion_Radiodebut.js";
import Produktion_SpringMusikvideo from "./objects/Infoelemente/Produktionen/Produktion_SpringMusikvideo.js";
import Produktion_StageReconquista from "./objects/Infoelemente/Produktionen/Produktion_StageReconquista.js";
import Produktion_SteigDurchDasTamtam from "./objects/Infoelemente/Produktionen/Produktion_SteigDurchDasTamtam.js";
import Produktion_Teldex2020 from "./objects/Infoelemente/Produktionen/Produktion_Teldex2020.js";
import Produktion_VeitstanzMusikvideo from "./objects/Infoelemente/Produktionen/Produktion_VeitstanzMusikvideo.js";
import Produktion_VirtualEdition from "./objects/Infoelemente/Produktionen/Produktion_VirtualEdition.js";

// import Musiker-Infoseiten
import MusikerInfo_BenjaminFischer from "./objects/Infoelemente/MusikerInfo/MusikerInfo_BenjaminFischer.js";
import MusikerInfo_CarolMcGonnell from "./objects/Infoelemente/MusikerInfo/MusikerInfo_CarolMcGonnell.js";
import MusikerInfo_ChrisGrammel from "./objects/Infoelemente/MusikerInfo/MusikerInfo_ChrisGrammel.js";
import MusikerInfo_DanDeSimone from "./objects/Infoelemente/MusikerInfo/MusikerInfo_DanDeSimone.js";
import MusikerInfo_DanielSeroussi from "./objects/Infoelemente/MusikerInfo/MusikerInfo_DanielSeroussi.js";
import MusikerInfo_ErmisTheodorakis from "./objects/Infoelemente/MusikerInfo/MusikerInfo_ErmisTheodorakis.js";
import MusikerInfo_HoriaDumitrache from "./objects/Infoelemente/MusikerInfo/MusikerInfo_HoriaDumitrache.js";
import MusikerInfo_IgorSpallati from "./objects/Infoelemente/MusikerInfo/MusikerInfo_IgorSpallati.js";
import MusikerInfo_JohannesLauer from "./objects/Infoelemente/MusikerInfo/MusikerInfo_JohannesLauer.js";
import MusikerInfo_KerrinBanz from "./objects/Infoelemente/MusikerInfo/MusikerInfo_KerrinBanz.js";
import MusikerInfo_LukasGothszalk from "./objects/Infoelemente/MusikerInfo/MusikerInfo_LukasGothszalk.js";
import MusikerInfo_MariusRehmet from "./objects/Infoelemente/MusikerInfo/MusikerInfo_MariusRehmet.js";
import MusikerInfo_MartinGruetter from "./objects/Infoelemente/MusikerInfo/MusikerInfo_MartinGruetter.js";
import MusikerInfo_MartinOffik from "./objects/Infoelemente/MusikerInfo/MusikerInfo_MartinOffik.js";
import MusikerInfo_MathildeConley from "./objects/Infoelemente/MusikerInfo/MusikerInfo_MathildeConley.js";
import MusikerInfo_NathanWatts from "./objects/Infoelemente/MusikerInfo/MusikerInfo_NathanWatts.js";
import MusikerInfo_OliviaPalmerBaker from "./objects/Infoelemente/MusikerInfo/MusikerInfo_OliviaPalmerBaker.js";
import MusikerInfo_RobertLaessig from "./objects/Infoelemente/MusikerInfo/MusikerInfo_RobertLaessig.js";
import MusikerInfo_SamuelStoll from "./objects/Infoelemente/MusikerInfo/MusikerInfo_SamuelStoll.js";
import MusikerInfo_SaschaFriedl from "./objects/Infoelemente/MusikerInfo/MusikerInfo_SaschaFriedl.js";
import MusikerInfo_SimoneBeneventi from "./objects/Infoelemente/MusikerInfo/MusikerInfo_SimoneBeneventi.js";
import MusikerInfo_TitusEngel from "./objects/Infoelemente/MusikerInfo/MusikerInfo_TitusEngel.js";
import MusikerInfo_VictorGuaita from "./objects/Infoelemente/MusikerInfo/MusikerInfo_VictorGuaita.js";
import MusikerInfo_WillOvercash from "./objects/Infoelemente/MusikerInfo/MusikerInfo_WillOvercash.js";
import MusikerInfo_WojciechGarbowski from "./objects/Infoelemente/MusikerInfo/MusikerInfo_WojciechGarbowski.js";

// import Warnhinweise
import SafariKugel from "./objects/Spielelemente/Warnhinweise/SafariKugel.js";
import Warnhinweis_Safari from "./objects/Infoelemente/Warnhinweise/Warnhinweis_Safari.js";
import Warnhinweis_Mobil from "./objects/Infoelemente/Warnhinweise/Warnhinweis_Mobil.js";
import { SwipeEvents } from "./SwipeEvents.js";
import { ClickStateManager } from "./ClickStateManager.js";


// init Utilities
let mediator = new Mediator();
//initMeters(mediator);
//initScores(mediator);
//activateSwipes();

mediator.checkForceConcert();
mediator.detectGPU();

mediator.addObject(new TamTam("tamtam"));
mediator.addObject(new Logo("logo"));

if (!mediator.clientIsMobile) {
    // init Objects
    mediator.addObject(new Barrel("barrel"));
    /*mediator.addObject(new Ballon("ballon"));*/
    mediator.addObject(new Bombe("bombe"));
    /*mediator.addObject(new Farnbluete_01("farnbluete_01"));
    mediator.addObject(new Farnbluete_02("farnbluete_02"));
    mediator.addObject(new Farnbluete_03("farnbluete_03"));
    mediator.addObject(new Farnbluete_04("farnbluete_04"));
    mediator.addObject(new Farnbluete_06("farnbluete_06"));
    mediator.addObject(new Farnbluete_07("farnbluete_07"));
    mediator.addObject(new Farnbluete_08("farnbluete_08"));
    mediator.addObject(new Farnbluete_09("farnbluete_09"));
    mediator.addObject(new Glaskugel("glaskugel"));*/
    //mediator.addObject(new Messerfass("messerfass"));
    /*mediator.addObject(new Paket("paket"));*/
    mediator.addObject(new Scheinwerfer_Gehaeuse("scheinwerfer_gehaeuse"));
    mediator.addObject(new Scheinwerfer_Lichtkegel("scheinwerfer_lichtkegel"));
    mediator.addObject(new Snake("snake"));
    mediator.addObject(new Skull("skull"));
    mediator.addObject(new SkullLight("skulllight"));
    mediator.addObject(new TestSquare("testsquare"));
    mediator.addObject(new Tetris("tetris"));
    mediator.addObject(new Vase("vase"));
    mediator.addObject(new Wulst("wulst"));

    // init Musiker-Spielelemente
    mediator.addObject(new MusikerSpielelement_WillOvercash("musikerspielelement_willovercash"));
    mediator.addObject(new MusikerSpielelement_DanielSeroussi("musikerspielelement_danielseroussi"));
    mediator.addObject(new MusikerSpielelement_HoriaDumitrache("musikerspielelement_horiadumitrache"));
    mediator.addObject(new MusikerSpielelement_LukasGothszalk("musikerspielelement_lukasgothszalk"));
    mediator.addObject(new MusikerSpielelement_MalikaMaminova("musikerspielelement_malikamaminova"));
    //mediator.addObject(new MusikerSpielelement_OliviaPalmerBaker("musikerspielelement_oliviapalmerbaker"));
    //mediator.addObject(new MusikerSpielelement_SaschaFriedl("musikerspielelement_saschafriedl"));
    //mediator.addObject(new MusikerSpielelement_VictorGuaita("musikerspielelement_victorguaita"));
    mediator.addObject(new MusikerSpielelement_CarolMcGonnell("musikerspielelement_carolmcgonnell"));
    //mediator.addObject(new MusikerSpielelement_DansHands("musikerspielelement_danshands"));
    //mediator.addObject(new MusikerSpielelement_IgorSpallati("musikerspielelement_igorspallati"));

    // init Schwelbrandvideos
    /*mediator.addObject(new Soundtrack2019("soundtrack2019"));
    mediator.addObject(new Autogamie2019("autogamie2019"));
    mediator.addObject(new Veitstanz2019("veitstanz2019"));
    mediator.addObject(new Metallics2019("metallics2019"));*/

}

// init Textfenster
mediator.addObject(new Textfenster_About("textfenster_about"));
mediator.addObject(new Textfenster_CallForScores("textfenster_callforscores"));
mediator.addObject(new Textfenster_Galerie("textfenster_galerie"));
mediator.addObject(new Textfenster_Kontakt("textfenster_kontakt"));
mediator.addObject(new Textfenster_Schwelbrandorchester("textfenster_schwelbrandorchester"));
mediator.addObject(new Textfenster_Termine("textfenster_termine"));
mediator.addObject(new Textfenster_Unterstuetzen("textfenster_unterstuetzen"));
mediator.addObject(new Trailer("trailer"));
mediator.addObject(new Textfenster_Galerie_Veitstanz_MakingOf("textfenster_galerie_veitstanz_makingof"));
mediator.addObject(new Textfenster_Galerie_Spring_MakingOf("textfenster_galerie_spring_makingof"));
mediator.addObject(new Textfenster_Erfolgsseite("textfenster_erfolgsseite"));
mediator.addObject(new Textfenster_DoubleOptInSeite("textfenster_doubleoptinseite"));

// init Videofenster
mediator.addObject(new Videofenster_Trailer("videofenster_trailer"));
mediator.addObject(new Videofenster_Autogamie2019("videofenster_autogamie2019"));
mediator.addObject(new Videofenster_DiatonischeSinfonie2023("videofenster_diatonischesinfonie2023"));
mediator.addObject(new Videofenster_Farnbluete("videofenster_farnbluete"));
mediator.addObject(new Videofenster_Metallics2019("videofenster_metallics2019"));
mediator.addObject(new Videofenster_Metallics2023("videofenster_metallics2023"));
mediator.addObject(new Videofenster_Spring2020("videofenster_spring2020"));
mediator.addObject(new Videofenster_Veitstanz2019("videofenster_veitstanz2019"));
mediator.addObject(new Videofenster_VeitstanzMusikvideo("videofenster_veitstanzmusikvideo"));
mediator.addObject(new Videofenster_Velocity2019("videofenster_velocity2019"));

// init Produktionen
mediator.addObject(new Produktion_StageReconquista("produktion_stagereconquista"));
mediator.addObject(new Produktion_SteigDurchDasTamtam("produktion_steigdurchdastamtam"));
mediator.addObject(new Produktion_ASeven2019("produktion_aseven2019"));
mediator.addObject(new Produktion_Debut("produktion_debut"));
mediator.addObject(new Produktion_Farnbluete("produktion_farnbluete"));
mediator.addObject(new Produktion_Freeze2020("produktion_freeze2020"));
mediator.addObject(new Produktion_Overkill("produktion_overkill"));
mediator.addObject(new Produktion_Plasma("produktion_plasma"));
mediator.addObject(new Produktion_Radiodebut("produktion_radiodebut"));
mediator.addObject(new Produktion_SpringMusikvideo("produktion_springmusikvideo"));
mediator.addObject(new Produktion_Teldex2020("produktion_teldex2020"));
mediator.addObject(new Produktion_VeitstanzMusikvideo("produktion_veitstanzmusikvideo"));
mediator.addObject(new Produktion_VirtualEdition("produktion_virtualedition"));

// init Musiker-Infoseiten
mediator.addObject(new MusikerInfo_BenjaminFischer("musikerinfo_benjaminfischer"));
mediator.addObject(new MusikerInfo_CarolMcGonnell("musikerinfo_carolmcgonnell"));
mediator.addObject(new MusikerInfo_ChrisGrammel("musikerinfo_chrisgrammel"));
mediator.addObject(new MusikerInfo_DanDeSimone("musikerinfo_dandesimone"));
mediator.addObject(new MusikerInfo_DanielSeroussi("musikerinfo_danielseroussi"));
mediator.addObject(new MusikerInfo_ErmisTheodorakis("musikerinfo_ermistheodorakis"));
mediator.addObject(new MusikerInfo_HoriaDumitrache("musikerinfo_horiadumitrache"));
mediator.addObject(new MusikerInfo_IgorSpallati("musikerinfo_igorspallati"));
mediator.addObject(new MusikerInfo_JohannesLauer("musikerinfo_johanneslauer"));
mediator.addObject(new MusikerInfo_KerrinBanz("musikerinfo_kerrinbanz"));
mediator.addObject(new MusikerInfo_LukasGothszalk("musikerinfo_lukasgothszalk"));
mediator.addObject(new MusikerInfo_MariusRehmet("musikerinfo_mariusrehmet"));
mediator.addObject(new MusikerInfo_MartinGruetter("musikerinfo_martingruetter"));
mediator.addObject(new MusikerInfo_MartinOffik("musikerinfo_martinoffik"));
mediator.addObject(new MusikerInfo_MathildeConley("musikerinfo_mathildeconley"));
mediator.addObject(new MusikerInfo_NathanWatts("musikerinfo_nathanwatts"));
mediator.addObject(new MusikerInfo_OliviaPalmerBaker("musikerinfo_oliviapalmerbaker"));
mediator.addObject(new MusikerInfo_RobertLaessig("musikerinfo_robertlaessig"));
mediator.addObject(new MusikerInfo_SamuelStoll("musikerinfo_samuelstoll"));
mediator.addObject(new MusikerInfo_SaschaFriedl("musikerinfo_saschafriedl"));
mediator.addObject(new MusikerInfo_SimoneBeneventi("musikerinfo_simonebeneventi"));
mediator.addObject(new MusikerInfo_TitusEngel("musikerinfo_titusengel"));
mediator.addObject(new MusikerInfo_VictorGuaita("musikerinfo_victorguaita"));
mediator.addObject(new MusikerInfo_WillOvercash("musikerinfo_willovercash"));
mediator.addObject(new MusikerInfo_WojciechGarbowski("musikerinfo_wojciechgarbowski"));

// init Menupunkte
mediator.addObject(new Menupunkt_About("menupunkt_about"));
mediator.addObject(new Menupunkt_CallForScores("menupunkt_callforscores"));
mediator.addObject(new Menupunkt_Galerie("menupunkt_galerie"));
mediator.addObject(new Menupunkt_Kontakt("menupunkt_kontakt"));
mediator.addObject(new Menupunkt_Schwelbrandorchester("menupunkt_schwelbrandorchester"));
mediator.addObject(new Menupunkt_Termine("menupunkt_termine"));
mediator.addObject(new Menupunkt_Trailer("menupunkt_trailer"));
mediator.addObject(new Menupunkt_Unterstuetzen("menupunkt_unterstuetzen"));

// init Warnhinweise
mediator.addObject(new SafariKugel("safarikugel"));
mediator.addObject(new Warnhinweis_Safari("warnhinweis_safari"));
mediator.addObject(new Warnhinweis_Mobil("warnhinweis_mobil"));

// init MOTHER
mediator.addObject(new MOTHER("mother"));

if (mediator.isGameEnabled()) {
    window.onload = () => { let steeldrum = new Steeldrum(mediator); }
} else {
    mediator.lockSteeldrum();
    mediator.lockHammerOnLogo();
}

if (mediator.clientIsMobile) {
    activateSwipes(mediator);
}

mediator.loadHTMLFiles();

mediator.openStartPage();
//mediator.printAllMediaData();
//mediator.schreibeLadeDramaturgie();

mediator.checkTestversion();
mediator.laderegie(-1, "loadForStart");
mediator.addKeyboardControls();
mediator.disableBrowserBackButton();


// trigger rotation adjustments
screen.orientation.addEventListener("change", (event) => {
    window.addEventListener("resize", resizeActiveObjects);
});

function resizeActiveObjects() {
    for (let key in mediator.objects) {
        let currentObject = mediator.objects[key]
        if(currentObject.active && currentObject.name != "mother"){
            mediator.objects[key].resize();
        }
    }
    window.removeEventListener("resize", resizeActiveObjects);
}