import TextDivGalerie from "./TextDivGalerie.js";
import * as CSM from "../../../ClickStateManager.js";

export default class Textfenster_Galerie_Veitstanz_MakingOf extends TextDivGalerie {

    textURI = "Textfenster/Textfenster_Galerie_Veitstanz_MakingOf.html";
    title = "Making of »Veitstanz« – Musikvideo";
    width = 100;                                   //object size in percent of screenwidth
    height = 100;
    position = { x: 0, y: 0 };                  //top left position of video object in percent of screen width and screen height

    images = [
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2283.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2258_cropped.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2326.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2339.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2365.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2295.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2393.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2291.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2285.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2305.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2340.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2320.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2325.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2343.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2284.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/IMG_2368.webp"}
    ]


    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Catch All",             elements: 3,    probability: 0,     krassitaet: 1, allowedInEnvironments: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
    ];

    combiGroups = [];

    clickActions = [

        // Action 0-1 - Init Action
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement,
                    targetObjectSelector: "#textfenster_galerie_veitstanz_makingof"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_01_textdiv"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_04_textdiv"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 150,
                    followAction: {group: 0, action: 3}
                }                
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement,
                    targetObjectSelector: "#textfenster_galerie_veitstanz_makingof"
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ]

}