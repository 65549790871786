import * as CSM from "../../../ClickStateManager.js";

export default class TestSquare extends CSM.ClickStateManager {

    videoSize = { x: 1200, y: 1200 };               // width and height of the original video material
    clickAreaPosition = { x: 300, y: 300 };         // position of Object in original Video in px
    clickAreaSize = { x: 600, y: 600 };             // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;    // Form of the clickable Area
    mediaPfad = "/spielelemente/testsquare";

    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});

        if (this.clickCount >= 1) {this.executeAction(this.findActionNumber(1, 7)); return;}
        //if (this.clickCount == 2) {this.executeAction(this.findActionNumber(1, 4)); return;}
        return;

        this.lokalerEskalationswert = 0.5;
        
        this.findPossibleNextActions();

        // Sonderregeln

        console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();

    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "Init und Exit",                 elements: 2,    probability: 0,     krassitaet: 1},
        {name: "Catch All",                     elements: 7,    probability: 0.7,   krassitaet: 1}
    ];

    combiGroups = [
//        {name: "Normalgröße",               elements: 1,    probability: 0.3,   krassitaet: 1,  addableToGroups: [1, 2, 3],         incompatibleCombiGroups: [1, 2]},
    ];


    normalClickActions = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "testsquare"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "testsquare_cssanim_01_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "testsquare_cssanim_01_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "testsquare_cssanim_02_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "testsquare_cssanim_02_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "testsquare_cssanim_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "testsquare_cssanim_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "testsquare_cssanim_04_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "testsquare",
                    nrOfInstances: 1,
                    inheritMotherScale: true,
                    targetAction: {group: 1, action: 6}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "testsquare_cssanim_05_relativePositionX",
                    removeAtEnd: true
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "testsquare_cssanim_05_relativePositionY",
                    removeAtEnd: true
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "testsquare_cssanim_05_relativeScale",
                    removeAtEnd: true
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "testsquare_cssanim_07a"
                }
            ],
            allowHover: false,
            allowClick: true
        },


    ];

    combiClickActions = [];

    clickActions = this.normalClickActions.concat(this.combiClickActions);

}