import * as CSM from "../../../ClickStateManager.js";

export default class Menupunkt_Unterstuetzen extends CSM.ClickStateManager {

    videoSize = { x: 400, y: 225 };               // width and height of the original video material
    clickAreaPosition = { x: 40, y: 72 };         // position of Object in original Video in px
    clickAreaSize = { x: 314, y: 72 };             // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;    //Form of the clickable Area
    rotation = 0;
    mediaPfad = "/hauptmenupunkte";

    onMouseDown() {
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        if (!this.mediator.clientIsMobile) {
            this.executeAction(2);
            let spendenformular = <HTMLElement>document.querySelector("#unterstuetzen_html");
            if (spendenformular) {
                spendenformular.style.display = "flex";
                spendenformular.style.visibility = "visible";
                spendenformular.classList.add("textfenster_cssanim_01_textdiv");
            }
        } else {
            this.executeAction(3);
            let textfenster_unterstuetzen = <HTMLElement>document.querySelector('#textfenster_unterstuetzen');
            if (textfenster_unterstuetzen) {
                textfenster_unterstuetzen.style.display='block';
                textfenster_unterstuetzen.style.visibility='visible';
                textfenster_unterstuetzen.scrollTop = 0; 
            }
        }
    }

    openImmediately() {
        if (!this.mediator.clientIsMobile) {
            let spendenformular = <HTMLElement>document.querySelector("#unterstuetzen_html");
            if (spendenformular) {
                spendenformular.style.display = "flex";
                spendenformular.style.visibility = "visible";
            }
        } else {
            this.executeAction(4);
            let textfenster_unterstuetzen = <HTMLElement>document.querySelector('#textfenster_unterstuetzen');
            if (textfenster_unterstuetzen) {
                textfenster_unterstuetzen.style.display='block';
                textfenster_unterstuetzen.style.visibility='visible';
                textfenster_unterstuetzen.scrollTop = 0; 
            }
        }        
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "Catch All",             elements: 4,    probability: 1,     krassitaet: 1},
    ];

    clickActions = [

        // Init Action
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Menupunkt_Unterstuetzen_frame0001"
                }

            ],
            allowHover: false,
            allowClick: true
        },

        // Exit Action
        {
            singleActions: [

            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Menupunkt_Unterstuetzen",
                    startTime: 150
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "menupunkt_unterstuetzen_cssanim_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "menupunkt_unterstuetzen_cssanim_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "menupunkt_unterstuetzen_cssanim_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "menupunkt_unterstuetzen_cssanim_01_opacity"
                }
            ],
            allowHover: true,
            allowClick: true
        },

        // Action 3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Menupunkt_Unterstuetzen",
                    startTime: 150
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "menupunkt_unterstuetzen_cssanim_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "menupunkt_unterstuetzen_cssanim_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "menupunkt_unterstuetzen_cssanim_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "menupunkt_unterstuetzen_cssanim_01_opacity"
                },
                {
                    actionType: CSM.ActionType.ActivateObject,
                    targetObject: "textfenster_unterstuetzen"
                }
            ],
            allowHover: true,
            allowClick: true
        },

        // Action 4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ActivateObject,
                    targetObject: "textfenster_unterstuetzen"
                }
            ],
            allowHover: true,
            allowClick: true
        }        
    ]

}