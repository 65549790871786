import * as CSM from "../../../ClickStateManager.js";

export default class Wulst extends CSM.ClickStateManager {

    videoSize = { x: 2048, y: 1024 };               // width and height of the original video material
    clickAreaPosition = { x: 700, y: 200 };         // position of Object in original Video in px
    clickAreaSize = { x: 700, y: 700 };             // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Circle;      //Form of the clickable Area
    mediaPfad = "/spielelemente/wulst";

    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        this.checkAnimationTypes();
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

        if (this.mediator.currentEnvironment == 1) {
            this.lokalerEskalationswert = (this.clickCount / 50) + 0.3;
        }
        if (this.lokalerEskalationswert > 1) {this.lokalerEskalationswert = 1;}
        
        this.findPossibleNextActions();

        // Sonderregeln
        // –––

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    randomX() {return "translateX(" + (10 + Math.floor(Math.random()*80)) + "vw)";}
    randomY() {return "translateY(" + (10 + Math.floor(Math.random()*80)) + "vh)";}
    randomScale() {return "scale(" + (0.5 + Math.random() * 1) + ")";}

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Init und Exit",             elements: 2,    probability: 0,     krassitaet: 1,    allowedInEnvironments: [0, 1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "1 Düsenjäger",                elements: 6,    probability: 0.4,   krassitaet: 2,  allowedInEnvironments: [0, 1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "2 Aufblähen",                 elements: 6,    probability: 0.1,   krassitaet: 2,    allowedInEnvironments: [0, 1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "3 Drehungen",                elements: 6,    probability: 0.05,  krassitaet: 3, allowedInEnvironments: [0, 1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        //{name: "4 Schnell zappeln",          elements: 2,    probability: 0.05,  krassitaet: 3, allowedInEnvironments: [10] },
]

    combiGroups = [
        {name: "0 3D-Rotationen",                   elements: 6,    probability: 0.3,     krassitaet: 1,  addableToGroups: [1, 2, 3],    incompatibleCombiGroups: [], allowedInEnvironments: [0, 1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "1 Skews",                           elements: 6,    probability: 0.3,     krassitaet: 1,  addableToGroups: [1, 2, 3],    incompatibleCombiGroups: [], allowedInEnvironments: [0, 1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "2 Düsenjäger",                      elements: 6,    probability: 0.3,     krassitaet: 1,  addableToGroups: [3],             incompatibleCombiGroups: [], allowedInEnvironments: [0, 1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "3 Größenveränderungen",             elements: 6,    probability: 0.3,     krassitaet: 2,  addableToGroups: [1, 3],             incompatibleCombiGroups: [], allowedInEnvironments: [0, 1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "4 Farbveränderungen",               elements: 6,    probability: 0.8,     krassitaet: 3,  addableToGroups: [1, 3],      incompatibleCombiGroups: [], allowedInEnvironments: [0, 1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
    ];

    allowedCSSAnimationTypes: Array<CSM.allowedCSSAnimationType> = [
        {animationType: "rotate",      maxClickCount: 30, maxGlobalClickCount: 60},
        {animationType: "skew",        maxClickCount: 30, maxGlobalClickCount: 60},
        {animationType: "rotate3d",    maxClickCount: 30, maxGlobalClickCount: 60},
        {animationType: "perspective", maxClickCount: 30, maxGlobalClickCount: 60},
        {animationType: "blend",       maxGlobalClickCount: 30},
    ];

    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Wulst",
                    playBackRate: 1,
                    loop: true
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_07_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 8000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 20, y: -9999}
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 40, y: 60}
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: -10, y: 70}
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 70, y: 20}
        },

        // Action 1-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 30, y: 55}
        },

        // Action 1-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: -9999, y: -20}
        },

        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_01_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_01_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1]}
                                ],
            addState: ["psychedelic", "large"],
            tellAudioTargetAbsoluteScale: [3, 3000]
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_02_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_02_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1]}
                                ],
            addState: ["psychedelic", "large"],
            tellAudioTargetAbsoluteScale: [3, 3000]
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_03_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_03_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_03_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1]}
                                ],
            addState: ["psychedelic", "large"],
            tellAudioTargetAbsoluteScale: [3, 3000]
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_04_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_04_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_04_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1]}
                                ],
            addState: ["psychedelic", "large"],
            tellAudioTargetAbsoluteScale: [3, 3000]
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_05_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_05_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_05_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1]}
                                ],
            addState: ["psychedelic", "large"],
            tellAudioTargetAbsoluteScale: [3, 3000]
        },

        // Action 2-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_06_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_06_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_06_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_06_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1]}
                                ],
            addState: ["psychedelic", "large"],
            tellAudioTargetAbsoluteScale: [3, 3000]
        },

        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_01_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_01_rotate"
                }
            ],
            minClickCount: 6,
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [ 
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [2, 3, 4, 5, 6]}
            ]
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_02_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6,
            possiblePrevActions: [ 
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 3, 4, 5, 6]}
            ]
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_03_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_03_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6,
            possiblePrevActions: [ 
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 4, 5, 6]}
            ]
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_04_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_04_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6,
            possiblePrevActions: [ 
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 5, 6]}
            ]
        },

        // Action 3-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_05_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_05_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6,
            possiblePrevActions: [ 
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 6]}
            ]
        },

        // Action 3-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_06_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_06_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6,
            possiblePrevActions: [ 
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 5]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 4 *** ///////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Wulst",
                    startTime:  0 + (0 * 33.33),
                    playBackRate: 4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000 + (9 * 33.33),
                    followAction: {group: 4, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 5, 6]},
                {group: 12, actions: [1, 2, 3]}
            ],
            addState: ["zappeln"]
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Wulst",
                    startTime:  0 + (6 * 33.33),
                    playBackRate: 1,
                    loop: true
                }

            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [{group: 15, actions: [1]}]
        },
    ];

    combiClickActions: Array<CSM.ClickAction> = [
        ///////////////////////////////////////// *** COMBIGROUP 0 *** /////////////////////////////////////////
        // Action 0-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_11_01_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_11_02_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_11_03_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_11_04_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_11_05_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_11_06_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 1 *** /////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_12_01_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_12_02_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_12_03_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_12_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_12_05_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_12_06_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 2 *** /////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 3 *** /////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_13_01_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            tellAudioTargetAbsoluteScale: [1, 500]
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_13_02_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            tellAudioTargetAbsoluteScale: [1.3, 500]
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_13_03_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            tellAudioTargetAbsoluteScale: [1.7, 500]
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_13_04_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            tellAudioTargetAbsoluteScale: [2.2, 500]
        },

        // Action 3-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_13_05_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            tellAudioTargetAbsoluteScale: [0.8, 500]
        },

        // Action 3-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_13_06_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            tellAudioTargetAbsoluteScale: [0.6, 500]
        },

        ///////////////////////////////////////// *** COMBIGROUP 4 *** /////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_01_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_01_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["dark", "psychedelic"]
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_02_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_02_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["dark", "psychedelic"]
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_03_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_03_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["dark", "psychedelic"]
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_04_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_04_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["dark", "psychedelic"]
        },

        // Action 4-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_05_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_05_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["dark", "psychedelic"]
        },

        // Action 4-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_06_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_06_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["dark", "psychedelic"]
        },
    ]


    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);

    audioActions: Array<CSM.AudioAction> = [
        // 1 Düsenjäger
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2, 3, 4, 5, 6]}
            ],
            possibleCombiActions: [
                {group: 2, actions: [1, 2, 3, 4, 5, 6]}
            ],
            standardSounds: [
                {
                    sample: "../../tamtam/audio/TT Metal",
                    midiRange: [30, 53],
                    dynamicRange: [0.2, 0.2],
                    forbiddenState: ["psychedelic"]
                }
            ],
            alternativeSounds: [
                {
                    sample: "../../tamtam/audio/TT pp",
                    midiRange: [90, 100],
                    dynamicRange: [0.15, 0.15],
                    voices: 2,
                    afterIdenticalActionOnly: true,
                    forbiddenState: ["psychedelic"]
                },
                {
                    sample: "../../tamtam/audio/TT ratsch",
                    midiRange: [72, 77],
                    dynamicRange: [0.2, 0.2],
                    afterIdenticalActionOnly: true,
                    forbiddenState: ["psychedelic"]
                },
                {
                    sample: "../../tamtam/audio/TT Metal",
                    midiRange: [70, 85],
                    dynamicRange: [0.2, 0.2],
                    forbiddenState: ["psychedelic"]
                },
                {
                    sample: "../../tamtam/audio/TT pp",
                    midiRange: [65, 80],
                    dynamicRange: [0.2, 0.2],
                    demandedState: ["psychedelic"]
                }
            ],
            allowedInEnvironments: [0, 1, 10]
        },

        // 2 Bunt werden
        {
            possibleClickActions: [
                {group: 2, actions: [1, 2, 3, 4, 5, 6]}
            ],
            standardSounds: [
                {
                    sample: "../../tamtam/audio/TT pp",
                    midiRange: [60, 70],
                    dynamicRange: [0.8, 0.8]
                }
            ],
            allowedInEnvironments: [0, 1, 10]
        },

        // 3 Dunkelwerden
        {
            demandedNewState: "dark",
            standardSounds: [
                {
                    sample: "../../tamtam/audio/TT pp",
                    midiRange: [50, 60],
                    voices: 2,
                    dynamicRange: [0.2, 0.2]
                }
            ],
            allowedInEnvironments: [0, 1, 10]
        },

        // 4 Environment 13
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2, 3, 4, 5, 6]},
                {group: 2, actions: [1, 2, 3, 4, 5, 6]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6]}
            ],
            possibleCombiActions: [
                {group: 2, actions: [1, 2, 3, 4, 5, 6]}
            ],
            alternativeSounds: [
                {
                    sample: "../../tamtam/audio/TT ff",
                    midiRange: [50, 60],
                    dynamicRange: [0.6, 0.6]
                },
                {
                    sample: "../../tamtam/audio/TT Metal",
                    midiRange: [50, 60],
                    dynamicRange: [0.35, 0.35]
                },
                {
                    sample: "../../tamtam/audio/TT mf",
                    midiRange: [50, 60],
                    dynamicRange: [0.7, 0.7]
                },
                {
                    sample: "../../tamtam/audio/TT orient ff",
                    midiRange: [35, 60],
                    dynamicRange: [0.2, 0.2]
                },
                {
                    sample: "../../tamtam/audio/TT pp",
                    midiRange: [50, 70],
                    dynamicRange: [0.6, 0.6]
                },
            ],
            allowedInEnvironments: [2, 13, 14, 15, 16, 17, 18, 19, 20]
        },      

    ];

    audioHierarchy = [
        {
            description: "Farbveränderungen",
            actionGroups: [2],
            combiGroups: []                        // Bei mehreren Combigroups haben die früher genannten Priorität
        },
        {
            description: "Positionsveränderungen",
            actionGroups: [1],
            combiGroups: [2]
        },
        {
            description: "Formveränderungen",
            actionGroups: [3],
            combiGroups: [0, 1, 3]
        }
    ]


}