import * as CSM from "../../../ClickStateManager.js";

export default class MusikerSpielelement_HoriaDumitrache extends CSM.ClickStateManager {

    videoSize = { x: 4096, y: 2160 };               // width and height of the original video material
    clickAreaPosition = { x: 2190, y: 280 };        // position of Object in original Video in px
    clickAreaSize = { x: 1000, y: 1500 };           // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;   //Form of the clickable Area
    mediaPfad = "/spielelemente/musiker";


    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

        /*if (this.clickCount == 1) {this.executeAction(this.findActionNumber(2, 1)); return;}
        if (this.clickCount == 2) {this.executeAction(this.findActionNumber(2, 2)); return;}
        if (this.clickCount == 3) {this.executeAction(this.findActionNumber(2, 3)); return;}
        if (this.clickCount == 4) {this.executeAction(this.findActionNumber(2, 2)); return;}
        if (this.clickCount == 5) {this.executeAction(this.findActionNumber(2, 3)); return;}
        if (this.clickCount == 6) {this.executeAction(this.findActionNumber(1, 8)); return;}
        if (this.clickCount == 7) {this.executeAction(this.findActionNumber(6, 15)); return;}
        if (this.clickCount == 8) {this.executeAction(this.findActionNumber(6, 15)); return;}
        return;*/

        this.lokalerEskalationswert = 0.8;
        
        this.findPossibleNextActions();

        // Sonderregeln

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Init und Exit",                 elements: 2,    probability: 0,     krassitaet: 1},
        {name: "1 Videospielen",                  elements: 2,    probability: 0.7,   krassitaet: 1},
        {name: "2 Silhouette",                    elements: 2,    probability: 0.7,   krassitaet: 1},
        {name: "3 Farbrotation",                  elements: 6,    probability: 0.7,   krassitaet: 1}
    ];

    combiGroups = [
        {name: "360-Grad-Rotation",               elements: 6,    probability: 0.3,   krassitaet: 2,  addableToGroups: [1, 2, 3],         incompatibleCombiGroups: []},
    ];


    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "HoriaDumitrache_Veitstanz_01_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_01a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "HoriaDumitrache_Veitstanz_01",
                    startTime: 0,
                    playBackRate: 1,
                    loop: true
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 2, actions: [1, 2]}
            ]
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "HoriaDumitrache_Veitstanz_02",
                    startTime: 0,
                    playBackRate: 1,
                    loop: true
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_02_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_02_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "HoriaDumitrache_Veitstanz_01_NurKopf"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_00_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_00_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_00_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_00_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_00_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_00_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_00_contrast"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_00a"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    startAnimation: false,
                    playBackRateCommand: "reverse"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "rotate",
                    startAnimation: false,
                    playBackRateCommand: "reverse"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [1, 2]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_04_01_hue-rotate"
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_04_02_hue-rotate"
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_04_03_hue-rotate"
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_04_04_hue-rotate"
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 3-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_04_05_hue-rotate"
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 3-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_04_06_hue-rotate"
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

    ];

    combiClickActions = [
        ///////////////////////////////////////// *** COMBIGROUP 0 *** /////////////////////////////////////////
        // Action 0-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_03_01_rotate3d"     // Ist eigentlich rotate im "falschen" Div
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_03_02_rotate3d"     // Ist eigentlich rotate im "falschen" Div
                },
            ],
            allowHover: false,
            allowClick: true
        },        

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_03_03_rotate3d"     // Ist eigentlich rotate im "falschen" Div
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_03_04_rotate3d"     // Ist eigentlich rotate im "falschen" Div
                },
            ],
            allowHover: false,
            allowClick: true
        },        

        // Action 0-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_03_05_rotate3d"     // Ist eigentlich rotate im "falschen" Div
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "horiadumitrache_cssanim_03_06_rotate3d"     // Ist eigentlich rotate im "falschen" Div
                },
            ],
            allowHover: false,
            allowClick: true
        },                
    ];

    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }

            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);



    audioActions: Array<CSM.AudioAction> = [
        // 1 Veitstanz-Schnipsel
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6]}
            ],
            alternativeSounds: [
                {
                    sample: "veitstanz1",
                    midiRange: [60, 66],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "veitstanz2",
                    midiRange: [60, 66],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "veitstanz3",
                    midiRange: [60, 66],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "veitstanz4",
                    midiRange: [60, 66],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "veitstanz5",
                    midiRange: [60, 66],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "veitstanz6",
                    midiRange: [60, 66],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "veitstanz7",
                    midiRange: [60, 66],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "veitstanz8",
                    midiRange: [60, 66],
                    dynamicRange: [1, 1],
                }
            ],
            allowedInEnvironments: [17, 18]
        },

        // 2 Normales Anschlaggeräusch für spätere Environments
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6]}
            ],
            alternativeSounds: [
                {
                    sample: "../../vase/audio/VasenPling24",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling25",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling26",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling27",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling28",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling29",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling30",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling31",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling32",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling33",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling34",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling35",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                }
            ],
            allowedInEnvironments: [18, 19]
        },        
    
        // 3 Impros für spätere Environments
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6]}
            ],
            alternativeSounds: [
                {
                    sample: "horiaimpro1",
                    midiRange: [60, 75],
                    dynamicRange: [1.5, 1.5],
                },
                {
                    sample: "horiaimpro2",
                    midiRange: [55, 65],
                    dynamicRange: [2, 2],
                },
                {
                    sample: "horiaimpro3",
                    midiRange: [55, 65],
                    dynamicRange: [2, 2],
                },
                {
                    sample: "horiaimpro4",
                    midiRange: [55, 65],
                    dynamicRange: [1.5, 1.5],
                },
            ],
            allowedInEnvironments: [19]
        }
    
    ]

    audioHierarchy = [
        {
            description: "Catch All",
            actionGroups: [0, 1, 2, 3],
            combiGroups: [0]                        // Bei mehreren Combigroups haben die früher genannten Priorität
        }
    ]



}