import * as CSM from "../../../ClickStateManager.js";

export default class Skull extends CSM.ClickStateManager {

    videoSize = { x: 1080, y: 1080 };               // width and height of the original video material
    clickAreaPosition = { x: 390, y: 360 };         // position of Object in original Video in px
    clickAreaSize = { x: 255, y: 310 };             // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Circle;   //Form of the clickable Area
    mediaPfad = "/spielelemente/skull";

    scale: number;
    animationSpeed: number;

    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        if (this.state.includes("tetris")) {
            this.mediator.totalSkullTetrisClickCount++;
        }
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

/*        if (this.clickCount == 1) {
            this.executeAction(this.findActionNumber(8, 1));
            this.findBestAudioForActionAndCombiActions(this.prevActions[this.prevActions.length - 1], []);
            return;
        }
        if (this.clickCount == 2) {
            this.executeAction(this.findActionNumber(6, 4));
            this.findBestAudioForActionAndCombiActions(this.prevActions[this.prevActions.length - 1], []);
            return;
        }
        
/*        if (this.clickCount <= 15) {
            this.executeAction(this.findActionNumber(2, 16));
            this.findBestAudioForActionAndCombiActions(this.prevActions[this.prevActions.length - 1], []);
            return;}*/
/*        if (this.clickCount == 2) {this.executeAction(this.findActionNumber(9, 2)); return;}
        if (this.clickCount == 3) {this.executeAction(this.findActionNumber(2, 23)); return;}
        if (this.clickCount == 4) {this.executeAction(this.findActionNumber(3, 4)); return;}
        if (this.clickCount == 5) {this.executeAction(this.findActionNumber(3, 5)); return;}*/

        if (this.mediator.currentEnvironment == 1) {
            this.lokalerEskalationswert = 0.15 + this.clickCount / 60;
        }
        if (this.mediator.currentEnvironment == 10) {
            this.lokalerEskalationswert = 0.5 + this.clickCount / 20;
        }
        if (this.lokalerEskalationswert > 1) {this.lokalerEskalationswert = 1;}
        
        this.findPossibleNextActions();

        // Sonderregeln
        this.applyPingPongRule(2, 4, 2, 5, 6);
        this.applyPingPongRule(2, 12, 2, 13, 6);

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    randomX() {return "translateX(" + (10 + Math.floor(Math.random()*80)) + "vw)";}
    randomAnimationSpeed() {
        this.scale = 0.2 + Math.random() * 0.6;
        this.animationSpeed = 3000 / this.scale;
        return Math.floor(this.animationSpeed) + "ms";
    }
    randomScale() {return "scale(" + this.scale + ")";}
    randomAnimationDelay() {return Math.floor(Math.random() * 20000) + "ms";}

    randomY() {return "translateY(" + (10 + Math.floor(Math.random()*80)) + "vh)";}
    randomScaleForReverseExplosion() {return "scale(" + (0.5 + Math.random() * 1) + ")";}

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Init und Exit",                           elements: 2,    probability: 0,     krassitaet: 1, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "1 Video 1 in Tranchen (Köpfe und Gitter)",  elements: 13,   probability: 0.7,   krassitaet: 1, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "2 Video 2 in Tranchen (Fischlein)",         elements: 20,   probability: 0.7,   krassitaet: 1, allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "3 Video 3 in Tranchen (Zerfetzen)",         elements: 25,   probability: 0.7,   krassitaet: 1, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "4 Skull-Tetris",                            elements: 6,    probability: 0.7,   krassitaet: 1, allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "5 Video 4 (viele kleine Schädel)",          elements: 2,    probability: 0/*.1*/,   krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "6 Video 6 (zerbrechen)",                    elements: 6,    probability: 0.1,   krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "7 Langsam einfaden",                        elements: 4,    probability: 0.7,   krassitaet: 1, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "8 Düsenjäger",                              elements: 6,    probability: 0.4,   krassitaet: 2, allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "9 Aufblähen",                               elements: 6,    probability: 0.1,   krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
    ];

    combiGroups = [
        {name: "0 Verformungen",               elements: 6,    probability: 0.6,   krassitaet: 1,  addableToGroups: [1, 2, 3, 8, 9],       incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "1 Herumschweben",              elements: 2,    probability: 0.05,  krassitaet: 3,  addableToGroups: [3],          incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "2 Blendmodes Light Steeldrum", elements: 5,    probability: 0.05,  krassitaet: 3,  addableToGroups: [1, 2, 3, 4, 5, 6, 8], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20], demandedSteelDrumState: "light" },
        {name: "3 Blendmodes Dark Steeldrum",  elements: 6,    probability: 0.05,  krassitaet: 3,  addableToGroups: [1, 2, 3, 4, 5, 6, 8], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20], demandedSteelDrumState: "dark" },
        {name: "4 Duesenjaeger",               elements: 6,    probability: 0.3,   krassitaet: 1,  addableToGroups: [1, 2, 3],       incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "5 Größenveränderungen",        elements: 6,    probability: 0.6,   krassitaet: 1,  addableToGroups: [1, 2, 3, 5, 8],    incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "6 Rotationen",                 elements: 6,    probability: 0.6,   krassitaet: 1,  addableToGroups: [1, 2, 3, 8, 9],       incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "7 Farbeffekte",                elements: 6,    probability: 0.1,   krassitaet: 2,  addableToGroups: [1, 2, 4, 5, 6, 7, 8], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
    ];


    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Skull_01_frame0001"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_01",
                    startTime:  0 + (8 * 33.33),
                    endTime:    0 + (13 * 33.33),
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_01_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [2, 5, 9, 11, 13]},
                {group: 2, actions: [2, 9, 14, 19, 20]},
                {group: 3, actions: [2, 4, 8, 14]},
                {group: 5, actions: [2]},
                {group: 6, actions: [4]},
                {group: 7, actions: [1]}
            ]
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_01",
                    startTime:  0 + (13 * 33.33),
                    endTime:    0 + (8 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1]}
            ]
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_01",
                    startTime:  0 + (8 * 33.33),
                    endTime:    0 + (26 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_03_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [2, 5, 9, 11, 13]},
                {group: 2, actions: [2, 9, 14, 19, 20]},
                {group: 3, actions: [2, 4, 8, 14]},
                {group: 5, actions: [2]},
                {group: 6, actions: [4]},
                {group: 7, actions: [1]}
            ]
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_01",
                    startTime:  0 + (26 * 33.33),
                    endTime:    1000 + (11 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_04_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [3]}
            ]
        },

        // Action 1-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_01",
                    startTime:  1000 + (11 * 33.33),
                    endTime:    0 + (8 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [4]}
            ]
        },

        // Action 1-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_01",
                    startTime:  1000 + (11 * 33.33),
                    endTime:    3000 + (5 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_05_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [5]}
            ]
        },

        // Action 1-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_01",
                    startTime:  3000 + (5 * 33.33),
                    endTime:    3000 + (26 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_05_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [6]}
            ]
        },

        // Action 1-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_01",
                    startTime:  3000 + (26 * 33.33),
                    endTime:    4000 + (10 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_06_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [7]}
            ]
        },

        // Action 1-9
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_01",
                    startTime:  4000 + (10 * 33.33),
                    endTime:    5000 + (0 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [8]}
            ]
        },

        // Action 1-10
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_01",
                    startTime:  4000 + (25 * 33.33),
                    endTime:    4000 + (18 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_07_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [2, 5, 9, 11, 13]},
                {group: 2, actions: [2, 9, 14, 19, 20]},
                {group: 3, actions: [2, 4, 8, 14]},
                {group: 5, actions: [2]},
                {group: 6, actions: [4]},
                {group: 7, actions: [1]}
            ]
        },

        // Action 1-11
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_01",
                    startTime:  4000 + (18 * 33.33),
                    endTime:    5000 + (0 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [10]}
            ]
        },

        // Action 1-12
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_01",
                    startTime:  4000 + (25 * 33.33),
                    endTime:    4000 + (12 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [2, 5, 9, 11, 13]},
                {group: 2, actions: [2, 9, 14, 19, 20]},
                {group: 3, actions: [2, 4, 8, 14]},
                {group: 5, actions: [2]},
                {group: 6, actions: [4]},
                {group: 7, actions: [1]}
            ]
        },

        // Action 1-13
        {
        singleActions: [
            {
                actionType: CSM.ActionType.VideoPlayback,
                mediaTitle: "Skull_01",
                startTime:  4000 + (12 * 33.33),
                playBackRate: -2,
                loop: false
            },
            {
                actionType: CSM.ActionType.ChangeAnimation,
                animationDiv: "clickArea",
                newClass: "skull_cssanim_01_02"
            }
        ],
        allowHover: false,
        allowClick: true,
        possiblePrevActions: [
            {group: 1, actions: [12]}
        ]
        },

        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  0 + (0 * 33.33),
                    endTime:    0 + (6 * 33.33),
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_09_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [2, 5, 9, 11, 13]},
                {group: 2, actions: [2, 9, 14, 19, 20]},
                {group: 3, actions: [2, 4, 8, 14]},
                {group: 5, actions: [2]},
                {group: 6, actions: [4]},
                {group: 7, actions: [1]}
            ]
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  0 + (6 * 33.33),
                    endTime:    0 + (0 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [2]}
            ]
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  0 + (6 * 33.33),
                    endTime:    1000 + (17 * 33.33),
                    playBackRate: 4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_10_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [1]}
            ]
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  1000 + (17 * 33.33),
                    endTime:    1000 + (10 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_10_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [3, 5, 15]}
            ]
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  1000 + (10 * 33.33),
                    endTime:    1000 + (17 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_10_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [4]}
            ]
        },

        // Action 2-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  1000 + (10 * 33.33),
                    endTime:    3000 + (12 * 33.33),
                    playBackRate: 2.5,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_11_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [4]}
            ]
        },

        // Action 2-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  3000 + (12 * 33.33),
                    endTime:    2000 + (18 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_12_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [6, 8]}
            ]
        },

        // Action 2-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  2000 + (18 * 33.33),
                    endTime:    3000 + (12 * 33.33),
                    playBackRate: 2,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_11_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [7]}
            ]
        },

        // Action 2-9
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  3000 + (12 * 33.33),
                    endTime:    6000 + (20 * 33.33),
                    playBackRate: 4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [6, 8]}
            ]
        },

        // Action 2-10
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  6000 + (20 * 33.33),
                    endTime:    6000 + (8 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_13_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [2, 5, 9, 11, 13]},
                {group: 2, actions: [2, 9, 14, 19, 20]},
                {group: 3, actions: [2, 4, 8, 14]},
                {group: 5, actions: [2]},
                {group: 6, actions: [4]},
                {group: 7, actions: [1]}
            ]
        },

        // Action 2-11
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  6000 + (8 * 33.33),
                    endTime:    4000 + (18 * 33.33),
                    playBackRate: -2,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_14_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [10]}
            ]
        },

        // Action 2-12
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  4000 + (18 * 33.33),
                    endTime:    4000 + (28 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_15_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [11, 13]}
            ]
        },

        // Action 2-13
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  4000 + (28 * 33.33),
                    endTime:    4000 + (18 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_14_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [12]}
            ]
        },

        // Action 2-14
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  4000 + (28 * 33.33),
                    endTime:    0 + (0 * 33.33),
                    playBackRate: -4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [12]}
            ]
        },

        // Action 2-15
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02",
                    startTime:  4000 + (28 * 33.33),
                    endTime:    1000 + (17 * 33.33),
                    playBackRate: -4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_10_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [12]}
            ]
        },

        // Action 2-16
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02_01_MG",
                    startTime:  0 + (0 * 33.33),
                    loop: false,
                    followAction: {group: 2, action: 17}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_10_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [2, 5, 9, 11, 13]},
                {group: 2, actions: [2, 9, 14, 19, 20]},
                {group: 3, actions: [2, 4, 8, 14]},
                {group: 5, actions: [2]},
                {group: 6, actions: [4]},
                {group: 7, actions: [1]}
            ],
            minClickCount: 15
        },

        // Action 2-17
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02_02_MG",
                    startTime:  0 + (0 * 33.33),
                    loop: true
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [16]}
            ],
            addState: ["zappeln2"]
        },

        // Action 2-18
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02_02_MG",
                    playBackRateCommand: "reverse",
                    startVideo: false,
                    loop: false,
                    followAction: {group: 2, action: 19}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [17]},
                {group: 8, actions: [1, 2, 3, 4, 5, 6]},
            ],
            demandedState: ["zappeln2"]
        },
 
        // Action 2-19
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02_01_MG",
                    startTime:  -1,
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_28_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [18]}
            ],
            removeState: ["zappeln2"]
        },

        // Action 2-20
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02_01_MG",
                    playBackRateCommand: "reverse",
                    startVideo: false,
                    loop: false
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [16]}
            ]
        },
 

        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  0 + (0 * 33.33),
                    endTime:    0 + (3 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_17_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [2, 5, 9, 11, 13]},
                {group: 2, actions: [2, 9, 14, 19, 20]},
                {group: 3, actions: [2, 4, 8, 14]},
                {group: 5, actions: [2]},
                {group: 6, actions: [4]},
                {group: 4, actions: [1, 2, 3, 4, 5]}
            ]
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  0 + (3 * 33.33),
                    endTime:    0 + (0 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [1, 5]}
            ]
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  0 + (0 * 33.33),
                    endTime:    0 + (11 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_18_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [2, 5, 9, 11, 13]},
                {group: 2, actions: [2, 9, 14, 19, 20]},
                {group: 3, actions: [2, 4, 8, 14]},
                {group: 5, actions: [2]},
                {group: 6, actions: [4]},
                {group: 7, actions: [1]}
            ]
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  0 + (11 * 33.33),
                    endTime:    0 + (0 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [3, 6, 12]}
            ]
        },

        // Action 3-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  0 + (11 * 33.33),
                    endTime:    0 + (3 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_17_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [3, 6, 12]}
            ]
        },        

        // Action 3-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  0 + (3 * 33.33),
                    endTime:    0 + (11 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_18_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [1, 5]}
            ]
        },

        // Action 3-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  0 + (3 * 33.33),
                    endTime:    0 + (23 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_19_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [1, 5]}
            ]
        },

        // Action 3-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  0 + (23 * 33.33),
                    endTime:    0 + (0 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [7]}
            ]
        },

        // Action 3-9
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  0 + (11 * 33.33),
                    endTime:    1000 + (6 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_20_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [3, 6, 12]}
            ]
        },

        // Action 3-10
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  0 + (3 * 33.33),
                    endTime:    1000 + (6 * 33.33),
                    playBackRate: 2.5,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_20_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [1, 5]}
            ]
        },

        // Action 3-11
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  1000 + (6 * 33.33),
                    endTime:    2000 + (27 * 33.33),
                    playBackRate: 2,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_21_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [9, 10]}
            ]
        },

        // Action 3-12
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  2000 + (27 * 33.33),
                    endTime:    0 + (11 * 33.33),
                    playBackRate: -3,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_18_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [11]}
            ]
        },

        // Action 3-13
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  2000 + (27 * 33.33),
                    endTime:    5000 + (4 * 33.33),
                    playBackRate: 3,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_21_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [11]}
            ]
        },

        // Action 3-14
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  5000 + (4 * 33.33),
                    endTime:    0 + (0 * 33.33),
                    playBackRate: -5,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [13]}
            ]
        },

        // Action 3-15
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  5000 + (4 * 33.33),
                    endTime:    5000 + (12 * 33.33),
                    playBackRate: 4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_22_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [13]}
            ]
        },

        // Action 3-16
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  5000 + (12 * 33.33),
                    endTime:    4000 + (27 * 33.33),
                    playBackRate: -4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_23_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [15, 20]}
            ]
        },

        // Action 3-17
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  4000 + (27 * 33.33),
                    endTime:    5000 + (0 * 33.33),
                    playBackRate: 4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_24_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [16]}
            ]
        },

        // Action 3-18
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  4000 + (24 * 33.33),    // 24 scheint besser zu sein als das eigentlich korrekte 27
                    endTime:    4000 + (15 * 33.33),
                    playBackRate: -4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_25_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [16]}
            ]
        },

        // Action 3-19
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  5000 + (0 * 33.33),
                    endTime:    4000 + (15 * 33.33),
                    playBackRate: -4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_25_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [17]}
            ]
        },

        // Action 3-20
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03",
                    startTime:  4000 + (15 * 33.33),
                    endTime:    5000 + (12 * 33.33),
                    playBackRate: 4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_22_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [18, 19]}
            ]
        },

        // Action 3-21
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03_01_MG",
                    startTime:  0 + (0 * 33.33),
                    playBackRate: 4,
                    loop: false,
                    followAction: {group: 3, action: 22}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_20_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [2, 5, 9, 11, 13]},
                {group: 2, actions: [2, 9, 14, 19, 20]},
                {group: 3, actions: [2, 4, 8, 14]},
                {group: 5, actions: [2]},
                {group: 6, actions: [4]},
                {group: 4, actions: [1, 2, 3, 4, 5]}
            ],
            minClickCount: 20
        },

        // Action 3-22
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03_02_MG",
                    startTime:  0 + (0 * 33.33),
                    playBackRate: 10,
                    loop: true
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_06_01_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [21]},
            ],
            addState: ["zappeln3"]
        },

        // Action 3-23
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03_02_MG",
                    playBackRate: -4,
                    startVideo: false,
                    loop: false,
                    followAction: {group: 3, action: 24}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_27_clickArea"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_06_01_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [22]},
                {group: 8, actions: [1, 2, 3, 4, 5, 6]},
            ],
            demandedState: ["zappeln3"]
        },

        // Action 3-24
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03_01_MG",
                    startTime:  -1,
                    playBackRate: -4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_06_02_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [23]}
            ],
            removeState: ["zappeln3"]
        },        

        // Action 3-25
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_03_01_MG",
                    playBackRateCommand: "reverse",
                    startVideo: false,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_27_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [21]}
            ]
        },        

        /////////////////////////////////////////// *** GRUPPE 4 *** ///////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Skull_01_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    newProperties: [{property: "transform", value: "this.randomX()"}]
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newProperties: [
                        {property: "animationDuration", value: "this.randomAnimationSpeed()"},
                        {property: "animationDelay", value: "this.randomAnimationDelay()"}
                    ],
                    newClass: "skull_cssanim_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newProperties: [
                        {property: "animationDuration", value: "this.randomAnimationSpeed()"},
                        {property: "animationDelay", value: "this.randomAnimationDelay()"}
                    ],
                    newClass: "skull_cssanim_02_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absoluteScale",
                    newProperties: [{property: "transform", value: "this.randomScale()"}]
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [],
            addState: ["tetris"]
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    newClass: "skull_cssanim_02_01"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfTransformIs: "translateX(0vw)",
            possiblePrevActions: [
                {group: 4, actions: [1, 3, 4, 5]}
            ]
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    newClass: "skull_cssanim_02_02"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfTransformIs: "translateX(-30vw)",
            possiblePrevActions: [
                {group: 4, actions: [1, 2, 4, 5]}
            ]
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    newClass: "skull_cssanim_02_03"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfTransformIs: "translateX(30vw)",
            possiblePrevActions: [
                {group: 4, actions: [1, 2, 3, 5]}
            ]
        },

        // Action 4-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionY",
                    playBackRateCommand: "reverse"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 4, actions: [1, 2, 3, 4, 5]}
            ]
        },

        // Action 4-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tetris_cssanim_05_relativeScale"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 5000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 5 *** ///////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    //mediaTitle: "Skull_04",
                    mediaTitle: "Skull_03",            // eigentlich 04, aber der soll nicht geladen werden
                    startTime:  0,
                    playBackRate: 1,
                    loop: false,
                    followAction: {group: 0, action: 2}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_03_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 10,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [2, 5, 9, 11, 13]},
                {group: 2, actions: [2, 9, 14, 19, 20]},
                {group: 3, actions: [2, 4, 8, 14]},
                {group: 5, actions: [2]},
                {group: 6, actions: [4]},
                {group: 4, actions: [1, 2, 3, 4, 5]}
            ]
        },

        // Action 5-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    //mediaTitle: "Skull_04",
                    mediaTitle: "Skull_03",            // eigentlich 04, aber der soll nicht geladen werden
                    startTime:  0 + (26 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_04_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_04_opacity"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    newProperties: [{property: "transform", value: "this.randomX()"}]
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionY",
                    newProperties: [{property: "transform", value: "this.randomY()"}]
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absoluteScale",
                    newProperties: [{property: "transform", value: "this.randomScaleForReverseExplosion()"}]
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 6 *** ///////////////////////////////////////////
        // Action 6-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_06",
                    startTime:  0,
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_26_clickArea"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 10,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [2, 5, 9, 11, 13]},
                {group: 2, actions: [2, 9, 14, 19, 20]},
                {group: 3, actions: [2, 4, 8, 14]},
                {group: 5, actions: [2]},
                {group: 6, actions: [4]},
            ]
        },

        // Action 6-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_06",
                    startTime:  -1,
                    endTime:    3000 + (17 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_26_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 6, actions: [1]}
            ]
        },

        // Action 6-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_06",
                    startTime:  3000 + (17 * 33.33),
                    playBackRate: 1.5,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_26_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 6, actions: [2]}
            ]
        },

        // Action 6-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_06",
                    startTime:  -1,
                    playBackRate: -4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 6, actions: [1, 3, 6]}
            ]
        },

        // Action 6-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Skull_01_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_26_clickArea"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    newProperties: [{property: "transform", value: "this.randomX()"}]
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_02_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1300,
                    followAction: {group: 6, action: 6}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 6-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_06",
                    startTime:  0,
                    playBackRate: 1,
                    loop: false
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 6, actions: [5]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 7 *** ///////////////////////////////////////////
        // Action 7-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Skull_01_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_05_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_05_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_05_opacity"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 7-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Skull_02_02_MG",
                    startTime:  0 + (0 * 33.33),
                    loop: true
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_07_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_07_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_07_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_07_opacity"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_10b_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [],
            addState: ["zappeln4"]
        },

        // Action 7-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    playBackRate: 4
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionY",
                    playBackRate: 4
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "rotate",
                    playBackRate: 4
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_10b_clickArea"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 7, action: 4}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 7, actions: [2, 4]}
            ]
        },

        // Action 7-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionY",
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "skull_cssanim_01_10b_clickArea"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "rotate",
                    playBackRate: 1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 8 *** ///////////////////////////////////////////
        // Action 8-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 20, y: -9999},
            demandedState: ["zappeln2", "zappeln3"]
        },

        // Action 8-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 40, y: 60},
            demandedState: ["zappeln2", "zappeln3"]
        },

        // Action 8-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: -10, y: 70},
            demandedState: ["zappeln2", "zappeln3"]
        },

        // Action 8-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 70, y: 20},
            demandedState: ["zappeln2", "zappeln3"]
        },

        // Action 8-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 30, y: 55},
            demandedState: ["zappeln2", "zappeln3"]
        },

        // Action 8-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: -9999, y: -20},
            demandedState: ["zappeln2", "zappeln3"]
        },

        /////////////////////////////////////////// *** GRUPPE 9 *** ///////////////////////////////////////////
        // Action 9-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_01_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_01_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                }
            ],
            allowHover: false,
            allowClick: true,
            demandedState: ["zappeln2", "zappeln3"],
            addState: ["psychedelic"]
        },

        // Action 9-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_02_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_02_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                }
            ],
            allowHover: false,
            allowClick: true,
            demandedState: ["zappeln2", "zappeln3"],
            addState: ["psychedelic"]
        },

        // Action 9-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_03_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_03_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_03_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                }
            ],
            allowHover: false,
            allowClick: true,
            demandedState: ["zappeln2", "zappeln3"],
            addState: ["psychedelic"]
        },

        // Action 9-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_04_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_04_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_04_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                }
            ],
            allowHover: false,
            allowClick: true,
            demandedState: ["zappeln2", "zappeln3"],
            addState: ["psychedelic"]
        },

        // Action 9-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_05_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_05_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_05_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                }
            ],
            allowHover: false,
            allowClick: true,
            demandedState: ["zappeln2", "zappeln3"],
            addState: ["psychedelic"]
        },

        // Action 9-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_06_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_06_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_06_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_06_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                }
            ],
            allowHover: false,
            allowClick: true,
            demandedState: ["zappeln2", "zappeln3"],
            addState: ["psychedelic"]
        },



    ];


    combiClickActions: Array<CSM.ClickAction> = [
        ///////////////////////////////////////// *** COMBIGROUP 0 *** /////////////////////////////////////////
        // Action 0-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_01_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_02_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_03_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_04_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_05_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_06_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 1 *** /////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_01_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_01_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_02_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_02_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },


        ///////////////////////////////////////// *** COMBIGROUP 2 *** /////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_01a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_01_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_02a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_02_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_03a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_03_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_04a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_04_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_05a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_05_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        ///////////////////////////////////////// *** COMBIGROUP 3 *** /////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_06a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_06_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_07a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_07_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_08a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_08_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_09a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_09_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 3-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_10a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_10_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 3-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_11a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_11_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },


        ///////////////////////////////////////// *** COMBIGROUP 4 *** /////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 5 *** /////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_01_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_02_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_03_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_04_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_05_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_06_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 6 *** /////////////////////////////////////////
        // Action 6-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_01_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_01_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_02_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_02_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_03_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_03_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_03_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_04_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_04_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_04_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_05_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_05_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_05_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 7 *** /////////////////////////////////////////
        // Action 7-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_01_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_01_saturate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 7-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_02_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_02_saturate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 7-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_03_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_03_saturate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 7-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_04_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_04_saturate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 7-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_05_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_05_saturate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 7-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_06_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_06_saturate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },



    ];


    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);



    audioActions: Array<CSM.AudioAction> = [
        // 1 Verformen kurz in Schädel-Grundgestalt
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2, 10, 11]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6]}
            ],
            alternativeSounds: [
                {sample: "knirschen1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "knirschen3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Boxer",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-FuzzyFilterfunk",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-LadderFilter",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-minus6Halbtoene",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-plus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 100},              
            ],
            allowedInAudioEnvironments: [0, 1]
        },

        // 2 Verformen kurz in zerfranster Schädelgestalt
        {
            possibleClickActions: [
                {group: 3, actions: [15, 16, 17, 18, 19, 20]}
            ],
            alternativeSounds: [
                {sample: "knirschen1",            midiRange: [55, 70], dynamicRange: [1, 1],    endTime: 100},
                {sample: "knirschen3",            midiRange: [55, 70], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-Palace4",            midiRange: [55, 70], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-RustedRose1",            midiRange: [55, 70], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-RustedRose2",            midiRange: [55, 70], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-RustedRose3",            midiRange: [55, 70], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-RustedRose4",            midiRange: [55, 70], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-RustedRose5",            midiRange: [55, 70], dynamicRange: [1, 1],    endTime: 100},
                {sample: "Skull-RustedRose6",            midiRange: [55, 70], dynamicRange: [1, 1],    endTime: 100},
            ],
            allowedInAudioEnvironments: [0, 1]
        },

        // 3 Verformen ca. 660ms
        {
            possibleClickActions: [
                {group: 1, actions: [3, 7]},
                {group: 3, actions: [7]}
            ],
            alternativeSounds: [
                {sample: "knirschen2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "knirschen4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-Boxer",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-FuzzyFilterfunk",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-LadderFilter",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-minus6Halbtoene",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-plus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
                {sample: "Skull-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 660},
            ],
            allowedInAudioEnvironments: [0, 1]
        },

        // 4 Verformen ca. 750ms
        {
            possibleClickActions: [
                {group: 3, actions: [8, 13]}
            ],
            alternativeSounds: [
                {sample: "knirschen2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "knirschen4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-Boxer",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-FuzzyFilterfunk",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-LadderFilter",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-minus6Halbtoene",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-plus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
                {sample: "Skull-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 750},
            ],
            allowedInAudioEnvironments: [0, 1]
        },

        // 5 Verformen ca. 840ms
        {
            possibleClickActions: [
                {group: 3, actions: [9, 11, 12]}
            ],
            alternativeSounds: [
                {sample: "Skull-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-Boxer",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-FuzzyFilterfunk",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-LadderFilter",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-minus6Halbtoene",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-plus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
                {sample: "Skull-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 840},
            ],
            allowedInAudioEnvironments: [0, 1]
        },

        // 5 Verformen ca. 440ms
        {
            possibleClickActions: [
                {group: 1, actions: [4, 8, 9, 12]},
                {group: 2, actions: [3, 10]},
                {group: 3, actions: [10]}
            ],
            alternativeSounds: [
                {sample: "knirschen2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "knirschen4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-Boxer",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-FuzzyFilterfunk",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-LadderFilter",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-minus6Halbtoene",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
                {sample: "Skull-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 440},
            ],
            allowedInAudioEnvironments: [0, 1]
        },

        // 6 Verformen ca. 1030ms
        {
            possibleClickActions: [
                {group: 1, actions: [5]},
                {group: 2, actions: [14]},
                {group: 3, actions: [14]}
            ],
            alternativeSounds: [
                {sample: "Skull-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-Boxer",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-FuzzyFilterfunk",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-LadderFilter",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-minus6Halbtoene",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-plus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
                {sample: "Skull-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1030},
            ],
            allowedInAudioEnvironments: [0, 1]
        },

        // 7 Verformen in Rotation
        {
            possibleClickActions: [
                {group: 3, actions: [21]}
            ],
            alternativeSounds: [
                {
                    sample: "RockSkull",
                    midiRange: [59, 63],
                    dynamicRange: [1, 1],
                    fadeOut: 2000,
                    addToInfinitelyPlayingPlayers: true
                }
            ],
            allowedInAudioEnvironments: [0, 1, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        },

        // 8 Verformen ca. 2400ms
        {
            possibleClickActions: [
                {group: 3, actions: [23, 25]}
            ],
            alternativeSounds: [
                {sample: "Skull-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-Boxer",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-FuzzyFilterfunk",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-LadderFilter",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-minus6Halbtoene",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-plus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
                {sample: "Skull-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2400},
            ],
            allowedInAudioEnvironments: [0, 1],
            killSamples: ["RockSkull"]
        },

        // 9 Verformen ca. 1800ms
        {
            possibleClickActions: [
                {group: 1, actions: [6]}
            ],
            alternativeSounds: [
                {sample: "Skull-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-Boxer",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-FuzzyFilterfunk",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-LadderFilter",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-minus6Halbtoene",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-plus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
                {sample: "Skull-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 1800},
            ],
            allowedInAudioEnvironments: [0, 1]
        },

        // 10 Verformen ca. 2100ms
        {
            possibleClickActions: [
                {group: 1, actions: [13]}
            ],
            alternativeSounds: [
                {sample: "Skull-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-Boxer",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-FuzzyFilterfunk",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-LadderFilter",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-autowah",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Flanger",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-minus6Halbtoene",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-Palace1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-Palace2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-Palace3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-Palace4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-Palace5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-Palace6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-plus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-RustedRose1",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-RustedRose2",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-RustedRose3",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-RustedRose4",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-RustedRose5",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-RustedRose6",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
                {sample: "Skull-Shrub",            midiRange: [55, 60], dynamicRange: [1, 1],    endTime: 2100},
            ],
            allowedInAudioEnvironments: [0, 1]
        },

        // 11 Fischchen 100ms
        {
            possibleClickActions: [
                {group: 2, actions: [1, 2, 4, 5, 12, 13]},
            ],
            alternativeSounds: [
                {sample: "SkullRRRR",            midiRange: [70, 90], dynamicRange: [0.5, 0.5],    endTime: 100}             
            ],
            allowedInAudioEnvironments: [0, 1]
        },

        // 12 Fischchen 840ms
        {
            possibleClickActions: [
                {group: 2, actions: [6, 7, 8, 9, 11, 15]},
            ],
            alternativeSounds: [
                {sample: "SkullRRRR",            midiRange: [70, 90], dynamicRange: [0.5, 0.5],    endTime: 840}             
            ],
            allowedInAudioEnvironments: [0, 1]
        },

        // 13 Psychedelic Technicolor Sunset
        {
            possibleClickActions: [
                {group: 2, actions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]},
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 8, actions: [1, 2, 3, 4, 5, 6]}
            ],
            alternativeSounds: [
                {
                    sample: "../../tamtam/audio/TT Metal",
                    midiRange: [44, 50],
                    dynamicRange: [0.02, 0.02]
                },
                {
                    sample: "../../tamtam/audio/TT pp",
                    midiRange: [55, 80],
                    dynamicRange: [0.1, 0.8]
                },
                {
                    sample: "../../tamtam/audio/TT ff",
                    midiRange: [50, 60],
                    dynamicRange: [0.6, 0.8]
                }
            ],
            allowedInAudioEnvironments: [5]
        },

        // 14 Spätere Audio-Environments
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]},
                {group: 2, actions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 23, 24, 25]},
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 8, actions: [1, 2, 3, 4, 5, 6]}
            ],
            alternativeSounds: [
                {
                    sample: "../../../impactsounds/Impro/SheetMetalHangarratscheabwaerts_fadeout",
                    midiRange: [50, 65],
                    dynamicRange: [0.6, 1.2]
                },
                {
                    sample: "../../../impactsounds/Impro/SheetMetalHangarratscheaufwaerts_fadeout",
                    midiRange: [50, 65],
                    dynamicRange: [0.6, 1.2]
                },
                {
                    sample: "../../../impactsounds/Impro/SheetMetalHangerratscheabwaerts2_fadeout",
                    midiRange: [50, 65],
                    dynamicRange: [0.6, 1.2]
                }
            ],
            allowedInAudioEnvironments: [2, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        },

        /*// 15 Psychedelisch werden
        // demandedNewState funktioniert irgendwie nicht, darum hab ich das hier mal deaktiviert
        {
            demandedNewState: "psychedelic",
            alternativeSounds: [
                {
                    sample: "../../../impactsounds/AnfangGrosse/HadronHall_08",
                    midiRange: [50, 60],
                    dynamicRange: [1.5, 2]
                },
                {
                    sample: "../../../impactsounds/AnfangMittlere/HadronHall_03",
                    midiRange: [50, 60],
                    dynamicRange: [2, 3]
                },
                {
                    sample: "../../../impactsounds/AnfangMittlere/HadronHall_05",
                    midiRange: [50, 60],
                    dynamicRange: [2, 3]
                },
                {
                    sample: "../../../impactsounds/AnfangMittlere/HadronHall_06",
                    midiRange: [50, 60],
                    dynamicRange: [2, 3]
                },
                {
                    sample: "../../../impactsounds/AnfangMittlere/HadronHall_07",
                    midiRange: [50, 60],
                    dynamicRange: [2, 3]
                }
            ],
            allowedInAudioEnvironments: [0, 1, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        },*/

        // 16 Fischchen dauerhaft
        {
            possibleClickActions: [
                {group: 2, actions: [16]},
            ],
            alternativeSounds: [
                {sample: "fischleinrotieren",            midiRange: [65, 75], dynamicRange: [0.5, 0.5] }             
            ],
            allowedInAudioEnvironments: [0, 1, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        },

        // 17 Fischchen reverse
        {
            possibleClickActions: [
                {group: 2, actions: [18, 19, 20]},
            ],
            alternativeSounds: [
                {
                    sample: "../../tamtam/audio/TT pp",
                    midiRange: [55, 80],
                    dynamicRange: [0.05, 0.05]
                },
            ],
            allowedInAudioEnvironments: [0, 1, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        },

        // 18 Viele Schädel
        {
            possibleClickActions: [
                {group: 5, actions: [1]},
            ],
            alternativeSounds: [
                {
                    sample: "SkullExplosion",
                    midiRange: [60, 60],
                    dynamicRange: [0.5, 0.5]
                },
            ],
            allowedInAudioEnvironments: [0, 1, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        },        

        // 19 Zerbrechen
        {
            possibleClickActions: [
                {group: 6, actions: [1]},
            ],
            alternativeSounds: [
                {
                    sample: "SkullBreak",
                    midiRange: [60, 60],
                    dynamicRange: [0.5, 0.5]
                },
            ],
            allowedInAudioEnvironments: [0, 1, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        },        

        // 20 Wühlen im Debris
        {
            possibleClickActions: [
                {group: 6, actions: [2, 3]},
            ],
            alternativeSounds: [
                {
                    sample: "SkullBreak",
                    midiRange: [50, 60],
                    dynamicRange: [0.25, 0.25],
                    startTime: 2000,
                    fadeIn: 500,
                    fadeOut: 500
                },
            ],
            allowedInAudioEnvironments: [0, 1, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        },        

        // 21 Wieder zusammensetzen
        {
            possibleClickActions: [
                {group: 6, actions: [4]},
            ],
            alternativeSounds: [
                {
                    sample: "SkullBreakReverse",
                    midiRange: [60, 60],
                    dynamicRange: [0.5, 0.5],
                    startTime: 2200,
                    fadeIn: 1400
                },
            ],
            allowedInAudioEnvironments: [0, 1, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        },        

        // 21 Runterfallen
        {
            possibleClickActions: [
                {group: 6, actions: [5]},
            ],
            alternativeSounds: [
                {
                    sample: "SkullBreak2",
                    midiRange: [60, 60],
                    dynamicRange: [0.5, 0.5],
                    startTime: 200
                },
            ],
            allowedInAudioEnvironments: [0, 1, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        },        

        // 22 Herumscheuchen
        {
            possibleClickActions: [
                {group: 7, actions: [3]}
            ],
            alternativeSounds: [
                {sample: "knirschen2",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "knirschen4",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-autowah",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-BadQuality1",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-BadQuality2",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-BadQuality3",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-BadQuality4",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-BadQuality5",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-BadQuality6",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-BadQuality7",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-Boxer",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-Eggshell1",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-Eggshell2",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-Eggshell3",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-Eggshell4",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-Flanger",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-FuzzyFilterfunk",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-HedgeHog",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-LadderFilter",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-autowah",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Flanger",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Palace1",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Palace2",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Palace3",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Palace4",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Palace5",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Palace6",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-RustedRose1",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-RustedRose2",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-RustedRose3",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-RustedRose4",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-RustedRose5",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-RustedRose6",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene-Shrub",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-minus6Halbtoene",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-Palace1",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-Palace2",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-Palace3",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-Palace4",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-Palace5",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-Palace6",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality1",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality2",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality3",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality4",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality5",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality6",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-BadQuality7",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-CrustedFlakes",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-Eggshell1",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-Eggshell2",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-Eggshell3",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-Eggshell4",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-Eggshell5",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-Eggshell6",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-plus6Halbtoene-HedgeHog",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-RustedRose1",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-RustedRose2",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-RustedRose3",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-RustedRose4",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-RustedRose5",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-RustedRose6",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
                {sample: "Skull-Shrub",            midiRange: [55, 60], dynamicRange: [2, 2],    endTime: 440},
            ],
            allowedInEnvironments: [19]
        },


    ];


    audioHierarchy = [
        {
            description: "Catch All",
            actionGroups: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            combiGroups: [0, 1, 2, 3, 4, 5, 6, 7]                        // Bei mehreren Combigroups haben die früher genannten Priorität
        }
    ]


}