import * as CSM from "../../../ClickStateManager.js";
import musician_info from "../../../musician_info.js";

export default class MusikerInfo_DanielSeroussi extends musician_info {

    textURI = "MusikerInfo/MusikerInfo_DanielSeroussi.html";
    title = "Daniel Seroussi, Klavier";
    width = 35;                                   //object size in percent of screenwidth
    height = 100;
    position = { x: 62, y: 0 };                  //top left position of video object in percent of screen width and screen height

    onMouseDown() {
        this.clickCount++;
        this.executeAction(1);
    }
    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Catch All",             elements: 2,    probability: 0,     krassitaet: 1, allowedInEnvironments: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
    ];

    combiGroups = [];

    clickActions = [

        // Action 0-1 - Init Action
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement,
                    targetObjectSelector: "#musikerinfo_danielseroussi"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_03_textdiv"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_04_textdiv"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 150,
                    followAction: {group: 0, action: 3}
                }                
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement,
                    targetObjectSelector: "#musikerinfo_danielseroussi"
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ]

}