import * as CSM from "../../../ClickStateManager.js";

export default class MusikerSpielelement_MalikaMaminova extends CSM.ClickStateManager {

    videoSize = { x: 4096, y: 2160 };               // width and height of the original video material
    clickAreaPosition = { x: 1300, y: 700 };        // position of Object in original Video in px
    clickAreaSize = { x: 1330, y: 1030 };           // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;   //Form of the clickable Area
    mediaPfad = "/spielelemente/musiker";


    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

        /*if (this.clickCount >= 1) {
            this.executeAction(this.findActionNumber(1, 1));
            this.executeCombiAction(this.findCombiActionNumber(4, this.clickCount));
            return;
        }
        return
        if (this.clickCount == 2) {
            this.executeAction(this.findActionNumber(1, 1));
            this.executeCombiAction(this.findCombiActionNumber(1, 2));
            return;
        }
        if (this.clickCount == 3) {this.executeAction(this.findActionNumber(2, 2)); return;}
        if (this.clickCount == 4) {this.executeAction(this.findActionNumber(2, 2)); return;}
        if (this.clickCount == 5) {this.executeAction(this.findActionNumber(2, 3)); return;}
        if (this.clickCount == 6) {this.executeAction(this.findActionNumber(1, 8)); return;}
        if (this.clickCount == 7) {this.executeAction(this.findActionNumber(6, 15)); return;}
        if (this.clickCount == 8) {this.executeAction(this.findActionNumber(6, 15)); return;}
        return;*/

        this.lokalerEskalationswert = 0.15 + this.clickCount / 20;
        if (this.lokalerEskalationswert > 1) {this.lokalerEskalationswert = 1;}
        
        this.findPossibleNextActions();

        // Sonderregeln

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Init und Exit",                 elements: 2,    probability: 0,     krassitaet: 1},
        {name: "1 Videospielen",                  elements: 1,    probability: 0.7,   krassitaet: 2},
        {name: "2 Silhouette",                    elements: 2,    probability: 0.7,   krassitaet: 1},
        {name: "3 Herumwirbeln",                  elements: 1,    probability: 0.7,   krassitaet: 2},
        {name: "4 Reinkommen",                    elements: 4,    probability: 0.7,   krassitaet: 2}
    ];

    combiGroups = [
        {name: "0 Fahren",                          elements: 4,    probability: 1.2,   krassitaet: 2,  addableToGroups: [1, 3],         incompatibleCombiGroups: []},
        {name: "1 Invertieren",                     elements: 2,    probability: 0.3,   krassitaet: 2,  addableToGroups: [1, 3],         incompatibleCombiGroups: []},
        {name: "2 Farben",                          elements: 8,    probability: 0.3,   krassitaet: 2,  addableToGroups: [1, 3],         incompatibleCombiGroups: []},
        {name: "3 Drehung",                         elements: 4,    probability: 0.3,   krassitaet: 3,  addableToGroups: [1, 3],         incompatibleCombiGroups: []},
        {name: "4 Größenveränderung",               elements: 4,    probability: 0.3,   krassitaet: 2,  addableToGroups: [1, 3],         incompatibleCombiGroups: []},
    ];


    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "MalikaMaminova_Spring_01_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_01a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "MalikaMaminova_Spring_01",
                    startTime: 0,
                    playBackRate: 1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [2]},
                {group: 2, actions: [1, 2]},
                {group: 3, actions: [1]}
            ],
            minClickCount: 3
        },

        // Action 1-2
        /*{
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "MalikaMaminova_Spring_01",
                    startVideo: false,
                    playBackRate: -1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1]}
            ]
        },*/

        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_00_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_00_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_00_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_00_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_00_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_00_contrast"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    startAnimation: false,
                    playBackRateCommand: "reverse"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [1, 2]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "MalikaMaminova_Spring_01_Sekunde1_OhneSchlagzeug"
                }/*,
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_00_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_00_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_00_01_absoluteScale"
                }*/,
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_00_01_rotate"
                }/*,
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_00_01_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_00_01_contrast"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_00a"
                }*/
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1, 2]},
                {group: 2, actions: [1, 2]},
                {group: 3, actions: [1]}
            ],
            minClickCount: 2
        },

        /////////////////////////////////////////// *** GRUPPE 4 *** ///////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "MalikaMaminova_Spring_01_Sekunde1_OhneSchlagzeug"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_07_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_07_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_07_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_07_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_07_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_07_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_07_contrast"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 4, action: 4}
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 28000,
                    followAction: {group: 4, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_08_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_08_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_08_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_09_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_09_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_09_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_09_brightness"
                },
                {
                    actionType: CSM.ActionType.StartNewEnvironment
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 500,
                    followAction: {group: 2, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 4, actions: [1, 2, 4]},
            ]
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.PlayAudio,
                    mediaTitle: "springdrohend",
                    fadeIn: 2000,
                    volume: 2.5
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },


    ];

    combiClickActions = [
        ///////////////////////////////////////// *** COMBIGROUP 0 *** /////////////////////////////////////////
        // Action 0-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_02_01_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_02_02_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_02_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_02_04_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 1 *** /////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_03_01_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_03_02_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 2 *** /////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_01_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_01_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_02_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_02_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_03_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_03_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_04_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_04_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_05_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_05_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_06_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_06_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_07_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_07_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_08_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_04_08_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 3 *** /////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_05_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_05_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_05_03_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_05_04_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 4 *** /////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_06_01_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_06_02_absoluteScale"
                }

            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_06_03_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "malikamaminova_cssanim_06_04_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },




    ];

    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);




    audioActions: Array<CSM.AudioAction> = [
        // 1 Einfaches Anschlagen
        {
            possibleClickActions: [
                {group: 2, actions: [2]}
            ],
            alternativeSounds: [
                {
                    sample: "Malika_01",
                    midiRange: [57, 63],
                    dynamicRange: [0.8, 1],
                    fadeOut: 100
                },
                {
                    sample: "Malika_06",
                    midiRange: [57, 63],
                    dynamicRange: [0.8, 1],
                    fadeOut: 100
                },
                {
                    sample: "Malika_10",
                    midiRange: [57, 63],
                    dynamicRange: [0.8, 1],
                    fadeOut: 100
                }
            ]
        },   

        // 2 Komplexeres Anschlagen
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2]},
                {group: 3, actions: [1]}
            ],
            alternativeSounds: [
                {
                    sample: "Malika_01",
                    midiRange: [57, 63],
                    dynamicRange: [0.8, 1],
                    fadeOut: 100
                },
                {
                    sample: "Malika_06",
                    midiRange: [57, 63],
                    dynamicRange: [0.8, 1],
                    fadeOut: 100
                },
                {
                    sample: "Malika_07",
                    midiRange: [57, 63],
                    dynamicRange: [0.8, 1],
                    fadeOut: 100
                },
                {
                    sample: "Malika_08",
                    midiRange: [57, 63],
                    dynamicRange: [0.8, 1],
                    fadeOut: 100
                },
                {
                    sample: "Malika_10",
                    midiRange: [57, 63],
                    dynamicRange: [0.8, 1],
                    fadeOut: 100
                },
                {
                    sample: "Malika_12",
                    midiRange: [57, 63],
                    dynamicRange: [0.8, 1],
                    fadeOut: 100
                },
            ]
        },   

        // 3 Drohend reinkommen
        /*{
            possibleClickActions: [
                {group: 4, actions: [1]},
            ],
            alternativeSounds: [
                {
                    sample: "springdrohend",
                    midiRange: [50, 55],
                    dynamicRange: [1, 1],
                    fadeIn: 2000
                }
            ]
        },   */


        
    ]

    audioHierarchy = [
        {
            description: "Catch All",
            actionGroups: [1, 2, 3],
            combiGroups: [0, 1, 2, 3, 4]
        }
    ]


}