import { GroupActionContainer } from "./ClickStateManager.js";
import { TextDiv } from "./TextDiv.js";

export default class production extends TextDiv {
    public removeHTML(): void {
        super.removeHTML();
        let oldMusicianString = this.mediator.activeMusician;
        if(oldMusicianString != ""){
            this.mediator.objects[oldMusicianString].executeAction(1);
        }
    }
}