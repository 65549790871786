import * as CSM from "../../../ClickStateManager.js";

export default class Menupunkt_CallForScores extends CSM.ClickStateManager {

    videoSize = { x: 400, y: 225 };               // width and height of the original video material
    clickAreaPosition = { x: 112, y: 78 };         // position of Object in original Video in px
    clickAreaSize = { x: 168, y: 70 };             // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;    //Form of the clickable Area
    rotation = 0;
    mediaPfad = "/hauptmenupunkte";

    onMouseDown() {
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        this.executeAction(2);
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "Catch All",             elements: 3,    probability: 1,     krassitaet: 1},
    ];

    clickActions = [

        // Init Action
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Menupunkt_CallForScores_frame0001"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Exit Action
        {
            singleActions: [

            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Menupunkt_CallForScores",
                    startTime: 150
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "menupunkt_callforscores_cssanim_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "menupunkt_callforscores_cssanim_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "menupunkt_callforscores_cssanim_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "menupunkt_callforscores_cssanim_01_opacity"
                },
                {
                    actionType: CSM.ActionType.ActivateObject,
                    targetObject: "textfenster_callforscores"
                }
            ],
            allowHover: true,
            allowClick: true
        }

    ]

}