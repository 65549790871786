import * as CSM from "../../../ClickStateManager.js";

export default class SkullLight extends CSM.ClickStateManager {

    videoSize = { x: 340, y: 340 };               // width and height of the original video material
    clickAreaPosition = { x: 20, y: 20 };         // position of Object in original Video in px
    clickAreaSize = { x: 300, y: 300 };             // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Circle;   //Form of the clickable Area
    mediaPfad = "/spielelemente/skull";
    performResetActions = false;

    scale: number;
    animationSpeed: number;

    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        if (this.state.includes("tetris")) {
            this.mediator.totalSkullTetrisClickCount++;
        }
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

/*        if (this.clickCount == 1) {
            this.executeAction(this.findActionNumber(8, 1));
            this.findBestAudioForActionAndCombiActions(this.prevActions[this.prevActions.length - 1], []);
            return;
        }
        if (this.clickCount == 2) {
            this.executeAction(this.findActionNumber(6, 4));
            this.findBestAudioForActionAndCombiActions(this.prevActions[this.prevActions.length - 1], []);
            return;
        }
        
/*        if (this.clickCount <= 15) {
            this.executeAction(this.findActionNumber(2, 16));
            this.findBestAudioForActionAndCombiActions(this.prevActions[this.prevActions.length - 1], []);
            return;}*/
/*        if (this.clickCount == 2) {this.executeAction(this.findActionNumber(9, 2)); return;}
        if (this.clickCount == 3) {this.executeAction(this.findActionNumber(2, 23)); return;}
        if (this.clickCount == 4) {this.executeAction(this.findActionNumber(3, 4)); return;}
        if (this.clickCount == 5) {this.executeAction(this.findActionNumber(3, 5)); return;}*/

        this.lokalerEskalationswert = 0.5 + this.clickCount / 20;

/*        if (this.mediator.currentEnvironment == 1) {
            this.lokalerEskalationswert = 0.15 + this.clickCount / 60;
        }
        if (this.mediator.currentEnvironment == 10) {
            this.lokalerEskalationswert = 0.5 + this.clickCount / 20;
        }*/
        if (this.lokalerEskalationswert > 0.9) {this.lokalerEskalationswert = 0.9;}
        
        this.findPossibleNextActions();

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    randomX() {return "translateX(" + (10 + Math.floor(Math.random()*80)) + "vw)";}
    randomAnimationSpeed() {
        this.scale = 0.05 + Math.random() * 0.15;
        this.animationSpeed = 750 / this.scale;
        return Math.floor(this.animationSpeed) + "ms";
    }
    returnAnimationSpeed() {
        return Math.floor(this.animationSpeed) + "ms";
    }
    returnScale() {return "scale(" + this.scale + ")";}
    randomScale() {
        this.scale = 0.05 + Math.random() * 0.15;
        return "scale(" + this.scale + ")";
    }
    randomAnimationDelay() {return Math.floor(Math.random() * 10000) + "ms";}

    randomY() {return "translateY(" + (10 + Math.floor(Math.random()*80)) + "vh)";}
    returnScaleForReverseExplosion() {return "scale(" + (0.5 + Math.random() * 1) + ")";}

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Init und Exit",                           elements: 2,    probability: 0,     krassitaet: 1, allowedInEnvironments: [0, 1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "1 Skull-Tetris",                            elements: 2,    probability: 0,   krassitaet: 1, allowedInEnvironments: [0, 1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "2 Düsenjäger",                              elements: 6,    probability: 0.7,   krassitaet: 1, allowedInEnvironments: [0, 1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
    ];

    combiGroups = [
        {name: "0 Verformungen",               elements: 6,    probability: 0.5,   krassitaet: 2,  addableToGroups: [2],       incompatibleCombiGroups: [], allowedInEnvironments: [0, 1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
//        {name: "1 Herumschweben",              elements: 2,    probability: 0.0,  krassitaet: 2,  addableToGroups: [1],          incompatibleCombiGroups: [], allowedInEnvironments: [0, 1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
//        {name: "2 Blendmodes Light Steeldrum", elements: 5,    probability: 0.0,  krassitaet: 3,  addableToGroups: [1], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20], demandedSteelDrumState: "light" },
//        {name: "3 Blendmodes Dark Steeldrum",  elements: 6,    probability: 0.0,  krassitaet: 3,  addableToGroups: [1], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20], demandedSteelDrumState: "dark" },
//        {name: "4 Düsenjäger",                 elements: 6,    probability: 0.6,   krassitaet: 1,  addableToGroups: [1],       incompatibleCombiGroups: [], allowedInEnvironments: [0, 1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "5 Größenveränderungen",        elements: 1,    probability: 0.5,   krassitaet: 2,  addableToGroups: [2],    incompatibleCombiGroups: [], allowedInEnvironments: [0, 1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
//        {name: "6 Rotationen",                 elements: 6,    probability: 0.0,   krassitaet: 1,  addableToGroups: [1],       incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
//        {name: "7 Farbeffekte",                elements: 6,    probability: 0.0,   krassitaet: 2,  addableToGroups: [1], incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
    ];


    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Skull_cropped"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },     

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Skull_cropped"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    newProperties: [{property: "transform", value: "this.randomX()"}]
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newProperties: [
                        {property: "animationDuration", value: "this.randomAnimationSpeed()"},
                        {property: "animationDelay", value: "this.randomAnimationDelay()"}
                    ],
                    newClass: "skull_cssanim_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newProperties: [
                        {property: "animationDuration", value: "this.returnAnimationSpeed()"},
                        //{property: "animationDelay", value: "this.randomAnimationDelay()"}
                    ],
                    newClass: "skull_cssanim_02_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absoluteScale",
                    newProperties: [{property: "transform", value: "this.returnScale()"}]
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [],
            addState: ["tetris"]
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tetris_cssanim_05_relativeScale"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 5000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true/*,
            possiblePrevActions: [
                {group: 1, actions: [2]}
            ]*/
        },       

/*        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionY",
                    playBackRateCommand: "reverse"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1, 2, 3, 4, 5]}
            ]
        },*/

        ///////////////////////////////////////// *** COMBIGROUP 4 *** /////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1]},
                {group: 2, actions: [2, 3, 4, 5, 6]}
            ]
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1]},
                {group: 2, actions: [1, 3, 4, 5, 6]}
            ]
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1]},
                {group: 2, actions: [1, 2, 4, 5, 6]}
            ]
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1]},
                {group: 2, actions: [1, 2, 3, 5, 6]}
            ]
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1]},
                {group: 2, actions: [1, 2, 3, 4, 6]}
            ]
        },

        // Action 2-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1]},
                {group: 2, actions: [1, 2, 3, 4, 5]}
            ]
        },        

    ];


    combiClickActions: Array<CSM.ClickAction> = [
        ///////////////////////////////////////// *** COMBIGROUP 0 *** /////////////////////////////////////////
        // Action 0-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_01_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_02_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_03_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_04_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_05_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_06_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

/*        ///////////////////////////////////////// *** COMBIGROUP 1 *** /////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_01_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_01_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_02_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_02_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_05_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },


        ///////////////////////////////////////// *** COMBIGROUP 2 *** /////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_01a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_01_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_02a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_02_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_03a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_03_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_04a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_04_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_05a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_05_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        ///////////////////////////////////////// *** COMBIGROUP 3 *** /////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_06a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_06_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_07a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_07_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_08a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_08_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_09a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_09_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 3-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_10a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_10_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 3-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_11a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_06_11_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },
*/

        ///////////////////////////////////////// *** COMBIGROUP 5 *** /////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absoluteScale",
                    newProperties: [{property: "transform", value: "this.randomScale()"}]
                }
            ],
            allowHover: false,
            allowClick: true
        },



/*        ///////////////////////////////////////// *** COMBIGROUP 6 *** /////////////////////////////////////////
        // Action 6-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_01_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_01_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_02_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_02_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_03_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_03_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_03_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_04_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_04_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_04_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_05_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_05_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_05_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_09_06_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 7 *** /////////////////////////////////////////
        // Action 7-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_01_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_01_saturate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 7-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_02_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_02_saturate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 7-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_03_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_03_saturate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 7-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_04_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_04_saturate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 7-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_05_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_05_saturate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

        // Action 7-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_06_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_10_06_saturate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["psychedelic"]
        },

*/

    ];


    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);


    audioActions: Array<CSM.AudioAction> = [
        // 1 Tetris
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2, 3, 4, 5, 6]}
            ],
            alternativeSounds: [
                {
                    sample: "../../../impactsounds/Impro/SheetMetalHangarratscheabwaerts_fadeout",
                    midiRange: [50, 65],
                    dynamicRange: [0.6, 1.2]
                },
                {
                    sample: "../../../impactsounds/Impro/SheetMetalHangarratscheaufwaerts_fadeout",
                    midiRange: [50, 65],
                    dynamicRange: [0.6, 1.2]
                },
                {
                    sample: "../../../impactsounds/Impro/SheetMetalHangerratscheabwaerts2_fadeout",
                    midiRange: [50, 65],
                    dynamicRange: [0.6, 1.2]
                }
            ],
            allowedInAudioEnvironments: [6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        }
    ];


    audioHierarchy = [
        {
            description: "Catch All",
            actionGroups: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            combiGroups: [0, 1, 2, 3, 4, 5, 6, 7]                        // Bei mehreren Combigroups haben die früher genannten Priorität
        }
    ]


}