import * as CSM from "../../../ClickStateManager.js";

export default class MusikerSpielelement_CarolMcGonnell extends CSM.ClickStateManager {

    videoSize = { x: 1080, y: 1080 };               // width and height of the original video material
    clickAreaPosition = { x: 140, y: 280 };        // position of Object in original Video in px
    clickAreaSize = { x: 525, y: 730 };           // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;   //Form of the clickable Area
    mediaPfad = "/spielelemente/musiker";


    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

        this.lokalerEskalationswert = this.clickCount / 10;
        if (this.lokalerEskalationswert > 1) {this.lokalerEskalationswert = 1;}

        this.findPossibleNextActions();

        // Sonderregeln

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Init und Exit",                 elements: 2,    probability: 0,     krassitaet: 1},
        {name: "1 Hüpfen",                        elements: 2,    probability: 0.7,   krassitaet: 1},
        {name: "2 Rotieren",                      elements: 2,    probability: 0.7,   krassitaet: 1},
        {name: "3 Video in Tranchen",             elements: 9,    probability: 0.7,   krassitaet: 2}
    ];

    combiGroups = [
//        {name: "Normalgröße",               elements: 1,    probability: 0.3,   krassitaet: 1,  addableToGroups: [1, 2, 3],         incompatibleCombiGroups: [1, 2]},
    ];


    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "CarolMcGonnell_Spring_frame0261"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "CarolMcGonnell_Spring_frame0261"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "carolmcgonnell_cssanim_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "carolmcgonnell_cssanim_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "carolmcgonnell_cssanim_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "carolmcgonnell_cssanim_01_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "carolmcgonnell_cssanim_01_brightness"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2300,
                    followAction: {group: 1, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]}
            ]
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "carolmcgonnell_cssanim_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "carolmcgonnell_cssanim_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "carolmcgonnell_cssanim_02_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "carolmcgonnell_cssanim_02_rotate"
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "carolmcgonnell_cssanim_03_01_rotate3d"     // Ist eigentlich rotate im "falschen" Div
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1, 2]},
                {group: 2, actions: [1, 2, 3, 4]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6, 7, 8, 9]}
            ]
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "carolmcgonnell_cssanim_03_02_rotate3d"     // Ist eigentlich rotate im "falschen" Div
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2]},
                {group: 2, actions: [1, 2, 3, 4]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6, 7, 8, 9]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "CarolMcGonnell_Spring",
                    startTime:  8000 + (20 * 33.33),
                    endTime:    11000 + (16 * 33.33),
                    loop: false
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2]},
                {group: 2, actions: [1, 2, 3, 4]},
                {group: 3, actions: [2, 9]}
            ]
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "CarolMcGonnell_Spring",
                    startTime:  11000 + (16 * 33.33),
                    endTime:    8000 + (20 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [1]}
            ]
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "CarolMcGonnell_Spring",
                    startTime:  8000 + (20 * 33.33),
                    endTime:    10000 + (10 * 33.33),
                    loop: false
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2]},
                {group: 2, actions: [1, 2, 3, 4]},
                {group: 3, actions: [2, 9]}
            ]
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "CarolMcGonnell_Spring",
                    startTime:  10000 + (10 * 33.33),
                    endTime:    10000 + (0 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [3, 5]}
            ]
        },

        // Action 3-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "CarolMcGonnell_Spring",
                    startTime:  10000 + (0 * 33.33),
                    endTime:    10000 + (10 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [4]}
            ]
        },

        // Action 3-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "CarolMcGonnell_Spring",
                    startTime:  10000 + (0 * 33.33),
                    endTime:    9000 + (6 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "carolmcgonnell_cssanim_04_01_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [4]}
            ]
        },

        // Action 3-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "CarolMcGonnell_Spring",
                    startTime:  9000 + (6 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "carolmcgonnell_cssanim_04_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [6]}
            ]
        },

        // Action 3-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "CarolMcGonnell_Spring",
                    startTime:  8000 + (20 * 33.33),
                    playBackRate: -1,
                    loop: false
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [2, 9]}
            ]
        },

        // Action 3-9
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "CarolMcGonnell_Spring",
                    startTime:  0,
                    endTime:    8000 + (20 * 33.33),
                    playBackRate: 1,
                    loop: false
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 3, actions: [7, 8]}
            ]
        },

    ];

    combiClickActions = [];

    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);


    audioActions: Array<CSM.AudioAction> = [
        // 1 Spring-Schnipsel für Videos
        {
            possibleClickActions: [
                {group: 3, actions: [1, 2, 3, 4, 5, 6, 7, 8, 9]}
            ],
            alternativeSounds: [
                {
                    sample: "spring1",
                    midiRange: [60, 64],
                    dynamicRange: [1, 1],
                    addToInfinitelyPlayingPlayers: true
                },
                {
                    sample: "spring2",
                    midiRange: [60, 64],
                    dynamicRange: [1, 1],
                    addToInfinitelyPlayingPlayers: true
                },
                {
                    sample: "spring3",
                    midiRange: [60, 64],
                    dynamicRange: [1, 1],
                    addToInfinitelyPlayingPlayers: true
                },
                {
                    sample: "spring4",
                    midiRange: [60, 64],
                    dynamicRange: [1, 1],
                    addToInfinitelyPlayingPlayers: true
                }
            ],
            allowedInEnvironments: [16, 17],
            killSamples: ["spring1", "spring2", "spring3", "spring4"]
        },

        // 2 Spring-Schnipsel für Drehungen
        {
            possibleClickActions: [
                {group: 2, actions: [1, 2]}
            ],
            alternativeSounds: [
                {
                    sample: "spring5",
                    midiRange: [57, 63],
                    dynamicRange: [0.5, 0.5],
                },
                {
                    sample: "spring6",
                    midiRange: [57, 63],
                    dynamicRange: [0.5, 0.5],
                    fadeOut: 200,
                    endTime: 800
                },
                {
                    sample: "spring7",
                    midiRange: [57, 63],
                    dynamicRange: [1, 1]
                }
            ],
            allowedInEnvironments: [16, 17, 18]
        },

        // 3 Normales Anschlaggeräusch für spätere Environments
        {
            possibleClickActions: [
                {group: 2, actions: [1, 2]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6, 7, 8, 9]}
            ],
            alternativeSounds: [
                {
                    sample: "../../vase/audio/VasenPling24",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling25",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling26",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling27",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling28",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling29",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling30",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling31",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling32",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling33",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling34",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                },
                {
                    sample: "../../vase/audio/VasenPling35",
                    midiRange: [45, 65],
                    dynamicRange: [0.8, 0.8],
                }
            ],
            allowedInEnvironments: [18, 19]
        },

        // 4 Impros für spätere Environments
        {
            possibleClickActions: [
                {group: 2, actions: [1, 2]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6, 7, 8, 9]}
            ],
            alternativeSounds: [
                {
                    sample: "carolimpro1",
                    midiRange: [55, 60],
                    dynamicRange: [0.4, 0.4],
                    endTime: 1500
                },
                {
                    sample: "carolimpro2",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4],
                },
                {
                    sample: "carolimpro3",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4],
                },
                {
                    sample: "carolimpro4",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4],
                },
                {
                    sample: "carolimpro5",
                    midiRange: [60, 70],
                    dynamicRange: [0.4, 0.4],
                    endTime: 1500
                },
                {
                    sample: "carolimpro5",
                    midiRange: [60, 70],
                    dynamicRange: [0.4, 0.4],
                    startTime: 1500
                },
                {
                    sample: "carolimpro6",
                    midiRange: [60, 70],
                    dynamicRange: [0.4, 0.4],
                },
                {
                    sample: "carolimpro7",
                    midiRange: [60, 70],
                    dynamicRange: [0.4, 0.4],
                },
                {
                    sample: "carolimpro8",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4],
                },
            ],
            allowedInEnvironments: [19]
        },

    ]

    audioHierarchy = [
        {
            description: "Catch All",
            actionGroups: [0, 1, 2, 3],
            combiGroups: []                        // Bei mehreren Combigroups haben die früher genannten Priorität
        }
    ]



}