import * as CSM from "../../../ClickStateManager.js";

export default class Snake extends CSM.ClickStateManager {

    dimensionsDefault: CSM.dimensions = {
        size: 40,                                   //object size in percent of screenwidth
        position: { x: 5, y: 20 },                 //top left position of video object in percent of screen width and screen height
        videoSize: { x: 2048, y: 1024 },            //width and height of the original video material
        clickAreaPosition: { x: 700, y: 200 },      //position of Object in original Video in px
        clickAreaSize: { x: 700, y: 700 }           //Size of the clickable Form in the original video in px, radius for ellipses
    }

    dimensionsMobile: CSM.dimensions = {
        size: 40,                                   //object size in percent of screenwidth
        position: { x: 55, y: 28 },                 //top left position of video object in percent of screen width and screen height
        videoSize: { x: 2048, y: 1024 },            //width and height of the original video material
        clickAreaPosition: { x: 700, y: 200 },      //position of Object in original Video in px
        clickAreaSize: { x: 700, y: 700 }           //Size of the clickable Form in the original video in px, radius for ellipses
    }

    clickAreaForm = CSM.ClickAreaForm.Circle;   //Form of the clickable Area
    mediaPfad = "/spielelemente/oelfass"


    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        if (this.checkPrevActions(1, 2, false, true)) {
            console.log("hier")
            this.executeAction(2);
            return;
        }
        if (this.checkPrevActions(1, 2, true, true)) {
            console.log("da")
            this.executeAction(3);
            return;
        }

    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    clickActions = [

        // Init Action
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement,
                    targetObjectSelector: "#snake",
                },
                {
                    actionType: CSM.ActionType.PlayVideo,
                    mediaTitle: "OilBarrel_02_Snake",
                    playBackRate: 0.25,
                    followAction: {group: 0, action: 1},
                    loop: false
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement,
                    targetObjectSelector: "#snake"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeVideoProperties,
                    mediaTitle: "OilBarrel_02_Snake",
                    playBackRate: 3,
                    loop: false
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeVideoProperties,
                    mediaTitle: "snake",
                    playBackRate: 0.5,
                    loop: false
                }
            ],
            allowHover: false,
            allowClick: true
        }

    ]
}