import * as CSM from "../../../ClickStateManager.js";

export default class Tetris extends CSM.ClickStateManager {

    videoSize = { x: 450, y: 450 };               // width and height of the original video material
    clickAreaPosition = { x: 100, y: 100 };         // position of Object in original Video in px
    clickAreaSize = { x: 250, y: 250 };             // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;   //Form of the clickable Area
    mediaPfad = "/spielelemente/tetris";

    scale: number;
    animationSpeed: number;
    videoSpeed: number;

    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.totalTetrisClickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

        this.lokalerEskalationswert = 0.8;
        
        this.findPossibleNextActions();

        // Sonderregeln
        // ---

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();        

    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    randomX() {return "translateX(" + (10 + Math.floor(Math.random()*80)) + "vw)";}
    randomStartTime() {return Math.floor(Math.random()*19000);}
    randomVideoSpeed() {
        this.scale = 0.1 + Math.random() * 0.2;
        //console.log("Hier erzeug ich dat Skaling: " + this.scale);
        this.animationSpeed = 600 / this.scale;
        this.videoSpeed = 0.2 / this.scale;
        return this.videoSpeed;
    }
    randomAnimationSpeed() {return Math.floor(this.animationSpeed) + "ms";}
    randomScale() {return "scale(" + this.scale + ")";}

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Init und Exit",                 elements: 2,    probability: 0,     krassitaet: 1},
        {name: "1 Horizontal verschieben",        elements: 3,    probability: 0.7,   krassitaet: 1},
        {name: "2 Bewegungsrichtung umkehren",    elements: 1,    probability: 0.7,   krassitaet: 1},
        {name: "3 Ausfaden",                      elements: 1,    probability: 0.7,   krassitaet: 1}
    ];

    combiGroups = [
        {name: "Drehungen",     elements: 1,    probability: 0.3,   krassitaet: 1,  addableToGroups: [1, 2], incompatibleCombiGroups: []},
        {name: "Skew",          elements: 1,    probability: 0.3,   krassitaet: 1,  addableToGroups: [1, 2], incompatibleCombiGroups: []},
    ];


    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tetris_01",
                    startTimeFunction: "this.randomStartTime()",
                    loop: true,
                    playBackRateFunction: "this.randomVideoSpeed()"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    newProperties: [{property: "transform", value: "this.randomX()"}]
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tetris_cssanim_01_absolutePositionY",
                    newProperties: [{property: "animationDuration", value: "this.randomAnimationSpeed()"}]
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absoluteScale",
                    newProperties: [{property: "transform", value: "this.randomScale()"}]
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tetris_cssanim_02_01_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfTransformIs: "translateX(0vw)"
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tetris_cssanim_02_02_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfTransformIs: "translateX(-30vw)"
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tetris_cssanim_02_03_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfTransformIs: "translateX(30vw)"
        },

        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionY",
                    playBackRateCommand: "reverse"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tetris_cssanim_05_relativeScale"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 5000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        }

    ];

    combiClickActions: Array<CSM.ClickAction> = [
        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tetris_cssanim_03_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tetris_cssanim_04_01_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },        

    ];


    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);



    audioActions: Array<CSM.AudioAction> = [
        // 1 Anschlaggeräusch
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2, 3]},
                {group: 2, actions: [1]}
            ],
            alternativeSounds: [
                {
                    sample: "../../vase/audio/VasenPling24",
                    midiRange: [45, 65],
                    dynamicRange: [1.2, 1.2],
                },
                {
                    sample: "../../vase/audio/VasenPling25",
                    midiRange: [45, 65],
                    dynamicRange: [1.2, 1.2],
                },
                {
                    sample: "../../vase/audio/VasenPling26",
                    midiRange: [45, 65],
                    dynamicRange: [1.2, 1.2],
                },
                {
                    sample: "../../vase/audio/VasenPling27",
                    midiRange: [45, 65],
                    dynamicRange: [1.2, 1.2],
                },
                {
                    sample: "../../vase/audio/VasenPling28",
                    midiRange: [45, 65],
                    dynamicRange: [1.2, 1.2],
                },
                {
                    sample: "../../vase/audio/VasenPling29",
                    midiRange: [45, 65],
                    dynamicRange: [1.2, 1.2],
                },
                {
                    sample: "../../vase/audio/VasenPling30",
                    midiRange: [45, 65],
                    dynamicRange: [1.2, 1.2],
                },
                {
                    sample: "../../vase/audio/VasenPling31",
                    midiRange: [45, 65],
                    dynamicRange: [1.2, 1.2],
                },
                {
                    sample: "../../vase/audio/VasenPling32",
                    midiRange: [45, 65],
                    dynamicRange: [1.2, 1.2],
                },
                {
                    sample: "../../vase/audio/VasenPling33",
                    midiRange: [45, 65],
                    dynamicRange: [1.2, 1.2],
                },
                {
                    sample: "../../vase/audio/VasenPling34",
                    midiRange: [45, 65],
                    dynamicRange: [1.2, 1.2],
                },
                {
                    sample: "../../vase/audio/VasenPling35",
                    midiRange: [45, 65],
                    dynamicRange: [1.2, 1.2],
                }
            ],
            allowedInEnvironments: [19]
        },        
    ]

    audioHierarchy = [
        {
            description: "Catch All",
            actionGroups: [1, 2],
            combiGroups: []                        // Bei mehreren Combigroups haben die früher genannten Priorität
        }
    ]


}