import Mediator from "./Mediator.js";

type SwipeHandler = (event: TouchEvent, mediator: Mediator) => void;

interface SwipeEventListeners {
    swipeUp?: SwipeHandler;
    swipeDown?: SwipeHandler;
    swipeLeft?: SwipeHandler;
    swipeRight?: SwipeHandler;
    swipeUpLeft?: SwipeHandler;
    swipeUpRight?: SwipeHandler;
    swipeDownLeft?: SwipeHandler;
    swipeDownRight?: SwipeHandler;
}

interface StartTouch {
    x: number;
    y: number;
    time: number;
}

const SWIPE_THRESHOLD = 30; // Minimum distance (px) for a swipe
const SWIPE_TIME_THRESHOLD = 500; // Maximum time (ms) for a swipe

class SwipeEvents {
    private startTouch: StartTouch | null = null;
    private listeners: SwipeEventListeners;
    mediator: Mediator;

    constructor(element: HTMLElement, listeners: SwipeEventListeners, mediator: Mediator) {
        this.listeners = listeners;
        this.mediator = mediator;
        element.addEventListener('touchstart', this.handleTouchStart.bind(this), { passive: true });
        element.addEventListener('touchend', this.handleTouchEnd.bind(this), { passive: true });
    }

    private handleTouchStart(event: TouchEvent): void {
        const touch = event.touches[0];
        this.startTouch = {
            x: touch.clientX,
            y: touch.clientY,
            time: Date.now()
        };
    }

    private handleTouchEnd(event: TouchEvent): void {
        if (!this.startTouch) return;

        const touch = event.changedTouches[0];
        const dx = touch.clientX - this.startTouch.x;
        const dy = touch.clientY - this.startTouch.y;
        const dt = Date.now() - this.startTouch.time;

        if (dt > SWIPE_TIME_THRESHOLD) return;

        if (Math.abs(dx) >= SWIPE_THRESHOLD || Math.abs(dy) >= SWIPE_THRESHOLD) {
            const absDx = Math.abs(dx);
            const absDy = Math.abs(dy);

            // Determine primary and secondary directions
            if (absDx > absDy * 2) {
                // Horizontal swipe
                if (dx > 0) {
                    this.listeners.swipeRight?.(event, this.mediator);
                } else {
                    this.listeners.swipeLeft?.(event, this.mediator);
                }
            } else if (absDy > absDx * 2) {
                // Vertical swipe
                if (dy > 0) {
                    this.listeners.swipeDown?.(event, this.mediator);
                } else {
                    this.listeners.swipeUp?.(event, this.mediator);
                }
            } else {
                // Diagonal swipe
                if (dx > 0 && dy > 0) {
                    this.listeners.swipeDownRight?.(event, this.mediator);
                } else if (dx > 0 && dy < 0) {
                    this.listeners.swipeUpRight?.(event, this.mediator);
                } else if (dx < 0 && dy > 0) {
                    this.listeners.swipeDownLeft?.(event, this.mediator);
                } else if (dx < 0 && dy < 0) {
                    this.listeners.swipeUpLeft?.(event, this.mediator);
                }
            }
        }

        this.startTouch = null;
    }
}

export { SwipeEvents, SwipeEventListeners };