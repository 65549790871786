import * as CSM from "./ClickStateManager.js"

const musiciansData = {
    "marius_rehmet": "musikerinfo_mariusrehmet",
    "kerrin_banz": "musikerinfo_kerrinbanz",
    "wojciech_garbowski": "musikerinfo_wojciechgarbowski",
    "titus_engel": "musikerinfo_titusengel",
    "igor_spallati": "musikerinfo_igorspallati",
    "victor_guaita": "musikerinfo_victorguaita",
    "carol_mcgonnell": "musikerinfo_carolmcgonnell",
    "olivia_palmer-baker": "musikerinfo_oliviapalmerbaker",
    "simone_beneventi": "musikerinfo_simonebeneventi",
    "chris_grammel": "musikerinfo_chrisgrammel",
    "ermis_theodorakis": "musikerinfo_ermistheodorakis",
    "nathan_watts": "musikerinfo_nathanwatts",
    "samuel_stoll": "musikerinfo_samuelstoll",
    //"martin_offik": "musikerinfo_martinoffik",
    "martin_gruetter": "musikerinfo_martingruetter",
    "robert_laessig": "musikerinfo_robertlaessig",
    "lukas_gothszalk": "musikerinfo_lukasgothszalk",
    "daniel_seroussi": "musikerinfo_danielseroussi",
    "dan_desimone": "musikerinfo_dandesimone",
    "mathilde_conley": "musikerinfo_mathildeconley",
    "will_overcash": "musikerinfo_willovercash",
    "johannes_lauer": "musikerinfo_johanneslauer",
    "horia_dumitrache": "musikerinfo_horiadumitrache",
    "sascha_friedl": "musikerinfo_saschafriedl",
    "benjamin_fischer": "musikerinfo_benjaminfischer"
};
export default musiciansData;