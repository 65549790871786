import * as CSM from "../../../ClickStateManager.js";

export default class SafariKugel extends CSM.ClickStateManager {

    videoSize = { x: 234, y: 233 };               // width and height of the original video material
    clickAreaPosition = { x: 0, y: 0 };        // position of Object in original Video in px
    clickAreaSize = { x: 234, y: 233 };            // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Circle;   //Form of the clickable Area
    mediaPfad = "/spielelemente/warnhinweise"

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.executeAction(1);
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "Init und Exit",         elements: 3,    probability: 0,     krassitaet: 1}
    ];

    clickActions = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "browser"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "safarikugel_cssanim_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "safarikugel_cssanim_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "safarikugel_cssanim_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "safarikugel_cssanim_01_opacity"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "safarikugel_cssanim_01_rotate"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 5000,
                    followAction: {group: 0, action: 3}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }                    
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ActivateObject,
                    targetObject: "warnhinweis_safari"
                }
            ],
            allowHover: false,
            allowClick: true
        }


    ]


}