import { VideoDiv } from "../../../TextDiv.js";
import * as CSM from "../../../ClickStateManager.js";

export default class Videofenster_VeitstanzMusikvideo extends VideoDiv {
    title = "Martin Grütter & Aron Kitzig: Veitstanz";
    videoURI = "90TXcPZUqZE";

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Catch All",             elements: 2,    probability: 0,     krassitaet: 1, allowedInEnvironments: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
    ];

    combiGroups = [];

    clickActions = [

        // Action 0-1 - Init Action
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement,
                    targetObjectSelector: "#videofenster_veitstanzmusikvideo"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_03_textdiv"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement,
                    targetObjectSelector: "#videofenster_veitstanzmusikvideo"
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ]

}