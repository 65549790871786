import TextDivGalerie from "./TextDivGalerie.js";
import * as CSM from "../../../ClickStateManager.js";

export default class Textfenster_Galerie extends TextDivGalerie {

    textURI = "Textfenster/Textfenster_Galerie.html";
    title = "SCHWELBRAND – Galerie";
    width = 100;                                   //object size in percent of screenwidth
    height = 100;
    position = { x: 0, y: 0 };                  //top left position of video object in percent of screen width and screen height

    images = [
        {filename: "media/produktionen/2023 stagereconquista/img/00 _O1A3428-LoRes-WEB.webp"},
        {filename: "media/produktionen/2023 stagereconquista/img/02 _O1A3487-LoRes-WEB.webp"},
        {filename: "media/produktionen/2023 stagereconquista/img/03 _O1A3468-LoRes-WEB.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/veitstanz5.webp"},
        {filename: "media/produktionen/2019 aseven/img/01 _DSC6227.webp"},
        {filename: "media/produktionen/2019 aseven/img/08 _DSC6248.webp"},
        {filename: "media/produktionen/2019 aseven/img/09 _DSC6185.webp"},
        {filename: "media/produktionen/2019 aseven/img/10 _DSC6157.webp"},
        {filename: "media/produktionen/2021 farnbluete-video/img/frame-001149_MG.webp"},
        {filename: "media/produktionen/2023 stagereconquista/img/04 _O1A3616-LoRes-WEB.webp"},
        {filename: "media/produktionen/2023 stagereconquista/img/05 _O1A3610-LoRes-WEB.webp"},
        {filename: "media/produktionen/2023 stagereconquista/img/06 _O1A3408-LoRes-WEB.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/samuel.webp"},
        {filename: "media/produktionen/2023 stagereconquista/img/07 _O1A3414-LoRes-WEB.webp"},
        {filename: "media/produktionen/2023 stagereconquista/img/08 _O1A3416-LoRes-WEB.webp"},
        {filename: "media/produktionen/2019 aseven/img/15 _DSC6027.webp"},
        {filename: "media/produktionen/2019 aseven/img/18 _DSC6141.webp"},
        {filename: "media/produktionen/2019 aseven/img/19 _DSC6314.webp"},
        {filename: "media/produktionen/2019 aseven/img/20 _DSC6135.webp"},
        {filename: "media/produktionen/2023 stagereconquista/img/11 _O1A3482-LoRes-WEB.webp"},
        {filename: "media/produktionen/2019 aseven/img/16 _DSC6112.webp"},
        {filename: "media/produktionen/2019 aseven/img/17 _DSC6084.webp"},
        {filename: "media/produktionen/2023 stagereconquista/img/01 _O1A3588-LoRes-WEB.webp"},
        {filename: "media/produktionen/2019 aseven/img/32 _DSC6054.webp"},
        {filename: "media/produktionen/2019 aseven/img/33 _DSC6146.webp"},
        {filename: "media/produktionen/2019 aseven/img/34 _DSC6096.webp"},
        {filename: "media/produktionen/2021 farnbluete-video/img/frame-001040_MG.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/martin.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/matthew.webp"},
        {filename: "media/produktionen/2023 stagereconquista/img/12 _O1A3377-LoRes-WEB.webp"},
        {filename: "media/produktionen/2023 stagereconquista/img/13 _O1A3275-LoRes-WEB.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/veitstanz.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/veitstanz2.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/veitstanz3.webp"},
        {filename: "media/produktionen/2019 aseven/img/21 _DSC6322.webp"},
        {filename: "media/produktionen/2019 aseven/img/22 _DSC6293.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/ermis.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/horia.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/igor.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/johanneshaase.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/johanneslauer.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/victor.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/will.webp"},
        {filename: "media/produktionen/2019 aseven/img/02 _DSC6254.webp"},
        {filename: "media/produktionen/2019 aseven/img/03 _DSC6202.webp"},
        {filename: "media/produktionen/2019 aseven/img/04 _DSC6212.webp"},
        {filename: "media/produktionen/2021 farnbluete-video/img/frame-001741_MG.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/veitstanz7.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/veitstanz8.webp"},
        {filename: "media/produktionen/2019 aseven/img/05 _DSC6172.webp"},
        {filename: "media/produktionen/2019 aseven/img/06 _DSC6288.webp"},
        {filename: "media/produktionen/2019 aseven/img/07 _DSC6307.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/kerrin.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/lukas.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/daniel.webp"},
        {filename: "media/produktionen/2021 farnbluete-video/img/frame-1002712_MG.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/benjamin.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/carol.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/michael.webp"},
        {filename: "media/produktionen/2019 aseven/img/11 _DSC6283.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/veitstanz6.webp"},
        {filename: "media/produktionen/2019 aseven/img/31 _DSC6126.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/olivia.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/sascha.webp"},
        {filename: "media/produktionen/2019 aseven/img/23 _DSC6088.webp"},
        {filename: "media/produktionen/2019 aseven/img/24 _DSC6124.webp"},
        {filename: "media/produktionen/2019 aseven/img/25 _DSC6127.webp"},
        {filename: "media/produktionen/2019 aseven/img/26 _DSC6117.webp"},
        {filename: "media/produktionen/2021 farnbluete-video/img/frame-1000044_MG.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/dina.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/titus.webp"},
        {filename: "media/produktionen/2019 aseven/img/27 _DSC6047.webp"},
        {filename: "media/produktionen/2019 aseven/img/28 _DSC6207.webp"},
        {filename: "media/produktionen/2019 aseven/img/29 _DSC6258.webp"},
        {filename: "media/produktionen/2019 aseven/img/30 _DSC5985.webp"},
        {filename: "media/produktionen/2023 stagereconquista/img/09 _O1A3509-LoRes-WEB.webp"},
        {filename: "media/produktionen/2023 stagereconquista/img/10 _O1A3635-LoRes-WEB.webp"},
        {filename: "media/produktionen/2021 veitstanz-video/img/veitstanz4.webp"},
        {filename: "media/produktionen/2021 farnbluete-video/img/frame-1001276_MG.webp"},
        {filename: "media/produktionen/2019 aseven/img/12 _DSC6296.webp"},
        {filename: "media/produktionen/2019 aseven/img/13 _DSC6313.webp"},
        {filename: "media/produktionen/2019 aseven/img/14 _DSC6075.webp"},
        {filename: "media/produktionen/2020 stroboskop/img/original schwarz/dan.webp"},
        {filename: "media/produktionen/2019 aseven/img/35 _DSC6015.webp"},
        {filename: "media/produktionen/2019 aseven/img/36 _DSC6009.webp"},
        {filename: "media/produktionen/2019 aseven/img/37 _DSC6011.webp"},
        {filename: "media/produktionen/2019 aseven/img/38 _DSC6024.webp"},
        {filename: "media/produktionen/2019 aseven/img/39 _DSC6002.webp"}
    ]


    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Catch All",             elements: 3,    probability: 0,     krassitaet: 1, allowedInEnvironments: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
    ];

    combiGroups = [];

    clickActions = [

        // Action 0-1 - Init Action
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement,
                    targetObjectSelector: "#textfenster_galerie"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_01_textdiv"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_04_textdiv"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 150,
                    followAction: {group: 0, action: 3}
                }                
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement,
                    targetObjectSelector: "#textfenster_galerie"
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ]

}