import * as CSM from "../../../ClickStateManager.js";

export default class MusikerSpielelement_LukasGothszalk extends CSM.ClickStateManager {

    videoSize = { x: 4096, y: 3112 };               // width and height of the original video material
    clickAreaPosition = { x: 1470, y: 750 };        // position of Object in original Video in px
    clickAreaSize = { x: 1180, y: 1810 };           // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;   //Form of the clickable Area
    mediaPfad = "/spielelemente/musiker";
    performResetActions = false;

    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

        //this.executeAction(this.findActionNumber(7, this.clickCount)); return;
        /*if (this.clickCount == 1) {this.executeAction(this.findActionNumber(5, 2)); return;}
        if (this.clickCount == 2) {this.executeAction(this.findActionNumber(7, 2)); return;}
        if (this.clickCount == 3) {this.executeAction(this.findActionNumber(7, 2)); return;}
        if (this.clickCount == 4) {this.executeAction(this.findActionNumber(7, 3)); return;}
        if (this.clickCount == 5) {this.executeAction(this.findActionNumber(7, 4)); return;}
        if (this.clickCount == 6) {this.executeAction(this.findActionNumber(7, 5)); return;}
        if (this.clickCount == 7) {this.executeAction(this.findActionNumber(7, 6)); return;}
        if (this.clickCount == 8) {this.executeAction(this.findActionNumber(7, 7)); return;}
        return;*/

        this.lokalerEskalationswert = this.clickCount / 15;
        if (this.lokalerEskalationswert > 1) {this.lokalerEskalationswert = 1;}
    
        this.findPossibleNextActions();

        // Sonderregeln

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Init und Exit",                 elements: 2,    probability: 0,     krassitaet: 1},
        {name: "1 Videospielen (INAKTIV)",        elements: 7,    probability: 0,     krassitaet: 1},
        {name: "2 Silhouette (INAKTIV)",          elements: 3,    probability: 0,     krassitaet: 1},
        {name: "3 Vom Himmel fallen",             elements: 3,    probability: 0,     krassitaet: 1},
        {name: "4 ZackZack Größenveränderungen (INAKTIV)",  elements: 6,    probability: 0,     krassitaet: 1},
        {name: "5 Erstes Video spielen",          elements: 4,    probability: 0.7,   krassitaet: 1},
        {name: "6 Rotieren",                      elements: 4,    probability: 0.7,   krassitaet: 1},
        {name: "7 Standbilder",                   elements: 20,   probability: 0.2,   krassitaet: 3},
        {name: "8 Wegflitzen",                    elements: 2,    probability: 0.2,   krassitaet: 3},
        {name: "9 Springen",                      elements: 4,    probability: 0.7,   krassitaet: 2},
        {name: "10 Andere Geschwindigkeiten Follow", elements: 4, probability: 0,     krassitaet: 3}
    ];

    combiGroups = [
        {name: "0 Farbeffekte",                   elements: 8,    probability: 0.7,   krassitaet: 2,  addableToGroups: [5, 6, 7, 8, 9],  incompatibleCombiGroups: []},
        {name: "1 Rotieren",                      elements: 4,    probability: 0.7,   krassitaet: 1,  addableToGroups: [7, 9],           incompatibleCombiGroups: []},
        {name: "2 ZackZack Größenveränderungen",  elements: 6,    probability: 0.7,   krassitaet: 2,  addableToGroups: [5, 6, 7, 8, 9],  incompatibleCombiGroups: []},
        {name: "3 Andere Geschwindigkeiten",      elements: 4,    probability: 0.7,   krassitaet: 3,  addableToGroups: [5, 6, 7, 9],  incompatibleCombiGroups: []},
    
    ];


    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_02_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_01a"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "LukasGothszalk_Veitstanz_02",
                    startTime: 2000 + (23 * 33.33),
                    endTime:   3000 + (7 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [7]},
                {group: 2, actions: [1, 3]}
            ]
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "LukasGothszalk_Veitstanz_02",
                    startTime: 3000 + (17 * 33.33),
                    endTime:   4000 + (18 * 33.33),
                    playBackRate: 1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [1]}
            ]
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "LukasGothszalk_Veitstanz_02",
                    startTime: 5000 + (2 * 33.33),
                    endTime:   5000 + (17 * 33.33),
                    playBackRate: 1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2]}
            ]
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "LukasGothszalk_Veitstanz_02",
                    startTime: 5000 + (2 * 33.33),
                    endTime:   7000 + (2 * 33.33),
                    playBackRate: 2
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2]}
            ]
        },

        // Action 1-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "LukasGothszalk_Veitstanz_02",
                    startTime: 5000 + (17 * 33.33),
                    endTime:   7000 + (2 * 33.33),
                    playBackRate: 1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [3]}
            ]
        },

        // Action 1-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "LukasGothszalk_Veitstanz_02",
                    startTime: 7000 + (2 * 33.33),
                    endTime:   12000 + (24 * 33.33),
                    playBackRate: 5
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [4, 5]}
            ]
        },

        // Action 1-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "LukasGothszalk_Veitstanz_02",
                    startTime: 12000 + (24 * 33.33),
                    endTime:   0,
                    playBackRate: -4
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [6]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_02_NurKopf"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_00_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_00_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_00_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_00_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_00_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_00_contrast"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_00a"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    startAnimation: false,
                    playBackRate: -1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [1, 3]}
            ]
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_02_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    startAnimation: false,
                    playBackRate: 1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [2]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_01_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_02_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_02_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_02_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_02_contrast"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_02_opacity"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_01_clickArea"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2400,
                    followAction: {group: 3, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_03_01_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_03_02_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 4 *** ///////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_04_01_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_04_02_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_04_03_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_04_04_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 4-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_04_05_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 4-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_04_06_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 5 *** ///////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "LukasGothszalk_Veitstanz_01",
                    startTime: 0,
                    endTime:   0 + (12 * 40),
                    playBackRate: 1.5
                }/*,
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_02_clickArea"
                }*/
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["LukasGothszalk_Veitstanz_01_frame0001"]
        },

        // Action 5-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "LukasGothszalk_Veitstanz_01",
                    startTime: 0,
                    endTime:   0 + (25 * 40),
                    playBackRate: 1.5
                }/*,
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_02_clickArea"
                }*/
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["LukasGothszalk_Veitstanz_01_frame0001"]
        },

        // Action 5-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "LukasGothszalk_Veitstanz_01",
                    startTime: 0 + (25 * 40),
                    endTime:   0,
                    playBackRate: -1.5
                }/*,
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_02_clickArea"
                }*/
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["LukasGothszalk_Veitstanz_01_frame0025"]
        },

        // Action 5-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "LukasGothszalk_Veitstanz_01",
                    startTime: 0 + (12 * 40),
                    endTime:   0,
                    playBackRate: -1.5
                }/*,
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_02_clickArea"
                }*/
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["LukasGothszalk_Veitstanz_01_frame0012"]
        },

        /////////////////////////////////////////// *** GRUPPE 6 *** ///////////////////////////////////////////
        // Action 6-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_06_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 5, actions: [1]},
                {group: 6, actions: [2]},
            ]
        },

        // Action 6-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_06_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1]},
            ]
        },


        // Action 6-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_06_03_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1, 2, 3, 4]},
            ]
        },


        // Action 6-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_06_04_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1, 2, 3, 4]},
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 7 *** ///////////////////////////////////////////
        // Action 7-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_01_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_01_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF"
            ]
        },

        // Action 7-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_01_frame0104"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_03_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF"
            ]
        },

        // Action 7-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_02_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_04_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF",
                "LukasGothszalk_Veitstanz_02_frame0293"
            ]
        },

        // Action 7-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_02_frame0081"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_05_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF",
                "LukasGothszalk_Veitstanz_02_frame0268",
                "LukasGothszalk_Veitstanz_02_frame0321",
                "LukasGothszalk_Veitstanz_02_frame0325"
            ]
        },

        // Action 7-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_02_frame0215"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_06_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF"
            ]
        },

        // Action 7-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_02_frame0254"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_07_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF",
                "LukasGothszalk_Veitstanz_02_frame0215"
            ]
        },

        // Action 7-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_02_frame0268"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_08_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF",
                "LukasGothszalk_Veitstanz_02_frame0081",
                "LukasGothszalk_Veitstanz_02_frame0317"
            ]
        },

        // Action 7-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_02_frame0293"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_09_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF",
                "LukasGothszalk_Veitstanz_02_frame0001"
            ]
        },

        // Action 7-9
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_02_frame0311"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_10_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF"
            ]
        },

        // Action 7-10
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_02_frame0317"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_11_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF",
                "LukasGothszalk_Veitstanz_02_frame0268"
            ]
        },

        // Action 7-11
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_02_frame0321"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_12_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF",
                "LukasGothszalk_Veitstanz_02_frame0081",
                "LukasGothszalk_Veitstanz_02_frame0325"
            ]
        },

        // Action 7-12
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Veitstanz_02_frame0325"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_13_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF",
                "LukasGothszalk_Veitstanz_02_frame0081",
                "LukasGothszalk_Veitstanz_02_frame0321"
            ]
        },

        // Action 7-13
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Standbilder_00"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_14_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF"
            ]
        },

        // Action 7-14
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Standbilder_01"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_15_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF"
            ]
        },

        // Action 7-15
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Standbilder_02"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_16_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF"
            ]
        },

        // Action 7-16
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Standbilder_03"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_17_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF"
            ]
        },

        // Action 7-17
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Standbilder_04"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_18_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF"
            ]
        },

        // Action 7-18
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Standbilder_05"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_19_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF"
            ]
        },

        // Action 7-19
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Standbilder_06"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_20_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF"
            ]
        },

        // Action 7-20
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "LukasGothszalk_Standbilder_07"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_05_21_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: [
                "SELF"
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 8 *** ///////////////////////////////////////////
        // Action 8-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_07_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1800,
                    followAction: {group: 3, action: 3}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 8-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_07_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1800,
                    followAction: {group: 3, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 9 *** ///////////////////////////////////////////
        // Action 9-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_08_01_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 9-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_08_02_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 9-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_08_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 9-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_08_04_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 10 *** ///////////////////////////////////////////
        // Action 10-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_10_02_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 10-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_10_03_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 10-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_10_04_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 10-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_10_05_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },


    ];

    combiClickActions = [
        ///////////////////////////////////////// *** COMBIGROUP 0 *** /////////////////////////////////////////
        // Action 0-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_01_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_01_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_02_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_01_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_03_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_01_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_04_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_01_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_05_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_01_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_06_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_01_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_07_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_01_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_08_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_09_01_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        
        ///////////////////////////////////////// *** COMBIGROUP 1 *** /////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_06_01_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_06_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_06_03_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_06_04_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 2 *** /////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_04_01_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_04_02_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_04_03_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_04_04_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_04_05_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_04_06_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 3 *** /////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_10_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 150,
                    followAction: {group: 10, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_10_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 150,
                    followAction: {group: 10, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_10_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 150,
                    followAction: {group: 10, action: 3}
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "lukasgothszalk_cssanim_10_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 150,
                    followAction: {group: 10, action: 4}
                }
            ],
            allowHover: false,
            allowClick: true
        },



    ];


    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);


    audioActions: Array<CSM.AudioAction> = [
        // 1 Normale Bewegungen
        {
            possibleClickActions: [
                //{group: 1, actions: [1]},
                {group: 5, actions: [1, 2, 3, 4]},
                {group: 6, actions: [1, 2, 3, 4]},
                {group: 7, actions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]},
                {group: 8, actions: [1, 2]}
            ],
            alternativeSounds: [
                {
                    sample: "lukasimpro1",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro2",
                    midiRange: [60, 72],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro3",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro4",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro5",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro6",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro7",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro8",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro9",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro10",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro11",
                    midiRange: [55, 65],
                    dynamicRange: [1, 1],
                }
            ]
        },   

        // 2 Springen
        {
            possibleClickActions: [
                {group: 9, actions: [1, 2, 3, 4]}
            ],
            alternativeSounds: [
                {
                    sample: "lukasimpro12",
                    midiRange: [55, 62],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro13",
                    midiRange: [55, 62],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro14",
                    midiRange: [60, 72],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro15",
                    midiRange: [55, 62],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro16",
                    midiRange: [55, 62],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "lukasimpro17",
                    midiRange: [55, 62],
                    dynamicRange: [1, 1],
                }
            ]
        },   
    ]

    audioHierarchy = [
        {
            description: "Catch All",
            actionGroups: [5, 6, 7, 8, 9],
            combiGroups: []                        // Bei mehreren Combigroups haben die früher genannten Priorität
        }
    ]



}