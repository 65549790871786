import * as CSM from "./ClickStateManager.js";
import musician_info from "./musician_info.js";
import production from "./production.js";

export class TextDiv extends CSM.ClickStateManager {
    textURI: string;
    textURI_mobile: string;
    title: string = "";
    text: string;
    width: number;
    position: { x: number, y: number };
    internalLinks: string[] = [];
    funktionen: string[] = [];
    private activeMusician: string = "";

    public override async prepareObject(): Promise<void> {

        this.text = this.mediator.alleHTMLSeiten["html/" + this.textURI].text;
        //console.log(this.text);
        // create object div wrapper
        this.objDiv = document.createElement("div");
        this.objDiv.id = this.name;
        this.objDiv.classList.add("object", "textdiv");

        // calculate and set positions and dimensions
        let positionX = this.position.x / 100 * window.innerWidth;
        this.objDiv.style.left = positionX + "px";

        let positionY = this.position.y / 100 * window.innerHeight;
        this.objDiv.style.top = positionY + "px";

        this.displaySize.x = this.width / 100 * window.innerWidth;
        this.objDiv.style.width = this.displaySize.x + "px";

        this.displaySize.y = this.height / 100 * window.innerHeight;
        this.objDiv.style.height = this.displaySize.y + "px";

        let objectWrapper = this.bySelector(".objectWrapper");
        if (this.parentObjectID == null && objectWrapper) {
            objectWrapper.appendChild(this.objDiv);
            //            document.body.appendChild(this.objDiv);
        } else {
            let parentObj = this.byID(this.parentObjectID);
            parentObj?.appendChild(this.objDiv);
        }

        //create HTML
        this.createHTML();

        // bind() keeps the context of "this", otherwise "this" would refer to the htmlElement calling the event
        // https://stackoverflow.com/questions/44970378/es6-class-call-method-from-within-event-handler
        this.objDiv.addEventListener('click', this.onMouseDown.bind(this));
    }

    public resize() {
        // calculate and set positions and dimensions for objdiv
        let positionX = this.position.x / 100 * window.innerWidth;
        this.objDiv.style.left = positionX + "px";

        let positionY = this.position.y / 100 * window.innerHeight;
        this.objDiv.style.top = positionY + "px";

        this.displaySize.x = this.width / 100 * window.innerWidth;
        this.objDiv.style.width = this.displaySize.x + "px";

        this.displaySize.y = this.height / 100 * window.innerHeight;
        this.objDiv.style.height = this.displaySize.y + "px";
    }

    protected createHTML(): void {
        // ggf. Werte aus URL auslesen

        this.text = this.mediator.textReplacements(this.text);

        this.animationWrapper = this.buildAnimationDivTree(this.animationsbaum, "");
        this.animationWrapper.innerHTML = this.text;

        // internalLinks generieren
        let collection = this.text.split("internal_link ");
        for (let link of collection) {
            if (link.includes("_link")) {
                this.internalLinks.push(link.slice(0,link.indexOf("_link")));
            }
        }
        this.internalLinks = [...new Set(this.internalLinks)];

        collection = this.text.split("function_");
        for (let funktion of collection.slice(1)) {
            if (funktion.includes('"')) {
                this.funktionen.push(funktion.slice(0,funktion.indexOf('"')));
            }
        }
    }

    public testemich(targetAction: CSM.GroupActionContainer) {
        //console.log("Getestet: " + targetAction);
    }

    public onActivation(targetAction: CSM.GroupActionContainer) {
        //console.log("targetAction: " + targetAction);
        //console.log("activated" + this.constructor.name);
        let gruppe = 0;
        let action = 1;
        if (targetAction) {
            gruppe = targetAction.group;
            action = targetAction.action;
        }
        if (this.constructor.name.includes("MusikerInfo") || this.constructor.name.includes("Textfenster_Unterstuetzen")
            || this.constructor.name.includes("Textfenster_Galerie") || this.constructor.name.includes("Textfenster_Trailer")
            || this.constructor.name.includes("Textfenster_Erfolgsseite") || this.constructor.name.includes("Textfenster_DoubleOptInSeite")) {
            this.objDiv.style.zIndex = (this.mediator.zIndexCounter + 10000000).toString();
        } else {
            this.objDiv.style.zIndex = (this.mediator.zIndexCounter + 1000000).toString();
        }
        this.mediator.zIndexCounter++;
        this.mediator.activeObject = this;
        //console.log(this);
        //console.log(this.mediator.activeObject);
        super.prepareRelatives();
        this.addListeners();
        this.executeAction(this.findActionNumber(gruppe, action));
        let textDiv = this.bySelector("#" + this.name);
        if (textDiv) { textDiv.scrollTop = 0; }
        this.active = true;

        // Unterstützen-Button nach vorne verschieben
        this.mediator.objects["mother→childmenupunkt_unterstuetzen01"].objDiv.style.zIndex = "2000000";

        // Browser-History updaten
        let title = this.title != "" ? this.title : this.constructor.name;
        this.mediator.switchPage(this.constructor.name, title, false);

        // Unterstützen-Menupunkt ggf. anpassen
        //console.log("vorderstesobjekt: hierry");
        this.mediator.checkMenupunktUnterstuetzen();
    }

    removeHTML() {
        this.active = false;
        this.objDiv.remove();
        this.mediator.updateDocumentTitle();
        this.mediator.checkMenupunktUnterstuetzen();
    }

    // link part/html element of this object to activate another object
    protected addListeners(): void {
        this.internalLinks.forEach((objectName: string) => {
            var linkCollection = document.getElementsByClassName(objectName + "_link");
            for (let element of linkCollection) {
                element.setAttribute("style", "pointer-events: all");
                element.addEventListener("click", (e) => {
                    var startAction = { group: 0, action: 1 };
                    e.stopPropagation();                                    // prevents the click from casting through to objects behind
                    if (this.byID(objectName) == null){
                        if( this.mediator.objects[objectName] instanceof TextDiv) {
                            //console.log(objectName);
                            let object = <TextDiv>this.mediator.objects[objectName];
                            object.prepareObject().then(() => object.onActivation({group: 0, action: 1}));
                        } else if (this.byID(objectName) == null && this.mediator.objects[objectName] instanceof VideoDiv) {
                            let object = <VideoDiv>this.mediator.objects[objectName];
                            object.prepareObject().then(() => object.onActivation({group: 0, action: 1}));
                        }
                    } else {
                        this.mediator.activateObject(objectName, startAction);
                    }

                });
            };
        });
        this.funktionen.forEach((functionName: string) => {
            var functionCollection = document.getElementsByClassName("function_" + functionName);
            for (let funktion of functionCollection) {
                //console.log(funktion);
                funktion?.setAttribute("style", "pointer-events: all; cursor: pointer;");
                funktion?.addEventListener("click", (e) => {
                    e.stopPropagation();                                    // prevents the click from casting through to objects behind
                    eval("this." + functionName + "(funktion);");
                });
            }
        });
    }

    bildanim(funktion: HTMLElement) {
        const removeClass = (funktion: HTMLElement, klasse: string) => {
            funktion.classList.remove(klasse);
        }
        //console.log(funktion);
        funktion.offsetHeight;
        let klassen = ["foerderlogo_rotation_rechts", "foerderlogo_rotation_links"];
        let neueklasse = klassen[Math.floor(Math.random() * klassen.length)];
        funktion.classList.add(neueklasse);
        funktion.addEventListener("animationend", removeClass.bind(this, funktion, neueklasse), false);
    }

}


export class VideoDiv extends CSM.ClickStateManager {
    videoURI: string;
    title: string = "";
    startTime: {minutes: number, seconds: number};

    textURI: string;
    textURI_mobile: string;
    text: string;
    width: number;
    position: { x: number, y: number };
    internalLinks: string[] = [];
    funktionen: string[] = [];

    public override async prepareObject(): Promise<void> {

        // create object div wrapper
        this.objDiv = document.createElement("div");
        this.objDiv.id = this.name;
        this.objDiv.classList.add("object", "videodiv");

        // calculate and set positions and dimensions
        this.objDiv.style.left = "0px";
        this.objDiv.style.top = "0px";
        this.objDiv.style.width = window.innerWidth + "px";
        this.objDiv.style.height = window.innerHeight + "px";

        let objectWrapper = this.bySelector(".objectWrapper");
        if (this.parentObjectID == null && objectWrapper) {
            objectWrapper.appendChild(this.objDiv);
        } else {
            let parentObj = this.byID(this.parentObjectID);
            parentObj?.appendChild(this.objDiv);
        }

        //create HTML
        this.createHTML();

        // bind() keeps the context of "this", otherwise "this" would refer to the htmlElement calling the event
        // https://stackoverflow.com/questions/44970378/es6-class-call-method-from-within-event-handler
        this.objDiv.addEventListener('click', this.onMouseDown.bind(this));
    }

    public resize() {
        // calculate and set positions and dimensions for objdiv
        this.objDiv.style.width = window.innerWidth + "px";
        this.objDiv.style.height = window.innerHeight + "px";
    }

    protected createHTML(): void {
        this.animationWrapper = this.buildAnimationDivTree(this.animationsbaum, "");
        let startTime = "";
        if (this.startTime) {
            startTime = "?start=" + ((this.startTime.minutes * 60) + this.startTime.seconds).toString();
        }
        let youTubeCode = '<div class="videofenster">';
        youTubeCode = youTubeCode + '<iframe style="pointer-events: all;" type="text/html" ';
        youTubeCode = youTubeCode + 'width="100%" height="100%" src="https://www.youtube.com/embed/';
        youTubeCode = youTubeCode + this.videoURI + startTime + '" frameborder="0" allowfullscreen></iframe>';
        youTubeCode = youTubeCode + '</div>';
        youTubeCode = youTubeCode + '<div class="close_video">&#x2715;</div>';
        //console.log(youTubeCode);
        this.animationWrapper.innerHTML = youTubeCode;
    }

    public onActivation(targetAction?: CSM.GroupActionContainer) {
        let gruppe = 0;
        let action = 1;
        if (targetAction) {
            gruppe = targetAction.group;
            action = targetAction.action;
        }
        //console.log("activated" + this.constructor.name);
        //console.log(targetAction);
        this.objDiv.style.zIndex = (this.mediator.zIndexCounter + 10000000).toString();
        this.mediator.zIndexCounter++;
        super.prepareRelatives();
        this.addListeners();
        this.executeAction(this.findActionNumber(gruppe, action));
        this.active = true;

        // Browser-History updaten
        let title = this.title != "" ? this.title : this.constructor.name;
        this.mediator.switchPage(this.constructor.name, title, false);

        // Unterstützen-Menupunkt ggf. anpassen
        //console.log("vorderstesobjekt: hierryoo " + this.name);
        this.mediator.checkMenupunktUnterstuetzen();
    }

    removeHTML() {
        this.active = false;
        this.objDiv.remove();
        this.mediator.updateDocumentTitle();
        //console.log("vorderstesobjekt: hier");
        this.mediator.checkMenupunktUnterstuetzen();
    }

    // link part/html element of this object to activate another object
    protected addListeners(): void {
        //console.log("Schließtag: " + "#" + this.name + " .close_video");
        let element = <HTMLElement>document.querySelector("#" + this.name + " .close_video");
        element.setAttribute("style", "pointer-events: all");
        element.addEventListener("click", (e) => {
            e.stopPropagation();                                    // prevents the click from casting through to objects behind
            let object = <VideoDiv>this.mediator.objects[this.name];
            object.onActivation({group: 0, action: 2});
        });
    }
}

export async function fetchHTML(textObj: TextDiv, uri: string) {
    const response = await fetch(uri);
    const text = await response.text();
    textObj.text = text;
}
