import * as CSM from "../../../ClickStateManager.js";

export default class MusikerSpielelement_DanielSeroussi extends CSM.ClickStateManager {

    videoSize = { x: 4096, y: 2160 };               // width and height of the original video material
    clickAreaPosition = { x: 2050, y: 380 };        // position of Object in original Video in px
    clickAreaSize = { x: 520, y: 900 };           // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Rectangle;   //Form of the clickable Area
    mediaPfad = "/spielelemente/musiker";


    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

        /*if (this.clickCount == 1) {
            this.executeAction(this.findActionNumber(5, 3));
            //this.executeCombiAction(this.findCombiActionNumber(1, 6));
            return;
        }
        if (this.clickCount == 2) {
            this.executeAction(this.findActionNumber(4, 4));
            //this.executeCombiAction(this.findCombiActionNumber(2, 1));
            return;
        }
        if (this.clickCount == 3) {this.executeAction(this.findActionNumber(2, 3)); return;}
        if (this.clickCount == 4) {this.executeAction(this.findActionNumber(2, 2)); return;}
        if (this.clickCount == 5) {this.executeAction(this.findActionNumber(2, 3)); return;}
        if (this.clickCount == 6) {this.executeAction(this.findActionNumber(1, 8)); return;}
        if (this.clickCount == 7) {this.executeAction(this.findActionNumber(6, 15)); return;}
        if (this.clickCount == 8) {this.executeAction(this.findActionNumber(6, 15)); return;}
        return;*/

        this.lokalerEskalationswert = 0.15 + this.clickCount / 10;
        if (this.lokalerEskalationswert > 0.9) {this.lokalerEskalationswert = 0.9;}
        
        this.findPossibleNextActions();

        // Sonderregeln

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Init und Exit",                 elements: 2,    probability: 0,     krassitaet: 1},
        {name: "1 Videospielen",                  elements: 2,    probability: 1,     krassitaet: 2},
        {name: "2 Silhouette",                    elements: 4,    probability: 0.7,   krassitaet: 1},
        {name: "3 Videos starten (inaktiv)",      elements: 3,    probability: 0,     krassitaet: 1},
        {name: "4 Drehen",                        elements: 4,    probability: 0.2,   krassitaet: 2},
        {name: "5 Flitzen",                       elements: 3,    probability: 0.2,   krassitaet: 2},
    ];

    combiGroups = [
        {name: "0 Videos starten (inaktiv)",      elements: 3,    probability: 0,   krassitaet: 3,  addableToGroups: [],       incompatibleCombiGroups: [] },
        {name: "1 Größenveränderungen",           elements: 8,    probability: 0.7,   krassitaet: 2,  addableToGroups: [1, 2],       incompatibleCombiGroups: [] },
        {name: "2 Drehen",                        elements: 4,    probability: 0.3,   krassitaet: 2,  addableToGroups: [1, 2],       incompatibleCombiGroups: [] },
    ];


    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "DanielSeroussi_Spring_01"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_01_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "DanielSeroussi_Spring_01",
                    startTime: 0,
                    playBackRate: 1,
                    endTime: 4100,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    startAnimation: false,
                    playBackRate: 1
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: ["DanielSeroussi_Spring_01_mirror"],
            minClickCount: 4
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "DanielSeroussi_Spring_01",
                    startTime: 0,
                    playBackRate: 1,
                    endTime: 6500,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    startAnimation: false,
                    playBackRate: 1
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: ["DanielSeroussi_Spring_01_mirror"],
            minClickCount: 4
        },

        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "DanielSeroussi_Spring_01"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_00_pre_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_00_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_00_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_00_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_00_contrast"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2966,
                    followAction: {group: 2, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_00_absolutePositionX"
                },
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "DanielSeroussi_Spring_01_mirror"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    startAnimation: false,
                    playBackRate: -1
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: ["DanielSeroussi_Spring_01_mirror"] 
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "DanielSeroussi_Spring_01"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    startAnimation: false,
                    playBackRate: 1
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevImages: ["DanielSeroussi_Spring_01"] 
        },

        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "autogamie2019",
                    nrOfInstances: 1,
                    inheritMotherScale: false,
                    targetAction: {group: 2, action: 1}
                }        
    
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "metallics2019",
                    nrOfInstances: 1,
                    inheritMotherScale: false,
                    targetAction: {group: 2, action: 1}
                }        
    
            ],
            allowHover: false,
            allowClick: true
        },       

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "veitstanz2019",
                    nrOfInstances: 1,
                    inheritMotherScale: false,
                    targetAction: {group: 2, action: 1}
                }        
    
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 4 *** ///////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_01_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_01_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 2]},
                {group: 2, actions: [3, 4]},
                {group: 4, actions: [2, 3, 4]},
                {group: 5, actions: [1, 2]}
            ]
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_02_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_02_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 2]},
                {group: 2, actions: [3, 4]},
                {group: 4, actions: [1, 3, 4]},
                {group: 5, actions: [1, 2]}
            ]
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_03_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_03_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 2]},
                {group: 2, actions: [3, 4]},
                {group: 4, actions: [1, 2, 4]},
                {group: 5, actions: [1, 2]}
            ]
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_04_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_04_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 2]},
                {group: 2, actions: [3, 4]},
                {group: 4, actions: [1, 2, 3]},
                {group: 5, actions: [1, 2]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 5 *** ///////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_04_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 200,
                    followAction: {group: 5, action: 2}
                } 
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_00_absolutePositionX"
                } 
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 5-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_04_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 350,
                    followAction: {group: 5, action: 2}
                } 
            ],
            allowHover: false,
            allowClick: true
        },



    ];

    combiClickActions: Array<CSM.ClickAction> = [
        ///////////////////////////////////////// *** COMBIGROUP 0 *** /////////////////////////////////////////
        // Action 0-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 3, action: 1}
                } 
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 3, action: 2}
                } 
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 3, action: 3}
                } 
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 1 *** /////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_01_absoluteScale"
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_02_absoluteScale"
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_03_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_03_absoluteScale"
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_04_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_04_absoluteScale"
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_05_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_05_absoluteScale"
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_06_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_06_absoluteScale"
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_07_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_07_absoluteScale"
                },
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_08_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_02_08_absoluteScale"
                },
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 2 *** /////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_01_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_01_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_02_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_02_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_03_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_03_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_04_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "danielseroussi_cssanim_03_04_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);




    audioActions: Array<CSM.AudioAction> = [
        // 1 Normales Anschlagen
        {
            possibleClickActions: [
                //{group: 1, actions: [1]},
                {group: 2, actions: [3, 4]},
                {group: 4, actions: [1, 2, 3, 4]},
                {group: 5, actions: [1, 3]}
            ],
            alternativeSounds: [
                {
                    sample: "../../vase/audio/VasenPling12",
                    midiRange: [45, 65],
                    dynamicRange: [0.08, 0.08],
                },
                {
                    sample: "../../vase/audio/VasenPling13",
                    midiRange: [45, 65],
                    dynamicRange: [0.3, 0.3],
                },
                {
                    sample: "../../vase/audio/VasenPling15",
                    midiRange: [45, 65],
                    dynamicRange: [0.15, 0.15],
                },
                {
                    sample: "../../vase/audio/VasenPling16",
                    midiRange: [45, 65],
                    dynamicRange: [0.08, 0.08],
                },
                {
                    sample: "../../vase/audio/VasenPling24",
                    midiRange: [45, 65],
                    dynamicRange: [0.15, 0.15],
                },
                {
                    sample: "../../vase/audio/VasenPling25",
                    midiRange: [45, 65],
                    dynamicRange: [0.15, 0.15],
                },
                {
                    sample: "../../vase/audio/VasenPling31",
                    midiRange: [45, 65],
                    dynamicRange: [0.1, 0.1],
                },
                {
                    sample: "../../vase/audio/VasenPling32",
                    midiRange: [45, 65],
                    dynamicRange: [0.3, 0.3],
                },
                {
                    sample: "../../vase/audio/VasenPling33",
                    midiRange: [45, 65],
                    dynamicRange: [0.15, 0.15],
                },
                {
                    sample: "../../vase/audio/VasenPling34",
                    midiRange: [45, 65],
                    dynamicRange: [0.3, 0.3],
                },
                {
                    sample: "../../vase/audio/VasenPling35",
                    midiRange: [45, 65],
                    dynamicRange: [0.1, 0.1],
                }
                /*
                {
                    sample: "danielpling01",
                    midiRange: [57, 63],
                    dynamicRange: [0.6, 0.6],
                },
                {
                    sample: "danielpling02",
                    midiRange: [57, 63],
                    dynamicRange: [0.6, 0.6],
                },
                {
                    sample: "danielpling03",
                    midiRange: [57, 63],
                    dynamicRange: [0.6, 0.6],
                },
                {
                    sample: "danielpling04",
                    midiRange: [57, 63],
                    dynamicRange: [0.6, 0.6],
                },
                {
                    sample: "danielpling05",
                    midiRange: [57, 63],
                    dynamicRange: [0.6, 0.6],
                },
                {
                    sample: "danielpling06",
                    midiRange: [57, 63],
                    dynamicRange: [0.6, 0.6],
                },
                {
                    sample: "danielpling07",
                    midiRange: [57, 63],
                    dynamicRange: [0.6, 0.6],
                }*/
            ]
        },   

        // 2 Klavierspielen kürzer
        {
            possibleClickActions: [
                {group: 1, actions: [1]}
            ],
            alternativeSounds: [
                {
                    sample: "spring_klavier_1",
                    midiRange: [55, 65],
                    dynamicRange: [0.5, 0.5],
                    fadeOut: 4000
                },
                {
                    sample: "spring_klavier_2",
                    midiRange: [55, 65],
                    dynamicRange: [0.3, 0.3],
                    fadeIn: 1000
                }
            ],
            allowedInEnvironments: [14]
        },
        
        // 3 Klavierspielen länger
        {
            possibleClickActions: [
                {group: 1, actions: [2]}
            ],
            alternativeSounds: [
                {
                    sample: "spring_klavier_3",
                    midiRange: [55, 65],
                    dynamicRange: [0.5, 0.5],
                    fadeIn: 1000
                }
            ],
            allowedInEnvironments: [14]
        },

    ]

    audioHierarchy = [
        {
            description: "Catch All",
            actionGroups: [1, 2, 4, 5],
            combiGroups: [0]                        // Bei mehreren Combigroups haben die früher genannten Priorität
        }
    ]


}