import * as CSM from "../../../ClickStateManager.js";

export default class TamTam extends CSM.ClickStateManager {

    videoSize = { x: 1200, y: 1200 };               // width and height of the original video material
    clickAreaPosition = { x: 358, y: 420 };         // position of Object in original Video in px
    clickAreaSize = { x: 457, y: 457 };             // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Circle;      //Form of the clickable Area
    mediaPfad = "/spielelemente/tamtam";

    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        this.checkAnimationTypes();
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

        /*if (this.clickCount == 2) {
            this.executeAction(this.findActionNumber(3, 5)); return;
            //this.executeCombiAction(this.findCombiActionNumber(4, 1));return;
        }
        if (this.clickCount == 3) {
            this.executeAction(this.findActionNumber(8, 3)); return;
            //this.executeCombiAction(this.findCombiActionNumber(4, 1));return;
        }            
        if (this.clickCount == 2) {
            this.executeAction(this.findActionNumber(0, 2)); return;
            //this.executeCombiAction(this.findCombiActionNumber(4, 6));return;
        }   

        if (this.clickCount == 1 && this.name=="mother→childtamtam01") {
            this.mediator.checkAudioEnvironments();
            this.mediator.executeEnvironmentStartAction();
        }*/
/*        if (this.clickCount == 2 && this.name=="mother→childtamtam01") {
            this.clickCount = 15;
        }*/

        //if (this.clickCount >= 2) {this.executeAction(this.findActionNumber(13, 1)); return;}

/*        if (this.clickCount == 1) {this.executeAction(this.findActionNumber(14, 1)); return;}
        if (this.clickCount == 2) {this.executeAction(this.findActionNumber(14, 2)); return;}
        if (this.clickCount == 3) {this.executeAction(this.findActionNumber(14, 3)); return;}
        if (this.clickCount == 4) {this.executeAction(this.findActionNumber(14, 4)); return;}
        if (this.clickCount == 5) {this.executeAction(this.findActionNumber(14, 5)); return;}
        if (this.clickCount == 6) {this.executeAction(this.findActionNumber(14, 6)); return;}
        if (this.clickCount == 7) {this.executeAction(this.findActionNumber(14, 6)); return;}
        if (this.clickCount == 8) {this.executeAction(this.findActionNumber(1, 6)); return;}*/

        if (this.mediator.currentEnvironment == 0) {
            this.lokalerEskalationswert = this.clickCount / 5;
        }
        if (this.mediator.currentEnvironment == 1) {
            this.lokalerEskalationswert = (this.clickCount / 50) + 0.2;
        }
        if (this.mediator.currentEnvironment == 10) {
            this.lokalerEskalationswert = this.clickCount / 25;
        }
        if (this.lokalerEskalationswert > 1) {this.lokalerEskalationswert = 1;}

        //console.log("Tamtam: currentEnvironment = " + this.mediator.currentEnvironment);
        //console.log("Aktueller Eskalationswert: " + this.lokalerEskalationswert);
        
        this.findPossibleNextActions();

        // Sonderregeln
        // –––

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    randomX() {return "translateX(" + (10 + Math.floor(Math.random()*80)) + "vw)";}
    randomY() {return "translateY(" + (10 + Math.floor(Math.random()*80)) + "vh)";}
    randomScale() {return "scale(" + (0.5 + Math.random() * 1) + ")";}

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Init und Exit",             elements: 4,    probability: 0,     krassitaet: 1,    allowedInEnvironments: [0, 1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "1 Normale Schläge",           elements: 6,    probability: 0.5,     krassitaet: 1,  allowedInEnvironments: [0, 1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "2 Schlackenschlag",           elements: 1,    probability: 0.1,     krassitaet: 2,  allowedInEnvironments: [0, 1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "3 Zackzack-Verbiegen",        elements: 6,    probability: 0.5,     krassitaet: 2,  allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "4 Düsenjäger",                elements: 6,    probability: 0.4,     krassitaet: 2,  allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "5 Aufblähen",                 elements: 6,    probability: 0.1,   krassitaet: 2,    allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "6 Zappeln",                   elements: 2,    probability: 0.2,   krassitaet: 2,    allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "7 Riesigwerden",              elements: 8,    probability: 0,       krassitaet: 3,    allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },     // gesperrt aus Performancegründen!!
        {name: "8 Back to Tamtam",            elements: 3,    probability: 0.2,     krassitaet: 1,  allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "9 Wucherungen",               elements: 4,    probability: 0.1,     krassitaet: 3,  allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "10 Explosion",                elements: 2,    probability: 0.0/*5*/,     krassitaet: 3, allowedInEnvironments: [1, 2 /*, 10*/, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "11 Drehungen",                elements: 6,    probability: 0.05,     krassitaet: 3, allowedInEnvironments: [1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "12 Emittiert Scheinwerfer",   elements: 3,    probability: 0.05,     krassitaet: 3, allowedInEnvironments: [0] },
        {name: "13 Zucken",                   elements: 3,    probability: 0.05,     krassitaet: 3, allowedInEnvironments: [0] },
        {name: "14 PlayTillNextOne",          elements: 2,    probability: 0,        krassitaet: 3, allowedInEnvironments: [4, 19, 20] },
        {name: "15 Schnell zappeln",          elements: 2,    probability: 0.05,     krassitaet: 3, allowedInEnvironments: [10] },
        {name: "16 Feuer",                    elements: 2,    probability: 0.05,     krassitaet: 3, allowedInEnvironments: [19, 20] },
        {name: "17 Neues festes Objekt",      elements: 3,    probability: 0,        krassitaet: 3, allowedInEnvironments: [0] },
        {name: "18 Warnhinweise",             elements: 3,    probability: 1,        krassitaet: 1, allowedInEnvironments: [0] },
        {name: "19 Steig aus dem Tamtam",     elements: 1,    probability: 0,        krassitaet: 1, allowedInEnvironments: [19] },
        {name: "20 Notaktionen ohne Videos",  elements: 4,    probability: 0.000001, krassitaet: 2, allowedInEnvironments: [0, 1, 2, 13, 14, 15, 16, 17, 18, 19, 20] },
//        {name: "X2 MeterTests",               elements: 3,    probability: 0.05,     krassitaet: 3, allowedInEnvironments: [] },
//        {name: "X3 ScoreTests",               elements: 1,    probability: 0.05,     krassitaet: 3, allowedInEnvironments: [] },
]

    combiGroups = [
        {name: "0 3D-Rotationen",                   elements: 6,    probability: 0.3,     krassitaet: 1,  addableToGroups: [1, 2, 3, 4, 5, 6, 7, 9, 11],    incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "1 Skews",                           elements: 6,    probability: 0.3,     krassitaet: 1,  addableToGroups: [1, 2, 3, 4, 5, 6, 7, 9, 11],    incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "2 Düsenjäger",                      elements: 6,    probability: 0.3,     krassitaet: 1,  addableToGroups: [1, 2, 3, 6, 9, 11],             incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "3 Größenveränderungen",             elements: 6,    probability: 0.3,     krassitaet: 2,  addableToGroups: [1, 2, 3, 4, 6, 11],             incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "4 Farbveränderungen",               elements: 6,    probability: 0.8,     krassitaet: 3,  addableToGroups: [1, 2, 3, 4, 6, 9, 10, 11],      incompatibleCombiGroups: [], allowedInEnvironments: [1, 2, 10, 13, 14, 15, 16, 17, 18, 19, 20] },
        {name: "5 Langsames Gleiten",               elements: 4,    probability: 0.8,     krassitaet: 3,  addableToGroups: [1],                             incompatibleCombiGroups: [], allowedInEnvironments: [0] },
        {name: "6 Langsame Größenveränderungen",    elements: 4,    probability: 0.8,     krassitaet: 3,  addableToGroups: [1],                             incompatibleCombiGroups: [], allowedInEnvironments: [0] },
        {name: "7 Teaser zum Konzert",              elements: 1,    probability: 0.0,     krassitaet: 3,  addableToGroups: [],                              incompatibleCombiGroups: [], allowedInEnvironments: [0] },
        {name: "8 Neues festes Objekt Wait",        elements: 3,    probability: 0.8,     krassitaet: 3,  addableToGroups: [/*12*/],                        incompatibleCombiGroups: [], allowedInEnvironments: [0] }/*,
        //{name: "Projektion aufs Tamtam",            elements: 0,    probability: 0.0,   krassitaet: 1,  addableToGroups: [1, 2],        incompatibleCombiGroups: [], allowedInEnvironments: [0] },
        {name: "Emittiert silber-fragiles Element", elements: 2,    probability: 0.2,   krassitaet: 1,  addableToGroups: [1, 4, 9, 10], incompatibleCombiGroups: [], allowedInEnvironments: [0] },
        //{name: "Emittiert dunkel-massives Element", elements: 0,    probability: 0.0,   krassitaet: 1,  addableToGroups: [2],           incompatibleCombiGroups: [], allowedInEnvironments: [0] },
        {name: "Bewegungen im Raum",                elements: 6,    probability: 0.3,   krassitaet: 1,  addableToGroups: [3, 5, 8],     incompatibleCombiGroups: [], allowedInEnvironments: [0] },
        {name: "Normalgröße",                       elements: 1,    probability: 0.3,   krassitaet: 1,  addableToGroups: [3, 4, 5],        incompatibleCombiGroups: [5, 6], allowedInEnvironments: [0] },
        {name: "Vergrößern",                        elements: 1,    probability: 0.3,   krassitaet: 1,  addableToGroups: [2, 3, 4, 5],     incompatibleCombiGroups: [4, 6], allowedInEnvironments: [0] },
        {name: "Verkleinern",                       elements: 1,    probability: 0.3,   krassitaet: 1,  addableToGroups: [3, 4, 5],        incompatibleCombiGroups: [4, 5], allowedInEnvironments: [0] }/*,
        {name: "langsame Farbrotation",             elements: 0,    probability: 0.0,   krassitaet: 5,  addableToGroups: [],            incompatibleCombiGroups: [], allowedInEnvironments: [0] },
        {name: "schnelle Farbrotation",             elements: 0,    probability: 0.0,   krassitaet: 5,  addableToGroups: [],            incompatibleCombiGroups: [], allowedInEnvironments: [0] },
        {name: "Skew and Rotate",                   elements: 0,    probability: 0.0,   krassitaet: 4,  addableToGroups: [],            incompatibleCombiGroups: [], allowedInEnvironments: [0] }*/
    ];

    allowedCSSAnimationTypes: Array<CSM.allowedCSSAnimationType> = [
        {animationType: "rotate",      maxClickCount: 15, maxGlobalClickCount: 30},
        {animationType: "skew",        maxClickCount: 15, maxGlobalClickCount: 30},
        {animationType: "rotate3d",    maxClickCount: 15, maxGlobalClickCount: 30},
        {animationType: "perspective", maxClickCount: 15, maxGlobalClickCount: 30},
        {animationType: "blend",       maxGlobalClickCount: 30},
    ];

    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Tamtam_01_frame0001"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Tamtam_01_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_25_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1500,
                    followAction: {group: 0, action: 4}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 0-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ActivateObject,
                    targetObject: "produktion_steigdurchdastamtam",
                    targetAction: {group: 0, action: 4}
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },
        

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_01",
                    //loadOnStart: true
                }
            ],
            allowHover: false,
            allowClick: true,
            maxClickCount: 20,
            forbidAtClickCounts: [2],
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 5, 6]},
                {group: 12, actions: [1, 2, 3]},
                {group: 20, actions: [1, 2, 3, 4]}
            ]
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_07",
                    startTime: 633,
                    playBackRate: 1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 267,
                    followAction: {group: 1, action: 3}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_03_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_03_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_03_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_05_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 2,
            possiblePrevActions: [
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 5, 6]},
                {group: 12, actions: [1, 2, 3]},
                {group: 20, actions: [1, 2, 3, 4]}
            ]
        },

        // Action 1-3
        {
        singleActions: [
            {
                actionType: CSM.ActionType.VideoPlayback,
                mediaTitle: "Tamtam_07",
                playBackRate: -1,
                endTime: 400,//633,
                startVideo: false
            }
        ],
        allowHover: false,
        allowClick: true,
        possiblePrevActions: [{group: 1, actions: [2]}]
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_07",
                    startTime: 4833,
                    playBackRate: -1,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 233,
                    followAction: {group: 1, action: 5}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_03_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_03_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_03_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_05_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 2,
            possiblePrevActions: [
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 5, 6]},
                {group: 12, actions: [1, 2, 3]},
                {group: 20, actions: [1, 2, 3, 4]}
            ]
        },

        // Action 1-5
        {
        singleActions: [
            {
                actionType: CSM.ActionType.VideoPlayback,
                mediaTitle: "Tamtam_07",
                playBackRate: 1,
                startVideo: false
            }
        ],
        allowHover: false,
        allowClick: true,
        possiblePrevActions: [{group: 1, actions: [4]}]
        },

        // Action 1-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_01",
                    //loadOnStart: true
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_02_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_02_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_02_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_02_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            forceAtClickCounts: [1],
            maxClickCount: 1,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 5, 6]},
                {group: 12, actions: [1, 2, 3]},
                {group: 20, actions: [1, 2, 3, 4]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_04",
                    startTime: 9 * 33
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_01_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_02_relativePositionX",
                    removeAtEnd: true               
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_02_relativePositionY",
                    removeAtEnd: true
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_03_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 2,
            possiblePrevActions: [
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 5, 6]},
                {group: 12, actions: [1, 2, 3]},
                {group: 20, actions: [1, 2, 3, 4]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_05_MG",
                    startTime:  0 + (9 * 33.33),
                    endTime:    1000 + (15 * 33.33),
                    playBackRate: 10,
                    loop: false
                }/*,
                {
                    actionType: CSM.ActionType.PlayAudio,
                    mediaTitle: "tamtam-bieg",
                    volume: 0.5
                }*/
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6,
            possiblePrevActions: [  {group: 1, actions: [1, 3, 5, 6]},
                                    {group: 2, actions: [1]},
                                    {group: 3, actions: [2, 4]},
                                    {group: 8, actions: [2, 3/*, 4*/]}
                                ]
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_05_MG",
                    startTime:  1000 + (15 * 33.33),
                    endTime:    0 + (9 * 33.33),
                    playBackRate: -10,
                    loop: false
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActionsByVideoPosition: true
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_05_MG",
                    startTime:  1000 + (15 * 33.33),
                    endTime:    3000 + (0 * 33.33),
                    playBackRate: 10,
                    loop: false
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActionsByVideoPosition: true
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_05_MG",
                    startTime:  3000 + (0 * 33.33),
                    endTime:    0 + (9 * 33.33),
                    playBackRate: -10,
                    loop: false
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActionsByVideoPosition: true
        },

        // Action 3-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_05_MG",
                    startTime:  3000 + (0 * 33.33),
                    playBackRate: 1,
                    loop: false/*,
                    followAction: {group: 3, action: 6}*/
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000 + (6 * 33.33),
                    followAction: {group: 3, action: 6}
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 16,
            possiblePrevActionsByVideoPosition: true,
            addState: ["zappeln"]
        },

        // Action 3-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_06_MG",
                    startTime:  0 + (6 * 33.33),
                    playBackRate: 1,
                    loop: true
                }

            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [{group: 3, actions: [5]}]
        },

        /////////////////////////////////////////// *** GRUPPE 4 *** ///////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 20, y: -9999},
            demandedState: ["zappeln"]
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 40, y: 60},
            demandedState: ["zappeln"]
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: -10, y: 70},
            demandedState: ["zappeln"]
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 70, y: 20},
            demandedState: ["zappeln"]
        },

        // Action 4-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 30, y: 55},
            demandedState: ["zappeln"]
        },

        // Action 4-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: -9999, y: -20},
            demandedState: ["zappeln"]
        },

        /////////////////////////////////////////// *** GRUPPE 5 *** ///////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_01_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_01_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1]}
                                ],
            demandedState: ["zappeln"],
            addState: ["psychedelic", "large"],
            tellAudioTargetAbsoluteScale: [3, 3000]
        },

        // Action 5-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_02_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_02_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1]}
                                ],
            demandedState: ["zappeln"],
            addState: ["psychedelic", "large"],
            tellAudioTargetAbsoluteScale: [3, 3000]
        },

        // Action 5-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_03_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_03_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_03_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1]}
                                ],
            demandedState: ["zappeln"],
            addState: ["psychedelic", "large"],
            tellAudioTargetAbsoluteScale: [3, 3000]
        },

        // Action 5-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_04_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_04_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_04_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1]}
                                ],
            demandedState: ["zappeln"],
            addState: ["psychedelic", "large"],
            tellAudioTargetAbsoluteScale: [3, 3000]
        },

        // Action 5-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_05_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_05_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_05_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1]}
                                ],
            demandedState: ["zappeln"],
            addState: ["psychedelic", "large"],
            tellAudioTargetAbsoluteScale: [3, 3000]
        },

        // Action 5-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_06_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_06_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_06_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_06_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_03_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1]}
                                ],
            demandedState: ["zappeln"],
            addState: ["psychedelic", "large"],
            tellAudioTargetAbsoluteScale: [3, 3000]
        },

        /////////////////////////////////////////// *** GRUPPE 6 *** ///////////////////////////////////////////
        // Action 6-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_06_MG",
                    playBackRate: 5,
                    startVideo: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_07_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_07_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_07_relativeScale"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 500,
                    followAction: {group: 6, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            demandedState: ["zappeln"]
        },

        // Action 6-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_06_MG",
                    playBackRate: 1,
                    startVideo: false
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 6, actions: [1]}]
        },

        /////////////////////////////////////////// *** GRUPPE 7 *** ///////////////////////////////////////////
        // Action 7-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_04_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_01_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_01_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_01_blur"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 25,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 7, actions: [2, 3, 4, 5, 6, 7, 8]},
                {group: 8, actions: [1]}
            ],
            demandedState: ["zappeln"]
        },
        
        // Action 7-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_04_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_02_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_02_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_02_blur"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 25,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 7, actions: [1, 3, 4, 5, 6, 7, 8]},
                {group: 8, actions: [1]}
            ],
            demandedState: ["zappeln"]
        },
        
        // Action 7-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_04_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_03_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_03_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_03_blur"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 25,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 7, actions: [1, 2, 4, 5, 6, 7, 8]},
                {group: 8, actions: [1]}
            ],
            demandedState: ["zappeln"]
        },
        
        // Action 7-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_04_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_04_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_04_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_04_blur"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 25,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 7, actions: [1, 2, 3, 5, 6, 7, 8]},
                {group: 8, actions: [1]}
            ],
            demandedState: ["zappeln"]
        },
        
        // Action 7-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_04_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_05_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_05_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_05_blur"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_05_opacity"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 25,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 7, actions: [1, 2, 3, 4, 6, 7, 8]},
                {group: 8, actions: [1]}
            ],
            demandedState: ["zappeln"]
        },
        
        // Action 7-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_04_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_06_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_06_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_06_blur"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_06_opacity"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 25,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 7, actions: [1, 2, 3, 4, 5, 7, 8]},
                {group: 8, actions: [1]}
            ],
            demandedState: ["zappeln"]
        },
         
        // Action 7-7
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_04_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_07_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_07_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_07_blur"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_07_opacity"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 25,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 7, actions: [1, 2, 3, 4, 5, 6, 8]},
                {group: 8, actions: [1]}
            ],
            demandedState: ["zappeln"]
        },
                
        // Action 7-8
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_04_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_08_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_08_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_08_blur"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_08_08_opacity"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 25,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 15, actions: [2]}
                                ],
            possibleNextActions: [
                {group: 7, actions: [1, 2, 3, 4, 5, 6, 7]},
                {group: 8, actions: [1]}
            ],
            demandedState: ["zappeln"]
        },
 
        /////////////////////////////////////////// *** GRUPPE 8 *** ///////////////////////////////////////////
        // Action 8-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_06_MG",
                    playBackRate: -4,
                    loop: false,
                    startVideo: false,
                    followAction: {group: 8, action: 2}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_relativeScale2"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_opacity"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_skew"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_contrast"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_blur"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_hue-rotate"
                }
                
                /*,
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    targetObjectSelector: "#tamtam",
                    newClass: "tamtam_cssanim_00"
                }*/     // Bewegung im Raum laut Tabelle, füge ich später hinzu
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 3, actions: [6]},
                                    {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                                    {group: 6, actions: [2]},
                                    {group: 7, actions: [1, 2, 3, 4, 5, 6, 7, 8]},
                                    {group: 15, actions: [2]}
                                ],
            demandedState: ["zappeln"],
            minClickCount: 40            
        },

        // Action 8-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_05_MG",
                    playBackRate: -2,
                    startTime: -1,
                    loop: false
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 8, actions: [1]}],
            removeState: ["zappeln", "psychedelic", "large"]
        },

        // Action 8-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_07",
                    playBackRate: -3,
                    endTime: 634,
                    startVideo: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_04_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_03_remove_relativePositionX",
                    removeAtEnd: true
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_03_remove_relativePositionY",
                    removeAtEnd: true
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [  {group: 9, actions: [2, 3, 4]}]
        },

        // Action 8-4
        /*{
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_08",
                    startTime: -1,
                    playBackRate: -1
                }/*,
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    targetObjectSelector: "#tamtam",
                    newClass: "tamtam_cssanim_01"
                }*/     // Beschleunigung und Bewegung im Raum laut Tabelle, füge ich später hinzu
            /*],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },*/


        /////////////////////////////////////////// *** GRUPPE 9 *** ///////////////////////////////////////////
        // Action 9-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_07",
                    playBackRate: 1,
                    startTime: 633
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_13_06_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 9, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 15,
            possiblePrevActions: [
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 5, 6]},
                {group: 12, actions: [1, 2, 3]}
            ]
        },
        
        // Action 9-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_07",
                    playBackRate: 0.3,
                    startVideo: false
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_03_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_03_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_03_brightness"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 8333,
                    followAction: {group: 9, action: 3}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 9-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_07",
                    playBackRate: -0.3,
                    startVideo: false
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 8333,
                    followAction: {group: 9, action: 4}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 9-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_07",
                    playBackRate: 0.3,
                    startVideo: false
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 8333,
                    followAction: {group: 9, action: 3}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },        
        /////////////////////////////////////////// *** GRUPPE 10 *** ///////////////////////////////////////////
        // Action 10-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    //mediaTitle: "Tamtam_08",
                    mediaTitle: "Tamtam_07",            // eigentlich 08, aber der soll nicht geladen werden
                    startTime: 10 * 33.33,
                    followAction: {group: 0, action: 2}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_09_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_09_01_brightness"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 30,
            possiblePrevActions: [ 
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 5, 6]},
                {group: 12, actions: [1, 2, 3]}
            ]
        },

        // Action 10-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    //mediaTitle: "Tamtam_08",
                    mediaTitle: "Tamtam_07",            // eigentlich 08, aber der soll nicht geladen werden
                    startTime: -1,
                    playBackRate: -1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_09_02_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionX",
                    newProperties: [{property: "transform", value: "this.randomX()"}]
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absolutePositionY",
                    newProperties: [{property: "transform", value: "this.randomY()"}]
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "absoluteScale",
                    newProperties: [{property: "transform", value: "this.randomScale()"}]
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 11 *** ///////////////////////////////////////////
        // Action 11-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_01_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_01_rotate"
                }
            ],
            minClickCount: 6,
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [ 
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [2, 3, 4, 5, 6]}
            ]
        },

        // Action 11-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_02_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6,
            possiblePrevActions: [ 
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 3, 4, 5, 6]}
            ]
        },

        // Action 11-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_03_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_03_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6,
            possiblePrevActions: [ 
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 4, 5, 6]}
            ]
        },

        // Action 11-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_04_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_04_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6,
            possiblePrevActions: [ 
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 5, 6]}
            ]
        },

        // Action 11-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_05_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_05_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6,
            possiblePrevActions: [ 
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 6]}
            ]
        },

        // Action 11-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_06_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_10_06_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 6,
            possiblePrevActions: [ 
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 5]}
            ]
        },


        /////////////////////////////////////////// *** GRUPPE 12 *** ///////////////////////////////////////////
        // Action 12-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_01"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_19_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_19_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "steeldrum",
                    newClass: "steeldrum_cssanim_01"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 12, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            minClickCount: 4,
            maxClickCount: 6,
            forceIfNotHappenedTillClickCount: 6,
            forbidClicksForNextMilliseconds: 23000,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 12-2
        {
            singleActions: [
                /*,
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "scheinwerfer_gehaeuse",
                    nrOfInstances: 1,
                    inheritMotherScale: true,
                    targetAction: {group: 1, action: 1}
                }*/
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "scheinwerfer_lichtkegel",
                    nrOfInstances: 2,
                    inheritMotherScale: true,
                    forceZIndex: 99999,
                    targetAction: {group: 1, action: 1}
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 500,
                    followAction: {group: 12, action: 3}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 12, actions: [1]},
            ]
        },

        // Action 12-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_19_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_19_opacity"
                },
                {
                    actionType: CSM.ActionType.StartNewEnvironment
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 12, actions: [2]},
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 13 *** ///////////////////////////////////////////
        // Action 13-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Tamtam_01_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_01_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_01_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_01_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            maxClickCount: 1,
            possiblePrevActions: []
        },

        // Action 13-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Tamtam_01_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_24_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 8000,
                    followAction: {group: 13, action: 3}
                }
            ],
            allowHover: false,
            allowClick: true,
            maxClickCount: 1,
            possiblePrevActions: []
        },

        // Action 13-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_01_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_01_relativePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_15_01_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            maxClickCount: 1,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 14 *** ///////////////////////////////////////////

        // Action 14-1
        {
            singleActions: [
/*                {
                    actionType:         CSM.ActionType.PlayAudioTillNextOne,
//                    mediaTitleAttack:   "WillOvercashAttackAlt",
                    mediaTitle:         "WillOvercashCresc",
                    metricLevel:        "LoopStart"
                },
                {
                    actionType:         CSM.ActionType.ChangeAnimation,
                    newClass:           "tamtam_cssanim_cresc_relativeScale",
                    metricLevel:        "LoopStart"
                },
                {
                    actionType:         CSM.ActionType.ChangeAnimation,
                    newClass:           "tamtam_cssanim_cresc_rotate",
                    metricLevel:        "LoopStart"
                },
                {
                    actionType:         CSM.ActionType.ChangeAnimation,
                    newClass:           "tamtam_cssanim_cresc_hue-rotate",
                    metricLevel:        "LoopStart"
                }*/
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 14-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_cresc_relativeScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_cresc_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_cresc_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 15 *** ///////////////////////////////////////////
        // Action 15-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_05_MG",
                    startTime:  0 + (0 * 33.33),
                    playBackRate: 4,
                    loop: false
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000 + (9 * 33.33),
                    followAction: {group: 15, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]},
                {group: 1, actions: [1, 3, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [2, 4]},
                {group: 8, actions: [2, 3/*, 4*/]},
                {group: 10, actions: [2]},
                {group: 11, actions: [1, 2, 3, 4, 5, 6]},
                {group: 12, actions: [1, 2, 3]}
            ],
            addState: ["zappeln"]
        },

        // Action 15-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_06_MG",
                    startTime:  0 + (6 * 33.33),
                    playBackRate: 1,
                    loop: true
                }

            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [{group: 15, actions: [1]}]
        },

        /////////////////////////////////////////// *** GRUPPE 16 *** ///////////////////////////////////////////
        // Action 16-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_06_MG",
                    startTime:  0 + (6 * 33.33),
                    playBackRate: 1,
                    loop: true
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_18_opacity"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_18_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_18a"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_18_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_18_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_18_init_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_18_blenda"
                }/*,
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 8000,
                    followAction: {group: 16, action: 2}
                }*/
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [],
            addState: ["zappeln", "psychedelic"]
        },

        // Action 16-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_18_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 17 *** ///////////////////////////////////////////
        // Action 17-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "barrel",
                    nrOfInstances: 1,
                    inheritMotherScale: false,
                    targetAction: {group: 9, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },        

        // Action 17-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "vase",
                    nrOfInstances: 1,
                    inheritMotherScale: false,
                    targetAction: {group: 6, action: 1}
                }/*,
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "barrel",
                    nrOfInstances: 1,
                    inheritMotherScale: false,
                    targetAction: {group: 9, action: 1}
                }*/
            ],
            allowHover: false,
            allowClick: true
        },        
/*
        // Action 17-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "messerfass",
                    nrOfInstances: 1,
                    inheritMotherScale: false,
                    targetAction: {group: 7, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },*/

        // Action 17-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "skull",
                    nrOfInstances: 1,
                    inheritMotherScale: false,
                    targetAction: {group: 7, action: 1}
                }/*,
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "barrel",
                    nrOfInstances: 1,
                    inheritMotherScale: false,
                    targetAction: {group: 9, action: 1}
                }*/
            ],
            allowHover: false,
            allowClick: true
        },        

        /////////////////////////////////////////// *** GRUPPE 18 *** ///////////////////////////////////////////
        // Action 18-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_01"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 18, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            forceIfNotChromeBrowserIsUsed: true,
            possiblePrevActions: [
                {group: 0, actions: [1]}
            ]
        },
        
        // Action 18-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "safarikugel",
                    nrOfInstances: 1,
                    inheritMotherScale: true,
                    targetAction: {group: 0, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },        

        // Action 18-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Tamtam_01"
                },
                {
                    actionType: CSM.ActionType.ActivateObject,
                    targetObject: "warnhinweis_mobil"
                }
            ],
            allowHover: false,
            allowClick: true,
            forceIfMobileIsUsed: true,
            possiblePrevActions: [
                {group: 0, actions: [1]}
            ]
        },
        
        /////////////////////////////////////////// *** GRUPPE 19 *** ///////////////////////////////////////////
        // Action 19-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "Tamtam_01_frame0001"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_21_hue-rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_21_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_21_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_21_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_21_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_21_opacity"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    animationDiv: "steeldrum",
                    newClass: "steeldrum_cssanim_04"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidClicksForNextMilliseconds: 23000,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 20 *** ///////////////////////////////////////////
        // Action 20-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_23_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: [
                {group: 20, actions: [1]}
            ],
            forceAtClickCounts: [1]
        },

        // Action 20-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_02_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_23_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: [
                {group: 20, actions: [2]}
            ],
            forceAtClickCounts: [1]
        },

        // Action 20-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_03_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_23_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: [
                {group: 20, actions: [3]}
            ],
            forceAtClickCounts: [1]
        },

        // Action 20-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_04_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_23_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            impossiblePrevActions: [
                {group: 20, actions: [4]}
            ],
            forceAtClickCounts: [1]
        },

        /////////////////////////////////////////// *** GRUPPE X3 *** ///////////////////////////////////////////
        // Action X3-1 (später wieder löschen!)
/*        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "barrel",
                    nrOfInstances: 12,
                    inheritMotherScale: false,
                    targetAction: {group: 8, action: 1}
                },
                {
                    actionType: CSM.ActionType.StartMeter,
                    mediaTitle: "WillOvercashMeter"
                },
                {
                    actionType:     CSM.ActionType.PlayScore,
                    mediaTitle:     "Fassballett",
                    metricLevel:    "Primary"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        }*/
    ];

    combiClickActions: Array<CSM.ClickAction> = [
        ///////////////////////////////////////// *** COMBIGROUP 0 *** /////////////////////////////////////////
        // Action 0-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_11_01_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_11_02_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_11_03_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_11_04_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_11_05_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_11_06_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 1 *** /////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_12_01_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_12_02_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_12_03_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_12_04_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_12_05_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_12_06_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 2 *** /////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_02_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 2-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 3 *** /////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_13_01_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            tellAudioTargetAbsoluteScale: [1, 500]
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_13_02_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            tellAudioTargetAbsoluteScale: [1.3, 500]
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_13_03_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            tellAudioTargetAbsoluteScale: [1.7, 500]
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_13_04_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            tellAudioTargetAbsoluteScale: [2.2, 500]
        },

        // Action 3-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_13_05_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            tellAudioTargetAbsoluteScale: [0.8, 500]
        },

        // Action 3-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_13_06_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true,
            tellAudioTargetAbsoluteScale: [0.6, 500]
        },

        ///////////////////////////////////////// *** COMBIGROUP 4 *** /////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_01_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_01_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["dark", "psychedelic"]
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_02_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_02_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["dark", "psychedelic"]
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_03_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_03_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["dark", "psychedelic"]
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_04_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_04_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["dark", "psychedelic"]
        },

        // Action 4-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_05_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_05_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["dark", "psychedelic"]
        },

        // Action 4-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_blenda"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_06_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_14_06_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            addState: ["dark", "psychedelic"]
        },

        ///////////////////////////////////////// *** COMBIGROUP 5 *** /////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_01_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_02_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 5-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_16_04_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 6 *** /////////////////////////////////////////
        // Action 6-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_17_01_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_17_02_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_17_03_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 6-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_17_04_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },


        ///////////////////////////////////////// *** COMBIGROUP 7 *** /////////////////////////////////////////
        // Action 7-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.GenerateInstances,
                    targetObject: "veitstanz",
                    nrOfInstances: 1,
                    inheritMotherScale: false,
                    targetAction: {group: 0, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        },        

        ///////////////////////////////////////// *** COMBIGROUP 8 *** /////////////////////////////////////////

        // Action 8-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 17, action: 1}
                }
            ],
            allowHover: false,
            allowClick: true
        }, 

        // Action 8-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 17, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        },  

        // Action 8-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 17, action: 3}
                }
            ],
            allowHover: false,
            allowClick: true
        },           

/*        // Action 8-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 17, action: 4}
                }
            ],
            allowHover: false,
            allowClick: true
        },     */   


    ]


    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);

    audioActions: Array<CSM.AudioAction> = [
        // 1 Normales Schwingen
        {
            possibleClickActions: [
                {group: 1, actions: [1, 6]},
                {group: 20, actions: [1, 2, 3, 4]}
            ],
            standardSounds: [
                {
                    sample: "TT pp",
                    midiRange: [55, 65],
                    dynamicRange: [0.04, 0.04]
                }
            ],
            alternativeSounds: [
                {
                    sample: "TT Metal",
                    midiRange: [44, 50],
                    dynamicRange: [0.02, 0.02],
                    afterIdenticalActionOnly: true
                }
            ],
            allowedInEnvironments: [0, 10]
        },

        // 2 Schmelzschläge
        {
            possibleClickActions: [
                {group: 1, actions: [2, 4]}
            ],
            standardSounds: [
                {
                    sample: "TT orient ff",
                    midiRange: [37, 45],
                    dynamicRange: [0.03, 0.03],
                    endTime: 5000,
                    fadeOut: 4900
                }
            ],
            alternativeSounds: [
                {
                    sample: "TT pp",
                    midiRange: [65, 72],
                    dynamicRange: [0.4, 0.7],
                    afterIdenticalActionOnly: true
                }
            ],
            allowedInEnvironments: [0, 1]
        },

        // 3 Verbiegen
        {
            possibleClickActions: [
                {group: 3, actions: [1, 2, 3, 4, 5, 6]}
            ],
            standardSounds: [
                {
                    sample: "Chinagong",
                    midiRange: [50, 55],
                    dynamicRange: [0.3, 0.3],
                    endTime: 1200,
                    fadeOut: 1100
                }
            ],
            alternativeSounds: [
                {
                    sample: "TT ff",
                    midiRange: [67, 77],
                    dynamicRange: [0.03, 0.03],
                    afterIdenticalActionOnly: true
                }
            ],
            allowedInEnvironments: [0, 1]
        },

        // 4 Düsenjäger
        {
            possibleClickActions: [
                {group: 4, actions: [1, 2, 3, 4, 5, 6]}
            ],
            possibleCombiActions: [
                {group: 2, actions: [1, 2, 3, 4, 5, 6]}
            ],
            standardSounds: [
                {
                    sample: "TT Metal",
                    midiRange: [30, 53],
                    dynamicRange: [0.2, 0.2],
                    forbiddenState: ["psychedelic"]
                }
            ],
            alternativeSounds: [
                {
                    sample: "TT pp",
                    midiRange: [90, 100],
                    dynamicRange: [0.15, 0.15],
                    voices: 2,
                    afterIdenticalActionOnly: true,
                    forbiddenState: ["psychedelic"]
                },
                {
                    sample: "TT ratsch",
                    midiRange: [72, 77],
                    dynamicRange: [0.2, 0.2],
                    afterIdenticalActionOnly: true,
                    forbiddenState: ["psychedelic"]
                },
                {
                    sample: "TT Metal",
                    midiRange: [70, 85],
                    dynamicRange: [0.2, 0.2],
                    forbiddenState: ["psychedelic"]
                },
                {
                    sample: "TT pp",
                    midiRange: [65, 80],
                    dynamicRange: [0.2, 0.2],
                    demandedState: ["psychedelic"]
                }
            ],
            allowedInEnvironments: [0, 1, 10]
        },

        // 5 Bunt werden
        {
            possibleClickActions: [
                {group: 5, actions: [1, 2, 3, 4, 5, 6]}
            ],
            standardSounds: [
                {
                    sample: "TT pp",
                    midiRange: [60, 70],
                    dynamicRange: [0.8, 0.8]
                }
            ],
            allowedInEnvironments: [0, 1, 10]
        },

        // 6 Aufleuchten
        {
            possibleClickActions: [
                {group: 6, actions: [1]}
            ],
            standardSounds: [
                {
                    sample: "TT ratsch",
                    midiRange: [70, 80],
                    dynamicRange: [0.2, 0.2],
                    forbiddenState: ["psychedelic"]
                }
            ],
            alternativeSounds: [
                {
                    sample: "TT ff",
                    midiRange: [50, 60],
                    dynamicRange: [0.8, 0.8],
                    demandedState: ["psychedelic"]
                }
            ],
            allowedInEnvironments: [0, 1, 10]
        },

        // 7 Riesigwerden
        /*{
            possibleClickActions: [
                {group: 7, actions: [1, 2, 3, 4, 5, 6, 7, 8]}
            ],
            standardSounds: [
                {
                    sample: "Riesigwerden",
                    midiRange: [60, 60],
                    dynamicRange: [1, 1]
                }
            ],
            allowedInEnvironments: [0, 1, 10, 13]
        },*/

        // 8 Back to Tamtam
        {
            possibleClickActions: [
                {group: 8, actions: [1]},
                {group: 9, actions: [1]}
            ],
            standardSounds: [
                {
                    sample: "TT pp",
                    midiRange: [60, 60],
                    dynamicRange: [0.5, 0.5]
                }
            ],
            allowedInEnvironments: [0, 1]
        },

        // 9 Dunkelwerden
        {
            demandedNewState: "dark",
            standardSounds: [
                {
                    sample: "TT pp",
                    midiRange: [50, 60],
                    voices: 2,
                    dynamicRange: [0.2, 0.2]
                }
            ],
            allowedInEnvironments: [0, 1, 10]
        },

        // 10 Zappeln
        /*{
            demandedNewState: "zappeln",
            standardSounds: [
                {
                    sample: "zappeln",
                    midiRange: [60, 60],
                    dynamicRange: [1, 1]
                }
            ],
            allowedInEnvironments: [0, 1, 13]
        },*/

        // 11 Schlackenschlag
        {
            possibleClickActions: [
                {group: 2, actions: [1]}
            ],
            standardSounds: [
                {
                    sample: "TT mf",
                    midiRange: [57, 63],
                    dynamicRange: [0.1, 0.1]
                }
            ],
            alternativeSounds: [
                {
                    sample: "TT mf",
                    midiRange: [57, 63],
                    dynamicRange: [0.1, 0.1]
                },
                {
                    sample: "TT ff",
                    midiRange: [77, 87],
                    dynamicRange: [0.15, 0.15],
                    afterIdenticalActionOnly: true
                }
            ],
            allowedInEnvironments: [0, 1]
        },

        // 12 Scheinwerfergeburt
        {
            possibleClickActions: [
                {group: 12, actions: [1]}
            ],
            alternativeSounds: [
                {
                    sample: "TT Comb",
                    midiRange: [60, 60],
                    dynamicRange: [0.5, 0.5]
                }
            ],
            allowedInEnvironments: [0]
        },

        // 13 Environment 13
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2, 3, 4, 5, 6]},
                {group: 2, actions: [1]},
                {group: 3, actions: [1, 2, 3, 4, 5, 6]},
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 5, actions: [1, 2, 3, 4, 5, 6]},
                {group: 6, actions: [1, 2]},
                {group: 8, actions: [1, 2, 3]},
                {group: 9, actions: [1, 2]},
                {group: 11, actions: [1, 2, 3, 4, 5, 6]}
            ],
            possibleCombiActions: [
                {group: 2, actions: [1, 2, 3, 4, 5, 6]}
            ],
            alternativeSounds: [
                {
                    sample: "TT ff",
                    midiRange: [50, 60],
                    dynamicRange: [0.6, 0.6]
                },
                {
                    sample: "TT Metal",
                    midiRange: [50, 60],
                    dynamicRange: [0.35, 0.35]
                },
                {
                    sample: "TT mf",
                    midiRange: [50, 60],
                    dynamicRange: [0.7, 0.7]
                },
                {
                    sample: "TT orient ff",
                    midiRange: [35, 60],
                    dynamicRange: [0.2, 0.2]
                },
                {
                    sample: "TT pp",
                    midiRange: [50, 70],
                    dynamicRange: [0.6, 0.6]
                },
            ],
            allowedInEnvironments: [2, 13, 14, 15, 16, 17, 18, 19, 20]
        },      
        
        // 14 Normales Schwingen ab dem Environment 1
        {
            possibleClickActions: [
                {group: 1, actions: [1, 6]}
            ],
            standardSounds: [
                {
                    sample: "TT pp",
                    midiRange: [55, 65],
                    dynamicRange: [0.15, 0.15]
                }
            ],
            alternativeSounds: [
                {
                    sample: "TT Metal",
                    midiRange: [44, 50],
                    dynamicRange: [0.25, 0.25],
                    afterIdenticalActionOnly: true
                }
            ],
            allowedInEnvironments: [1, 10]
        },   

        // !!LAST!! Dummy-Kurz-Tamtam
        {
            possibleClickActions: [
            ],
            alternativeSounds: [
                {
                    sample: "TT pp kurz",
                    midiRange: [55, 65],
                    dynamicRange: [0.04, 0.04]
                }
            ],
            allowedInEnvironments: []
        },           
    ];

    audioHierarchy = [
        {
            description: "Farbveränderungen",
            actionGroups: [5, 6, 7, 8, 10, 12],
            combiGroups: []                        // Bei mehreren Combigroups haben die früher genannten Priorität
        },
        {
            description: "Positionsveränderungen",
            actionGroups: [4, 20],
            combiGroups: [2]
        },
        {
            description: "Formveränderungen",
            actionGroups: [1, 2, 3, 11],
            combiGroups: [0, 1, 3]
        }
    ]


}