import { TextDiv } from "../../../TextDiv.js";
import * as CSM from "../../../ClickStateManager.js";

export default class Textfenster_Unterstuetzen extends TextDiv {
    textURI = "Textfenster/Textfenster_Unterstuetzen.html";
    title = "SCHWELBRAND – Unterstützen";
    width = 51;                                   //object size in percent of screenwidth
    height = 100;
    position = { x: 2, y: 0 };                  //top left position of video object in percent of screen width and screen height

    open_spendenformular_mobil() {
        let unterstuetzen_html = <HTMLElement>document.querySelector("#unterstuetzen_html");
        let unterstuetzentext_html = <HTMLElement>document.querySelector("#unterstuetzentext_html");
        let spendenformular = <HTMLElement>document.querySelector("#spendenformular");
        if (unterstuetzen_html) {
            unterstuetzen_html.style.display = "block";
            unterstuetzen_html.style.visibility = "visible";
        }
        if (unterstuetzentext_html) {
            unterstuetzentext_html.style.display = "none";
            unterstuetzentext_html.style.visibility = "hidden";
        }
        if (spendenformular) {
            spendenformular.style.display = "block";
            spendenformular.style.visibility = "visible";
        }
    }

    onMouseDown() {
        this.clickCount++;
        this.executeAction(1);
    }
    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Catch All",             elements: 3,    probability: 0,     krassitaet: 1, allowedInEnvironments: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
    ];

    combiGroups = [];

    clickActions = [

        // Action 0-1 - Init Action
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement,
                    targetObjectSelector: "#textfenster_unterstuetzen"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_01_textdiv"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "textfenster_cssanim_04_textdiv"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 150,
                    followAction: {group: 0, action: 3}
                }                
            ],
            allowHover: false,
            allowClick: true
        },
        
        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement,
                    targetObjectSelector: "#textfenster_unterstuetzen"
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ]

}