import * as CSM from "../../../ClickStateManager.js";

export default class Bombe extends CSM.ClickStateManager {

    videoSize = { x: 1200, y: 1200 };               // width and height of the original video material
    clickAreaPosition = { x: 780, y: 780 };         // position of Object in original Video in px
    clickAreaSize = { x: 120, y: 120 };             // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Circle;       //Form of the clickable Area
    mediaPfad = "/spielelemente/bombe"


    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

        if (this.clickCount == 1) {this.executeAction(this.findActionNumber(1, 2)); return;}

        this.lokalerEskalationswert = 0.5;
        
        this.findPossibleNextActions();

        // Sonderregeln

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "Init und Exit",             elements: 3,    probability: 0,     krassitaet: 1},
        {name: "Catch All",                 elements: 6,    probability: 0.7,   krassitaet: 1}
    ];

    combiGroups = [
//        {name: "Normalgröße",               elements: 1,    probability: 0.3,   krassitaet: 1,  addableToGroups: [1, 2, 3],         incompatibleCombiGroups: [1, 2]},
    ];

    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_05_Bombe",
                    startTime:  0 + (18 * 33.33),
                    endTime:    4000 + (21 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_02_02_clickArea"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_04_relativePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_04_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2 (Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.HideElement
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3 (Init 2)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_05_Bombe",
                    startTime:  0 + (18 * 33.33),
                    endTime:    4000 + (21 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_01_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_02_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_05_Bombe",
                    startTime:  3000 + (21 * 33.33),
                    endTime:    2000 + (25 * 33.33),
                    playBackRate: -1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_02_01_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_03_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_03_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_03_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_03_01_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_03_01_saturate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_03_01_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_03_01_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "Vase_05_Bombe",
                    startTime:  0 + (18 * 33.33),
                    endTime:    2000 + (10 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_05_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "bombe_cssanim_05_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1700,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        },


    ]

    combiClickActions: Array<CSM.ClickAction> = [];

    
    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);



}