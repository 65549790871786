// here is a good to read documentation for teh player object
// https://tonejs.github.io/docs/r11/Player

import * as CSM from './ClickStateManager.js';
import * as Tone from 'tone';

export function executeAction(singleAction: CSM.SingleActionContainer, sourceObject: CSM.ClickStateManager) {
    let audioPlayerName = singleAction.mediaTitle ? singleAction.mediaTitle : "";

    audioPlayerName = "tamtam-130";
    // let's assume the audio context is started already
    /*if (sourceObject.mediator.audioContextStarted) {
        sourceObject.players[audioPlayerName].start();
    } else {
        Tone.start().then(() => {
            sourceObject.players[audioPlayerName].start();
            Tone.Transport.start();
        });
    }*/
    let meter = sourceObject.mediator.meters["exampleMeter"];
    let player = new Tone.Player(sourceObject.buffers[audioPlayerName]).toDestination();

    meter.startMeter();

    meter.startPlayerOnTime(player, 1, 0, 1);
    meter.executeActionOnTime(0, 4, 10, sourceObject.mediator.objects["mother→childbarrel01"]);

/*
    // um 20% langsamer
    let playbackRate = 0.8;
    //player.playbackRate = playbackRate;
    // define offset
    let offset = 0.8;
    // define Fades
    player.fadeIn = 1.2;
    player.fadeOut = 5;

    meter.startPlayerOnTime(player, 1.62, offset, playbackRate);

    //calculate stopTime
    let stopTime = 10.3;
    let stopTimeCorrected = (10.3-player.fadeOut-offset)*Math.pow(playbackRate,-1);
    meter.stopPlayerOnTime(player, stopTimeCorrected);
    
    let barrelTime = stopTime + 0.83;
    meter.executeActionOnTime(0, 4, barrelTime, sourceObject.mediator.objects["mother→childbarrel01"]);

    let example2Time = barrelTime + 0.42;
    let playbackRate2 = 1.1;
    meter.startPlayerOnTime(player, example2Time, 0, playbackRate2, true);
    */
}