import * as Tone from 'tone';
import steelDrumData from './steelDrumData.js';
import Mediator from "./Mediator.js";

export default class Steeldrum {

    mediator: Mediator;

    private steelSounds: { [key: string]: {filenumber: string, transposition: number, volume: number} };

    private isDragging: boolean = false;
    private steeldrum: HTMLObjectElement | null = null;
    private currentlyPlaying: string | null = null;

    constructor(mediator: Mediator) {
        // Initialize the Tone.Players with all sound files
        this.steelSounds = steelDrumData;
        this.mediator = mediator;
        /*const urls: { [id: string]: string } = {};
        for (const [key, fileInfo] of Object.entries(this.steelSounds)) {
            let filename = "media/steeldrum/steeldrum_" + fileInfo.filenumber + ".ogg";
            if (this.mediator.mediaList[filename].blobURL) {}
            urls[key] = new URL(`media/steeldrum/steeldrum_${fileInfo.filenumber}.ogg`, window.location.href).toString();
        }
        this.buffers = new Tone.Buffers(urls);*/

        this.init();
    }

    private init(): void {
        this.steeldrum = document.querySelector<HTMLObjectElement>("#steeldrum");
        if (!this.steeldrum) {
            console.error("Steeldrum element not found");
            return;
        }


        Object.keys(this.steelSounds).forEach(id => {
            const clickArea = this.steeldrum?.contentDocument?.getElementById(id);
            if (clickArea) {
                clickArea.addEventListener('mousedown', this.handleMouseDown.bind(this, id));
            } else {
                console.warn(`Click area with id ${id} not found`);
            }
        });

        this.steeldrum?.contentDocument?.addEventListener('mousemove', this.handleMouseMove.bind(this));
        this.steeldrum?.contentDocument?.addEventListener('mouseup', this.handleMouseUp.bind(this));

    }

    private handleMouseDown(id: string, event: MouseEvent): void {
        if (this.mediator.returnBrowser()=="Safari" || this.mediator.clientIsMobile) {return;}
        this.isDragging = true;
        this.playSound(id);
    }

    private handleMouseMove(event: MouseEvent): void {
        if (this.mediator.returnBrowser()=="Safari" || this.mediator.clientIsMobile) {return;}
        if (!this.isDragging) return;
        if (!this.steeldrum) return;

        Object.keys(this.steelSounds).forEach(id => {
            const clickArea = this.steeldrum?.contentDocument?.getElementById(id);
            if (clickArea && clickArea.contains(event.target as Node) && this.currentlyPlaying !== id) {
                this.playSound(id);
            }
        });
    }

    private handleMouseUp(): void {
        if (this.mediator.returnBrowser()=="Safari" || this.mediator.clientIsMobile) {return;}
        this.isDragging = false;
        this.currentlyPlaying = null; // Reset on mouse up
    }

    private playSound(id: string): void {
        if (this.currentlyPlaying !== id) {
            //console.log(`Playing Steeldrum Area: ${id}`);
            let soundfilenumber = this.steelSounds[id].filenumber;
            let buffername = "media/steeldrum/steeldrum_" + soundfilenumber + ".ogg";
            //console.log(buffername);
            let buffer = this.mediator.mediaList[buffername];
            //console.log(buffer);
            if (buffer) {
                if (buffer.blobURL) {
                    let player = new Tone.Player(this.mediator.buffers[buffername]).toDestination();
                    player.volume.value = -18;
                    player.start();
                    player.onstop = () => {
                        player.dispose();
                    }
                    this.currentlyPlaying = id;
                }
            }
        }
    }
}