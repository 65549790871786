import * as CSM from "../../../ClickStateManager.js";

export default class MusikerSpielelement_WillOvercash extends CSM.ClickStateManager {

    videoSize = { x: 4096, y: 3112 };               // width and height of the original video material
    clickAreaPosition = { x: 1500, y: 800 };        // position of Object in original Video in px
    clickAreaSize = { x: 1400, y: 1400 };           // Size of the clickable Form in the original video in px, radius for ellipses
    clickAreaForm = CSM.ClickAreaForm.Circle;   //Form of the clickable Area
    mediaPfad = "/spielelemente/musiker";


    /////////////////////////////////////////////////////////
    //////////////// Probability Calculation ////////////////
    /////////////////////////////////////////////////////////

    onMouseDown() {
        if (!this.mediator.allowClicking) {return;}
        this.clickCount++;
        this.mediator.updateInfoElement({name: this.name, clickCount: this.clickCount});
        let audioInfoElement = this.bySelector("#audioInfoElement");
        if (audioInfoElement) {
            audioInfoElement.innerHTML = "";
        }

/*        if (this.clickCount >= 1) {
            this.executeAction(this.findActionNumber(3, 1));
            this.findBestAudioForActionAndCombiActions(this.findActionNumber(3, 1), []);
            return;}
        if (this.clickCount == 2) {this.executeAction(this.findActionNumber(1, 4)); return;}
        if (this.clickCount == 3) {this.executeAction(this.findActionNumber(1, 5)); return;}
        if (this.clickCount == 4) {this.executeAction(this.findActionNumber(1, 1)); return;}
        if (this.clickCount == 5) {this.executeAction(this.findActionNumber(1, 2)); return;}
        if (this.clickCount == 6) {this.executeAction(this.findActionNumber(1, 8)); return;}
        if (this.clickCount == 7) {this.executeAction(this.findActionNumber(6, 15)); return;}
        if (this.clickCount == 8) {this.executeAction(this.findActionNumber(6, 15)); return;}
        return;*/

        this.lokalerEskalationswert = 0.15 + this.clickCount / 30;
        if (this.lokalerEskalationswert > 1) {this.lokalerEskalationswert = 1;}
        
        this.findPossibleNextActions();

        // Sonderregeln

        //console.log("Possible Next Actions: " + this.possibleNextActions);
        this.executeActionByProbabilityCalculation();

        this.findPossibleNextCombiGroups();
        //console.log("Possible Next CombiGroups: " + this.possibleNextCombiGroups);
        this.executeCombiGroupsByProbabilityCalculation();

        this.mediator.regie();
    }

    ///////////////////////////////////////////////////
    //////////////// Action Definition ////////////////
    ///////////////////////////////////////////////////

    actionGroups = [
        // Name (nur für Übersichtlichkeit), Anzahl der Aktionen, Gruppenwahrscheinlichkeit, Krassitätswert (1-5)
        {name: "0 Init und Exit",                 elements: 3,    probability: 0,     krassitaet: 1,    allowedInEnvironments: [2, 14, 15, 16, 17, 18, 19, 20] },
        {name: "1 Geige ansetzen",                elements: 3,    probability: 1,   krassitaet: 2,    allowedInEnvironments: [2, 14, 15, 16, 17, 18, 19, 20] },
        {name: "2 Umherschweifen",                elements: 6,    probability: 0.7,   krassitaet: 2,    allowedInEnvironments: [2, 14, 15, 16, 17, 18, 19, 20] },
        {name: "3 Drehungen",                     elements: 4,    probability: 0.4,   krassitaet: 3,    allowedInEnvironments: [2, 14, 15, 16, 17, 18, 19, 20] },
        {name: "4 Düsenjäger",                    elements: 6,    probability: 0.4,   krassitaet: 2,    allowedInEnvironments: [2, 14, 15, 16, 17, 18, 19, 20] },
        {name: "5 Geige spielen",                 elements: 6,    probability: 1,   krassitaet: 2,    allowedInEnvironments: [15, 16, 17, 18, 19, 20] },
    ];

    combiGroups = [
        {name: "0 Verformungen",               elements: 6,    probability: 0.6,   krassitaet: 3,  addableToGroups: [1, 2, 3, 4],       incompatibleCombiGroups: [] },
        {name: "1 Größenveränderungen",        elements: 4,    probability: 0.6,   krassitaet: 3,  addableToGroups: [1, 2, 3, 4],    incompatibleCombiGroups: [] },
    ];


    normalClickActions: Array<CSM.ClickAction> = [

        /////////////////////////////////////////// *** GRUPPE 0 *** ///////////////////////////////////////////
        // Action 0-1 (Init)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ShowElement
                },
                {
                    actionType: CSM.ActionType.ShowImage,
                    mediaTitle: "WillOvercashVeitstanz_01_REV_edited"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_01_absolutePositionY"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_01_brightness"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_01_01_rotate"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_07_01_clickArea"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 11000,
                    followAction: {group: 0, action: 3}
                }
            ],
            allowHover: false,
            allowClick: true,
            checkPositionAtEnd: false
        },

        // Action 0-2 (no Exit)
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_01_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_05_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 1 *** ///////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "WillOvercashVeitstanz_01",
                    startTime: -1,
                    endTime: 17000 + (10 * 33.33),
                    playBackRate: -1
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["WillOvercashVeitstanz_01_REV_edited"],
            impossiblePrevActions: [
                {group: 0, actions: [1, 3]},
                {group: 2, actions: [1, 2, 3, 4, 5]}
            ]
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "WillOvercashVeitstanz_01",
                    startTime: 17000 + (10 * 33.33),
                    endTime: 14000 + (6 * 33.33),
                    playBackRate: -1,
                    followAction: {group: 1, action: 3}
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_07_02_clickArea"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["WillOvercashVeitstanz_01_frame0433"]
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_10_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_10_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },


/*        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.StartMeter,
                    mediaTitle: "WillOvercashMeter"
                }
            ],
            allowHover: false,
            allowClick: true
        }
*/

        /////////////////////////////////////////// *** GRUPPE 2 *** ///////////////////////////////////////////
        // Action 2-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_02_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_05_01_rotate"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 2, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [1]}
            ]
        },

        // Action 2-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_05_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 2-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_05_01_rotate"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 2, action: 4}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 0, actions: [3]},
                {group: 2, actions: [1, 2]}
            ]
        },

        // Action 2-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_05_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        // Action 2-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_05_01_rotate"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 1000,
                    followAction: {group: 2, action: 6}
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 2, actions: [3, 4]}
            ]
        },

        // Action 2-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_05_02_rotate"
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: []
        },

        /////////////////////////////////////////// *** GRUPPE 3 *** ///////////////////////////////////////////
        // Action 3-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_08_01_brightness"     // Eigentlich rotate, nur ausnwahmsweise im falschen DIV
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2, 3]},
                {group: 3, actions: [1, 2, 3, 4]},
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 5, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 3-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_08_02_brightness"     // Eigentlich rotate, nur ausnwahmsweise im falschen DIV
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2, 3]},
                {group: 3, actions: [1, 2, 3, 4]},
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 5, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 3-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_08_03_brightness"     // Eigentlich rotate, nur ausnwahmsweise im falschen DIV
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2, 3]},
                {group: 3, actions: [1, 2, 3, 4]},
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 5, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 3-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_08_04_brightness"     // Eigentlich rotate, nur ausnwahmsweise im falschen DIV
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevActions: [
                {group: 1, actions: [2, 3]},
                {group: 3, actions: [1, 2, 3, 4]},
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
                {group: 5, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 4 *** ///////////////////////////////////////////
        // Action 4-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 20, y: -9999},
            possiblePrevActions: [
                {group: 0, actions: [2]},
                {group: 1, actions: [2, 3]},
                {group: 3, actions: [1, 2, 3, 4]},
                {group: 4, actions: [2, 3, 4, 5, 6]},
                {group: 5, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 4-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionX"      // Absichtlich 02_01, weil 02_02 draußen im Nirvana wäre
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_01_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 40, y: 60},
            possiblePrevActions: [
                {group: 0, actions: [2]},
                {group: 1, actions: [2, 3]},
                {group: 3, actions: [1, 2, 3, 4]},
                {group: 4, actions: [1, 3, 4, 5, 6]},
                {group: 5, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 4-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: -10, y: 70},
            possiblePrevActions: [
                {group: 0, actions: [2]},
                {group: 1, actions: [2, 3]},
                {group: 3, actions: [1, 2, 3, 4]},
                {group: 4, actions: [1, 2, 4, 5, 6]},
                {group: 5, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 4-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_04_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 70, y: 20},
            possiblePrevActions: [
                {group: 0, actions: [2]},
                {group: 1, actions: [2, 3]},
                {group: 3, actions: [1, 2, 3, 4]},
                {group: 4, actions: [1, 2, 3, 5, 6]},
                {group: 5, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 4-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_05_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: 30, y: 55},
            possiblePrevActions: [
                {group: 0, actions: [2]},
                {group: 1, actions: [2, 3]},
                {group: 3, actions: [1, 2, 3, 4]},
                {group: 4, actions: [1, 2, 3, 4, 6]},
                {group: 5, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        // Action 4-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "wulst_cssanim_02_06_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true,
            forbidIfPositionIs: {x: -9999, y: -20},
            possiblePrevActions: [
                {group: 0, actions: [2]},
                {group: 1, actions: [2, 3]},
                {group: 3, actions: [1, 2, 3, 4]},
                {group: 4, actions: [1, 2, 3, 4, 5]},
                {group: 5, actions: [1, 2, 3, 4, 5, 6]}
            ]
        },

        /////////////////////////////////////////// *** GRUPPE 5 *** ///////////////////////////////////////////
        // Action 5-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "WillOvercashVeitstanz_01",
                    startTime: 14000 + (9 * 33.33), // war 6
                    endTime: 9000 + (23 * 33.33),
                    playBackRate: -2
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_06a",
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["WillOvercashVeitstanz_01_frame0355"]
        },

        // Action 5-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "WillOvercashVeitstanz_01",
                    startTime: 9000 + (23 * 33.33),
                    endTime: 12000 + (23 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_00a",
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["WillOvercashVeitstanz_01_frame0244"]
        },

        // Action 5-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "WillOvercashVeitstanz_01",
                    startTime: 14000 + (9 * 33.33), // war 6
                    endTime: 11000 + (23 * 33.33),
                    playBackRate: -1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_06a",
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["WillOvercashVeitstanz_01_frame0355"]
        },

        // Action 5-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "WillOvercashVeitstanz_01",
                    startTime: 11000 + (23 * 33.33),
                    endTime: 14000 + (9 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_00a",
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["WillOvercashVeitstanz_01_frame0294"]
        },

        // Action 5-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "WillOvercashVeitstanz_01",
                    startTime: 13000 + (17 * 33.33), // war 6
                    endTime: 11000 + (23 * 33.33),
                    playBackRate: -1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_06a",
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["WillOvercashVeitstanz_01_frame0339"]
        },

        // Action 5-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.VideoPlayback,
                    mediaTitle: "WillOvercashVeitstanz_01",
                    startTime: 11000 + (23 * 33.33),
                    endTime: 13000 + (17 * 33.33),
                    playBackRate: 1
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_00a",
                }
            ],
            allowHover: false,
            allowClick: true,
            possiblePrevImages: ["WillOvercashVeitstanz_01_frame0294"]
        },        



    ];

    combiClickActions = [
        ///////////////////////////////////////// *** COMBIGROUP 0 *** /////////////////////////////////////////
        // Action 0-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_01_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_09_01_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_02_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_09_02_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_03_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_09_03_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_04_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_09_04_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-5
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_05_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_09_05_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 0-6
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_04_06_rotate3d"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "willovercash_cssanim_09_06_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        ///////////////////////////////////////// *** COMBIGROUP 1 *** /////////////////////////////////////////
        // Action 1-1
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_03_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-2
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_04_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-3
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_05_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        // Action 1-4
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "vase_cssanim_08_06_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        }

    ];

    finalAction:  Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absolutePositionX"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_absoluteScale"
                },
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_22_01_opacity"
                },
                {
                    actionType: CSM.ActionType.Wait,
                    endTime: 2000,
                    followAction: {group: 0, action: 2}
                }
            ],
            allowHover: false,
            allowClick: true
        }
    ];

    resetActions: Array<CSM.ClickAction> = [
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativeScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_relativePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absoluteScale"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionX"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_absolutePositionY"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20a"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_opacity"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_rotate3d"
                }
            ],
            allowHover: false,
            allowClick: true
        },
        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_skew"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_perspective"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_brightness"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_contrast"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_blur"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_saturate"
                }
            ],
            allowHover: false,
            allowClick: true
        },

        {
            singleActions: [
                {
                    actionType: CSM.ActionType.ChangeAnimation,
                    newClass: "tamtam_cssanim_20_hue-rotate"
                }
            ],
            allowHover: false,
            allowClick: true
        },
    ];

    clickActions = this.normalClickActions.concat(this.combiClickActions).concat(this.finalAction).concat(this.resetActions);


    audioActions: Array<CSM.AudioAction> = [
        // 1 Längere Stellen
        {
            possibleClickActions: [
                {group: 5, actions: [1, 2, 3, 4]},
            ],
            alternativeSounds: [
                {
                    sample: "veitstanz9",
                    midiRange: [50, 55],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "spring8",
                    midiRange: [50, 55],
                    dynamicRange: [1, 1],
                },
            ],
            allowedInEnvironments: [15, 16, 17, 18, 19, 20]
        },   

        // 2 Leise Streicher
        /*{
            possibleClickActions: [
                {group: 1, actions: [2]},
            ],
            alternativeSounds: [
                {
                    sample: "spring_streicher_1",
                    midiRange: [50, 60],
                    dynamicRange: [1,1],
                    fadeIn: 4000,
                    //endTime: 8000,
                    fadeOut: 4000
                }
            ]
        },*/ 

        // 3 Kürzere Stellen
        {
            possibleClickActions: [
                {group: 5, actions: [5, 6]},
            ],
            alternativeSounds: [
                {
                    sample: "spring_streicher_2",
                    midiRange: [55, 60],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "spring_streicher_3",
                    midiRange: [55, 60],
                    dynamicRange: [1, 1],
                },
                {
                    sample: "spring_streicher_4",
                    midiRange: [55, 60],
                    dynamicRange: [3, 3],
                    startTime: 1000,
                    endTime: 2700,
                    fadeOut: 200
                }
            ],
            allowedInEnvironments: [15, 16, 17, 18, 19, 20]
        },   

        // 4 Flutschen in späteren Environments
        {
            possibleClickActions: [
                {group: 3, actions: [1, 2, 3, 4]},
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
            ],
            alternativeSounds: [
                {
                    sample: "veitstanz9",
                    midiRange: [50, 60],
                    dynamicRange: [0.5, 0.5],
                    startTime: 630,
                    fadeIn: 200,
                    endTime: 1050,
                    fadeOut: 100
                },
                {
                    sample: "spring_streicher_5",
                    midiRange: [50, 60],
                    dynamicRange: [0.6, 0.6],
                    fadeIn: 200
                },
                {
                    sample: "spring_streicher_6",
                    midiRange: [58, 68],
                    dynamicRange: [0.7, 0.7]
                },
                {
                    sample: "spring_streicher_7",
                    midiRange: [55, 65],
                    dynamicRange: [0.8, 0.8],
                    startTime: 300,
                    fadeIn: 100
                }
            ],
            allowedInEnvironments: [15, 16, 17, 18, 19, 20]
        },           

        // 5 Flutschen in früheren Environments
        {
            possibleClickActions: [
                {group: 1, actions: [1, 2]},
                {group: 3, actions: [1, 2, 3, 4]},
                {group: 4, actions: [1, 2, 3, 4, 5, 6]},
            ],
            alternativeSounds: [
                {
                    sample: "pizzicato01",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4]
                },
                {
                    sample: "pizzicato02",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4]
                },
                {
                    sample: "pizzicato03",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4]
                },
                {
                    sample: "pizzicato04",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4]
                },
                {
                    sample: "pizzicato05",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4]
                },
                {
                    sample: "pizzicato06",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4]
                },
                {
                    sample: "pizzicato07",
                    midiRange: [55, 65],
                    dynamicRange: [0.3, 0.3]
                },
                {
                    sample: "pizzicato08",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4]
                },
                {
                    sample: "pizzicato09",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4]
                },
                {
                    sample: "pizzicato10",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4]
                },
                {
                    sample: "pizzicato11",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4]
                },
                {
                    sample: "pizzicato12",
                    midiRange: [55, 65],
                    dynamicRange: [0.4, 0.4]
                },

            ],
            allowedInEnvironments: [2, 14]
        },
    ]

    audioHierarchy = [
        {
            description: "Catch All",
            actionGroups: [1, 2, 3, 4, 5],
            combiGroups: [0, 1]                        // Bei mehreren Combigroups haben die früher genannten Priorität
        }
    ]



}