import { SwipeEvents, SwipeEventListeners } from './SwipeEvents.js';
import Mediator from "./Mediator.js";
import TextDivGalerie from "./objects/Infoelemente/Textfenster/TextDivGalerie.js";

const element = document.body;
const listeners: SwipeEventListeners = {
    swipeLeft: (event: TouchEvent, mediator: Mediator) => {
        let activeObject = <TextDivGalerie>mediator.activeObject; 
        let correctClass = Object.getPrototypeOf(Object.getPrototypeOf(activeObject)).constructor == TextDivGalerie;    
        if (correctClass) {
            activeObject.bildergalerie_forward();
        }
    },
    swipeRight: (event: TouchEvent, mediator: Mediator) => {
        //alert('Swiped Right!');
        let activeObject = <TextDivGalerie>mediator.activeObject; 
        let correctClass = Object.getPrototypeOf(Object.getPrototypeOf(activeObject)).constructor == TextDivGalerie;    
        if (correctClass) {
            activeObject.bildergalerie_back();
        }
    }
};

export default function activateSwipes(mediator: Mediator) {
    if (element) {
        new SwipeEvents(element, listeners, mediator);
    }
}