import { GroupActionContainer } from "./ClickStateManager.js";
import { TextDiv } from "./TextDiv.js";

export default class musician_info extends TextDiv {
    public onActivation(targetAction: GroupActionContainer): void {
        super.onActivation(targetAction);
        let oldMusicianString = this.mediator.activeMusician;
        if(oldMusicianString !== "" && oldMusicianString !== this.name){
            this.mediator.objects[oldMusicianString].executeAction(1);
        }
        this.mediator.activeMusician = this.name;
    }
}